import { isEqual } from 'lodash'
import { useSelector } from 'react-redux'
import { getCurrentFarmSlug } from '#/store/ducks/farm'
import { getCurrentCompanySlug } from '#/store/ducks/company'
import { isBusinessEnvironment } from '#/utils'

export default function useAppContext() {
  const currentFarmSlug = useSelector(getCurrentFarmSlug, isEqual)
  const currentCompanySlug = useSelector(getCurrentCompanySlug, isEqual)

  if (currentFarmSlug && !isBusinessEnvironment()) {
    return 'farm'
  }
  if (currentCompanySlug) {
    return 'company'
  }
  return 'system'
}
