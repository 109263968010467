import React from 'react'
import { FindMyCowHeader } from './Header'
import { FindMyCowTitle } from './Title'
import { FindMyCowSubtitle } from './Subtitle'
import { FindMyCowGeneral } from './General'
import { FindMyCowWrapper } from './FindMyCow'
import { FindMyCowMap } from './Map'
import { FindMyCowMessage } from '#/components/FindMyCow/Types/Message'

export function FindMyCowElements(props) {
  const types = {
    'header': <FindMyCowHeader {...props} />,
    'title': <FindMyCowTitle {...props} />,
    'subtitle': <FindMyCowSubtitle {...props} />,
    'general': <FindMyCowGeneral {...props} />,
    'find-my-cow': <FindMyCowWrapper {...props} />,
    'map': <FindMyCowMap {...props} />,
    'message': <FindMyCowMessage {...props} />,
  }

  return <div key={props.id}>{types[props.id]}</div>
}
