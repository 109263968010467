/**
 * Subscribes a listener to a type of event
 * CAUTION: DO NOT FORGET TO *UNSUBSCRIBE*!
 *
 * @param {string} eventType - The name of the event
 * @param {function} handlerFunction - The listener function
 *
 * @returns {object} With the respective unsubscribe function
 *
 * @example
 * subscribe('alert-arrived', ({ id }) => refreshAlertById(id))
 * */
export function subscribe(eventType, handlerFunction) {
  if (typeof eventType === 'string' && typeof handlerFunction === 'function') {
    const listener = event => handlerFunction(event.detail)

    document.addEventListener(eventType, listener, { passive: true })

    return {
      unsubscribe: () => document.removeEventListener(eventType, listener),
    }
  }
}

/**
 * Publish a type of event ocurrence
 *
 * @param {string} eventType - The name of the event
 * @param {object} args - An object with arguments
 *
 * @returns {undefined} Nothing
 *
 * @example
 * publish('alert-arrived', { id: 268 })
 * */
export function publish(eventType, args) {
  if (typeof eventType === 'string') {
    const nativeEvent = new CustomEvent(eventType, { detail: args })
    document.dispatchEvent(nativeEvent)
  }
}

const PubSub = { subscribe, publish }

export default PubSub
