import React from 'react'
import Icon from '@mdi/react'
import { mdiCheck, mdiClose, mdiMinus } from '@mdi/js'
import { Tooltip } from '@mui/material'
import { uniqueId } from 'lodash'
import { styled } from '@mui/material/styles'
import Colors from '#/styles/Colors'

const StyledIcon = styled(Icon)({
  width: '1.5rem',
  height: '1.5rem',
  margin: '0 2px',
})

export default function TypePregnancyDiagnostics({ value }) {
  const statusIcon = {
    'reproduction.negative_pregnancy': (
      <StyledIcon path={mdiClose} color={Colors.feedback.danger.color} />
    ),
    'reproduction.pregnant': (
      <StyledIcon path={mdiCheck} color={Colors.feedback.success.color} />
    ),
    'reproduction.inconclusive_pregnancy': <StyledIcon path={mdiMinus} />,
  }
  return (
    <div>
      {value?.map(val => (
        <Tooltip title={val.text} key={uniqueId(val.text)}>
          {statusIcon[val.status]}
        </Tooltip>
      ))}
    </div>
  )
}
