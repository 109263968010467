import React from 'react'
import { Link } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import { isEqual } from 'lodash'
import { getCurrentFarmSlug } from '#/store/ducks/farm'
import Translate from '#/components/Translate'
import Moment from '#/components/Moment'
import { getLink } from '#/utils/getLink'

const linkToLink = ({ value, style, linkTo, ...rest }) => {
  let link = rest.href
  let { title } = rest

  const farm =
    rest.farmSlug ?? getCurrentFarmSlug(window.globalStore.getState(), isEqual)

  switch (linkTo) {
    case 'animal':
      link = getLink({ type: linkTo, farm, slug: rest.slug, tab: rest.tab })

      title = Translate({
        messageKey: 'access_item',
        params: { item: Translate({ messageKey: 'animal', lower: true }) },
      })
      break

    case 'farm-manage':
    case 'farm':
      link = getLink({ type: linkTo, farm, tab: rest.tab })
      title = Translate({
        messageKey: 'access_item',
        params: { item: Translate({ messageKey: 'farm', lower: true }) },
      })
      break

    case 'farm-op-status':
      link = getLink({ type: linkTo, farm: rest.slug, tab: rest.tab })
      title = Translate({
        messageKey: 'access_item',
        params: {
          item: Translate({ messageKey: 'operational_status', lower: true }),
        },
      })
      break

    case 'farm-ccom-op-status':
      link = getLink({ type: linkTo, farm, tab: rest.tab })
      title = Translate({
        messageKey: 'access_item',
        params: {
          item: Translate({
            messageKey: 'c_com',
            params: { item: `: ${rest.farmName}` },
          }),
        },
      })
      break

    case 'company-manage':
      link = getLink({ type: linkTo, company: rest.companySlug, tab: rest.tab })
      title = Translate({
        messageKey: 'access_item',
        params: { item: Translate({ messageKey: 'company', lower: true }) },
      })
      break

    case 'batch':
      link = getLink({ type: linkTo, farm, slug: rest.slug, tab: rest.tab })
      title = Translate({
        messageKey: 'access_item',
        params: {
          item: Translate({ messageKey: 'actual_batch', lower: true }),
        },
      })
      break

    case 'farm-ccom-fails-report':
      link = getLink({ type: linkTo, farm: rest.slug, tab: rest.tab })
      title = Translate({
        messageKey: 'access_item',
        params: {
          item: Translate({ messageKey: 'failure_report', lower: true }),
        },
      })
      break

    case 'ccom-status':
      link = getLink({ type: linkTo, id: value, tab: rest.tab })
      title = Translate({
        messageKey: 'access_item',
        params: {
          item: Translate({ messageKey: 'c_com_details', lower: true }),
        },
      })
      break

    case 'protocol-detail':
      link = getLink({ type: linkTo, farm, slug: rest.slug, tab: rest.tab })
      title = Translate({
        messageKey: 'access_item',
        params: { item: Translate({ messageKey: 'protocol', lower: true }) },
      })
      break

    case 'interval-detail':
      link = getLink({ type: linkTo, farm, tab: rest.tab })
      title = Translate({
        messageKey: 'access_item',
        params: {
          item: Translate({ messageKey: 'data_interval', lower: true }),
        },
      })
      break

    case 'milk-production':
      link = getLink({ type: linkTo, farm, slug: rest.slug, tab: rest.tab })
      title = Translate({
        messageKey: 'access_item',
        params: {
          item: Translate({ messageKey: 'production_details', lower: true }),
        },
      })
      value = Moment(value).format('L')
      break

    case 'semen':
      link = getLink({ type: linkTo, farm, id: rest.id, tab: rest.tab })
      title = Translate({
        messageKey: 'access_item',
        params: {
          item: Translate({ messageKey: 'storage', lower: true }),
        },
      })
      break

    case 'device-point':
      link = getLink({ type: linkTo, farm, id: rest.id, tab: rest.tab })
      title = Translate({
        messageKey: 'access_item',
        params: {
          item: Translate({ messageKey: 'environmental_comfort', lower: true }),
        },
      })
      break

    case 'campaing-detail':
      link = getLink({ type: linkTo, id: rest.id, tab: rest.tab })
      title = Translate({
        messageKey: 'access_item',
        params: {
          item: Translate({ messageKey: 'campaign', lower: true }),
        },
      })
      break

    case 'c-tech-break':
      link = getLink({ ...rest, type: linkTo })
      title = Translate({
        messageKey: 'access_item',
        params: {
          item: Translate({ messageKey: 'farm_distribution', lower: true }),
        },
      })
      break

    default: //nothing to moo but be aware
  }

  return (
    <div className={rest.classes.overMe} style={{ fontWeight: 700 }}>
      <Tooltip title={title} aria-label={value}>
        <Link to={link} style={style}>
          {value}
        </Link>
      </Tooltip>
    </div>
  )
}
export default linkToLink
