import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { endpoint } from '#/store/services/endpoints'
import { fetcher } from '#/store/hooks/request'

const defaultState = {
  current: null,
  filters: null,
}

export const loadCurrentThi = createAsyncThunk(
  'farm/loadCurrentThi',
  async (payload, thunkAPI) => {
    const { farmSlug, point } = payload

    try {
      const controller = endpoint.farm.cow_comfort.thi_resource({
        farm: farmSlug,
        point,
      })
      const { data } = await fetcher({ controller })

      return { data }
    } catch ({ message, code }) {
      return thunkAPI.rejectWithValue({ data: {}, error: { message, code } })
    }
  },
)

export const loadListPoints = createAsyncThunk(
  'farm/loadListPoints',
  async (payload, thunkAPI) => {
    const { farmSlug } = payload

    try {
      const controller = endpoint.farm.cow_comfort.point.list_filter({
        farm: farmSlug,
      })

      const {
        data: { results },
      } = await fetcher({ controller, params: { size: 100 } })
      return { data: results }
    } catch ({ message, code }) {
      return thunkAPI.rejectWithValue({ data: [], error: { message, code } })
    }
  },
)

export const thiSlice = createSlice({
  name: 'thi',
  initialState: defaultState,
  reducers: {
    resetCurrentThi(state) {
      state.current = defaultState
    },
  },
  extraReducers: builder => {
    builder.addCase(loadCurrentThi.rejected, (state, action) => {
      state.current = action.payload
    })
    builder.addCase(loadCurrentThi.fulfilled, (state, action) => {
      state.current = action.payload
    })
    builder.addCase(loadListPoints.rejected, (state, action) => {
      state.filters = action.payload
    })
    builder.addCase(loadListPoints.fulfilled, (state, action) => {
      state.filters = action.payload
    })
  },
})

export const { resetCurrentThi } = thiSlice.actions

// State Selectors
export const getCurrentThi = state => state?.thi?.current ?? {}
export const getCurrentThiPointName = state => state?.thi?.current?.data?.name
export const getListPoints = state => state?.thi?.filters ?? []

export default thiSlice.reducer
