import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export default function CowComfortIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 0 21 25"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M5.26063 4.55846L6.72859 8.70969H13.6563L15.0738 4.55846H5.26063ZM3.58712 4.32518C3.36852 3.707 3.82707 3.05846 4.48277 3.05846H15.8428C16.4942 3.05846 16.9523 3.69905 16.7418 4.31545L14.9487 9.56668C14.8174 9.95124 14.4561 10.2097 14.0497 10.2097H6.3397C5.93713 10.2097 5.57826 9.95595 5.44405 9.57641L3.58712 4.32518Z" />
      <path d="M15.0488 15.0315C15.2674 15.2413 15.3707 15.4918 15.3585 15.783C15.3464 16.0743 15.2249 16.3015 14.9941 16.4646C14.3261 16.954 13.5822 17.3356 12.7623 17.6094C11.9424 17.8832 11.077 18.0201 10.1661 18.0201C9.24296 18.0201 8.37148 17.8803 7.55162 17.6007C6.73176 17.321 5.98781 16.9307 5.31977 16.4297C5.089 16.2666 4.97057 16.0423 4.9645 15.7568C4.95843 15.4714 5.0647 15.2238 5.28333 15.0141C5.48982 14.816 5.74185 14.714 6.03943 14.7082C6.33701 14.7024 6.61333 14.781 6.8684 14.9441C7.3421 15.2587 7.85527 15.5005 8.40792 15.6694C8.96056 15.8384 9.54661 15.9229 10.1661 15.9229C10.7855 15.9229 11.3716 15.8355 11.9242 15.6607C12.4769 15.4859 12.984 15.2471 13.4455 14.9441C13.7127 14.7694 13.9951 14.6907 14.2927 14.7082C14.5903 14.7257 14.8423 14.8335 15.0488 15.0315ZM18.146 17.9851C18.3525 18.1832 18.4558 18.425 18.4558 18.7104C18.4558 18.9959 18.3465 19.2318 18.1278 19.4183C17.0468 20.2921 15.8322 20.9766 14.484 21.4718C13.1358 21.967 11.6965 22.2146 10.1661 22.2146C8.63566 22.2146 7.19634 21.967 5.84813 21.4718C4.49991 20.9766 3.2853 20.2921 2.2043 19.4183C1.98567 19.2318 1.87332 18.9988 1.86725 18.7192C1.86117 18.4395 1.96745 18.1949 2.18608 17.9851C2.39256 17.7871 2.6446 17.6822 2.94217 17.6706C3.23975 17.6589 3.51 17.7463 3.75293 17.9327C4.63959 18.6201 5.62646 19.1561 6.71354 19.5406C7.80061 19.9251 8.95145 20.1173 10.1661 20.1173C11.3807 20.1173 12.5315 19.9251 13.6186 19.5406C14.7057 19.1561 15.6925 18.6201 16.5792 17.9327C16.8221 17.7463 17.0924 17.6589 17.39 17.6706C17.6875 17.6822 17.9396 17.7871 18.146 17.9851ZM10.1661 11.0293C10.579 11.0293 10.9252 11.1633 11.2046 11.4313C11.4839 11.6992 11.6236 12.0313 11.6236 12.4275C11.6236 12.8236 11.4839 13.1557 11.2046 13.4236C10.9252 13.6916 10.579 13.8256 10.1661 13.8256C9.7531 13.8256 9.40693 13.6916 9.12757 13.4236C8.84821 13.1557 8.70853 12.8236 8.70853 12.4275C8.70853 12.0313 8.84821 11.6992 9.12757 11.4313C9.40693 11.1633 9.7531 11.0293 10.1661 11.0293Z" />
    </SvgIcon>
  )
}
