import React from 'react'
import { isEqual, isNil, uniqueId } from 'lodash'
import { useSelector } from 'react-redux'
import { Link, Tooltip } from '@mui/material'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import Translate from '#/components/Translate'
import { getCurrentFarmSlug } from '#/store/ducks/farm'
import Moment from '#/components/Moment'
import { HSLDarker } from '#/utils/colorManipulation'
import AsteriskIcon from '#/icons/Asterisk'
import Colors from '#/styles/Colors'
import { getLink } from '#/utils/getLink'

const styles = {
  insights: {
    warning: {
      'backgroundColor': Colors.feedback.warning.backgroundColor,
      'color': Colors.feedback.warning.color,
      '&:hover': {
        backgroundColor: HSLDarker(Colors.feedback.warning.backgroundColor, 15),
      },
    },
    default: {
      color: 'black',
      paddingRight: 1.5,
      paddingLeft: 1.5,
      fontSize: 14,
      minHeight: 'unset',
      height: '25px',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  asterisk: {
    fontSize: 12,
    marginLeft: '3px',
  },
}

/**
 * The function manipulates data based on the timestamp and returns an object with formatted text and a
 * boolean value indicating whether insight is allowed.
 * @param text - The data that needs to be manipulated.
 * @param timestamp - Timestamp that represents when the data was last updated.
 */
function manipulateData(text, timestamp) {
  const diffInHours = Moment.diff('now', timestamp, 'h')

  // 7 days
  if (diffInHours >= 168) {
    return {
      allowInsight: false,
      formattedText: (
        <div style={{ ...styles.container }}>
          <AsteriskIcon style={{ color: styles.color, ...styles.asterisk }} />
        </div>
      ),
    }
  }
  // 1 day
  if (diffInHours >= 24) {
    return {
      allowInsight: true,
      formattedText: (
        <div style={{ ...styles.container }}>
          <span style={{ lineHeight: '13px' }}>{text}</span>
          <AsteriskIcon style={{ color: styles.color, ...styles.asterisk }} />
        </div>
      ),
    }
  }
  return {
    allowInsight: true,
    formattedText: text,
  }
}

/**
 * Renders a button with an optional icon and tooltip that links to a
 * specified page using a dynamically generated URL
 * @param insight - Object with the type and message used when the data has a alteration indicator.
 * @param linkTo - String of the module name for the generetad URL.
 * @param slug - Unique identifier string of the page or resource.
 * @param tab - Specific tab string of the page that the link should navigate to.
 * @param filters - Object that contains filters to be applied to the target URL.
 * @param text - The text to display on the button.
 * @param tooltip - String that is used as the text for the tooltip
 * @param icon - String or a React component of the icon displayed on the button.
 * @param timestamp - Timestamp that represents when the data was last updated.
 * @returns A button with a link to a website.
 */
const TypeButtonLink = props => {
  const {
    insight,
    slug,
    linkTo,
    tab,
    filters,
    text,
    sx,
    tooltip,
    icon,
    timestamp,
  } = props

  const currentFarmSlug = useSelector(getCurrentFarmSlug, isEqual)

  const targetUrl = getLink({
    type: linkTo,
    farm: currentFarmSlug,
    slug,
    tab,
    query: filters,
  })

  const { allowInsight, formattedText } = manipulateData(text, timestamp)

  let buttonIcon = null
  let insightStyle = null
  let tooltipText = ''

  if (allowInsight) {
    switch (insight?.type) {
      case 'warning':
        insightStyle = { ...styles.insights.warning }
        buttonIcon = <PriorityHighIcon sx={{ fontSize: 14 }} />
        tooltipText = insight.message
        break

      case 'regular':
      default:
        tooltipText = Translate({
          messageKey: ['access_item', tooltip],
          lower: [false, true],
        })

        if (icon) {
          buttonIcon = icon
        }
    }
  } else {
    tooltipText = Translate({
      messageKey: ['access_item', tooltip],
      lower: [false, true],
    })
  }

  return (
    <Link href={targetUrl} underline="none" target="_blank" key={uniqueId()}>
      <Tooltip title={tooltipText}>
        <Button sx={{ ...styles.insights.default, ...insightStyle, ...sx }}>
          <Stack direction="row" spacing={0.5} alignItems="center">
            {buttonIcon}
            {!isNil(text) && (
              <div style={{ fontWeight: 500 }}>{formattedText}</div>
            )}
            {props.children}
          </Stack>
        </Button>
      </Tooltip>
    </Link>
  )
}
export default TypeButtonLink
