import { initializeApp } from 'firebase/app'
import { isSupported } from 'firebase/messaging'
import { getAnalytics } from 'firebase/analytics'
import {
  registerServiceWorkerForeground,
  updateServiceWorkerBackground,
} from '#/store/services/Firebase/RegisterServiceWorker'

const {
  VITE_FIREBASE_API_KEY,
  VITE_FIREBASE_APP_ID,
  VITE_FIREBASE_PROJECT_ID,
  VITE_FIREBASE_AUTH_DOMAIN,
  VITE_FIREBASE_DATABASE_URL,
  VITE_FIREBASE_STORAGE_BUCKET,
  VITE_FIREBASE_MESSAGING_SENDER_ID,
  VITE_FIREBASE_MEASUREMENT_ID,
} = import.meta.env

/* prettier-ignore */
export const firebaseConfig = {
  apiKey:            VITE_FIREBASE_API_KEY,             // Auth / General Use
  appId:             VITE_FIREBASE_APP_ID,              // General Use
  projectId:         VITE_FIREBASE_PROJECT_ID,          // General Use
  authDomain:        VITE_FIREBASE_AUTH_DOMAIN,         // Auth with popup/redirect
  databaseUrl:       VITE_FIREBASE_DATABASE_URL,        // Realtime Database
  storageBucket:     VITE_FIREBASE_STORAGE_BUCKET,      // Storage
  messagingSenderId: VITE_FIREBASE_MESSAGING_SENDER_ID, // Cloud Messaging
}

if (VITE_FIREBASE_MEASUREMENT_ID) {
  firebaseConfig.measurementId = VITE_FIREBASE_MEASUREMENT_ID // Analytics
}

/* Initializing the Firebase app with the configuration object. */
export const app = initializeApp(firebaseConfig)
export const analytics = firebaseConfig.projectId ? getAnalytics() : null

const initializeFirebase = () => {
  isSupported()
    .then(hasFirebaseMessagingSupport => {
      if (hasFirebaseMessagingSupport && firebaseConfig.apiKey) {
        /* Updating the service worker in the background. */
        updateServiceWorkerBackground()
        /*
         * Registering the service worker in the foreground.
         * For being a listener it needs to be registered each time the page is opened.
         */
        registerServiceWorkerForeground()
      }
    })
    .catch(error => console.warn(error))
}

export default initializeFirebase
