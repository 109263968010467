import React from 'react'

const TypeItemList = ({ value, attrName, ...rest }) => {
  let newValue = <span>-</span>

  //define the attrName if you are dealing with an array of objects
  const separator = (i, listLength) =>
    listLength > 1 && i < listLength - 1 ? (
      <span key={`span-${i}`}>&sbquo;&nbsp;</span>
    ) : (
      ''
    )
  const cols = rest.columns || 3 //the default number of columns is 3
  const br = i => (i % cols ? '' : <br key={`br-${i}`} />)
  if (value.length > 0) {
    const listItems = value.filter(each => (attrName ? each[attrName] : each))

    if (rest.onlyText) {
      /*Return only string*/
      newValue = attrName
        ? listItems.map(t => t.name).join(', ')
        : listItems.join(', ')
    } else {
      /*Return multiple elements*/
      newValue = (
        <p style={{ display: 'block' }}>
          {listItems.map((item, i) => {
            const eachItem = attrName ? item[attrName] : item
            return (
              eachItem && (
                <React.Fragment key={i}>
                  <span key={`text-${i}`}>
                    {eachItem}
                    {separator(i, listItems.length)}
                  </span>
                  {br(i + 1)}
                </React.Fragment>
              )
            )
          })}
        </p>
      )
    }
  }
  return newValue
}
export default TypeItemList
