import React from 'react'
import { isEqual } from 'lodash'
import { endpoint } from '#/store/services/endpoints'
import { getCurrentFarmSlug } from '#/store/ducks/farm'
import EventGroupList from '#/components/EventsGroupList'

const TypeEventGroupList = ({ value, reproduction }) => {
  if (!value) return <span>-</span>
  let controller = null
  //common params
  let paramsObj = {
    farm: getCurrentFarmSlug(window.globalStore.getState(), isEqual),
    animal: value['animal.slug'],
  }
  //common animal
  let animal = {
    slug: value['animal.slug'],
    name: value['animal.name'],
    earring: value['animal.earring'],
  }

  if (reproduction) {
    //heat case
    animal.health_status = value['animal.health_status']
    paramsObj.event_slug = value.event_group_slug
    controller = endpoint.farm.animal.event.heat_group(paramsObj)
  } else {
    //health case
    paramsObj.event_slug = value.slug
    controller = endpoint.farm.animal.event.health_group(paramsObj)
  }

  return <EventGroupList route={controller} animal={animal} />
}
export default TypeEventGroupList
