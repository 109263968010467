import { createTheme } from '@mui/material/styles'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { createBreakpoints } from '@mui/system'
import Fonts from './Fonts'
import OldColors from './Old/Colors'
import Colors from '#/styles/Colors'

const changeStyleMode = mode => {
  const root = document.getElementById('root')
  if (mode === 'light') {
    root.style.backgroundColor = '#fafafa'
  } else {
    root.style.backgroundColor = 'unset'
  }
}

export default (mode = 'light') => {
  const setPropertyIfIsLight = prop => (mode === 'light' ? prop : {})
  const setPropertyIfIsDark = prop => (mode === 'dark' ? prop : {})
  changeStyleMode(mode)

  const breakpoints = createBreakpoints({})
  const theme = createTheme({
    palette: {
      mode,
      primary: Colors.theme.colors.primary,
      secondary: Colors.theme.colors.secondary,
      info: Colors.theme.colors.info,
      text: Colors.theme.colors.text,
      error: Colors.theme.colors.error,
      white: Colors.theme.colors.white,
      neutral: Colors.theme.colors.neutral,
      baby: Colors.theme.colors.baby,
    },
    typography: {
      fontSize: Fonts.fontSize.S,
      htmlFontSize: Fonts.fontSize.M,
      h1: {
        fontSize: '6em',
      },
      h2: {
        fontSize: '3.75em',
      },
      h3: {
        fontSize: '3em',
      },
      h4: {
        fontSize: '2.125em',
      },
      h5: {
        fontSize: '1.5em',
      },
      h6: {
        fontSize: '1.25em',
      },
      subtitle: {
        fontSize: Fonts.fontSize.M,
        fontWeight: '500',
        color: '#4d4c4a',
        marginBottom: 10,
        display: 'block',
      },
      subtitle1: {
        fontSize: '1em',
      },
      subtitle2: {
        fontSize: '0.875em',
      },
      body1: {
        fontSize: '1em',
      },
      body2: {
        fontSize: '0.875em',
      },
      button: {
        fontSize: '0.875em',
      },
      caption: {
        fontSize: '0.85rem',
      },
      overline: {
        fontSize: '0.75em',
      },
      strong: {
        fontWeight: '500',
      },
      root: {
        color: ({ color }) => theme.palette[color].main,
      },
    },
    components: {
      MuiLink: {
        defaultProps: {
          underline: 'none',
        },
        styleOverrides: {
          root: {
            cursor: 'pointer',
          },
        },
      },
      MuiLoadingButton: {
        styleOverrides: {
          root: {
            'minWidth': 'unset',
            'padding': 12,
            'color': '#757575',
            'minHeight': 'unset',
            'fill': 'inherit',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            '&.MuiButton-sizeSmall': {
              padding: 5,
            },
            '&.MuiLoadingButton-loading': {
              color: '#757575',
            },
            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.04)',
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            '& > .MuiDialog-container > .MuiPaper-root': {
              borderRadius: 16,
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            ...setPropertyIfIsLight({ backgroundColor: 'white' }),
            '& .MuiToolbar-root': {
              minHeight: 50,
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            padding: '0 9px',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            '&.MuiDivider-middle.MuiDivider-vertical': {
              margin: '12px -2px 12px 0',
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            ...setPropertyIfIsDark({
              color: 'white',
              boxShadow:
                '0px 2px 1px -1px rgba(255,255,255,0.2), 0px 1px 1px 0px rgba(255,255,255,0.14), 0px 1px 3px 0px rgba(255,255,255,0.12)',
            }),
          },
        },
      },
      MuiAlert: {
        defaultProps: {
          iconMapping: {
            neutral: <InfoOutlinedIcon />,
          },
        },
        styleOverrides: {
          root: {
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
            maxWidth: '100%',
            width: '100%',
            borderRadius: 16,
            [breakpoints.up('xs')]: {
              flexDirection: 'column',
            },
            [breakpoints.up('sm')]: {
              flexDirection: 'row',
            },
          },
          standardSuccess: {
            backgroundColor: Colors.feedback.success.backgroundColor,
            color: Colors.feedback.success.color,
          },
          standardInfo: {
            backgroundColor: Colors.feedback.info.backgroundColor,
            color: Colors.feedback.info.color,
          },
          standardWarning: {
            backgroundColor: Colors.feedback.warning.backgroundColor,
            color: Colors.feedback.warning.color,
          },
          standardError: {
            backgroundColor: Colors.feedback.danger.backgroundColor,
            color: Colors.feedback.danger.color,
          },
          standardNeutral: {
            color: Colors.feedback.info.color,
          },
          standardBirth: Colors.reproduction.birth,
          standardHeat: Colors.reproduction.heat,
          standardCritical: Colors.health.critical,
          standardObservation: Colors.health.observation,
          standardChallenge: Colors.health.challenge,
          standardNormal: Colors.health.normal,
          action: {
            paddingTop: 0,
          },
          icon: {
            color: 'inherit',
          },
        },
      },
      MuiGrid2: {
        styleOverrides: {
          root: {
            margin: 0,
          },
        },
      },
      MuiSnackbar: {
        defaultProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            zIndex: 1,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          sizeExtended: {
            paddingLeft: 30,
            paddingRight: 30,
          },
          contained: {
            color: 'white',
          },
          root: {
            minHeight: 40,
            borderRadius: 100,
          },
          sizeSmall: {
            minHeight: 32,
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            'textTransform': 'none',
            'boxShadow': 'none !important',
            '&.clearButton > svg': {
              fontSize: 20,
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
          InputLabelProps: {
            shrink: true,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            minHeight: 39,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: Fonts.fontSize.M,
          },
          animated: {
            fontSize: Fonts.fontSize.M,
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          positionEnd: {
            fontSize: Fonts.fontSize.SS,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          deleteIcon: {
            ...setPropertyIfIsDark({ color: 'white' }),
          },

          root: {
            ...setPropertyIfIsDark({ color: 'white' }),
            fontSize: '0.9125em',
            whiteSpace: 'nowrap',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          sizeMedium: {
            borderRadius: '64px',
            height: '24px',
            fontSize: '14px',
          },
          sizeSmall: {
            fontSize: '0.875em',
            height: '18px',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: 11,
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: Fonts.fontSize.SS,
            marginLeft: 0,
          },
        },
      },

      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: Fonts.fontSize.S,
          },
        },
      },

      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: Fonts.fontSize.S,
          },
        },
      },
      MuiTabs: {
        defaultProps: {
          variant: 'scrollable',
          scrollButtons: 'auto',
          selectionFollowsFocus: true,
        },
        styleOverrides: {
          root: {
            ...setPropertyIfIsLight({ backgroundColor: '#fbfbfb' }),
          },
          scrollButtons: {
            '&.Mui-disabled': {
              display: 'none !important',
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            'textTransform': 'uppercase',
            '&:not(.Mui-selected)': {
              opacity: 0.7,
            },
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            ...setPropertyIfIsDark({ color: '#fff' }),
            fontSize: Fonts.fontSize.S,
          },
          caption: {
            fontSize: Fonts.fontSize.SS,
          },
          toolbar: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: Fonts.fontSize.XXL,
          },
          fontSizeMedium: {
            fontSize: 20,
          },
          fontSizeLarge: {
            fontSize: '3.1875em',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            fontSize: Fonts.fontSize.XXL,
          },
        },
      },
      MuiPickersBasePicker: {
        styleOverrides: {
          pickerView: {
            maxWidth: '100%',
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          daySelected: {
            'backgroundColor': Colors.primary,
            '&:hover': {
              backgroundColor: OldColors.primaryDark,
            },
          },
          day: {
            fontSize: '1em',
          },
        },
      },
    },
  })
  return theme
}
