import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export default function WarningIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 0 24 24"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M12,15.89a1.16,1.16,0,0,0-.82.32,1.15,1.15,0,0,0,0,1.61,1.21,1.21,0,0,0,1.64,0,1.15,1.15,0,0,0,0-1.61A1.16,1.16,0,0,0,12,15.89Z" />
      <path d="M22.5,19.46,13,3.41A1,1,0,0,0,12.56,3a1.17,1.17,0,0,0-1.12,0,1,1,0,0,0-.44.42L1.5,19.46a1.06,1.06,0,0,0-.16.58,1.3,1.3,0,0,0,.16.55,1.3,1.3,0,0,0,.4.41,1.17,1.17,0,0,0,.58.15h19A1.17,1.17,0,0,0,22.1,21a1.18,1.18,0,0,0,.56-1A1,1,0,0,0,22.5,19.46Zm-18-.56L12,6.22,19.54,18.9Z" />
      <path d="M11.18,10.19a1.1,1.1,0,0,0-.33.81v3a1.09,1.09,0,0,0,.33.8,1.21,1.21,0,0,0,1.64,0,1.09,1.09,0,0,0,.33-.8V11a1.1,1.1,0,0,0-.33-.81,1.21,1.21,0,0,0-1.64,0Z" />
    </SvgIcon>
  )
}
