import React from 'react'
import clsx from 'clsx'
import { components } from 'react-select'
import { emphasize } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import Chip from '@mui/material/Chip'
import MenuItem from '@mui/material/MenuItem'
import CancelIcon from '@mui/icons-material/Cancel'
import ClearIcon from '@mui/icons-material/Clear'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SearchIcon from '@mui/icons-material/Search'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Translate from '#/components/Translate'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    minWidth: '100%',
  },
  input: {
    display: 'flex !important',
  },
  inputMulti: {
    height: 'auto',
  },
  valueContainer: {
    'display': 'flex',
    'flex': 1,
    'alignItems': 'center',
    'overflow': 'hidden',
    '&:has(> :nth-child(3))': {
      flexWrap: 'wrap',
    },
  },
  chip: {
    'display': 'flex',
    'justifyContent': 'center',
    'flexWrap': 'nowrap',
    'marginRight': 2,
    'marginBottom': 2,
    '& > span': {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 150,
    },
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.mode === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  paper: {
    position: 'absolute',
    zIndex: 5,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
}))

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

const inputComponent = React.forwardRef((props, ref) => (
  <div ref={ref} {...props} />
))

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props

  const isMulti = props.getValue()?.length

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: [classes.input, isMulti > 1 ? classes.inputMulti : null],
          ref: innerRef,
          children,
          ...innerProps,
        },
        ...TextFieldProps.adornments,
      }}
      {...TextFieldProps}
    />
  )
}

function Option(props) {
  return (
    <MenuItem
      disabled={props.isDisabled}
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        whiteSpace: 'pre-wrap',
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

function Placeholder(props) {
  const { selectProps, innerProps = {}, children } = props
  return (
    <Typography
      color="textSecondary"
      component="div"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  )
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  )
}

function MultiValue(props) {
  return (
    <Chip
      size="small"
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  )
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      <components.Menu {...props}>{props.children}</components.Menu>
    </Paper>
  )
}

function DropdownIndicator(props) {
  return props.selectProps.isAsyncSearchable ? (
    <SearchIcon color="disabled" />
  ) : (
    <ExpandMoreIcon color="disabled" />
  )
}

// remove the indicator separator
function IndicatorSeparator() {
  return null
}

function ClearIndicator(props) {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props
  return (
    <div ref={ref} {...restInnerProps}>
      <Tooltip title={Translate({ messageKey: 'remove_selected_value' })}>
        <ClearIcon color="disabled" cursor="pointer" />
      </Tooltip>
    </div>
  )
}

export {
  useStyles,
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  DropdownIndicator,
  IndicatorSeparator,
  ClearIndicator,
}
