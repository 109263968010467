import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export default function ConnectedIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 0 21 16"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M1.7898 6.34069C3.97483 4.27192 6.93887 3.00003 10.2069 3.00003C13.4749 3.00003 16.439 4.27192 18.624 6.34069C19.0294 6.72195 19.669 6.70945 20.0554 6.31257C20.4417 5.91569 20.429 5.2813 20.0269 4.90005C17.4808 2.48439 14.0196 1 10.2069 1C6.39419 1 2.93298 2.48439 0.383782 4.89692C-0.0183899 5.2813 -0.0310568 5.91256 0.355281 6.31257C0.74162 6.71257 1.38446 6.72507 1.78663 6.34069H1.7898ZM10.2069 8.00009C12.0056 8.00009 13.646 8.65947 14.9 9.75011C15.3211 10.1157 15.9608 10.0751 16.3313 9.66261C16.7018 9.2501 16.6607 8.61572 16.2427 8.25009C14.634 6.85007 12.5186 6.00006 10.2069 6.00006C7.89521 6.00006 5.77985 6.85007 4.17433 8.25009C3.75316 8.61572 3.71516 9.24698 4.08566 9.66261C4.45617 10.0782 5.09584 10.1157 5.51701 9.75011C6.76786 8.65947 8.40822 8.00009 10.2101 8.00009H10.2069ZM12.2336 13.0002C12.2336 12.4697 12.0201 11.961 11.64 11.5859C11.2599 11.2108 10.7444 11.0001 10.2069 11.0001C9.66939 11.0001 9.1539 11.2108 8.77382 11.5859C8.39374 11.961 8.18021 12.4697 8.18021 13.0002C8.18021 13.5306 8.39374 14.0393 8.77382 14.4144C9.1539 14.7895 9.66939 15.0002 10.2069 15.0002C10.7444 15.0002 11.2599 14.7895 11.64 14.4144C12.0201 14.0393 12.2336 13.5306 12.2336 13.0002Z" />
    </SvgIcon>
  )
}
