import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export default function NutritionIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 0 24 24"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M23.64,9a.93.93,0,0,0-.91-.76h0A12.19,12.19,0,0,0,19,8.8l-1.15.38,0-.14c-.77-4.82-3.1-7.93-5.94-7.93s-5.36,3.48-6,8.46l0,.15-.15,0A18.23,18.23,0,0,0,1.2,8.84a.83.83,0,0,0-.59.25.82.82,0,0,0-.25.59.86.86,0,0,0,.6.81c.13,0,3.21,1.14,5.68,7.84l.11.31.2.48a7.44,7.44,0,0,0,.32.68,2.24,2.24,0,0,0,.13.27,6.92,6.92,0,0,0,1.83,2.35,4.21,4.21,0,0,0,2.44,1h.38a3.59,3.59,0,0,0,2.25-.79,9.29,9.29,0,0,0,3-4.89C19.75,11.21,22.68,10,23,9.87h0A.85.85,0,0,0,23.64,9ZM10.26,21.05h0A6,6,0,0,1,9,19.43a12.56,12.56,0,0,1-.77-1.71,21.84,21.84,0,0,0-3.5-6.36l-.25-.31.38.11c2.59.77,5.89,2.41,6.89,5.89.22.81.61,2.37,1.05,4.19l0,.26A3.52,3.52,0,0,1,10.26,21.05Zm5.47-4-.26.73-.13.33-.06.16A10.29,10.29,0,0,1,14.45,20l-.15.24L14,19.07c-.23-.94-.47-1.91-.63-2.47-.06-.13-.11-.28-.18-.46-.48-1.31-1.38-3.77-5.67-5.84l-.08,0v-.09c0-.18.4-4.45,2.86-6.74a2.22,2.22,0,0,1,1.51-.73,2.13,2.13,0,0,1,1.58.55v0l.22.22c1.75,1.93,2.75,5.14,2.75,8.79A17.16,17.16,0,0,1,15.73,17.08Zm3.5-6.31A12.75,12.75,0,0,0,18.29,12l-.21.32v-1.4l.07,0c.3-.13.64-.24.94-.34l.41-.13Z" />
    </SvgIcon>
  )
}
