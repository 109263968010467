import React from 'react'

const style = {
  videoContainer: {
    position: 'relative',
    paddingBottom: '56.25%',
    height: 0,
  },
  videoContainerIframe: {
    position: 'absolute',
    top: 15,
    left: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
  },
}

// Possible url formats:
// www.youtube.com/watch?v=aME8oBF9u50
// https://www.youtube.com/watch?v=aME8oBF9u50
// https://youtu.be/aME8oBF9u50

// Padronized url format:
// https://www.youtube.com/embed/aME8oBF9u50

function validateYoutubeUrl(url) {
  let formatedUrl = url
  if (!url.startsWith('http')) {
    formatedUrl = `https://${url}`
  }
  if (url.includes('/watch?v=')) {
    formatedUrl = formatedUrl.replace('/watch?v=', '/embed/')
  }
  if (url.includes('youtu.be/')) {
    formatedUrl = formatedUrl.replace('youtu.be/', 'www.youtube.com/embed/')
  }
  return formatedUrl
}

export default function Iframe({ url }) {
  let formatedUrl = validateYoutubeUrl(url)

  return (
    <div style={style.videoContainer}>
      <iframe
        showinfo="0"
        controls="2"
        style={style.videoContainerIframe}
        src={formatedUrl}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen="allowfullscreen"
      />
    </div>
  )
}
