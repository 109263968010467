import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export default function DiscardIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 0 24 24"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M18.37,17.2c0-.36-.2-.64-.46-.64s-.45.29-.45.64.2.64.45.64S18.37,17.55,18.37,17.2Z" />
      <path d="M7,3.89c-1.37-1-3.74-1.36-4.38-.49a1.37,1.37,0,0,0-.17,1.13A4.91,4.91,0,0,0,4.26,7.58a5.14,5.14,0,0,0,2.83.83,4.17,4.17,0,0,0,.83-.08,1.32,1.32,0,0,0,.93-.57C9.37,6.88,8.4,4.89,7,3.89ZM5.21,6.19a3,3,0,0,1-1.06-1.6A4.24,4.24,0,0,1,6,5.25,3.56,3.56,0,0,1,7.2,6.72a3.4,3.4,0,0,1-2-.53Z" />
      <path d="M7.58,16.15l-.12-.29-1.59.52L6,16.7a20,20,0,0,0,1.16,2.58.86.86,0,0,0,.73.44.84.84,0,0,0,.41-.11.86.86,0,0,0,.4-.5.89.89,0,0,0-.08-.64,16.46,16.46,0,0,1-1-2.32Z" />
      <path d="M6.06,11.51,6,11.19A10.08,10.08,0,0,1,5.66,9.3.84.84,0,0,0,4,9.3a10.79,10.79,0,0,0,.37,2.3l.08.33,1.61-.42Z" />
      <path d="M6,16l.74-.23a.85.85,0,0,0,.52-.44.83.83,0,0,0,.06-.67l-.7-2.25a.89.89,0,0,0-1.12-.58l-.75.23a.87.87,0,0,0-.52.44.92.92,0,0,0-.06.67l.71,2.25a.86.86,0,0,0,.43.52.88.88,0,0,0,.69.07Z" />
      <path d="M11.07,6.58a6.26,6.26,0,0,1,1.42-1.44,7.47,7.47,0,0,0-2.22-.26.84.84,0,1,0,0,1.68Z" />
      <path d="M20.68,15.92a15.56,15.56,0,0,0-1.08-1.13,6,6,0,0,1-1.55.84c.5.48,1,.94,1.28,1.3a2.83,2.83,0,0,1-1.12,1.85l-.63-.14A19.37,19.37,0,0,0,13.72,18a10.91,10.91,0,0,0-3.27.38.85.85,0,0,0-.57,1.05.9.9,0,0,0,.4.5.89.89,0,0,0,.64.07,9.25,9.25,0,0,1,2.73-.32,18.23,18.23,0,0,1,3.55.56,5.79,5.79,0,0,0,1.13.21,1,1,0,0,0,.51-.12A4.52,4.52,0,0,0,21,17.41a1.71,1.71,0,0,0-.27-1.48Z" />
      <path d="M14.59,10,13.17,8.59a1,1,0,1,1,1.42-1.42L16,8.59l1.41-1.42a1,1,0,1,1,1.42,1.42L17.41,10l1.42,1.41a1,1,0,1,1-1.42,1.42L16,11.41l-1.41,1.42a1,1,0,1,1-1.42-1.42Z" />
    </SvgIcon>
  )
}
