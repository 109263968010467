import React from 'react'
import ReactShowdown from 'react-showdown'
import makeStyles from '@mui/styles/makeStyles'

// remove react-showdown from package.json
// when we change the markdown editor

const useStyles = makeStyles(() => ({
  markdown: {
    '& h1, & h2, & h3, & h4, & h5, & h6': {
      margin: '10px 0',
      fontWeight: 'bold',
    },
    '& p': {
      margin: '0 0 10px',
    },
    '& blockquote': {
      margin: '0 0 20px',
      padding: '10px 20px',
      borderLeft: `5px solid #eee`,
    },
    '& blockquote ol:last-child, & blockquote p:last-child, & blockquote ul:last-child':
      {
        marginBottom: 0,
      },
    '& pre': {
      'display': 'block',
      'padding': '10px',
      'margin': '0 0 10px',
      'backgroundColor': '#f5f5f5',
      'border': '1px solid #ccc',
      'borderRadius': '4px',
      '& code': {
        padding: 0,
        fontSize: 'inherit',
        color: 'inherit',
        backgroundColor: 'transparent',
      },
    },
    '& code': {
      padding: '2px 4px',
      fontSize: '13px',
      color: '#c7254e',
      backgroundColor: '#f9f2f4',
      borderRadius: 4,
    },
    '& hr': {
      margin: '20px 0',
      border: 0,
      borderTop: '1px solid #eee',
    },
  },
}))

const MarkdownView = ({ style = {}, className = '', markdown = '' }) => {
  const classes = useStyles()

  return (
    <ReactShowdown
      style={style}
      className={[classes.markdown, className].join(' ')}
      markdown={markdown}
    />
  )
}

export default MarkdownView
