import {
  logEvent,
  setAnalyticsCollectionEnabled,
  setUserId,
  setUserProperties,
} from 'firebase/analytics'
import { analytics } from '#/store/services/Firebase'

/**
 * Logs `page_view` event to *analytics*
 *
 * @param {string} screenName - The CX screen name
 * @param {string} farmSlug - The slug of the farm
 *
 * @returns {undefined} Nothing
 *
 * @example
 * hitAnalyticsScreenView('Dashboard', 'farm-1097')
 * */
export function hitAnalyticsScreenView(page_title, fazenda) {
  if (analytics) {
    try {
      logEvent(analytics, 'page_view', { page_title, fazenda })
    } catch (e) {
      console.error('ANALYITCS BLOCKED BY CLIENT -> ', e)
    }
  }
}

/**
 * @function setAnalyticsUserInfo - set authenticated user info on Analytics
 * */
export function setAnalyticsUserInfo(user) {
  if (analytics) {
    try {
      setAnalyticsCollectionEnabled(analytics, true)
      setUserId(analytics, user.uuid)
      setUserProperties(analytics, {
        name: user.name,
        system_user: user.is_system_user.toString(),
        email: user.email,
      })
    } catch (e) {
      console.error(e)
      throw new Error(e.message)
    }
  }
}
