import { clarity } from 'react-microsoft-clarity'

const CLARITY_API_KEY = import.meta.env.VITE_CLARITY_API_KEY

function InitializeClarity(user) {
  // Verifica se a chave de API do Clarity e as informações do usuário no Redux estão disponíveis
  // Não é necessário iniciar o Clarity no localhost
  if (!CLARITY_API_KEY || !user) {
    return null
  }

  // Inicia a captura de dados no painel do Clarity com a chave de API fornecida
  clarity.init(CLARITY_API_KEY)

  // Configura o consentimento do Clarity (opcional, dependendo do seu caso de uso)
  clarity.consent()

  // Verifica se o Clarity já foi inicializado antes de chamar seus métodos
  if (clarity.hasStarted()) {
    // Identifica o usuário no Clarity com o UUID fornecido
    clarity.identify(user.uuid)

    // Identifica o tipo de usuário (exemplo: cowmed ou outro)
    clarity.setTag('user-type', user.is_system_user ? 'cowmed' : 'other')
  }
}

export default InitializeClarity
