import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

function HealthIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 0 24 24"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M11.94,21.76a2.27,2.27,0,0,1-.86-.16,2.13,2.13,0,0,1-.74-.49L3.61,14.35a5.48,5.48,0,0,1-1.3-2A6.7,6.7,0,0,1,1.9,10,6.37,6.37,0,0,1,3.59,5.53a5.42,5.42,0,0,1,4.2-1.86,5.51,5.51,0,0,1,2.27.48A6.25,6.25,0,0,1,12,5.49a5.74,5.74,0,0,1,1.86-1.34A5.67,5.67,0,0,1,20.3,5.53,6.28,6.28,0,0,1,22,10a6.5,6.5,0,0,1-.43,2.36,5.87,5.87,0,0,1-1.29,2L13.52,21.1a2.18,2.18,0,0,1-.73.49,2.23,2.23,0,0,1-.86.16ZM13,8.77a.91.91,0,0,1,.48.13.93.93,0,0,1,.35.33l1.71,2.57H19.7a4.79,4.79,0,0,0,.26-.87,4.41,4.41,0,0,0,.09-.89,4.48,4.48,0,0,0-1.11-2.92,3.7,3.7,0,0,0-2.59-1.3,3.8,3.8,0,0,0-1.71.3A3.43,3.43,0,0,0,13.39,7l-.68.74a.83.83,0,0,1-.33.24,1,1,0,0,1-.81,0,1.21,1.21,0,0,1-.35-.24L10.54,7A3.84,3.84,0,0,0,9.3,6.1a3.5,3.5,0,0,0-1.5-.33A3.54,3.54,0,0,0,5,7a4.47,4.47,0,0,0-1.1,3,5.26,5.26,0,0,0,.08.89,4.07,4.07,0,0,0,.25.87H9a.91.91,0,0,1,.48.13.83.83,0,0,1,.36.33l.88,1.3L12,9.49A1.06,1.06,0,0,1,12.4,9a1,1,0,0,1,.59-.2Zm.26,2.85-1.36,4.07a1,1,0,0,1-.38.5,1,1,0,0,1-.6.2.91.91,0,0,1-.48-.13.93.93,0,0,1-.35-.33L8.37,13.37H5.81l6,6,.09.06H12l.09-.06,5.92-6H15a.91.91,0,0,1-.48-.13,1.37,1.37,0,0,1-.39-.33l-.85-1.31Z" />
    </SvgIcon>
  )
}

export default HealthIcon
