/**
 * It takes a pathname and a mapping object, and returns the tracking code for that pathname
 * @param pathname - The current pathname of the page.
 * @param mapping - This is the object that contains the tracking codes for each page.
 * @returns The tracking id for the current page.
 */
export default function getTracking(pathname, mapping) {
  return Object.keys(mapping).reduce((prev, current) => {
    if (!prev) {
      const regex = new RegExp(`^${current}/?$`)
      return regex.test(pathname) ? mapping[current] : null
    }
    return prev
  }, null)
}
