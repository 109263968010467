import React from 'react'
import { Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import PPIIcon from '#/icons/Ppi'
import Translate from '#/components/Translate'
import Colors from '#/styles/Colors'

const Content = styled('div')(({ align }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: align,
}))

const Label = styled('p')({
  margin: 0,
  lineHeight: '16px',
  fontWeight: 500,
  color: '#666',
})

const Icon = styled(PPIIcon)(({ status }) => ({
  fontSize: 25,
  height: '30px',
  color: Colors.ppi[status],
}))

const TypePPI = ({ status, text, with_label = true, alignItems = 'start' }) => {
  if (!status) {
    return <span>-</span>
  }

  return (
    <Tooltip title={`${Translate({ messageKey: 'ppi' })} - ${text}`}>
      <Content align={alignItems}>
        <Icon status={status} />
        {with_label && <Label>{text}</Label>}
      </Content>
    </Tooltip>
  )
}

export default TypePPI
