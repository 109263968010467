import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

function CornIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 0 24 24"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M11 12H8.82A14.16 14.16 0 0 1 11 13.68V12m-4-1c.27-5.12 2.37-9 5-9 2.66 0 4.77 3.94 5 9.12 1.5-.69 3.17-1.12 5-1.12-5.75 2.57-3.75 12-10 12-6 0-4.07-9.43-10-12 1.82 0 3.5.4 5 1m4 0V9H8.24l-.21 2H11m0-3V6H9.05c-.25.6-.45 1.27-.62 2H11m0-3V3.3c-.55.33-1.05.92-1.5 1.7H11m1-2v2h1v1h-1v2h2v1h-2v2h3v1h-3v2h2v1h-1.77c1.19 1.45 1.92 3 2.09 4.23.99-1.67 1.64-4.39 1.68-7.47C15.94 7 14.13 3 12 3z" />
    </SvgIcon>
  )
}

export default CornIcon
