import React from 'react'
import { styled } from '@mui/material/styles'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import Translate from '#/components/Translate'
import Fonts from '#/styles/Fonts'
import { isTrue } from '#/utils'
import Colors from '#/styles/Colors'

const StyledBox = styled('span')(({ color }) => ({
  color,
  fontSize: Fonts.fontSize.SS,
  fontWeight: Fonts.fontWeight.bold,
  whiteSpace: 'nowrap',
}))

const BooleanBadge = ({ value, onlyText, invertedColors }) => {
  const Icon = isTrue(value) ? CheckIcon : CloseIcon
  const text = isTrue(value)
    ? Translate({ messageKey: 'yes' })
    : Translate({ messageKey: 'no' })

  let color

  if (invertedColors) {
    color = isTrue(value)
      ? Colors.feedback.error.color
      : Colors.feedback.success.color
  } else {
    color = isTrue(value)
      ? Colors.feedback.success.color
      : Colors.feedback.error.color
  }

  if (onlyText) {
    return <StyledBox color={color}>{text}</StyledBox>
  }

  return <Icon style={{ color }} />
}

export default BooleanBadge
