import React from 'react'
import { useDispatch } from 'react-redux'
import isFunction from 'lodash/isFunction'
import isEmpty from 'lodash/isEmpty'
import { toggleDialog } from '#/store/ducks/dialog'
import ResponseModal from '#/components/Modal/ResponseModal'

const ResponseDialog = props => {
  const { dialogResponse, afterClose = () => {} } = props
  const dispatch = useDispatch() // for dispatch actions

  if (isEmpty(dialogResponse) || !dialogResponse.name) return null

  const handleSuccessOnClose = () => {
    dispatch(toggleDialog({ name: dialogResponse.name }))
    if (isFunction(dialogResponse.onClose)) dialogResponse.onClose()
    if (isFunction(afterClose)) afterClose({})
    if (isFunction(dialogResponse.afterSuccess)) dialogResponse.afterSuccess()
  }

  const handleFailedOnClose = () => {
    dispatch(toggleDialog({ name: dialogResponse.name }))
    if (isFunction(afterClose)) afterClose({})
  }

  const handleOnClose = () => {
    if (dialogResponse.error) {
      return handleFailedOnClose
    }
    return handleSuccessOnClose
  }

  const handleActions = () => {
    if (dialogResponse.error) {
      return dialogResponse.conflictsAction
    }
    return dialogResponse.actionsOnSuccess
  }

  return (
    <ResponseModal
      open={dialogResponse.open}
      onClose={handleOnClose()}
      message={dialogResponse.message}
      details={dialogResponse.details}
      code={dialogResponse.code}
      closable={false}
      action={handleActions()}
      renderCustomResponse={dialogResponse.renderCustomResponse}
      status={dialogResponse.status}
    />
  )
}

export default React.memo(ResponseDialog)
