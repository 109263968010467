import React from 'react'
import QuickInfo from '#/components/QuickInfo'
import Translate from '#/components/Translate'

const TypeQuickInfo = ({ value, ...rest }) => {
  if (!value) return <span>-</span>
  return (
    <QuickInfo
      title={Translate({ messageKey: 'information_plural' })}
      {...rest}
    >
      {value}
    </QuickInfo>
  )
}

export default TypeQuickInfo
