import React, { useEffect, useMemo, useState } from 'react'
import { isEqual, isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import {
  getCurrentFarmSlug,
  getCurrentFarmVicAssistant,
} from '#/store/ducks/farm'
import { endpoint } from '#/store/services/endpoints'
import Translate from '#/components/Translate'
import SimpleDialog from '#/components/Modal'
import Moment from '#/components/Moment'
import MarkdownView from '#/components/MarkdownView'
import tryCache from '#/utils/tryCache'
import Colors from '#/styles/Old/Colors'
import ImgVickyRelease from '#/assets/vic/vicky_release.png'
import ImgVelmaRelease from '#/assets/vic/velma_release.png'
import ListLoader from '#/components/Loader/ContentLoader/ListLoader'
import { fetcher, useFetch } from '#/store/hooks/request'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  headerContainer: {
    paddingBottom: theme.spacing(2),
    display: 'flex',
  },
  vicImage: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  titleWrapper: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(3),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    fontWeight: 600,
  },
  subtitle: {
    color: Colors.textSecondary,
  },
  buttonsTab: {
    backgroundColor: Colors.tableRowWhite,
    fontWeight: 700,
  },
  button: {
    padding: '',
    fontWeight: 'bold',
    color: Colors.textSecondary,
  },
  markdownView: {
    minHeight: 140,
    maxHeight: '30vh',
    padding: 8,
    borderBottom: `1px solid ${Colors.tableRowWhite}`,
    overflow: 'auto',
  },
  contentContainer: {
    paddingTop: 0,
    paddingBottom: theme.spacing(3),
  },
  actionsContainer: {
    paddingTop: 0,
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
  },
  modalFooter: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'end',
  },
}))

const TC_VIEWED_KEY = 'viewed-last-rn'

const ReleaseStatus = () => {
  const classes = useStyles()
  const currentVic = useSelector(getCurrentFarmVicAssistant, isEqual)
  const farm = useSelector(getCurrentFarmSlug, isEqual)

  // popup visibility state
  const [openStatus, setOpenStatus] = useState(false)
  const [isReleaseTab, setIsReleaseTab] = useState(true)
  const [viewedData, setViewedData] = useState(null)

  // check for unread releases
  const cachedViewedData = tryCache.get(TC_VIEWED_KEY)

  const controller = useMemo(() => {
    return {
      last: endpoint.system.release_notes.last(),
      viewed_last: endpoint.system.release_notes.viewed_last(),
    }
  }, [])

  const { data: fetchedViewedData } = useFetch({
    controller: !cachedViewedData ? controller.viewed_last : null,
  })

  // fetch last published release note if opened
  const { data, isLoading } = useFetch({
    controller: openStatus ? controller.last : null,
  })

  useEffect(() => {
    if (cachedViewedData && !viewedData) {
      setViewedData(cachedViewedData)
    }
  }, [cachedViewedData, viewedData])

  useEffect(() => {
    if (fetchedViewedData) {
      setViewedData(fetchedViewedData)
      tryCache.set(TC_VIEWED_KEY, fetchedViewedData, 10) // 10 minutes
    }
  }, [fetchedViewedData])

  /**
   * Show Orange Gift if not click in "Ok, Entendi"
   * Open popup if never seen or if click in "Ver Depois" after 24 hours
   */
  useEffect(() => {
    const viewed_last_release = viewedData?.viewed_last_release
    if (viewedData) {
      if (viewed_last_release === null) {
        setOpenStatus(true)
      } else if (viewed_last_release?.viewed === false) {
        if (Moment.diff('now', viewed_last_release?.updated_at, 'h') > 24) {
          setOpenStatus(true)
        }
      }
    }
  }, [viewedData])

  function handleOnClick(type) {
    if (data?.id) {
      const controller =
        type === 'read-later'
          ? endpoint.system.release_notes.read_later({ note: data.id })
          : endpoint.system.release_notes.view({ note: data.id })

      fetcher({ method: 'put', controller })
        .then(() => {
          //Delete drawer header cache
          tryCache.clear(`notification-list-${farm}-header`)
          //Delete drawer tab release content
          tryCache.clear(`notification-list-release`)
        })
        .catch(error => console.error(error))
    }
    /**
     * Quando clicado em qualquer opção adiciona um cache
     * para não ser exibido por no minimo 1 dia (1440 minutos).
     */
    tryCache.set(
      TC_VIEWED_KEY,
      {
        viewed_last_release: {
          viewed: type !== 'read-later',
          updated_at: new Date(),
        },
      },
      1440,
    )
    setOpenStatus(false)
  }

  const renderModalContent = () => {
    if (isLoading) {
      return <ListLoader />
    }
    if (!isEmpty(data)) {
      return (
        <React.Fragment key="monitoring-status">
          <div>
            <div className={classes.headerContainer}>
              <div className={classes.vicImage}>
                <img
                  alt="VIC"
                  src={
                    currentVic === 'vicky' ? ImgVickyRelease : ImgVelmaRelease
                  }
                />
              </div>
              <div className={classes.titleWrapper}>
                <Typography variant="h6" className={classes.title}>
                  {Translate({ messageKey: 'your_software_was_updated' })}
                </Typography>
                <Typography className={classes.subtitle}>
                  {Translate({
                    messageKey: [
                      'version_updated_at',
                      Moment(data.release_date).format('L'),
                    ],
                    translate: [true, false],
                  })}
                </Typography>
              </div>
            </div>
            <div className={classes.buttonsTab}>
              <Button
                className={classes.button}
                style={{
                  color: isReleaseTab ? Colors.primary : Colors.textSecondary,
                }}
                onClick={() => setIsReleaseTab(true)}
                size="large"
              >
                {Translate({ messageKey: 'release_notes' })}
              </Button>
              <Button
                className={classes.button}
                style={{
                  color: isReleaseTab ? Colors.textSecondary : Colors.primary,
                }}
                onClick={() => setIsReleaseTab(false)}
                size="large"
              >
                {Translate({ messageKey: 'coming_soon' })}
              </Button>
            </div>
            <MarkdownView
              className={classes.markdownView}
              markdown={isReleaseTab ? data.release_note : data.coming_soon}
            />
          </div>
          <div className={classes.modalFooter}>
            <Button
              variant="contained"
              onClick={() => handleOnClick('read-later')}
              style={{ marginRight: 10 }}
            >
              {Translate({ messageKey: 'read_later' })}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleOnClick()}
            >
              {Translate({ messageKey: 'i_got_it' })}
            </Button>
          </div>
        </React.Fragment>
      )
    }
    return Translate({ messageKey: 'without_information' })
  }

  return (
    <SimpleDialog
      fullWidth
      maxWidth="sm"
      open={openStatus}
      onClose={() => handleOnClick('read-later')}
      element={renderModalContent()}
      contentContainerStyles={classes.contentContainer}
    />
  )
}

export default ReleaseStatus
