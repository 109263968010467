import React from 'react'
import { Avatar, Tooltip } from '@mui/material'
import Translate from '#/components/Translate'
import Colors from '#/styles/Colors'
import Palette from '#/styles/Palette'

const style = {
  tooltipTitle: {
    margin: 0,
  },
  root: {
    display: 'inline-flex',
    gap: 5,
  },
  avatar: {
    width: '30px',
    height: '30px',
    fontSize: '0.8rem',
    textTransform: 'uppercase',
  },
  male: {
    backgroundColor: Colors.sex.male.backgroundColor,
  },
  female: {
    backgroundColor: Colors.sex.female.backgroundColor,
  },
  noGender: {
    backgroundColor: 'transparent',
    border: `1px solid ${Palette.gray.light[500]}`,
    color: Palette.gray.light[500],
  },
}

const GetCalfBadge = ({ gender, calf_status, withCondition }) => {
  const letter = Translate({ messageKey: gender })?.substring(0, 1) ?? '-'

  const tooltipTitle = (
    <div>
      <p style={style.tooltipTitle}>
        {Translate({
          messageKey: ['gender', gender ?? 'without_information'],
          separator: ': ',
        })}
      </p>
      {withCondition && (
        <p style={style.tooltipTitle}>
          {Translate({
            messageKey: ['condition', calf_status ?? 'without_information'],
            separator: ': ',
          })}
        </p>
      )}
    </div>
  )

  let color = gender ? style[gender] : style.noGender

  if (calf_status === 'stillbirth') {
    color = {}
  }

  return (
    <Tooltip title={tooltipTitle}>
      <Avatar style={{ ...style.avatar, ...color }}>{letter}</Avatar>
    </Tooltip>
  )
}

const TypeCalvesBadge = ({ value, withCondition = true, customStyle }) => {
  if (!value) return <span>-</span>
  let calves = []

  /**
   * Verify if value is array
   * JSON.parse used on screen deliveries performed
   */
  calves = !Array.isArray(value) ? JSON.parse(value) : value

  return (
    <div style={{ ...style.root, ...customStyle }}>
      {calves?.map((props, index) => (
        <GetCalfBadge
          {...props}
          withCondition={withCondition}
          key={`badge-calf-${index}`}
        />
      ))}
    </div>
  )
}

export default TypeCalvesBadge
