import React, { memo } from 'react'
import { isEqual } from 'lodash'
import * as Sentry from '@sentry/react'
import { useSelector } from 'react-redux'
import UserFeedback from '#/components/ErrorBoundary/UserFeedback'
import { getError } from '#/store/ducks/error'
import { getUserInfo } from '#/store/ducks/auth'
import { getCurrentFarmSlug } from '#/store/ducks/farm'

function ErrorBoundary({ children }) {
  const error = useSelector(getError, isEqual)

  const userInfo = useSelector(getUserInfo, isEqual)
  const farmSlug = useSelector(getCurrentFarmSlug, isEqual)
  const whitelist = ['/system/settings/tests']

  /**
   * Erros retornados pelo backend
   */
  if (error && !whitelist.includes(window.location.pathname)) {
    return <UserFeedback error={error} backend />
  }

  /**
   * Adiciona informações de usuário ao sentry
   */
  const sessionScope = scope => {
    scope.setUser({
      id: userInfo.uuid,
      email: userInfo.email,
      username: userInfo.name,
    })

    if (farmSlug) {
      scope.setTag('farm', farmSlug)
    }
  }

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack }) => (
        <UserFeedback error={error} componentStack={componentStack} />
      )}
      beforeCapture={sessionScope}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default memo(ErrorBoundary)
