import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export default function InfoIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 0 24 24"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M12.8,16.5L12.8,16.5c0.5,0,0.9-0.4,0.9-0.9v-3.8c0-0.5-0.4-0.9-0.9-0.9h0c-0.5,0-0.9,0.4-0.9,0.9v3.8,C11.9,16.1,12.3,16.5,12.8,16.5z" />
      <path d="M21.5,8.3c-0.5-1.2-1.2-2.2-2-3s-1.9-1.5-3-2c-1.2-0.5-2.4-0.7-3.7-0.7s-2.5,0.2-3.7,0.7C8,3.8,7,4.5,6.1,5.3,s-1.5,1.9-2,3C3.6,9.5,3.4,10.7,3.4,12s0.2,2.5,0.7,3.7c0.5,1.2,1.2,2.2,2,3s1.9,1.5,3,2c1.2,0.5,2.4,0.7,3.7,0.7s2.5-0.2,3.7-0.7,c1.2-0.5,2.2-1.2,3-2s1.5-1.9,2-3c0.5-1.2,0.7-2.4,0.7-3.7S22,9.5,21.5,8.3z M18.2,17.4c-1.5,1.5-3.3,2.2-5.4,2.2s-3.9-0.7-5.4-2.2,S5.2,14.1,5.2,12S6,8.1,7.4,6.6s3.3-2.2,5.4-2.2s3.9,0.7,5.4,2.2s2.2,3.3,2.2,5.4S19.6,15.9,18.2,17.4z" />
      <circle cx="12.8" cy="9.1" r="0.9" />
    </SvgIcon>
  )
}
