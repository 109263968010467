import { Box, Divider, Typography } from '@mui/material'
import { isEmpty, uniqueId } from 'lodash'
import React from 'react'
import { styled } from '@mui/material/styles'
import Translate from '#/components/Translate'
import FormatCell from '#/components/FormatCell'

const Item = styled('div')({
  display: 'flex',
  gap: 5,
  lineHeight: 1.5,
})

const Wrapper = styled(Box)({
  margin: '5px 0 5px 20px',
})

const Title = styled(Typography)({
  fontSize: 12,
  paddingBottom: 5,
})

export default function BirthInfo({ birthInfos }) {
  const infos = [
    {
      label: Translate({ messageKey: 'birth_weight' }),
      allowed: birthInfos?.animal_weight_text,
      value: birthInfos?.animal_weight_text,
    },
    {
      label: Translate({ messageKey: 'navel_cure' }),
      allowed: birthInfos?.navel_cure,
      value: (
        <Wrapper>
          <Item>
            <span>{Translate({ messageKey: 'performed_female' })}:</span>
            <FormatCell
              type="boolean"
              value={birthInfos?.navel_cure?.performed}
              onlyText
            />
          </Item>
          <Item>
            <span>{Translate({ messageKey: 'observation' })}:</span>
            <span>{birthInfos?.navel_cure?.observations}</span>
          </Item>
        </Wrapper>
      ),
    },
    {
      label: Translate({ messageKey: 'colostration' }),
      allowed: !isEmpty(birthInfos?.colostrum),
      value: birthInfos?.colostrum?.map((line, index) => {
        return (
          <Wrapper key={uniqueId(line?.timestamp)}>
            <Item>
              <span>{Translate({ messageKey: 'supply_time' })}:</span>
              <FormatCell type="timestamp" value={line?.timestamp} />
            </Item>
            <Item>
              <span>{Translate({ messageKey: 'quantity_supplied' })}:</span>
              <span>{line?.amount}</span>
            </Item>
            <Item>
              <span>{Translate({ messageKey: 'colostrum_type' })}:</span>
              <span>{line?.type?.text}</span>
            </Item>
            <Item>
              <span>{Translate({ messageKey: 'brix' })}:</span>
              <span>{line?.brix}</span>
            </Item>
            <Item>
              <span>{Translate({ messageKey: 'observation' })}:</span>
              <span>{line?.observations}</span>
            </Item>
            {birthInfos?.colostrum.length > index + 1 ? (
              <Divider style={{ marginTop: 5 }} />
            ) : null}
          </Wrapper>
        )
      }),
    },
    {
      label: Translate({ messageKey: 'transfer_of_passive_immunity' }),
      allowed: birthInfos?.passive_immunity_transfer,
      value: (
        <Wrapper>
          <Item>
            <span>{Translate({ messageKey: 'evaluation_date' })}:</span>
            <FormatCell
              type="timestamp"
              value={birthInfos?.passive_immunity_transfer?.timestamp}
            />
          </Item>
          <Item>
            <span>{Translate({ messageKey: 'brix' })}:</span>
            <span>{birthInfos?.passive_immunity_transfer?.brix}</span>
          </Item>
          <Item>
            <span>{Translate({ messageKey: 'serum_proteins' })}:</span>
            <span>{birthInfos?.passive_immunity_transfer?.serum_proteins}</span>
          </Item>
        </Wrapper>
      ),
    },
  ]

  return infos
    ?.filter(i => i.allowed)
    ?.map(({ label, value }) => (
      <Title variant="body2" component="div" key={uniqueId(label)}>
        <strong>{label}: </strong>
        {value}
      </Title>
    ))
}
