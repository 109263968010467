import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getCurrentFarmSlug, resetCurrentFarm } from '../farm'
import { getCurrentCompanySlug, resetCurrentCompany } from '../company'
import {
  loadApiVersion,
  setCurrentLanguage,
  resetCurrentLanguage,
  setCurrentTheme,
} from '../app'
import ws from '#/utils/websocket'
import tryCache from '#/utils/tryCache'
import { setAnalyticsUserInfo } from '#/store/services/Firebase/Analytics'
import { firebaseSignIn } from '#/store/services/Firebase/SignIn'
import { firebaseSignOut } from '#/store/services/Firebase/SignOut'
import { endpoint } from '#/store/services/endpoints'
import { fetcher } from '#/store/hooks/request'

const defaultState = {
  login: {
    status: null,
    error: null,
  },
  firebase: {
    firebaseToken: null,
    messagingToken: null,
    status: null,
    error: null,
  },
  logout: {
    status: null,
    error: null,
  },
  user: null,
  access: {
    status: null,
    error: null,
    userCompanies: [],
    userFarms: [],
  },
  permissions: {
    system: {
      expires: 0,
    },
    farm: {
      expires: 0,
    },
    company: {
      expires: 0,
    },
  },
  help_pages: {
    error: null,
    list: null,
    expires: 0,
  },
}

/**
 * @function getPermissions - get user permissions based on his context
 * @param {string} context - current context 'system', 'farm', 'company'
 * */
export const getPermissions = createAsyncThunk(
  'auth/permissions',
  async ({ context, farmSlug, companySlug }, thunkAPI) => {
    const state = thunkAPI.getState()

    // checks if context permissions expired
    if (
      !tryCache.isExpired(state?.auth?.permissions?.[context]?.expires) &&
      state?.auth?.permissions?.[context]?.slug === farmSlug
    ) {
      return state?.auth?.permissions?.[context]
    }

    try {
      let url
      switch (context) {
        case 'farm':
          url = endpoint.user.farm_permissions({
            farm: farmSlug || getCurrentFarmSlug(state),
          })
          break
        case 'company':
          url = endpoint.user.company_permissions({
            company: companySlug || getCurrentCompanySlug(state),
          })
          break
        default:
          url = endpoint.user.system_permissions()
          break
      }

      const { data: permissions } = await fetcher({ controller: url })

      return {
        context,
        list: permissions,
        slug: farmSlug || companySlug,
        expires: tryCache.expiresIn(1),
      }
    } catch ({ message, code }) {
      return thunkAPI.rejectWithValue({ error: { message, code } })
    }
  },
)

/**
 * @function loadHelpPages - get list of help pages
 * */
export const loadHelpPages = createAsyncThunk(
  'auth/loadHelpPages',
  async (_, thunkAPI) => {
    try {
      const url = endpoint.database.help_pages.list()
      const { data: helpPages } = await fetcher({ controller: url })

      return helpPages
    } catch (e) {
      return thunkAPI.rejectWithValue({ message: e.message })
    }
  },
)

export const login = createAsyncThunk('auth/login', async (_, thunkAPI) => {
  try {
    // get user info
    const userUrl = endpoint.user.info()
    const { data: user } = await fetcher({ controller: userUrl })

    setAnalyticsUserInfo(user)

    // get user system permissions
    thunkAPI.dispatch(getPermissions({ context: 'system' }))

    // get help pages
    thunkAPI.dispatch(loadHelpPages())

    // set user's app language
    thunkAPI.dispatch(setCurrentLanguage({ language: user.locale }))

    // fetch api version
    thunkAPI.dispatch(loadApiVersion())

    // set user's app theme
    thunkAPI.dispatch(setCurrentTheme({ theme: user.theme }))

    // check if user is farm and has active farms
    user.isSuspended = !user.has_company && user.active_farm_count === 0

    return { user }
  } catch (e) {
    return thunkAPI.rejectWithValue({ message: e.message })
  }
})

export const getFirebaseToken = createAsyncThunk(
  'auth/getFirebaseToken',
  async (_, thunkAPI) => {
    try {
      const { firebaseToken, messagingToken } = await firebaseSignIn()
      if (messagingToken) {
        /* Registering the device token with the backend. */
        fetcher({
          method: 'post',
          controller: endpoint.auth.firebase_register_device(),
          params: {
            firebase_token: messagingToken,
          },
        })
      }
      return { firebaseToken, messagingToken }
    } catch (e) {
      return thunkAPI.rejectWithValue({ message: e.message })
    }
  },
)

export const resetFirebaseToken = createAsyncThunk(
  'auth/resetFirebaseToken',
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState()
      await firebaseSignOut(state.auth.firebase.messagingToken)

      thunkAPI.dispatch(resetFirebase())
    } catch (e) {
      return thunkAPI.rejectWithValue({ message: e.message })
    }
  },
)

export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  try {
    // logout from firebase
    const state = thunkAPI.getState()
    await firebaseSignOut(state.auth.firebase.messagingToken)

    // clears all localStorage
    localStorage.clear()
    thunkAPI.dispatch(resetLogin())
    thunkAPI.dispatch(resetCurrentFarm())
    thunkAPI.dispatch(resetCurrentCompany())
    thunkAPI.dispatch(resetCurrentLanguage())
    // disconnect from echo
    ws.disconnect()
  } catch (e) {
    return thunkAPI.rejectWithValue({ message: e.message })
  }
})

export const authSlice = createSlice({
  name: 'auth',
  initialState: defaultState,
  reducers: {
    resetFirebase(state) {
      state.firebase = defaultState.firebase
    },
    resetLogin(state) {
      state.login = defaultState.login
      state.user = defaultState.user
      state.access = defaultState.access
      state.permissions = defaultState.permissions
    },
    resetFarmPermission(state) {
      state.permissions.farm = defaultState.permissions.farm
    },
    resetCompanyPermission(state) {
      state.permissions.company = defaultState.permissions.company
    },
  },
  extraReducers: builder => {
    builder
      // LOGIN
      .addCase(login.pending, state => {
        state.login.status = 'started'
      })
      .addCase(login.fulfilled, (state, action) => {
        state.login.status = 'done'
        state.user = action.payload?.user
      })
      .addCase(login.rejected, (state, action) => {
        state.login.status = 'failed'
        state.login.error = action.payload?.message
      })
      // RESET TOKEN FIREBASE
      .addCase(resetFirebaseToken.fulfilled, state => {
        state.firebase.status = 'done'
      })
      .addCase(resetFirebaseToken.rejected, (state, action) => {
        state.firebase.status = 'failed'
        state.firebase.error = action.payload?.message
      })
      // TOKEN FIREBASE
      .addCase(getFirebaseToken.pending, state => {
        state.firebase.error = null
        state.firebase.status = 'started'
      })
      .addCase(getFirebaseToken.fulfilled, (state, action) => {
        state.firebase.firebaseToken = action.payload?.firebaseToken
        state.firebase.messagingToken = action.payload?.messagingToken
        state.firebase.status = 'done'
      })
      .addCase(getFirebaseToken.rejected, (state, action) => {
        state.firebase.status = 'failed'
        state.firebase.error = action.payload?.message
      })
      // PERMISSÕES
      .addCase(getPermissions.pending, (state, action) => {
        state.permissions[action.meta.arg.context].status = 'started'
      })
      .addCase(getPermissions.fulfilled, (state, action) => {
        state.permissions[action.meta.arg.context] = {
          ...(action?.payload ?? {}),
          status: 'done',
        }
      })
      .addCase(getPermissions.rejected, (state, action) => {
        // Adiciona delay para evitar ddos
        state.permissions[action.meta.arg.context] = {
          ...(action?.payload ?? {}),
          status: 'failed',
          expires: tryCache.expiresIn(0.1),
        }
      })
      // LOGOUT
      .addCase(logout.pending, state => {
        state.logout.status = 'started'
      })
      .addCase(logout.fulfilled, state => {
        state.login = defaultState.login
        state.firebase = defaultState.firebase
        state.logout = defaultState.logout
        state.user = defaultState.user
        state.permissions = defaultState.permissions
      })
      .addCase(logout.rejected, (state, action) => {
        state.logout.status = 'failed'
        state.logout.error = action.payload?.message
      })
      //HELP PAGES
      .addCase(loadHelpPages.fulfilled, (state, action) => {
        state.help_pages.list = action.payload
        state.help_pages.expires = tryCache.expiresIn(15)
        state.help_pages.error = null
      })
      .addCase(loadHelpPages.rejected, (state, action) => {
        state.help_pages.error = action.payload?.message
      })
  },
  selectors: {
    getLoginInfo: state => state?.user ?? null,
    getFirebaseInfo: state => state?.firebase ?? null,
    getUserInfo: state => state?.user ?? {},
    getHelpPages: state => state?.help_pages ?? null,
    getUserPermissions: (state, context, slug) => {
      const permission = state?.permissions?.[context]

      /**
       * System has no slug
       * Farm/Company compare only for warranty
       **/
      if (context !== 'system' && permission.slug === slug) {
        return permission
      }

      return {}
    },
  },
})

export const {
  actions: {
    resetLogin,
    resetFirebase,
    resetFarmPermission,
    resetCompanyPermission,
  },
  selectors: {
    getLoginInfo,
    getFirebaseInfo,
    getUserInfo,
    getHelpPages,
    getUserPermissions,
  },
} = authSlice

export default authSlice.reducer
