import React from 'react'
import { LinearProgress, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { isDev } from '#/utils'
import Translate from '#/components/Translate'
import LogoIcon from '#/icons/Logo'

const Wrapper = styled(`div`)(({ theme }) => ({
  'position': 'absolute',
  'zIndex': '1000000',
  'width': '100%',
  'height': '100%',
  'background': theme.palette.mode === 'light' ? 'white' : '#333',
  'display': 'flex',
  'justifyContent': 'center',
  'alignItems': 'center',
  'flexDirection': 'column',
  '& > svg': {
    width: '200px',
  },
  ...(theme.palette.mode === 'dark' && {
    '& > span': {
      color: 'white',
    },
  }),
}))

export default function PageLoader() {
  const [isLoading, setIsLoading] = React.useState(true)
  const [progress, setProgress] = React.useState(0)
  const { pathname } = window.location

  const allowlist = ['/maintenance']

  React.useEffect(() => {
    if (allowlist.includes(pathname)) {
      setIsLoading(true)
    }

    return () => setIsLoading(true)
  }, [])

  React.useEffect(() => {
    // Timeout to simulate loading completion with a delay for development purposes
    const timeout = setTimeout(() => setIsLoading(false), isDev ? 100 : 2000)

    // Interval to increment progress until it reaches 100%
    const timer = setInterval(() => {
      setProgress(prevState => (prevState < 100 ? prevState + 10 : 100))
    }, 150)

    // Cleanup functions for the timeout and interval when the component unmounts
    return () => {
      clearTimeout(timeout)
      clearInterval(timer)
    }
  }, [])

  return isLoading ? (
    <Wrapper>
      <LogoIcon style={{ fontSize: 160 }} />
      <Typography variant="overline" sx={{ mt: '30px' }}>
        {Translate({ messageKey: 'loading_just_a_moment' })}
      </Typography>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{ width: '200px' }}
      />
    </Wrapper>
  ) : null
}
