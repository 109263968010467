import React from 'react'
import { styled } from '@mui/material/styles'
import procEnv from '#/utils/processEnvironment'

const StyledTag = styled('div')(({ color }) => ({
  width: 120,
  height: 22,
  position: 'fixed',
  top: 8,
  left: -37,
  fontSize: '10px',
  lineHeight: '10px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9999,
  transform: 'rotate(-36deg)',
  pointerEvents: 'none',
  color: '#fff',
  opacity: 0.9,
  backgroundColor: color,
}))

const EnvTag = () => {
  const env = procEnv()
  if (env === 'production') return null

  const faviconEl = document.querySelector('link[rel="icon"]')
  const apticonEl = document.querySelector('link[rel="apple-touch-icon"]')

  const color = {
    dev: '#bb313f',
    staging: '#00bfe0',
    test: '#d0a500',
  }

  const envColor = color[env]

  if (envColor) {
    const iconURI = `/assets/images/favicon-gray-${env}.png`
    faviconEl.href = iconURI
    apticonEl.href = iconURI
    return <StyledTag color={envColor}>{env}</StyledTag>
  }

  return null
}
export default EnvTag
