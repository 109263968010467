import React from 'react'
import isNil from 'lodash/isNil'
import Tooltip from '@mui/material/Tooltip'

const TypeEnum = ({ value, bold, ...rest }) => {
  if (isNil(value)) return <span>-</span>

  const style = bold ? { fontWeight: 500 } : null

  return (
    <Tooltip title={value?.text} aria-label={value?.text}>
      <div className={rest.classes?.overMe} style={style}>
        {value?.text}
      </div>
    </Tooltip>
  )
}
export default TypeEnum
