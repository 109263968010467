import React from 'react'
import { Avatar, Badge } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  mdiBell,
  mdiCheckBold,
  mdiCloseThick,
  mdiExclamationThick,
  mdiTimerSand,
} from '@mdi/js'
import Icon from '@mdi/react'

import FavoriteBorder from '@mui/icons-material/FavoriteBorder'
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar'
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff'
import Router from '@mui/icons-material/Router'
import OpacityIcon from '@mui/icons-material/Opacity'
import clsx from 'clsx'
import CornIcon from '#/icons/Corn'
import OldColors from '#/styles/Old/Colors'
import ReproductionIcon from '#/icons/Reproduction'
import Colors from '#/styles/Colors'
import HealthIcon from '#/icons/Health'

const useStyles = makeStyles(() => ({
  dot: {
    height: 10,
    minWidth: 10,
    top: 5,
    right: 5,
  },
  normal: {
    backgroundColor: OldColors.dtr_normal,
  },
  moderated: {
    backgroundColor: OldColors.dtr_moderated,
  },
  intense: {
    backgroundColor: OldColors.dtr_intense,
  },
}))

export default function AlertIcon(props) {
  const { event_type, virtual_type, health_status, dtr } = props
  const classes = useStyles()

  if (event_type === 'success') {
    return (
      <Avatar style={{ backgroundColor: Colors.success }}>
        <Icon path={mdiCheckBold} size={1} />
      </Avatar>
    )
  }

  if (event_type === 'success-with-warnings') {
    return (
      <Avatar style={{ backgroundColor: Colors.warning }}>
        <Icon path={mdiExclamationThick} size={1} />
      </Avatar>
    )
  }

  if (event_type === 'failed') {
    return (
      <Avatar style={{ backgroundColor: Colors.danger }}>
        <Icon path={mdiCloseThick} size={1} />
      </Avatar>
    )
  }

  if (event_type === 'queued' || event_type === 'started') {
    return (
      <Avatar style={{ backgroundColor: Colors.info }}>
        <Icon path={mdiTimerSand} size={1} />
      </Avatar>
    )
  }

  if (event_type?.match(/reproduction*/)) {
    if (event_type?.match(/birth*/)) {
      return (
        <Avatar style={{ backgroundColor: OldColors.delivery_alert }}>
          <FavoriteBorder style={{ color: '#fff' }} />
        </Avatar>
      )
    }
    return (
      <Badge
        color="primary"
        classes={{ dot: clsx(classes.dot, classes[dtr]) }}
        variant="dot"
        invisible={!dtr}
      >
        <Avatar style={{ backgroundColor: OldColors.heat_alert }}>
          <ReproductionIcon style={{ color: '#fff' }} />
        </Avatar>
      </Badge>
    )
  }

  if (event_type?.match(/health.condition*/)) {
    if (virtual_type === 'health.condition_confirmation.user') {
      return (
        <Avatar style={{ backgroundColor: OldColors.milk_return }}>
          <OpacityIcon style={{ color: '#fff' }} />
        </Avatar>
      )
    }
    return (
      <Avatar
        style={{
          backgroundColor:
            health_status === 'critical'
              ? OldColors.health_critical_alert
              : OldColors.health_observation_alert,
        }}
      >
        <HealthIcon style={{ color: '#fff' }} />
      </Avatar>
    )
  }

  if (event_type?.match(/rumination*/)) {
    return (
      <Avatar style={{ backgroundColor: OldColors.nutrition_alert }}>
        <CornIcon style={{ color: '#fff' }} />
      </Avatar>
    )
  }

  if (event_type?.match(/farm*/)) {
    if (event_type?.match(/connected*/)) {
      return (
        <Avatar style={{ backgroundColor: Colors.success }}>
          <SignalWifi4BarIcon style={{ color: '#fff' }} />
        </Avatar>
      )
    }
    return (
      <Avatar style={{ backgroundColor: '#000' }}>
        <SignalWifiOffIcon style={{ color: '#fff' }} />
      </Avatar>
    )
  }

  if (event_type?.match(/ccom*/)) {
    if (event_type?.match(/connected*/)) {
      return (
        <Avatar style={{ backgroundColor: Colors.success }}>
          <Router style={{ color: '#fff' }} />
        </Avatar>
      )
    }
    return (
      <Avatar style={{ backgroundColor: '#000' }}>
        <Router style={{ color: '#fff' }} />
      </Avatar>
    )
  }

  return (
    <Avatar style={{ backgroundColor: Colors.info }}>
      <Icon path={mdiBell} size={1} />
    </Avatar>
  )
}
