import { Box, DialogContent } from '@mui/material'
import React from 'react'
import { uniqueId } from 'lodash'
import Typography from '@mui/material/Typography'
import Colors from '#/styles/Old/Colors'
import Translate from '#/components/Translate'
import CowmedGrid from '#/components/Grid'

function statusColor(status) {
  switch (status) {
    case 'neutral':
      return Colors.muted

    case 'failed':
    case 'negative':
      return Colors.error

    case 'success':
    case 'positive':
      return Colors.success

    case 'success-with-warnings':
      return Colors.darkWarning

    default:
      break
  }
}

function statusText(text) {
  switch (text) {
    case 'failed':
      return Translate({ messageKey: 'unprocessed' })

    case 'success':
      return Translate({ messageKey: 'processed_successfully' })

    case 'success-with-warnings':
      return Translate({ messageKey: 'processed_with_errors' })

    default:
      break
  }
}
export default function JobDetailContent(props) {
  const { result } = props
  const maxHeight = result?.length ? '50vh' : null
  return (
    <DialogContent sx={{ padding: 0, overflow: 'auto', maxHeight }}>
      {result?.map(({ identifier, status, line, output }) => {
        return (
          <Box
            key={uniqueId(identifier)}
            sx={{
              mb: '1px',
              borderLeft: `5px solid ${statusColor(status)}`,
              borderBottom: '1px solid #f1f1f1',
            }}
          >
            <CowmedGrid container sx={{ p: 1, display: 'flex', flexGrow: 1 }}>
              <CowmedGrid size={{ xs: 12, sm: 5 }}>
                <Typography
                  variant="subtitle2"
                  style={{ color: statusColor(status) }}
                >
                  {statusText(status)}
                </Typography>
                <Typography sx={{ color: Colors.textSecondary, fontSize: 12 }}>
                  {Translate({ messageKey: 'identification', suffix: ': ' })}
                  <span>
                    {identifier ??
                      Translate({ messageKey: 'without_information' })}
                  </span>
                </Typography>
                <Typography sx={{ color: Colors.textSecondary, fontSize: 12 }}>
                  {Translate({ messageKey: 'row', suffix: ': ' })}
                  <span>{line ?? '-'}</span>
                </Typography>
              </CowmedGrid>
              <CowmedGrid size={{ xs: 12, sm: 7 }}>
                {output?.map(item => (
                  <Typography
                    key={uniqueId(item.text)}
                    sx={{ color: statusColor(item.status), fontSize: 12 }}
                  >
                    {item.text}
                  </Typography>
                ))}
              </CowmedGrid>
            </CowmedGrid>
          </Box>
        )
      })}
    </DialogContent>
  )
}
