import React from 'react'
import { TourProvider } from '@reactour/tour'
import Colors from '#/styles/Colors'

export default function ProviderReactTour(props) {
  return (
    <TourProvider
      styles={{
        popover: (base, { position, verticalAlign }) => ({
          ...base,
          '--reactour-accent': Colors.primary,
          'borderRadius': 8,
          'marginLeft': ['bottom', 'top'].includes(position) ? 10 : 0,
          'marginTop': ['bottom'].includes(verticalAlign) ? 10 : 0,
        }),
        maskArea: base => ({ ...base, rx: 16 }),
        close: base => ({
          ...base,
          right: 8,
          top: 8,
          width: 11,
        }),
      }}
    >
      {props.children}
    </TourProvider>
  )
}
