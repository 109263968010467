import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export default function HeatStrengthIcon({ status = 3, ...rest }) {
  // Define a sequência de cores com base no strength
  const colorSequences = {
    1: ['#FBCF1A', '#D9D9D9', '#D9D9D9', '#D9D9D9', '#D9D9D9'],
    2: ['#FBCF1A', '#FBCF1A', '#D9D9D9', '#D9D9D9', '#D9D9D9'],
    3: ['#5CCB5F', '#5CCB5F', '#5CCB5F', '#D9D9D9', '#D9D9D9'],
    4: ['#009929', '#009929', '#009929', '#009929', '#D9D9D9'],
    5: ['#009929', '#009929', '#009929', '#009929', '#009929'],
  }

  return (
    /* prettier-ignore */
    <SvgIcon viewBox="0 0 24 24" {...rest}>
      <rect x="1.79913" y="15.1619" width="3.54502" height="3.09781" rx="1.77251" fill={colorSequences[status][0]}/>
      <rect x="6.01355" y="12.6410" width="3.54502" height="5.61917" rx="1.77251" fill={colorSequences[status][1]}/>
      <rect x="10.2273" y="9.97473" width="3.54502" height="8.28484" rx="1.77251" fill={colorSequences[status][2]}/>
      <rect x="14.4421" y="7.74188" width="3.54502" height="10.5182" rx="1.77251" fill={colorSequences[status][3]}/>
      <rect x="18.6558" y="5.73987" width="3.54502" height="12.5199" rx="1.77251" fill={colorSequences[status][4]}/>
    </SvgIcon>
  )
}
