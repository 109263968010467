import React from 'react'
import { Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import Translate from '#/components/Translate'
import HeatStrengthIcon from '#/icons/HeatStrength'

const Content = styled('div')(({ align, flexDirection }) => ({
  display: 'flex',
  alignItems: align,
  flexDirection,
  gap: flexDirection === 'row' ? 10 : 0,
}))

const Label = styled('p')({
  margin: 'auto 0',
  lineHeight: '16px',
  fontWeight: 500,
  color: '#666',
})

const TypeHeatStrength = props => {
  const {
    status,
    text,
    with_label = true,
    alignItems = 'start',
    flexDirection = 'column',
  } = props
  if (!status) return <span>-</span>

  return (
    <Tooltip title={`${Translate({ messageKey: 'heat_strength' })} - ${text}`}>
      <Content align={alignItems} flexDirection={flexDirection}>
        <HeatStrengthIcon status={status} style={{ fontSize: 30 }} />
        {with_label && <Label>{text}</Label>}
      </Content>
    </Tooltip>
  )
}

export default TypeHeatStrength
