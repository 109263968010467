import { deleteToken, getMessaging, isSupported } from 'firebase/messaging'
import { getAuth, signOut } from 'firebase/auth'
import { endpoint } from '#/store/services/endpoints'
import { fetcher } from '#/store/hooks/request'

/**
 * It removes the firebase token from the API, deletes the firebase token from the firebase server, and
 * unregisters the service workers
 * @param firebase_token - The firebase token that was generated when the user logged in.
 */
export async function firebaseSignOut(firebase_token) {
  isSupported().then(hasFirebaseMessagingSupport => {
    if (hasFirebaseMessagingSupport) {
      const messaging = getMessaging()
      const auth = getAuth()

      signOut(auth)
        .then(async () => {
          if (firebase_token && messaging) {
            /* Remove firebase customToken from API. */
            fetcher({
              method: 'post',
              controller: endpoint.auth.firebase_remove_device(),
              params: { firebase_token },
            }).catch(error => console.error(error))

            /* Deleting the firebase token from the firebase server. */
            deleteToken(messaging)

            /* Unregistering the service workers. */
            navigator.serviceWorker
              .getRegistrations()
              .then(registrations =>
                registrations.map(registration => registration.unregister()),
              )
          }
        })
        .catch(e => {
          console.error(e)
          throw new Error(e.message)
        })
    }
  })
}
