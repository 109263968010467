import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import isEmpty from 'lodash/isEmpty'
import ComunicationIcon from '@mui/icons-material/SignalWifi2Bar'
import Fonts from '#/styles/Fonts'
import Colors from '#/styles/Colors'
import Translate from '#/components/Translate'
import Moment from '#/components/Moment'

const getStatus = ({ last_status_update, last_sync_update }) => {
  let style = { color: Colors.success }
  let tooltipStyle = {
    last_sync_update: {
      color: Colors.muted,
    },
    last_status_update: {
      color: Colors.muted,
    },
  }

  if (last_status_update === null && last_sync_update === null) {
    style = { color: Colors.muted }
  }

  if (Moment.diff('now', last_status_update, 'minutes') > 45) {
    // problema no codigo do rabuske
    tooltipStyle.last_status_update = { color: Colors.warning }
  }

  if (Moment.diff('now', last_sync_update, 'minutes') > 1.5 * 60) {
    // problemas de conexão/
    style = { color: Colors.danger }
    tooltipStyle.last_sync_update = { color: Colors.danger }
  } else if (Moment.diff('now', last_sync_update, 'minutes') > 45) {
    // problemas de conexão
    style = { color: Colors.warning }
    tooltipStyle.last_sync_update = { color: Colors.warning }
  }

  return {
    style,
    tooltipStyle,
  }
}

const UnitStatus = props => {
  const style = {
    icon: {
      fontSize: Fonts.fontSize.XXL,
      marginRight: 5,
    },
    tooltip: {
      fontSize: Fonts.fontSize.S,
      fontWeight: Fonts.fontWeight.bold,
      lineHeight: 1.5,
    },
  }

  const { unit, customStyle = {}, withTitles = true } = props

  const status = getStatus(unit)
  const tooltipId = `tooltip-unit-${Math.floor(Math.random() * 1000)}`
  return (
    <span style={{ ...customStyle }}>
      <Tooltip
        id={tooltipId}
        title={
          <div>
            {withTitles ? (
              <h3>
                {Translate({ messageKey: 'unit' })}: {unit.name}
              </h3>
            ) : null}
            <div
              style={{
                ...style.tooltip,
                ...status.tooltipStyle.last_status_update,
              }}
            >
              {Translate({ messageKey: 'last_status' })}:{' '}
              {isEmpty(unit.last_status_update)
                ? '-'
                : Moment.view(unit.last_status_update)}
            </div>
            <div
              style={{
                ...style.tooltip,
                ...status.tooltipStyle.last_sync_update,
              }}
            >
              {Translate({ messageKey: 'last_cloud_sync' })}:{' '}
              {isEmpty(unit.last_sync_update)
                ? '-'
                : Moment.view(unit.last_sync_update)}
            </div>
          </div>
        }
      >
        <span
          data-for={tooltipId}
          style={{
            ...style.icon,
            ...status.style,
          }}
        >
          <ComunicationIcon />
        </span>
      </Tooltip>
    </span>
  )
}

export default UnitStatus
