import { useCallback, useEffect, useRef } from 'react'

/**
 * Sets a function to run with the desired delay
 *
 * @param {function} callback - Function to be delayed
 * @param {number} delay - Delay amount in milliseconds
 *
 * @returns {object} With a reset and clear functions
 *
 * @example
 * const { clear, reset } = useTimeout(() => setCount(0), 1000)
 * */
export default function useTimeout(callback, delay) {
  const callbackRef = useRef(callback)
  const timeoutRef = useRef()

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  const set = useCallback(() => {
    timeoutRef.current = setTimeout(() => callbackRef.current(), delay)
  }, [delay])

  const clear = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current)
  }, [])

  useEffect(() => {
    set()
    return clear
  }, [delay, set, clear])

  const reset = useCallback(() => {
    clear()
    set()
  }, [clear, set])

  return { reset, clear }
}
