import React, { useEffect, useState } from 'react'
import { mdiDownload, mdiFileDownload, mdiContentCopy } from '@mdi/js'
import { Button, Tooltip, DialogActions, IconButton } from '@mui/material'
import Icon from '@mdi/react'
import { isEmpty } from 'lodash'
import { endpoint } from '#/store/services/endpoints'
import Translate from '#/components/Translate'
import { useStyles } from '#/components/Jobs/JobDetail/styles'
import { fetcher } from '#/store/hooks/request'

export default function JobDetailFooter(props) {
  const { jobSlug, has_result_file, has_file, result } = props
  const classes = useStyles()
  const [fileController, setFileController] = useState(null)
  const [fileURLController, setFileURLController] = useState(null)

  const unprocessed = result
    ?.filter(({ status }) => status === 'failed')
    .map(({ identifier }) => identifier)

  const copyFailedCTech = () => {
    return navigator.clipboard.writeText(unprocessed.join(', '))
  }

  /**
   * This code block is rendering a button to copy the list of failed job identifiers
   * to the clipboard if there are any failed jobs in the `result` array.
   * */
  const CopyButton = () => {
    if (!isEmpty(unprocessed)) {
      return (
        <Tooltip title={Translate({ messageKey: 'copy_unprocessed' })}>
          <IconButton onClick={copyFailedCTech}>
            <Icon path={mdiContentCopy} size={1} />
          </IconButton>
        </Tooltip>
      )
    }
  }

  /**
   * Get file url on AWS
   */
  useEffect(() => {
    if (fileController) {
      fetcher({ controller: fileController })
        .then(response => {
          if (response?.data) setFileURLController(response.data)
        })
        .catch(error => console.error('e', error))
    }
  }, [fileController])

  /**
   * Save file without newpage
   */
  useEffect(() => {
    if (fileURLController && window.fetch && window.URL) {
      window
        .fetch(fileURLController)
        .then(response => response.blob())
        .then(blob => {
          const fileName = fileURLController.split('?')[0].split('/').pop()
          // Cria um URL para o blob de dados obtidos
          const url = window.URL.createObjectURL(blob)
          // Cria um elemento de link
          const link = document.createElement('a')
          // Define o URL para o link
          link.href = url
          // Define o nome de arquivo para download
          link.download = fileName
          // Adiciona o link ao corpo do documento
          document.body.appendChild(link)
          // Simula um clique no link para iniciar o download
          link.click()
          // Remove o link após o download ser iniciado com sucesso
          link.parentNode.removeChild(link)
        })
    }
    setFileController(null)
  }, [fileURLController, jobSlug])

  /**
   * Create footer buttons
   */
  let footer = []
  has_result_file &&
    footer.push({
      label: Translate({ messageKey: 'export_result_plural' }),
      icon: mdiFileDownload,
      onClick: () => setFileController(endpoint.job.result({ job: jobSlug })),
    })
  has_file &&
    footer.push({
      label: Translate({ messageKey: 'download_file' }),
      icon: mdiDownload,
      onClick: () => setFileController(endpoint.job.file({ job: jobSlug })),
    })

  return (
    !isEmpty(footer) && (
      <DialogActions sx={{ pl: 0, pr: 0, pb: 0 }}>
        <CopyButton />
        {footer.map(btn => {
          return (
            <Tooltip title={btn.label} key={btn.label}>
              <Button
                key={btn.label}
                color="secondary"
                variant="outlined"
                className={classes.download}
                disabled={!!fileController}
                onClick={() => btn.onClick()}
              >
                <span className={classes.download_label}>{btn.label}</span>
                <Icon path={btn.icon} size={1} />
              </Button>
            </Tooltip>
          )
        })}
      </DialogActions>
    )
  )
}
