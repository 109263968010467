import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export default function PerfilIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 0 15 17"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M9.9668 4.34219C9.9668 3.67915 9.7034 3.04327 9.23456 2.57443C8.76572 2.10559 8.12984 1.84219 7.4668 1.84219C6.80376 1.84219 6.16787 2.10559 5.69903 2.57443C5.23019 3.04327 4.9668 3.67915 4.9668 4.34219C4.9668 5.00523 5.23019 5.64112 5.69903 6.10996C6.16787 6.5788 6.80376 6.84219 7.4668 6.84219C8.12984 6.84219 8.76572 6.5788 9.23456 6.10996C9.7034 5.64112 9.9668 5.00523 9.9668 4.34219ZM3.4668 4.34219C3.4668 3.28133 3.88822 2.26391 4.63837 1.51377C5.38852 0.763621 6.40593 0.342194 7.4668 0.342194C8.52766 0.342194 9.54508 0.763621 10.2952 1.51377C11.0454 2.26391 11.4668 3.28133 11.4668 4.34219C11.4668 5.40306 11.0454 6.42048 10.2952 7.17062C9.54508 7.92077 8.52766 8.34219 7.4668 8.34219C6.40593 8.34219 5.38852 7.92077 4.63837 7.17062C3.88822 6.42048 3.4668 5.40306 3.4668 4.34219ZM2.00742 14.8422H12.9262C12.648 12.8641 10.948 11.3422 8.89492 11.3422H6.03867C3.98555 11.3422 2.28555 12.8641 2.00742 14.8422ZM0.466797 15.4141C0.466797 12.3359 2.96055 9.84219 6.03867 9.84219H8.89492C11.973 9.84219 14.4668 12.3359 14.4668 15.4141C14.4668 15.9266 14.0512 16.3422 13.5387 16.3422H1.39492C0.882422 16.3422 0.466797 15.9266 0.466797 15.4141Z" />
    </SvgIcon>
  )
}
