import { isEmpty } from 'lodash'
import { styled } from '@mui/material/styles'
import { FindMyCowElements } from './Elements'

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 25,
})

export const FindMyCowWrapper = ({ id, data, type }) => {
  if (isEmpty(data)) return null

  return (
    <Wrapper key={id}>
      {data?.map(item => (
        <div key={item.id} id={item.id}>
          <FindMyCowElements {...item} type={type} />
        </div>
      ))}
    </Wrapper>
  )
}
