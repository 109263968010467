import React from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import { isNumber } from 'lodash'

/**
 * If the value prop is passed, return a determinate progress bar with the value prop as the value.
 * If the status prop is 'failed', return a determinate progress bar with the color prop set to 'error'.
 * Otherwise, return an indeterminate progress bar
 * @returns A LinearProgress component with a variant of determinate and a value of value.
 */
export default function LinearIndeterminate({ status, value, absolute }) {
  if (status === 'done' || !status) return null

  const sx = absolute
    ? { width: '100%', position: 'absolute', top: 0, left: 0 }
    : {}

  /**
   * If has value then change variant to determinate and set value
   */
  const overrides = isNumber(value) ? { value, variant: 'determinate' } : {}

  if (status === 'failed') {
    return (
      <LinearProgress variant="determinate" color="error" value={100} sx={sx} />
    )
  }

  return <LinearProgress {...overrides} sx={sx} />
}
