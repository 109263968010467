import { Typography } from '@mui/material'
import { isEmpty, isObject } from 'lodash'
import { styled } from '@mui/material/styles'
import FormatCell from '#/components/FormatCell'

const Wrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  margin: '0 -10px',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(5, 1fr)',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}))

const Session = styled('div')({
  margin: '0 8px 8px',
  overflow: 'hidden',
})

const Content = styled('div')({
  minHeight: 30,
  display: 'flex',
  alignItems: 'center',
})

export const FindMyCowGeneral = ({ id, data }) => {
  if (isEmpty(data)) return null

  return (
    <Wrapper key={id}>
      {data.map(({ id, title, value, type = 'string' }) => {
        return (
          <Session key={id}>
            <Typography variant="strong" color="#444">
              {title}
            </Typography>
            <Content>
              <FormatCell type={type} value={value} />
            </Content>
          </Session>
        )
      })}
    </Wrapper>
  )
}
