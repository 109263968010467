import React, { useState } from 'react'
import TagsInput from 'react-tagsinput'
import { TextField } from '@mui/material'
import './tagsinput.css'

function EmailInput({
  name,
  value,
  placeholder,
  label,
  disabled,
  required,
  helperText,
  error,
  fullWidth,
  variant,
  onChange,
}) {
  const [emails, setEmails] = useState(value ?? [])

  const handleEmailChange = tags => {
    const uniqueTags = [...new Set(tags)]
    setEmails(uniqueTags)
    onChange(uniqueTags)
  }

  function handlePasteSplit(data) {
    // Dividir a string em um array usando vírgula, ponto e vírgula ou espaço como delimitadores.
    const emailArray = data.split(/[;, ]+/)

    // Remover espaços em branco do início e do final de cada email no array.
    return emailArray
      .filter(email => email.trim() !== '')
      .map(email => email.trim())
  }

  return (
    <div>
      <TextField
        name={name}
        label={label}
        fullWidth={fullWidth}
        variant={variant}
        multiline
        disabled={disabled}
        required={required}
        error={error}
        helperText={helperText}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputComponent: TagsInput,
          inputProps: {
            value: emails,
            addKeys: [13, ';', ','],
            removeKeys: [],
            addOnPaste: true,
            onChange: handleEmailChange,
            pasteSplit: handlePasteSplit,
            inputProps: {
              placeholder,
            },
          },
        }}
      />
    </div>
  )
}

export default EmailInput
