import makeStyles from '@mui/styles/makeStyles'
import Colors from '#/styles/Old/Colors'

export default makeStyles(theme => ({
  card: {
    padding: '25px 20px',
    color: 'white',
    borderRadius: 4,
    backgroundColor: Colors.darkgrey,
  },
  ul: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  ulNested: {
    paddingLeft: theme.spacing(2),
  },
  list: {
    'margin': 0,
    '&:not(:last-child)': {
      paddingBottom: 20,
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    '&:not(:first-child)': {
      paddingTop: 20,
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: 16,
    color: 'rgb(177, 209, 54)',
    margin: 0,
    marginBottom: 6,
  },
  ListItem: {
    alignItems: 'center',
    padding: '2px 0',
    display: 'flex',
  },
  ItemTitle: {
    fontWeight: 'bold',
    marginRight: 5,
  },
  subitem: {
    display: 'list-item',
    fontSize: 13,
    marginLeft: theme.spacing(2),
  },
  skeleton: {
    marginBottom: 20,
  },
  ItemValue: {
    display: 'inline-flex',
  },
}))
