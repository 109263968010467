import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export default function HandlingIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 0 24 24"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M15.22,11.43a1,1,0,0,0-.74.31l-4.07,4.07L8.75,14.14a1,1,0,0,0-.75-.3,1,1,0,0,0-.74.31,1,1,0,0,0-.3.74,1,1,0,0,0,.3.74L9.7,18.06a1,1,0,0,0,1.42,0L16,13.22a1,1,0,0,0,.31-.74,1,1,0,0,0-.31-.74A1,1,0,0,0,15.22,11.43Z" />
      <path d="M6.61.65a1,1,0,0,0-.72-.3,1,1,0,0,0-.73.3.94.94,0,0,0-.29.72V2.64H3.59a2.09,2.09,0,0,0-1.52.64,2.07,2.07,0,0,0-.64,1.53v16a2.05,2.05,0,0,0,.64,1.53A2,2,0,0,0,3.59,23h16a2.11,2.11,0,0,0,1.53-.64,2.08,2.08,0,0,0,.63-1.53v-16a2.06,2.06,0,0,0-.63-1.53,2.11,2.11,0,0,0-1.53-.64H18.36V1.37A1,1,0,0,0,16.61.65a.94.94,0,0,0-.29.72V2.64H6.91V1.37A1,1,0,0,0,6.61.65ZM19.75,21H3.47V9.27H19.75Zm0-16.28V7.23H3.47V4.69Z" />
    </SvgIcon>
  )
}
