import React from 'react'
import { Chip } from '@mui/material'
import { isNil } from 'lodash'

const CowmedChip = props => {
  const { value, customStyle = {}, width = 103, variant, ...rest } = props
  if (isNil(value)) return <span>-</span>

  return (
    <Chip
      variant={variant}
      style={{ width, ...customStyle }}
      label={value}
      {...rest}
    />
  )
}

export default CowmedChip
