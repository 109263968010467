import keyBy from 'lodash/keyBy'
import _isPLainObject from 'lodash/isPlainObject'
import Moment, { fmt } from '#/components/Moment'

const getDefaultValueByType = field => {
  //to contemplate zero integer and false
  const initial = field.value === 0 ? '0' : (field.value ?? field.default)

  switch (field.type) {
    case 'text':
      return initial ? String(initial) : ''

    case 'timestamp':
      return initial ? Moment(initial).format(fmt.api) : null

    case 'select':
      if (!field.multi) {
        return _isPLainObject(initial) ? initial.value : initial
      }
      if (Array.isArray(initial) && initial.length) {
        return initial.map(i => i.value)
      }
      return initial

    default:
      return initial
  }
}

export const getDefaultFormValues = formFields => {
  if (formFields && formFields.length) {
    const fieldsObject = keyBy(formFields, 'name')

    Object.keys(fieldsObject).forEach(obj => {
      // for group form field type
      if (fieldsObject[obj].type === 'group') {
        if (fieldsObject[obj].value) {
          fieldsObject[obj] = fieldsObject[obj].value
        } else {
          // map group fields and get his default values
          const mappedFields = fieldsObject[obj].fields
            .filter(f => f.value || f.default)
            .map(f => ({ [f.name]: getDefaultValueByType(f) }))

          if (mappedFields.length > 0) {
            // create and array with default values based on group min_entries key
            fieldsObject[obj] = Array.from(
              Array(fieldsObject[obj].max_entries),
              () => {
                // reduce and spread default fields values onto a single object
                return mappedFields.reduce((acc, current) => ({
                  ...current,
                  ...acc,
                }))
              },
            )
          }
        }
      } else {
        fieldsObject[obj] = getDefaultValueByType(fieldsObject[obj])
      }
    })
    return fieldsObject
  }
  return {}
}

export const getGroupFormValues = formFields => {
  const groupFields =
    Array.isArray(formFields) && formFields.length
      ? formFields.filter(i => i.type === 'group')
      : null

  if (groupFields) {
    let fieldsObject = keyBy(groupFields, 'name')

    Object.keys(fieldsObject).forEach(obj => {
      // map fields and get values from the group
      fieldsObject = {
        ...fieldsObject,
        [obj]: { ...fieldsObject[obj], groupedFields: [] },
      }
      const hasDefaultVals = !!fieldsObject[obj].value

      const genNewFields = (field, index) => ({
        ...field,
        value: fieldsObject[obj].value[index]
          ? fieldsObject[obj].value[index][field.name]
          : null,
      })

      for (
        let i = 0;
        i <
        Math.max(
          fieldsObject[obj].default_entries,
          hasDefaultVals ? fieldsObject[obj].value.length : 0,
        );
        i++
      ) {
        if (fieldsObject[obj].value) {
          const newFields = fieldsObject[obj].fields.map(f =>
            genNewFields(f, i),
          )
          fieldsObject[obj].groupedFields.push(newFields)
        } else {
          fieldsObject[obj].groupedFields.push([...fieldsObject[obj].fields])
        }
      }
    })
    return fieldsObject
  }
  return {}
}
