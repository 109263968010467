import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export default function NotificationIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 0 14 16"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M7 0C6.44682 0 5.9999 0.446875 5.9999 1V1.6C3.71842 2.0625 1.9995 4.08125 1.9995 6.5V7.29375C1.9995 8.7125 1.51508 10.0906 0.630614 11.1969L0.164943 11.7812C-0.0163254 12.0063 -0.0507038 12.3156 0.0743086 12.575C0.199321 12.8344 0.461847 13 0.749376 13H13.2506C13.5382 13 13.8007 12.8344 13.9257 12.575C14.0507 12.3156 14.0163 12.0063 13.8351 11.7812L13.3694 11.2C12.4849 10.0906 12.0005 8.7125 12.0005 7.29375V6.5C12.0005 4.08125 10.2816 2.0625 8.0001 1.6V1C8.0001 0.446875 7.55318 0 7 0ZM7 3C8.93457 3 10.5003 4.56563 10.5003 6.5V7.29375C10.5003 8.79062 10.9348 10.25 11.7411 11.5H2.2589C3.06523 10.25 3.49965 8.79062 3.49965 7.29375V6.5C3.49965 4.56563 5.06543 3 7 3ZM9.0002 14H7H4.9998C4.9998 14.5312 5.2092 15.0406 5.58423 15.4156C5.95927 15.7906 6.4687 16 7 16C7.5313 16 8.04073 15.7906 8.41576 15.4156C8.7908 15.0406 9.0002 14.5312 9.0002 14Z" />
    </SvgIcon>
  )
}
