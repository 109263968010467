import React from 'react'
import Translate from '#/components/Translate'
import Colors from '#/styles/Old/Colors'
import WarningIcon from '#/icons/Warning'

export const ExpiredTimeline = ({ diff, styles }) => {
  const messageKey = diff > 1 ? 'recommended_hour_plural' : 'recommended_hour'

  return (
    <div style={styles.expired_container}>
      <WarningIcon fontSize={styles.expired_icon} color={Colors.warning} />
      <small style={styles.expired_text}>
        {Translate({ messageKey, params: { item: diff } })}
      </small>
    </div>
  )
}
