import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export default function ExportIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 0 24 24"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M6.64,20a5.37,5.37,0,0,1-3.89-1.57,5.2,5.2,0,0,1-1.61-3.85,5.58,5.58,0,0,1,1.17-3.48,5.19,5.19,0,0,1,3.08-2A7.06,7.06,0,0,1,7.51,5.67a5.23,5.23,0,0,1,3.63-1.62,2,2,0,0,1,2,2V12.1l1.6-1.55,1.4,1.4-4,4-4-4,1.4-1.4,1.6,1.55v-6a4.43,4.43,0,0,0-3,1.84,5.24,5.24,0,0,0-1,3.06h-.5a3.48,3.48,0,0,0-3.48,3.9,3.23,3.23,0,0,0,.76,1.8A3.38,3.38,0,0,0,6.64,18h12a2.48,2.48,0,0,0,2.5-2.5,2.49,2.49,0,0,0-2.5-2.5h-1.5V11a4.67,4.67,0,0,0-.55-2.24A5.63,5.63,0,0,0,15.14,7V4.62a7,7,0,0,1,4,6.33,4.39,4.39,0,0,1,3.18,1.89,4.21,4.21,0,0,1,.8,2.21,4.42,4.42,0,0,1-1.29,3.59A4.37,4.37,0,0,1,18.64,20Z" />
    </SvgIcon>
  )
}
