import React from 'react'
import CheckIcon from '@mui/icons-material/Check'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { isTrue } from '#/utils'
import Colors from '#/styles/Old/Colors'

const HasDataBadge = props => {
  return isTrue(props.value) ? (
    <CheckIcon style={{ color: Colors.collar_available }} />
  ) : (
    <HelpOutlineIcon style={{ color: Colors.collar_revision }} />
  )
}

export default HasDataBadge
