/**
 * It takes a string and a limit, and returns the string with an ellipsis if it's longer than the limit
 * @param [string] - The string to be limited.
 * @param [limit=0] - The maximum number of characters to return.
 * @returns A string with a limit of characters.
 */
export default function stringLimit(string, limit = 0) {
  if (!string) return null
  const sufix = string.length > limit ? '...' : ''
  return `${string.substring(0, limit)}${sufix}`
}
