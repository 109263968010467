import { useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import { getPermissionsInContext } from '#/utils'

export const usePermissions = permissionsContexts => {
  return useSelector(
    state => getPermissionsInContext(state, permissionsContexts),
    isEqual,
  )
}
