import React, { useEffect, useState } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

export default function SnackbarAlert({
  open,
  setOpen = () => {},
  elevation = 6,
  variant = 'filled',
  severity = 'info',
  message = '',
  duration = 1500,
}) {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(open)
    setOpen(false)
  }, [open])

  if (!open) return null

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={duration}
      onClose={() => setIsOpen(false)}
    >
      <Alert elevation={elevation} variant={variant} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  )
}
