import React from 'react'
import isEmpty from 'lodash/isEmpty'
import Modal from './index'
import RemoveModal from '#/components/Modal/RemoveModal'

const ActionsDialog = ({ dialogActions }) => {
  if (dialogActions && !isEmpty(dialogActions)) {
    if (dialogActions.isDeleteModal) {
      return <RemoveModal dialogActions={dialogActions} />
    }
    return (
      dialogActions.dialogOpened && (
        <Modal
          fullWidth={dialogActions?.dialogFullWidth ?? true}
          maxWidth={dialogActions?.dialogWidth ?? 'md'}
          title={dialogActions?.dialogTitle}
          subtitle={dialogActions?.dialogSubTitle}
          element={dialogActions?.dialogContent}
          onClose={dialogActions?.dialogOnClose}
          withBottomCloseButton={dialogActions?.dialogWithBottomCloseButton}
          open={dialogActions?.dialogOpened}
          closable={false}
          action={dialogActions?.dialogAction ?? null}
          status={dialogActions?.status ?? null}
        />
      )
    )
  }
  return null
}

export default ActionsDialog
