const PRODUCTION = 'production'
const STAGING = 'staging'
const DEV = 'dev'

/**
 * checks the current process environment
 * @returns {string}
 */
export default function procEnv() {
  switch (import.meta.env.VITE_API_URL) {
    case 'https://api.cowmed.com.br':
      return PRODUCTION

    case 'https://api.chipinside.com.br':
      return STAGING

    default:
      return DEV
  }
}

export const fullAppVersion = import.meta.env.VITE_VERSION

/**
 * slices the full-app-version to remove content after patch
 * @returns {string} major minor and patch version string
 */
export function getReleaseVersion() {
  return fullAppVersion?.slice(0, fullAppVersion.indexOf('+')) ?? ''
}

export const isLocal = procEnv() === DEV

export const isDev = procEnv() !== PRODUCTION

export const isStaging = procEnv() === STAGING
