import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash'
import { endpoint } from '#/store/services/endpoints'
import { fetcher } from '#/store/hooks/request'

const defaultState = {}

export const loadBatches = createAsyncThunk(
  'batch/loadBatches',
  async (payload, thunkAPI) => {
    let { farmSlug, timestamp } = payload

    if (!isEmpty(farmSlug)) {
      try {
        const controller = endpoint.farm.animal.on_date({ farm: farmSlug })
        const { data } = await fetcher({ controller, params: { timestamp } })

        return { data }
      } catch (e) {
        return thunkAPI.rejectWithValue({ message: e.message })
      }
    } else {
      return thunkAPI.rejectWithValue({
        message: 'Batch Cards not found',
      })
    }
  },
)

export const batchSlice = createSlice({
  name: 'batch',
  initialState: defaultState,
  reducers: {
    resetBatch() {
      return defaultState
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadBatches.fulfilled, (_, action) => {
        return {
          ...(action?.payload ?? {}),
          status: 'done',
        }
      })
      .addCase(loadBatches.rejected, (_, action) => {
        return {
          ...(action?.payload ?? {}),
          status: 'failed',
        }
      })
  },
  selectors: {
    getBatches: state => state,
  },
})

export const {
  actions: { resetBatch },
  selectors: { getBatches },
} = batchSlice

export default batchSlice.reducer
