import React from 'react'
import clsx from 'clsx'
import DatasheetSkeleton from './skeleton'
import TypesDatasheet from './types'
import useStyles from './styles'
import Translate from '#/components/Translate'
import Colors from '#/styles/Colors'

const subItemList = [
  'last_reproduction_method',
  'last_insemination_semen',
  'donor',
]

export const DatasheetContent = ({ AnimalData, Loading }) => {
  const classes = useStyles()

  return (
    <>
      <h4
        style={{
          marginTop: 0,
          marginBottom: 16,
          color: Colors.primary,
          fontWeight: 'bold',
        }}
      >
        {Translate({ messageKey: 'datasheet' })}
      </h4>
      <div className={classes.card}>
        <ul className={classes.ul}>
          {AnimalData && !Loading ? (
            AnimalData?.map(({ title, data }) => {
              return (
                <li className={classes.list} key={title}>
                  <p className={classes.title}>{title}</p>
                  <span className={classes.nested}>
                    <ul className={clsx(classes.ul, classes.ulNested)}>
                      {data.map(({ title, id, value }) => (
                        <li
                          key={id}
                          className={clsx(
                            classes.ListItem,
                            subItemList.includes(id) ? classes.subitem : null,
                          )}
                        >
                          {subItemList.includes(id) ? <span>└ </span> : null}
                          <span className={classes.ItemTitle}>{title}:</span>
                          <span className={classes.IteValue}>
                            <TypesDatasheet id={id} value={value} />
                          </span>
                        </li>
                      ))}
                    </ul>
                  </span>
                </li>
              )
            })
          ) : (
            <DatasheetSkeleton />
          )}
        </ul>
      </div>
    </>
  )
}
