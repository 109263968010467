import React from 'react'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import SimpleDialog from '#/components/Modal'
import AttentionIcon from '#/icons/Attention'
import Palette from '#/styles/Palette'
import Translate from '#/components/Translate'
import Colors from '#/styles/Colors'

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
})

const Header = styled(Wrapper)({
  color: Palette.gray.self[700],
  marginBottom: 16,
  paddingTop: 20,
})

const TitleStyled = styled('div')({
  fontSize: 24,
  fontWeight: '500',
})

const Message = styled('div')({
  color: Colors.darkerGray,
  fontSize: '16px',
})

const Icon = styled(AttentionIcon)({
  color: Colors.danger,
  marginBottom: 30,
  fontSize: 70,
})

function RemoveModal({ dialogActions }) {
  const { dialogTitle, dialogSubTitle, dialogContent } = dialogActions

  const element = (
    <Wrapper>
      <Header>
        <Icon />
        <TitleStyled>{dialogTitle}</TitleStyled>
        <Typography>{dialogSubTitle}</Typography>
      </Header>
      <Message>{dialogContent}</Message>
    </Wrapper>
  )

  return (
    <SimpleDialog
      fullWidth
      maxWidth="sm"
      element={element}
      closable={false}
      withBottomCloseButton
      onClose={dialogActions?.dialogOnClose}
      open={dialogActions?.dialogOpened}
      action={{
        asDanger: true,
        title: Translate({ messageKey: 'remove_item' }),
        ...dialogActions?.dialogAction,
      }}
      status={dialogActions?.status ?? null}
    />
  )
}

export default React.memo(RemoveModal)
