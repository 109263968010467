import React from 'react'
import CowmedChip from '#/components/Chip'
import Colors from '#/styles/Colors'

const CollarStatusBadge = ({ value, label }) => {
  if (!label || !value) return '-'

  let style = { color: '#fff' }
  switch (value) {
    case 'replace': //Red
      style.backgroundColor = Colors.devices.ctech.replace.backgroundColor
      break

    case 'revision': //Yellow
      style.backgroundColor = Colors.devices.ctech.revision.backgroundColor
      break

    default: // Normal - Reset badge color
      style.backgroundColor = Colors.devices.ctech.normal.backgroundColor
  }
  return <CowmedChip value={label} customStyle={style} />
}

export default CollarStatusBadge
