import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

/**
 * Converts minutes to a formatted string representation of hours and minutes.
 * @param {number} mins - The input duration in minutes.
 * @returns {string} - Formatted string (e.g., "1h30m").
 */
const minutesToHours = mins => {
  const durationObj = dayjs.duration(mins, 'minutes')

  // Extract hours and minutes from the duration object
  const hours = durationObj.hours()
  const minutes = durationObj.minutes()

  // Check if the duration is less than an hour
  if (hours === 0) {
    return `${minutes}m` // Display only minutes
  }

  // Check if the duration is a whole number of hours
  if (minutes === 0) {
    return `${hours}h` // Display only hours
  }

  // Display hours and minutes, padding minutes with a leading zero if needed
  return `${hours}h${minutes < 10 ? '0' : ''}${minutes}`
}

export default minutesToHours
