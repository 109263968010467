import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { StyledEngineProvider } from '@mui/material/styles'
import store from '#/store/configureStore'
import LocalizationProviderConfig from './LocalizationProviderConfig'
import ProviderReactTour from '#/store/providers/ProviderReactTour'

// Componente Providers: Envolve a aplicação com todos os provedores necessários
export default function Providers(props) {
  return (
    // O Provider do Redux fornece o estado da loja para toda a aplicação
    <Provider store={store}>
      {/* PersistGate garante que a persistência do estado do Redux seja reidratada antes de renderizar a aplicação */}
      <PersistGate persistor={store.persist()} loading={false}>
        {/* LocalizationProviderConfig envolve a aplicação com o contexto de localização */}
        <LocalizationProviderConfig>
          {/* StyledEngineProvider garante que os estilos do Material-UI sejam aplicados corretamente */}
          <StyledEngineProvider injectFirst>
            {/* ProviderReactTour provider de visualização do componente de tour */}
            <ProviderReactTour>
              {/* Renderiza os componentes filhos */}
              {props.children}
            </ProviderReactTour>
          </StyledEngineProvider>
        </LocalizationProviderConfig>
      </PersistGate>
    </Provider>
  )
}
