import React from 'react'
import { Box, Button } from '@mui/material'

const style = {
  root: {
    textAlign: 'center',
  },
  box: {
    height: 'calc(100vh - 220px)',
    overflow: 'auto',
  },
  buttons: {
    margin: '0 5px',
  },
  title: {
    margin: 0,
  },
  header: {
    width: '100%',
    marginBottom: 20,
    textAlign: 'left',
  },
}

export default function TabPanel(props) {
  const { children, value, index, footer } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      style={style.root}
    >
      {value === index && (
        <>
          <Box style={style.box}>{children}</Box>
          {footer.map((foot, i) => (
            <Button
              key={i}
              style={style.buttons}
              variant="outlined"
              color="primary"
              onClick={foot.onClick}
            >
              {foot.text}
            </Button>
          ))}
        </>
      )}
    </div>
  )
}
