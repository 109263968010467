import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import { endpoint } from '#/store/services/endpoints'
import JobDetailHeader from '#/components/Jobs/JobDetail/Header'
import JobDetailFooter from '#/components/Jobs/JobDetail/Footer'
import LinearIndeterminate from '#/components/Loader'
import JobDetailContent from '#/components/Jobs/JobDetail/Content'
import { useFetch } from '#/store/hooks/request'

export default function JobDetail({ job }) {
  const jobSlug = job.slug

  const controller = useMemo(
    () => (jobSlug ? endpoint.job.self({ job: jobSlug }) : null),
    [jobSlug],
  )

  /*
   * Get Job data
   * if failed retry in 30 sec
   */
  const { data: jobDetails, isLoading } = useFetch({
    controller,
    options: { errorRetryInterval: 30 * 1000 },
  })

  return isLoading ? (
    <LinearIndeterminate status="started" />
  ) : (
    <Box>
      <JobDetailHeader {...jobDetails} />
      <JobDetailContent {...jobDetails} />
      <JobDetailFooter {...jobDetails} jobSlug={jobSlug} />
    </Box>
  )
}
