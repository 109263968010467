import React from 'react'
import { LoadingButton } from '@mui/lab'
import { CircularProgress } from '@mui/material'

/**
 * @param {boolean} props.isLoading - Indica se o botão está em um estado de carregamento.
 * @param {function} props.onClick - Função de retorno de chamada a ser executada quando o botão é clicado.
 * @param {React.ReactNode} props.icon - Ícone a ser exibido no botão.
 * @returns {React.ReactNode} - Retorna o componente ButtonIconLoader.
 */
export default function ButtonIconLoader(props) {
  const { isLoading, loadingSize = 16, onClick, icon, style, ...rest } = props

  return (
    <LoadingButton
      style={{ ...style }}
      loading={isLoading}
      disabled={isLoading}
      onClick={onClick}
      loadingIndicator={<CircularProgress color="primary" size={loadingSize} />}
      {...rest}
    >
      {icon}
    </LoadingButton>
  )
}
