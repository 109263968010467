/**
 * @copyright https://www.w3resource.com/javascript/form/email-validation.php
 * @type {RegExp}
 */

/**
 * Tests if it's a valid e-mail adress
 *
 * @param {string} value - The e-mail to be tested
 *
 * @returns {boolean} The result if it's valid or not
 * */
export default function isValidEmail(value) {
  const emailRegex = /^\w+([.+\w-]?)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
  return emailRegex.test(value)
}
