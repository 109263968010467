import React from 'react'
import { EventInfos } from '#/components/EventInfos'

const TypeEventInfos = ({ value, ...rest }) => {
  if (!value) return <span>-</span>
  return (
    <EventInfos
      event={value}
      topRight={rest.topRight}
      topLeft={rest.topLeft}
      bottomRight={rest.bottomRight}
      bottomLeft={rest.bottomLeft}
    />
  )
}
export default TypeEventInfos
