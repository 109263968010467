export default {
  fontWeight: {
    normal: '400',
    medium: '600',
    bold: '700',
  },

  fontSize: {
    SSS: 10,
    SS: 12,
    S: 14,
    M: 16,
    L: 18,
    XL: 20,
    XXL: 24,
  },
}
