import React from 'react'
import { mdiCloudSync, mdiGift, mdiRouterWireless } from '@mdi/js'
import Icon from '@mdi/react'
import { StyledTab, StyledTabs } from '../styles'
import Colors from '#/styles/Old/Colors'
import { Refresh } from './Refresh'
import AnimalIcon from '#/icons/Animal'

export const TabsButtons = props => {
  const {
    data,
    value,
    handleTabSwitch,
    hasNewRelease,
    setForceRefresh,
    context,
  } = props

  if (context !== 'farm') {
    return (
      <StyledTabs value={value} onChange={handleTabSwitch}>
        <StyledTab label={<Icon path={mdiCloudSync} size={1} />} />
        {/* Release Notes */}
        <StyledTab
          label={
            <Icon
              path={mdiGift}
              color={hasNewRelease ? Colors.help : null}
              size={1}
            />
          }
        />
        <Refresh
          value={value}
          setForceRefresh={setForceRefresh}
          handleTabSwitch={handleTabSwitch}
        />
      </StyledTabs>
    )
  }
  return (
    <StyledTabs value={value} onChange={handleTabSwitch}>
      {/* Herd */}
      <StyledTab
        label={<AnimalIcon fontSize={21} />}
        count={data?.unread_animal_alerts}
      />
      {/* System */}
      <StyledTab
        label={<Icon path={mdiRouterWireless} size={1} />}
        count={data?.unread_system_alerts}
      />
      {/* Jobs */}
      <StyledTab label={<Icon path={mdiCloudSync} size={1} />} />
      {/* Release Notes */}
      <StyledTab
        label={
          <Icon
            path={mdiGift}
            color={hasNewRelease ? Colors.help : null}
            size={1}
          />
        }
      />
      <Refresh
        value={value}
        setForceRefresh={setForceRefresh}
        handleTabSwitch={handleTabSwitch}
      />
    </StyledTabs>
  )
}
