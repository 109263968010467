import React from 'react'
import { uniqueId } from 'lodash'
import Translate from '#/components/Translate'
import Moment from '#/components/Moment'
import Colors from '#/styles/Colors'
import CowmedGrid from '#/components/Grid'

export default function JobDetailHeader(props) {
  const { queued_at, started_at, finished_at, name, summary } = props

  /*
   * Dialog Header
   * Creating an array of objects.
   */
  const items = [
    {
      label: Translate({ messageKey: 'queued_at' }),
      value: Moment.view(queued_at),
    },
    {
      label: Translate({ messageKey: 'started_at' }),
      value: Moment.view(started_at),
    },
    {
      label: Translate({ messageKey: 'finished_at' }),
      value: Moment.view(finished_at),
    },
  ]
  const success = summary?.success ?? 0
  const success_with_warnings = summary ? summary['success-with-warnings'] : 0
  const failed = summary?.failed ?? 0

  if (!name?.match('export')) {
    if (name === 'move-many-collars') {
      items.push(
        {
          label: Translate({
            messageKey: 'collar',
            params: { item: Translate({ messageKey: 'moved', lower: true }) },
          }),
          value: (
            <b style={{ color: Colors.success }}>
              {success + success_with_warnings}
            </b>
          ),
        },
        {
          label: Translate({
            messageKey: 'collar',
            params: {
              item: Translate({ messageKey: 'not_moved', lower: true }),
            },
          }),
          value: <b style={{ color: Colors.danger }}>{failed}</b>,
        },
      )
    } else {
      items.push(
        {
          label: Translate({ messageKey: 'processed_successfully' }),
          value: <b style={{ color: Colors.success }}>{success}</b>,
        },
        {
          label: Translate({ messageKey: 'processed_with_errors' }),
          value: (
            <b style={{ color: Colors.warning }}>{success_with_warnings}</b>
          ),
        },
        {
          label: Translate({ messageKey: 'unprocessed' }),
          value: <b style={{ color: Colors.danger }}>{failed}</b>,
        },
      )
    }
  }

  return (
    <CowmedGrid
      container
      spacing={1}
      mb={2}
      sx={{
        justifyContent: {
          xs: 'flex-start',
          md: 'space-between',
        },
      }}
    >
      {items.map(item => {
        return (
          <CowmedGrid
            sx={{
              minWidth: 120,
              width: { xs: '100%', sm: 'initial' },
              flexDirection: 'column',
            }}
            key={uniqueId()}
            display="flex"
            justifyContent="flex-start"
          >
            <label
              style={{
                color: '#9e9e9e',
                fontSize: '12px',
                whiteSpace: 'nowrap',
              }}
            >
              {item.label}
            </label>
            <span>{item.value}</span>
          </CowmedGrid>
        )
      })}
    </CowmedGrid>
  )
}
