import LocalizedStrings from 'localized-strings'
import upperFirst from 'lodash/upperFirst'
import toLower from 'lodash/toLower'
import toUpper from 'lodash/toUpper'
import trimStart from 'lodash/trimStart'
import trimEnd from 'lodash/trimEnd'
import pt_BR from '#/lang/pt.json'
import en_US from '#/lang/en.json'
import es from '#/lang/es.json'
import { LOCALE } from '#/store/ducks/app'

const strings = new LocalizedStrings(
  { en: en_US, pt: pt_BR, es },
  {
    logsEnabled: false,
    customLanguageInterface: () =>
      localStorage.getItem('language') || LOCALE.ENGLISH,
  },
)

function manipulatePhrase(phrase, lower, upper, trim) {
  if (trim) {
    phrase = trimStart(phrase)
    phrase = trimEnd(phrase)
  }
  if (lower) {
    phrase = toLower(phrase)
  }
  if (upper) {
    phrase = toUpper(phrase)
  }
  if (!lower && !upper) {
    phrase = upperFirst(phrase)
  }
  return phrase
}
function insertCapsule(message, capsule = false) {
  switch (capsule) {
    case '()':
      return `(${message})`
    case '[]':
      return `[${message}]`
    case '{}':
      return `{${message}}`

    default:
      return message
  }
}

function TranslateArray(props) {
  const {
    messageKey,
    lower,
    upper,
    params,
    separator = ' ',
    translate = true,
    capsule = false,
    ...rest
  } = props

  const formattedMessages = []
  for (let i = 0; i < messageKey.length; i++) {
    if (translate[i] === false) {
      if (capsule[i]) {
        formattedMessages.push(insertCapsule(messageKey[i], capsule[i]))
      } else {
        formattedMessages.push(messageKey[i])
      }
    } else {
      const message = Translate({
        messageKey: messageKey[i],
        upper: upper ? upper[i] : null,
        lower: lower ? lower[i] : null,
        ...(params ? { params: params[i] } : null),
        ...rest,
      })
      if (capsule[i]) {
        formattedMessages.push(insertCapsule(message, capsule[i]))
      } else {
        formattedMessages.push(message)
      }
    }
  }

  return formattedMessages.join(separator)
}

export const Translate = props => {
  const {
    messageKey,
    params = {},
    lower,
    upper,
    trim = true,
    prefix,
    suffix,
  } = props
  if (!messageKey) return null

  const lang = localStorage.getItem('language')

  if (lang && strings._language !== lang) {
    strings.setLanguage(lang)
  }

  if (Array.isArray(messageKey)) {
    return TranslateArray(props)
  }

  let phrase = strings.formatString(messageKey, params)
  phrase = manipulatePhrase(phrase, lower, upper, trim)

  if (prefix) {
    phrase = prefix + phrase
  }
  if (suffix) {
    phrase += suffix
  }

  return phrase
}

export default Translate
