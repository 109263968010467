import { useLayoutEffect } from 'react'

export function useScrollPosition(handleScroll, selector = null) {
  useLayoutEffect(() => {
    const dialogContentElement = selector
      ? document.querySelector(selector)
      : window

    dialogContentElement &&
      dialogContentElement.addEventListener('scroll', handleScroll)

    return () =>
      dialogContentElement &&
      dialogContentElement.removeEventListener('scroll', handleScroll)
  }, [])
}
