import React, { useMemo, useRef } from 'react'
import NoSsr from '@mui/material/NoSsr'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import CreatableSelect from 'react-select/creatable'
import AsyncCreatableSelect from 'react-select/async-creatable'
import _has from 'lodash/has'
import _some from 'lodash/some'
import _isNil from 'lodash/isNil'
import _unionBy from 'lodash/unionBy'
import _debounce from 'lodash/debounce'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import Typography from '@mui/material/Typography'
import {
  ClearIndicator,
  Control,
  DropdownIndicator,
  IndicatorSeparator,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  useStyles,
  ValueContainer,
} from '../Select/SearchReactSelect/index'
import Translate from '#/components/Translate'
import { useScrollPosition } from '#/store/hooks/scroll'
import { fetcher } from '#/store/hooks/request'

const useInputStyles = makeStyles(() => ({
  input: {
    minHeight: 19,
    display: 'flex',
  },
  inputOutlined: {
    minHeight: 19,
    display: 'flex',
  },
}))

export default function ReactSelect({
  value,
  onChange,
  name,
  label,
  components = {
    Control,
    Menu,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    MultiValue,
    DropdownIndicator,
    IndicatorSeparator,
    ClearIndicator,
  },
  placeholder,
  options = null,
  error,
  required,
  readOnly,
  disabled,
  isMulti,
  allowSelectAll = false,
  allowCreateOption,
  allOption = {
    label: Translate({ messageKey: 'select_all' }),
    value: '*',
  },
  defaultValue,
  source,
  sourceParams = {},
  variant,
  customStyles,
  async,
  loadOptions,
  defaultOptions,
  searchable,
  cacheOptions,
  adornments,
}) {
  const classes = useStyles()
  const classesInput = useInputStyles()
  const theme = useTheme()

  // number 0 (zero) value is converted to string somehow but defaultValue is not
  const valueFix = value === '0' && typeof defaultValue === 'number' ? 0 : value

  const [dataList, setDataList] = React.useState(options || [])
  const [selectedValue, setSelectValue] = React.useState(
    !_isNil(valueFix) ? valueFix : defaultValue,
  )
  const [formattedSelectedValue, setFormattedSelectedValue] = React.useState()

  const [isLoadingSource, setIsLoadingSource] = React.useState(false)

  const [scrollPosition, setScrollPosition] = React.useState(0)

  const selectRef = useRef(null)

  const ReactSelect = (() => {
    if (async) {
      if (allowCreateOption) {
        return AsyncCreatableSelect
      }
      return AsyncSelect
    }
    if (allowCreateOption) {
      return CreatableSelect
    }
    return Select
  })()

  const handleChange = data => {
    let selected

    const isNewOption = data && data?.__isNew__

    if (async) {
      // only for async select
      if (
        isMulti &&
        Array.isArray(data) &&
        data.length &&
        _has(data, '0.value')
      ) {
        selected = data
      } else {
        selected = _has(data, 'value') ? data : null
      }
    } else {
      // not async select
      if (!isMulti) {
        selected = _has(data, 'value') ? data.value : data
      } else if (Array.isArray(data) && data.length) {
        selected = _some(data, 'value') ? data.map(i => i.value) : data
      }
    }

    // test if select all option was clicked
    if (
      !_isNil(selected) &&
      Array.isArray(selected) &&
      selected[selected.length - 1] === allOption.value
    ) {
      const allSelected = dataList.map(i => i.value)
      onChange(allSelected)
      setSelectValue(allSelected)
    } else {
      onChange(!_isNil(selected) ? selected : '', isNewOption)
      setSelectValue(selected)
    }

    // test if is a new value
    if (isNewOption) {
      setDataList(_unionBy(dataList, [data], 'value'))
    }
  }

  useScrollPosition(({ target }) => {
    _debounce(() => setScrollPosition(target.scrollTop / 30), 20)()
  }, 'div[class^=MuiDialogContent-root]')

  React.useEffect(() => {
    if (source && !searchable && !loadOptions) {
      const hasDefaultOptions =
        defaultOptions && Array.isArray(defaultOptions) && defaultOptions.length
      // for instant field value feedback while waiting source response
      if (hasDefaultOptions) {
        setDataList(defaultOptions)
      }
      // if has source load data options async
      setIsLoadingSource(true)

      fetcher({
        controller: source,
        params: sourceParams,
      })
        .then(({ data: { results = [] } = {} }) => {
          // add default option for synchronous select
          if (hasDefaultOptions) {
            setDataList(_unionBy(results, defaultOptions, 'value'))
          } else {
            setDataList(results)
          }
        })
        .catch(() => {
          setDataList([])
        })
        .finally(() => {
          setIsLoadingSource(false)
        })
    }
  }, [])

  React.useEffect(() => {
    const val = formattedValue(selectedValue, dataList)
    setFormattedSelectedValue(val)
  }, [selectedValue, dataList, defaultOptions])

  React.useEffect(() => {
    if (!async) {
      setSelectValue(valueFix) // value
    }
  }, [valueFix]) // value

  const placeHolder = useMemo(
    () =>
      isLoadingSource
        ? Translate({
            messageKey: ['loading', 'option_plural', '...'],
            lower: [false, true],
          })
        : placeholder ||
          Translate({
            messageKey: 'select_one_item_female',
            params: { item: Translate({ messageKey: 'option', lower: true }) },
          }),
    [isLoadingSource, placeholder],
  )

  const formattedValue = (val, availableOptions) => {
    if (async) {
      // only async selects
      // only async with defaultOptions
      if (
        !_isNil(val) &&
        loadOptions &&
        Array.isArray(defaultOptions) &&
        defaultOptions.length
      ) {
        if (_has(val, 'value') && _has(val, 'label')) {
          // if it's an object
          return val
        }
        if (
          Array.isArray(val) &&
          _has(val, '0.value') &&
          _has(val, '0.label')
        ) {
          // it's an array of objects
          return val
        }
        return defaultOptions.filter(({ value }) => String(val).includes(value)) // gets labels from defaultOptions
      }
      return val
    }
    if (!_isNil(val) && availableOptions && availableOptions.length) {
      // check if available options is array of arrays.
      if (Array.isArray(availableOptions[0].options)) {
        let mergedOptions = []
        availableOptions.forEach(value => {
          mergedOptions = mergedOptions.concat(value.options)
        })
        availableOptions = mergedOptions
      }

      return Array.isArray(val) && val.length
        ? availableOptions.filter(({ value }) => String(val).includes(value))
        : availableOptions.filter(({ value }) => value === val)
    }
    return null
  }

  const selectStyles = {
    input: base => ({
      ...base,
      'color': theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    menuPortal: base => ({
      ...base,
      top: base.top - scrollPosition,
      zIndex: 9999,
    }),
    menu: style => ({
      ...style,
      ...(theme.palette.mode === 'dark'
        ? { background: 'rgb(51, 51, 51)' }
        : {}),
      margin: 0,
    }),
  }

  return (
    <div ref={selectRef} className={classes.root}>
      <NoSsr>
        <ReactSelect
          name={name}
          isMulti={isMulti}
          isCreatable={allowCreateOption}
          formatCreateLabel={inputValue =>
            Translate({
              messageKey: 'add_new_option',
              params: { item: `"${inputValue}"` },
            })
          }
          inputId={`${name}-react-select`}
          options={allowSelectAll ? [allOption, ...dataList] : dataList}
          loadOptions={loadOptions}
          defaultOptions={!searchable ? defaultOptions : null} //no need for defaultOptions if it's searchable
          cacheOptions={cacheOptions}
          value={formattedSelectedValue}
          classes={{
            ...classes,
            input:
              variant === 'outlined'
                ? classesInput.inputOutlined
                : classesInput.input,
            ...customStyles,
          }}
          styles={selectStyles}
          TextFieldProps={{
            label,
            adornments: !value ? adornments : {},
            InputLabelProps: {
              htmlFor: `${name}-react-select`,
              shrink: true,
            },
            error,
            required,
            readOnly,
            variant,
            disabled: disabled || isLoadingSource,
          }}
          components={components}
          menuPosition="fixed"
          menuPlacement="auto"
          onChange={handleChange}
          placeholder={<Typography noWrap>{placeHolder}</Typography>}
          noOptionsMessage={({ inputValue }) =>
            inputValue
              ? Translate({
                  messageKey: 'no_results_found_for_item',
                  params: { item: `"${inputValue}"` },
                })
              : Translate({ messageKey: 'type_to_find' })
          }
          isDisabled={disabled || isLoadingSource}
          isClearable
          isAsyncSearchable={searchable}
        />
      </NoSsr>
    </div>
  )
}
