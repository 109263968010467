import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import Colors from '#/styles/Old/Colors'

export const useEventStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    rootList: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    containerList: {
      '&:nth-child(odd)': {
        backgroundColor: 'rgb(232, 232, 232)',
      },
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    icon: {
      color: '#797979',
    },
    iconSuccess: {
      color: Colors.primary,
    },
    iconError: {
      color: Colors.critical,
    },
    iconVisualHealth: {
      fontSize: '.9em !important',
      position: 'absolute',
      color: Colors.view_cattle,
      left: '10px',
      bottom: '0px',
    },
    iconVisualReproduction: {
      bottom: '-5px',
    },
    title: {
      fontWeight: 'bold',
      color: '#333',
      fontSize: '.9em',
    },
    value: {
      fontSize: '.9em',
    },
    textMuted: {
      color: Colors.muted,
    },
    textMutedBold: {
      color: Colors.muted,
      fontWeight: 700,
    },
    fabProgress: {
      color: Colors.primary,
      position: 'absolute',
      top: 12,
      left: 12,
      zIndex: 1,
    },
    healthIconStatus: {
      fontSize: '1.5em',
      position: 'absolute',
      top: 20,
      left: -5,
      zIndex: 1,
    },
    buttonProgress: {
      color: Colors.primary,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
)
