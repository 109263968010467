export default function lazyRetry(componentImport) {
  return new Promise((resolve, reject) => {
    const maxRetries = 2 // Define o número máximo de tentativas de reload

    const retryCount = Number(
      window.sessionStorage.getItem('retry-lazy-retries') || 0,
    )

    if (retryCount >= maxRetries) {
      // Se já tentou reload o número máximo de vezes, rejeita com erro
      window.sessionStorage.removeItem('retry-lazy-retries')
      reject(new Error('Failed to load component after retrying.'))
      return
    }

    componentImport()
      .then(component => {
        window.sessionStorage.removeItem('retry-lazy-retries')
        resolve(component)
      })
      .catch(error => {
        if (error.name === 'TypeError') {
          // Se ocorrer um TypeError(ChunkError), tenta novamente
          const updatedRetryCount = retryCount + 1
          window.sessionStorage.setItem('retry-lazy-retries', updatedRetryCount)
          window.location.reload()
        } else {
          // Se for outro tipo de erro, rejeita com o erro original
          window.sessionStorage.removeItem('retry-lazy-retries')
          reject(error)
        }
      })
  })
}
