import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export default function ProductionIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 0 24 24"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M20.43,4.33h-18A.67.67,0,0,0,1.75,5V6.52h1L5.22,20.05A1.77,1.77,0,0,0,7,21.51h8.91a1.77,1.77,0,0,0,1.75-1.46L20,6.7l0-.19H21.1V5A.67.67,0,0,0,20.43,4.33ZM17.84,6.52,15.56,19.45H7.31L5,6.52Z" />
    </SvgIcon>
  )
}
