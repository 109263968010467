import { signInWithCustomToken, getAuth } from 'firebase/auth'
import { getToken, getMessaging, isSupported } from 'firebase/messaging'
import { endpoint } from '#/store/services/endpoints'
import { registerServiceWorkerBackground } from '#/store/services/Firebase/RegisterServiceWorker'
import { fetcher } from '#/store/hooks/request'

export async function firebaseSignIn() {
  const hasFirebaseMessagingSupport = await isSupported()

  if (hasFirebaseMessagingSupport) {
    const messaging = getMessaging()
    const auth = getAuth()

    /* Checking if the user has granted permission to notify. */
    if (notificationIsGranted()) {
      const {
        data: { token: firebaseToken },
      } = await fetcher({
        controller: endpoint.auth.firebase_custom_token(),
      }).catch(error => console.error(error))

      /**
       *  Validates the token received by the backend.
       *  Authenticates with firebase with the received token.
       */
      signInWithCustomToken(auth, firebaseToken).catch(e => {
        throw new Error(e.message)
      })

      /* Registering the service worker in the background. */
      registerServiceWorkerBackground()

      /**
       * If you have a token and validation was successful, try to get the token used to receive pushes.
       */
      if (firebaseToken) {
        /**
         * getToken it is not necessary to send data beyond the messaging.
         * That's because it contains the vapidKey and there is the sw: firebase-cloud-messaging-push-scope
         */
        const messagingToken = await getToken(messaging)
        return { firebaseToken, messagingToken }
      }
    }
  }
  return { firebaseToken: null, messagingToken: null }
}

/**
 * If the browser supports notifications, and the user has granted permission to notify, then return
 * true
 * @returns A boolean value.
 */
const notificationIsGranted = () => {
  /* Checking if the browser supports notifications. */
  if (Notification.permission === 'granted') {
    return true
  }
  if (Notification.permission !== 'denied') {
    /* A callback function that is executed when the user grants permission to notify. */
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        return true
      }
    })
  }

  return false
}
