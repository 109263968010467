import isEmpty from 'lodash/isEmpty'

const hasPermission = (state, context, permission) => {
  let list = []

  switch (context) {
    case 'system':
      list = state.auth.permissions.system.list
      break

    case 'farm':
      list = state.auth.permissions.farm.list
      break

    case 'company':
      list = state.auth.permissions.company.list
      break

    default:
      // Nope!
      break
  }

  if (isEmpty(list) || !Array.isArray(list)) {
    return false
  }

  return !!list?.find(item => item === permission) // return true if have permission in context
}

export default hasPermission
