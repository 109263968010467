import React from 'react'

export const Square = props => {
  const { styles = {}, isRecomended, style } = props
  let customStyle = ''

  if (isRecomended !== undefined) {
    customStyle = isRecomended ? styles.recomended : styles.not_recomended
  }

  return (
    <div style={{ ...customStyle, ...styles.square, ...style }}>
      {props.children}
    </div>
  )
}
