import React from 'react'
import uniqueId from 'lodash/uniqueId'

const TypeStringArray = ({ value, customStyle = {} }) => {
  return (
    <div style={customStyle}>
      {value?.map(item => (
        <p key={uniqueId()} style={{ margin: '2px 0' }}>
          {item}
        </p>
      ))}
    </div>
  )
}
export default TypeStringArray
