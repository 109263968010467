import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import relativeTime from 'dayjs/plugin/relativeTime'
import duration from 'dayjs/plugin/duration'
import minMax from 'dayjs/plugin/minMax'
import { getCurrentFarmTimezone } from '#/store/ducks/farm'

/**
 * Dayjs Plugins
 * https://day.js.org/docs/en/plugin/plugin
 */

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(customParseFormat)
dayjs.extend(isBetween)
dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(minMax)

/**
 * It takes a timestamp as a prop, and returns a formatted date string
 * @param props - The props passed to the component.
 * @returns The dayjs function is being returned.
 */
const Moment = props => {
  const state = window?.globalStore?.getState()
  const timezone = getCurrentFarmTimezone(state)

  if (typeof props === 'string') {
    // Ignore date only
    if (dayjs(props, 'YYYY-MM-DD', true).isValid()) {
      return dayjs(props)
    }
  }
  return dayjs.utc(props).tz(timezone)
}

/**
 * https://day.js.org/docs/en/display/difference
 * Return the difference between two dates in a given measurement
 * @param [later=now] - The date to start the diff from. Defaults to now.
 * @param previous - The date to compare against.
 * @param measurement - The measurement to use. Can be one of the following: d,M,y,h,m,s,ms
 * @returns The difference between two dates in a given measurement.
 */
const diff = (later = 'now', previous, measurement = 'ms') => {
  const start = later === 'now' ? dayjs() : dayjs(later)
  return start.diff(previous, measurement)
}

/**
 * It returns the number of days between the current date and the date passed in
 * @returns The difference in days between the current date and the date passed in.
 */
const diffInDays = date => {
  const compare = dayjs(date).startOf('d')
  return dayjs().startOf('d').diff(compare, 'd')
}

/**
 * It takes a date string, and returns a formatted date string
 * @returns A function that takes a date as an argument and returns a string.
 */
const view = date => {
  if (!date || date.trim() === '-') {
    return '-'
  }
  return Moment(date).format('L LT')
}

/**
 * Be careful with the pure function
 * It doesn't return the data in the current timezone. you will need to manually change
 */
Moment.pure = dayjs
Moment.view = view
Moment.diff = diff
Moment.diffInDays = diffInDays
Moment.isValid = (datetime, format) => dayjs(datetime, format).isValid()
export default Moment

/**
 * Custom formats to api
 * if need formats use this link: https://day.js.org/docs/en/display/format#list-of-localized-formats
 */
export const fmt = {
  api: 'YYYY-MM-DD HH:mm:ssZZ',
  iso: 'YYYY-MM-DDTHH:mm:ssZ',
  hour: 'HH:mm',
  date: 'YYYY-MM-DD',
}
