import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export default function ImportIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 0 24 24"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M6.5,20a5.37,5.37,0,0,1-3.89-1.57A5.21,5.21,0,0,1,1,14.57,5.55,5.55,0,0,1,2.17,11.1,5.19,5.19,0,0,1,5.25,9.15,6.94,6.94,0,0,1,12,4a6.75,6.75,0,0,1,5,2,6.75,6.75,0,0,1,2,5,4.39,4.39,0,0,1,3.18,1.89A4.31,4.31,0,0,1,23,15.1a4.43,4.43,0,0,1-1.3,3.59A4.37,4.37,0,0,1,18.5,20H13a2,2,0,0,1-2-2V12.85L9.4,14.4,8,13l4-4,4,4-1.4,1.4L13,12.85V18h5.5A2.48,2.48,0,0,0,21,15.5,2.49,2.49,0,0,0,18.5,13H17V11.06A5,5,0,0,0,12,6,5,5,0,0,0,8.49,7.43,4.84,4.84,0,0,0,7,11H6.5A3.49,3.49,0,0,0,3,14.9a3.23,3.23,0,0,0,.76,1.8A3.4,3.4,0,0,0,6.5,18H9v2Z" />
    </SvgIcon>
  )
}
