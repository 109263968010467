import React, { useMemo } from 'react'
import { isEqual } from 'lodash'
import { useSelector } from 'react-redux'
import Icon from '@mdi/react'
import { mdiFileDocument, mdiHammerWrench } from '@mdi/js'
import { usePermissions } from '#/store/hooks/permissions'
import { getCurrentFarmSlug } from '#/store/ducks/farm'
import Translate from '#/components/Translate'
import HealthIcon from '#/icons/Health'
import AnimalIcon from '#/icons/Animal'
import ConceptionIcon from '#/icons/Conception'
import ReproductionIcon from '#/icons/Reproduction'
import NutritionIcon from '#/icons/Nutrition'
import HandlingIcon from '#/icons/Handling'

export default function MenuB2B() {
  const currentFarmSlug = useSelector(getCurrentFarmSlug, isEqual)
  const { permissions } = usePermissions({
    farm: [
      'list_animal',
      'list_batch',
      'list_collar',
      'list_collar_alert',
      'list_diet',
      'list_farm_collar_alert',
      'list_farm_job',
      'list_farm_semen',
      'list_handling_event',
      'list_health_alert',
      'list_health_event',
      'list_ingredient',
      'list_milk_production',
      'list_protocol_schedule',
      'list_reproduction_event',
      'list_collar_review_on_farm',
      'relate_animal_batch',
      'view_collar_status_report',
      'view_conception_analytics',
      'view_discard_analytics',
      'view_health_analytics',
      'view_heat_return_analytics',
      'view_service_analytics',
      'view_temperature_history',
    ],
  })

  const menuItemsB2C = useMemo(
    () => [
      /**************** ANIMAL LISTS *****************************************/
      {
        key: 'animal-lists',
        title: Translate({ messageKey: 'animal_lists' }),
        icon: <Icon path={mdiFileDocument} size={1} />,
        items: [
          {
            enabled: permissions.farm.listAnimal,
            label: Translate({ messageKey: 'VWP_hint' }),
            link: `/farm/${currentFarmSlug}/modules/herd/animals?status=empty-post-delivery`,
          },
          {
            enabled: permissions.farm.listAnimal,
            label: Translate({ messageKey: 'open_animal_plural' }),
            link: `/farm/${currentFarmSlug}/modules/herd/animals?status=empty`,
          },
          {
            enabled: permissions.farm.listAnimal,
            label: Translate({ messageKey: 'inseminated_plural' }),
            link: `/farm/${currentFarmSlug}/modules/herd/animals?status=inseminated`,
          },
          {
            enabled: permissions.farm.listAnimal,
            label: Translate({ messageKey: 'late_inseminated' }),
            link: `/farm/${currentFarmSlug}/modules/herd/animals?status=late-inseminated`,
          },
          {
            enabled: permissions.farm.listAnimal,
            label: Translate({ messageKey: 'pregnant' }),
            link: `/farm/${currentFarmSlug}/modules/herd/animals?status=pregnant`,
          },
          {
            enabled: permissions.farm.listAnimal,
            label: Translate({ messageKey: 'pregnancy_pre_calving' }),
            link: `/farm/${currentFarmSlug}/modules/herd/animals?status=pregnant-pre-delivery`,
          },
          {
            enabled: permissions.farm.listAnimal,
            label: Translate({ messageKey: 'calf_plural_female' }),
            link: `/farm/${currentFarmSlug}/modules/herd/animals?status=calf`,
          },
          {
            enabled: permissions.farm.listAnimal,
            label: Translate({ messageKey: 'ineligible_heifer_plural' }),
            link: `/farm/${currentFarmSlug}/modules/herd/animals?status=heifer`,
          },
        ],
      },
      /**************** REPRODUCTION *****************************************/
      {
        key: 'reproduction',
        title: Translate({ messageKey: 'reproductive_management' }),
        icon: <HandlingIcon fontSize={21} />,
        items: [
          {
            enabled: permissions.farm.listAnimal,
            label: Translate({ messageKey: 'gynecologic_examination' }),
            link: `/farm/${currentFarmSlug}/modules/reproduction/gynecologic`,
          },
          {
            enabled: permissions.farm.listReproductionEvent,
            label: Translate({ messageKey: 'pregnancy_diagnosis' }),
            link: `/farm/${currentFarmSlug}/modules/reproduction/pregnancy`,
          },
          {
            enabled: permissions.farm.viewHeatReturnAnalytics, //cow-analytics
            label: Translate({ messageKey: 'heat_return' }),
            link: `/farm/${currentFarmSlug}/cow-analytics/heat-return`,
          },
          {
            enabled: permissions.farm.listReproductionEvent,
            label: Translate({
              messageKey: 'prediction_of',
              params: {
                item: Translate({ messageKey: 'drying', lower: true }),
              },
            }),
            link: `/farm/${currentFarmSlug}/modules/reproduction/drying`,
          },
          {
            enabled: permissions.farm.listReproductionEvent,
            label: Translate({ messageKey: 'drying_projection' }),
            link: `/farm/${currentFarmSlug}/modules/reproduction/drying/projection`,
          },
          {
            enabled: permissions.farm.listReproductionEvent,
            label: Translate({
              messageKey: 'prediction_of',
              params: {
                item: Translate({ messageKey: 'pre_calving', lower: true }),
              },
            }),
            link: `/farm/${currentFarmSlug}/modules/reproduction/pre-calving-prediction`,
          },
          {
            enabled: permissions.farm.listReproductionEvent,
            label: Translate({
              messageKey: 'prediction_of',
              params: {
                item: Translate({ messageKey: 'calving_plural', lower: true }),
              },
            }),
            link: `/farm/${currentFarmSlug}/modules/reproduction/calving`,
          },
          {
            enabled: permissions.farm.listReproductionEvent,
            label: Translate({ messageKey: 'calving_projection' }),
            link: `/farm/${currentFarmSlug}/modules/reproduction/calving/projection`,
          },
          {
            enabled: permissions.farm.listFarmSemen,
            label: Translate({
              messageKey: 'registration_of',
              params: { item: Translate({ messageKey: 'bull_or_semen' }) },
            }),
            link: `/farm/${currentFarmSlug}/modules/reproduction/bull-semen`,
          },
          {
            enabled: permissions.farm.listProtocolSchedule,
            label: Translate({ messageKey: 'protocol_plural' }),
            link: `/farm/${currentFarmSlug}/modules/reproduction/protocol`,
          },
        ],
      },
      /**************** SERVICES *********************************************/
      {
        key: 'services',
        title: Translate({ messageKey: 'service_plural' }),
        icon: <ReproductionIcon fontSize={21} />,
        items: [
          {
            enabled: permissions.farm.viewServiceAnalytics, //cow-analytics
            label: Translate({
              messageKey: ['service_rate', 'historic'],
              lower: [null, true],
              capsule: [null, '()'],
            }),
            link: `/farm/${currentFarmSlug}/cow-analytics/services/rate`,
          },
          {
            enabled: permissions.farm.viewServiceAnalytics, //cow-analytics
            label: Translate({ messageKey: 'DIM_on_first_service' }),
            link: `/farm/${currentFarmSlug}/cow-analytics/services/dim-first-service`,
          },
          {
            enabled: permissions.farm.viewServiceAnalytics, //cow-analytics
            label: Translate({ messageKey: 'services_weekly' }),
            link: `/farm/${currentFarmSlug}/cow-analytics/services/weekly`,
          },
          {
            enabled: permissions.farm.viewServiceAnalytics, //cow-analytics
            label: Translate({ messageKey: 'performed' }),
            link: `/farm/${currentFarmSlug}/cow-analytics/services/performed`,
          },
          {
            enabled: permissions.farm.viewServiceAnalytics, //cow-analytics
            label: Translate({ messageKey: 'service_cycles' }),
            link: `/farm/${currentFarmSlug}/cow-analytics/services/cycles`,
          },
          {
            enabled: permissions.farm.viewServiceAnalytics, //cow-analytics
            label: Translate({
              messageKey: ['cow_plural', 'no_service'],
              lower: [null, true],
            }),
            link: `/farm/${currentFarmSlug}/cow-analytics/no-service`,
          },
          {
            enabled: permissions.farm.viewServiceAnalytics, //cow-analytics
            label: Translate({ messageKey: 'eligible_cow_plural' }),
            link: `/farm/${currentFarmSlug}/cow-analytics/eligible-cows`,
          },
          {
            enabled: permissions.farm.viewServiceAnalytics, //cow-analytics
            label: Translate({ messageKey: 'ineligible_cow_plural' }),
            link: `/farm/${currentFarmSlug}/cow-analytics/services/ineligible-cows`,
          },
        ],
      },
      /**************** CONCEPTION *******************************************/
      {
        key: 'conception',
        title: Translate({ messageKey: 'conception' }),
        icon: <ConceptionIcon fontSize={21} />,
        items: [
          {
            enabled: permissions.farm.viewConceptionAnalytics, //cow-analytics
            label: Translate({
              messageKey: ['conception_rate', 'historic'],
              lower: [null, true],
              capsule: [null, '()'],
            }),
            link: `/farm/${currentFarmSlug}/cow-analytics/conception/rate`,
          },
          {
            enabled: permissions.farm.viewConceptionAnalytics, //cow-analytics
            label: Translate({
              messageKey: ['conception', 'per_item'],
              lower: [null, true],
              params: [
                null,
                { item: Translate({ messageKey: 'inseminator', lower: true }) },
              ],
            }),
            link: `/farm/${currentFarmSlug}/cow-analytics/conception/per-inseminator`,
          },
          {
            enabled: permissions.farm.viewConceptionAnalytics, //cow-analytics
            label: Translate({
              messageKey: ['conception', 'per_item'],
              lower: [null, true],
              params: [
                null,
                {
                  item: Translate({ messageKey: 'bull_or_semen', lower: true }),
                },
              ],
            }),
            link: `/farm/${currentFarmSlug}/cow-analytics/conception/per-bull-semen`,
          },
          {
            enabled: permissions.farm.viewConceptionAnalytics, //cow-analytics
            label: Translate({
              messageKey: ['conception', 'per_item'],
              lower: [null, true],
              params: [
                null,
                {
                  item: Translate({ messageKey: 'service_type', lower: true }),
                },
              ],
            }),
            link: `/farm/${currentFarmSlug}/cow-analytics/conception/per-service-type`,
          },
          {
            enabled: permissions.farm.viewConceptionAnalytics, //cow-analytics
            label: Translate({ messageKey: 'conception_per_DEL' }),
            link: `/farm/${currentFarmSlug}/cow-analytics/conception/per-del`,
          },
        ],
      },
      /**************** HERD *************************************************/
      {
        key: 'herd',
        title: Translate({ messageKey: 'herd' }),
        icon: <AnimalIcon fontSize={21} />,
        items: [
          {
            enabled: permissions.farm.listAnimal,
            label: Translate({
              messageKey: 'animal_plural',
            }),
            link: `/farm/${currentFarmSlug}/modules/herd/animals`,
          },
          {
            enabled: permissions.farm.listBatch,
            label: Translate({ messageKey: 'batch_plural' }),
            link: `/farm/${currentFarmSlug}/modules/herd/batch`,
          },
          {
            enabled: permissions.farm.relateAnimalBatch,
            label: Translate({ messageKey: 'change_group_plural' }),
            link: `/farm/${currentFarmSlug}/modules/herd/manage`,
          },
          {
            enabled: permissions.farm.viewDiscardAnalytics, //cow-analytics
            label: Translate({
              messageKey: ['discard_rate', 'historic'],
              lower: [null, true],
              capsule: [null, '()'],
            }),
            link: `/farm/${currentFarmSlug}/cow-analytics/discard/rate`,
          },
          {
            enabled: permissions.farm.viewDiscardAnalytics, //cow-analytics
            label: Translate({ messageKey: 'discard_reason' }),
            link: `/farm/${currentFarmSlug}/cow-analytics/discard/reason`,
          },
          {
            enabled: permissions.farm.viewDiscardAnalytics, //cow-analytics
            label: Translate({ messageKey: 'marked_for_discard' }),
            link: `/farm/${currentFarmSlug}/cow-analytics/discard/unfit`,
          },
          {
            enabled: permissions.farm.viewDiscardAnalytics, //cow-analytics
            label: Translate({ messageKey: 'discarded_animals' }),
            link: `/farm/${currentFarmSlug}/cow-analytics/discard/report`,
          },
        ],
      },
      /**************** HEALTH ***********************************************/
      {
        key: 'health',
        title: Translate({ messageKey: 'health' }),
        icon: <HealthIcon fontSize={21} />,
        items: [
          {
            enabled: permissions.farm.listHealthEvent,
            label: Translate({ messageKey: 'infirmary' }),
            link: `/farm/${currentFarmSlug}/modules/health/infirmary`,
          },
          {
            enabled:
              permissions.farm.listHealthEvent &&
              permissions.farm.listHealthAlert,
            label: Translate({ messageKey: 'challenged_animals' }),
            link: `/farm/${currentFarmSlug}/modules/health/challenge`,
          },
          {
            enabled: permissions.farm.viewHealthAnalytics, //cow-analytics
            label: Translate({ messageKey: 'total_occurrences' }),
            link: `/farm/${currentFarmSlug}/cow-analytics/health/occurrences`,
          },
          {
            enabled: permissions.farm.viewHealthAnalytics, //cow-analytics
            label: Translate({
              messageKey: 'item_history',
              params: { item: Translate({ messageKey: 'case', lower: true }) },
            }),
            link: `/farm/${currentFarmSlug}/cow-analytics/health/history`,
          },
        ],
      },
      /**************** NUTRITION & THERMAL STRESS ***************************/
      {
        key: 'nutrition-thermal-stress',
        title: Translate({
          messageKey: ['nutrition', 'thermal_stress'],
          separator: ' / ',
        }),
        icon: <NutritionIcon fontSize={21} />,
        items: [
          {
            enabled:
              permissions.farm.listBatch && permissions.farm.listMilkProduction,
            label: Translate({
              messageKey: 'item_monitored_plural',
              params: {
                item: Translate({ messageKey: 'batch_plural', lower: true }),
              },
            }),
            link: `/farm/${currentFarmSlug}/modules/precision-nutrition`,
          },
          {
            enabled: permissions.farm.listIngredient,
            label: Translate({ messageKey: 'ingredient_management' }),
            link: `/farm/${currentFarmSlug}/modules/precision-nutrition/ingredient`,
          },
          {
            enabled: permissions.farm.listDiet,
            label: Translate({
              messageKey: 'registration_of',
              params: {
                item: Translate({ messageKey: 'diet_label', lower: true }),
              },
            }),
            link: `/farm/${currentFarmSlug}/modules/precision-nutrition/diet`,
          },
          {
            enabled: permissions.farm.viewTemperatureHistory,
            label: Translate({ messageKey: 'environmental_comfort' }),
            link: `/farm/${currentFarmSlug}/modules/thermal-stress`,
          },
          {
            enabled: permissions.farm.listHandlingEvent,
            label: Translate({ messageKey: 'handling_events' }),
            link: `/farm/${currentFarmSlug}/modules/cattle-handling`,
          },
        ],
      },
      /**************** TOOLS ************************************************/
      {
        key: 'tools',
        title: Translate({ messageKey: 'tool_plural' }),
        icon: <Icon path={mdiHammerWrench} size={1} />,
        items: [
          {
            enabled: permissions.farm.listFarmJob,
            label: Translate({ messageKey: 'import_data' }),
            link: `/farm/${currentFarmSlug}/import`,
          },
          {
            enabled: permissions.farm.listFarmJob,
            label: Translate({ messageKey: 'export_data' }),
            link: `/farm/${currentFarmSlug}/export`,
          },
          {
            enabled: permissions.farm.listCollar,
            label: Translate({ messageKey: 'change_collars' }),
            link: `/farm/${currentFarmSlug}/modules/monitoring/c-tech/replace`,
          },
          {
            enabled: permissions.farm.listCollarReviewOnFarm,
            label: Translate({ messageKey: 'farm_revision' }),
            link: `/farm/${currentFarmSlug}/modules/monitoring/c-tech/on-farm`,
          },
        ],
      },
    ],
    [currentFarmSlug, permissions],
  )

  return menuItemsB2C
}
