import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export default function DataAnalyticsIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 0 24 24"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M8.08,14.13l2.75-2.76,2.75,2.76a1.09,1.09,0,0,0,1.49,0l5-5a1.11,1.11,0,0,0,.31-.77,1.08,1.08,0,0,0-.31-.76,1.1,1.1,0,0,0-1.53,0l-4.21,4.22L11.6,9.09a1.11,1.11,0,0,0-1.53,0L6.55,12.6a1.11,1.11,0,0,0-.31.77,1.07,1.07,0,0,0,.31.76A1.1,1.1,0,0,0,8.08,14.13Z" />
      <path d="M20.49,18.44H5.26a.69.69,0,0,1-.68-.68V4.87a1.08,1.08,0,1,0-2.15,0V17.76a2.83,2.83,0,0,0,2.83,2.83H20.49a1.08,1.08,0,1,0,0-2.15Z" />
    </SvgIcon>
  )
}
