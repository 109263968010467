import React from 'react'
import CowmedChip from '#/components/Chip'
import Colors from '#/styles/Old/Colors'

function ProtocolStatusBadge({ status, value }) {
  let backgroundColor = ''

  switch (status) {
    case 'awaiting':
      backgroundColor = Colors.protocol_awaiting
      break

    case 'implanted':
      backgroundColor = Colors.protocol_implanted
      break

    case 'completed':
      backgroundColor = Colors.protocol_completed
      break

    default:
      backgroundColor = Colors.protocol_programmed
      break
  }

  return (
    <CowmedChip
      value={value}
      customStyle={{ color: '#fff', backgroundColor }}
    />
  )
}

export default ProtocolStatusBadge
