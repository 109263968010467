import React from 'react'
import { isNil } from 'lodash'
import BooleanBadge from '#/components/badges/BooleanBadge'

const TypeBoolean = ({ value, ...rest }) => {
  if (isNil(value)) return '-'

  return (
    <BooleanBadge
      value={value}
      onlyText={rest.onlyText}
      invertedColors={rest.invertedColors}
    />
  )
}

export default TypeBoolean
