import { matchPath } from 'react-router'

/**
 * It returns true if the current location matches the link
 * @returns A boolean value
 */
export function routerMatch({ link, exact }) {
  const location = window.location.pathname

  if (link === '/') {
    return link === location
  }

  if (exact) {
    return (
      matchPath(link, {
        path: location,
      })?.isExact ?? false
    )
  }

  const splitedLink = link.split('/')
  const splitedLocation = location.split('/')

  return (
    matchPath(link, {
      path: splitedLocation.slice(0, splitedLink.length).join('/'),
    })?.isExact ?? false
  )
}
