import { Box, SvgIcon } from '@mui/material'
import React from 'react'
import LabelIcon from '@mui/icons-material/Label'
import { styled } from '@mui/material/styles'
import Colors from '#/styles/Colors'

const Wrapper = styled(Box)({
  position: 'relative',
})

const Tag = styled(LabelIcon)(({ status }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    fill: Colors.inapt[status],
    WebKitTransform: 'scaleX(-1)',
    transform: 'scaleX(-1)',
  }
})

export default function InaptIcon(props = {}) {
  if (!props?.status) return null

  const { size = 34 } = props

  const IconStyle = {
    color: 'white',
    position: 'absolute',
    top: size / 2,
    left: size / 2 + 1,
    transform: 'translate(-50%, -50%)',
  }

  // Definindo um objeto que mapeia os tipos de dados
  const IconsList = {
    discard: InaptDiscardIcon,
    induction: InaptInductionIcon,
    unfit: InaptUnfitIcon,
  }

  // Definindo e renderiza o componente com as propriedades
  const ComponentsSwitch = status => {
    const Component = IconsList[status]
    return <Component style={IconStyle} fontSize={size / 1.5} />
  }

  return (
    <Wrapper>
      <Tag status={props.status} style={{ fontSize: size }} />
      {ComponentsSwitch(props.status)}
    </Wrapper>
  )
}

export function InaptInductionIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 0 21 21"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M14.6541 11.9279C14.6541 13.9383 12.8927 15.5861 10.7438 15.5861C8.59495 15.5861 6.83356 13.9383 6.83356 11.9279C6.83356 9.9176 10.7438 4.41388 10.7438 4.41388C10.7438 4.41388 14.6541 9.9176 14.6541 11.9279Z" />
    </SvgIcon>
  )
}

export function InaptDiscardIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 0 21 21"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M15.2103 6.70779C15.6007 6.31735 15.6007 5.68327 15.2103 5.29283C14.8198 4.90239 14.1858 4.90239 13.7953 5.29283L10.5062 8.58504L7.21402 5.29595C6.82358 4.90551 6.1895 4.90551 5.79906 5.29595C5.40862 5.6864 5.40862 6.32047 5.79906 6.71092L9.09126 10L5.80218 13.2922C5.41174 13.6826 5.41174 14.3167 5.80218 14.7072C6.19262 15.0976 6.8267 15.0976 7.21714 14.7072L10.5062 11.415L13.7984 14.704C14.1889 15.0945 14.823 15.0945 15.2134 14.704C15.6038 14.3136 15.6038 13.6795 15.2134 13.2891L11.9212 10L15.2103 6.70779Z" />
    </SvgIcon>
  )
}

export function InaptUnfitIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 0 21 21"
      style={{ fontSize, color, ...style, padding: 2 }}
      {...rest}
    >
      <path d="M14.4682 2.63806C15.5504 2.49051 16.4723 2.77578 17.0435 3.43485L17.0636 3.41518C17.6648 4.10376 17.7851 5.07762 17.4143 6.15968C17.0936 7.09419 16.4122 8.0287 15.5103 8.78614C14.5984 9.54359 13.5563 10.0551 12.5642 10.2223C12.3237 10.2617 12.0932 10.2814 11.8728 10.2814C11.4519 10.2814 11.0711 10.2125 10.7404 10.0748C10.1793 10.5666 9.56799 10.7437 9.09701 10.8716C8.60599 10.9995 8.45568 11.0585 8.35547 11.2159C7.96466 11.8356 7.96466 12.6422 7.96466 13.3603C7.96466 13.9702 7.96465 14.5506 7.7442 15.0523C7.09454 16.5142 6.04613 16.6745 5.27508 16.7925L5.26905 16.7934C4.75799 16.8721 4.39724 16.9213 4.07657 17.3049C3.91624 17.4918 3.68576 17.6 3.44526 17.6C3.25487 17.6 3.07449 17.5312 2.92418 17.4131C2.57345 17.1377 2.52335 16.6262 2.81395 16.2819C3.52723 15.4455 4.37762 15.311 4.99721 15.213L5.01853 15.2097L5.02339 15.2089C5.67123 15.1111 5.94117 15.0703 6.23105 14.4227C6.31122 14.2358 6.31122 13.7833 6.31122 13.38V13.38C6.3012 12.4947 6.3012 11.3929 6.94253 10.3797C7.39002 9.65736 8.07434 9.47747 8.63317 9.33057L8.64607 9.32718C8.98678 9.23864 9.27738 9.15995 9.5279 8.98288C8.86653 7.62539 9.59805 5.59898 11.3918 4.09393C12.3438 3.29714 13.436 2.77578 14.4682 2.63806Z" />
    </SvgIcon>
  )
}
