import qs from 'qs'

export function getLink({ type, farm, slug, company, id, tab, ...rest }) {
  /* prettier-ignore */
  const links = {
    // System
    'farm-manage':                                  `/accounts/farm/${farm}`,
    'company-manage':                               `/accounts/company/${company}`,
    'campaing-detail':                              `/cs/nps/campaigns/${id}/answers`,
    'farm-op-status':                               `/system/inventory/c-tech/farm/${farm}/status/all`,
    'interval-detail':                              `/system/inventory/c-com/interval/details/${farm}`,
    'ccom-status':                                  `/system/inventory/c-com/${id}/status`,
    'farm-ccom-op-status':                          `/system/inventory/c-com/farm/${farm}`,
    'farm-ccom-fails-report':                       `/system/inventory/c-com/report-fails/${farm}`,
    'c-tech-status':                                `/system/inventory/c-tech/list/${id}/status`,
    'c-tech-break':                                 `/system/inventory/c-tech/break/${rest.version}/${rest.manufacture}`,


    // Farm
    'farm':                                         `/farm/${farm}`,
    'batch':                                        `/farm/${farm}/modules/herd/batch/${slug}`,
    'animal':                                       `/farm/${farm}/modules/herd/animals/${slug}`,
    'device-point':                                 `/farm/${farm}/modules/thermal-stress/${id}`,
    'points-manage':                                `/farm/${farm}/modules/monitoring/c-env/points`,
    'milk-production':                              `/farm/${farm}/modules/milk-control/production/${slug}`,
    'semen':                                        `/farm/${farm}/modules/reproduction/bull-semen/${id}`,
    'protocol-detail':                              `/farm/${farm}/modules/reproduction/protocol/${slug}/detail`,
    'monitoring':                                   `/farm/${farm}/modules/monitoring/animals`,
    'c-tech-map':                                   `/farm/${farm}/modules/monitoring/c-tech/details/${id}/map`,
    'c-tech-history':                               `/farm/${farm}/modules/monitoring/c-tech/details/${id}/history`,
  }

  let link = links[type]

  if (tab) {
    link += `/${tab}`
  }

  if (rest.query) {
    let query = qs.stringify(rest.query)
    link += `?${query}`
  }

  return link
}
