import React from 'react'
import { styled } from '@mui/material/styles'
import Map from '#/components/Map'
import UFO from '#/assets/ufo.svg'
import Palette from '#/styles/Palette'

const Wrapper = styled('div')({
  display: 'flex',
  width: '100%',
  height: '400px',
  borderRadius: 16,
  overflow: 'hidden',
})

const EmptyWrapper = styled(Wrapper)({
  height: 'unset',
  minHeight: 400,
  backgroundColor: Palette.gray.light[100],
  fontSize: 16,
  padding: '0 32px 20px',
  textAlign: 'center',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  color: Palette.gray.self[700],
})

const Img = styled('img')({
  width: 'auto',
  height: 370,
  padding: 25,
})

export const FindMyCowMap = ({ value }) => {
  if (!value) return null

  const { last_point, message } = value

  if (last_point?.lat && last_point?.lng) {
    return (
      <Wrapper>
        <Map
          latitude={last_point?.lat}
          longitude={last_point?.lng}
          findMyCow={value}
          zoom={17}
        />
      </Wrapper>
    )
  }

  return (
    <EmptyWrapper>
      <Img src={UFO} alt={message} />
      <span>{message}</span>
    </EmptyWrapper>
  )
}
