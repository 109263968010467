import * as React from 'react'
import Typography from '@mui/material/Typography'
import NorthIcon from '@mui/icons-material/North'
import SouthIcon from '@mui/icons-material/South'
import Stack from '@mui/material/Stack'
import { isNumber } from 'lodash'
import precise from '#/utils/numberPrecise'
import Palette from '#/styles/Old/Palette'
import CowmedChip from '#/components/Chip'

const sxStyles = {
  value: {
    'fontSize': 14,
    'fontWeight': 500,
    '& .MuiSvgIcon-root': {
      fontSize: 14,
    },
  },
}

/**
 * If the value is greater than 0, return green, otherwise return red
 * @param value - The value of the chip.
 * @param reverse - If true, the chip will be green if the value is negative and red if the value is
 * positive.
 * @returns A function that returns an object.
 */
function chipStyle(value, reverse) {
  if (!isNumber(value)) return value

  const green = {
    color: Palette.green.accent[4],
    backgroundColor: Palette.green.lighten[5],
  }
  const red = {
    color: Palette.red.accent[4],
    backgroundColor: Palette.red.lighten[5],
  }

  if (reverse) {
    return value > 0 ? red : green
  }
  return value > 0 ? green : red
}

/**
 * It renders a label for the variation, with optional prefix, suffix, and precision
 * @param props - The props passed to the component.
 * @param styles - This is the styles object that is passed to the component.
 * @returns A function that returns a Stack component.
 */
const renderLabel = props => {
  const { variation, precision = 1, suffix, prefix, reverse } = props

  let formattedPrefix = prefix

  if (!prefix && isNumber(variation) && prefix !== false) {
    if (reverse) {
      formattedPrefix = variation > 0 ? <SouthIcon /> : <NorthIcon />
    } else {
      formattedPrefix = variation > 0 ? <NorthIcon /> : <SouthIcon />
    }
  }

  const stackProps = {
    direction: 'row',
    spacing: 0.5,
    alignItems: 'center',
  }

  return (
    <Stack {...stackProps} sx={sxStyles.value}>
      {prefix !== false && (
        <span style={{ display: 'flex' }}>{formattedPrefix}</span>
      )}
      <span>{precise(variation, precision)}</span>
      {suffix}
    </Stack>
  )
}

/**
 * If the subtype prop is chip, return a StatisticChip component, if the subtype prop is card, return a
 * StatisticCard component, otherwise return null.
 * @returns A component
 */

const TypeStatistic = ({ subtype, ...rest }) => {
  switch (subtype) {
    case 'text':
      return <StatisticText {...rest} />

    default:
      return <StatisticChip {...rest} />
  }
}

const StatisticText = props => {
  const { value, reverse } = props
  let color
  if (isNumber(value)) {
    if (reverse) {
      color = value > 0 ? Palette.red.accent[4] : Palette.green.accent[4]
    } else {
      color = value > 0 ? Palette.green.accent[4] : Palette.red.accent[4]
    }
  }

  return <Typography sx={{ color }}>{renderLabel(props)}</Typography>
}

/**
 * It renders a Chip with a value, prefix, suffix, and precision
 * @returns A React component that renders a Chip component with a startDecorator and endDecorator.
 */
const StatisticChip = props => {
  const { value, reverse, variation, ...rest } = props

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {value && <span>{value}</span>}
      {variation ? (
        <CowmedChip
          value={renderLabel(props)}
          width="unset"
          customStyle={chipStyle(variation, reverse)}
          {...rest}
        />
      ) : null}
    </Stack>
  )
}

export default TypeStatistic
