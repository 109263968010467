import farmMarkerIcon from '#/assets/map-icons/homePin_blue.svg'

import ccomPriMarkerIconGreen from '#/assets/map-icons/ccomPrimaryPin_green.svg'
import ccomPriMarkerIconGrey from '#/assets/map-icons/ccomPrimaryPin_grey.svg'
import ccomPriMarkerIconRed from '#/assets/map-icons/ccomPrimaryPin_red.svg'

import ccomSecMarkerIconGreen from '#/assets/map-icons/ccomSecondaryPin_green.svg'
import ccomSecMarkerIconGrey from '#/assets/map-icons/ccomSecondaryPin_grey.svg'
import ccomSecMarkerIconRed from '#/assets/map-icons/ccomSecondaryPin_red.svg'
import { contrastColor } from '#/utils/contrastColor'

// c-com type and status icon set
const cComIcons = {
  primary: {
    operational: ccomPriMarkerIconGreen,
    offline: ccomPriMarkerIconRed,
    inactive: ccomPriMarkerIconGrey,
  },
  secondary: {
    operational: ccomSecMarkerIconGreen,
    offline: ccomSecMarkerIconRed,
    inactive: ccomSecMarkerIconGrey,
  },
}

export const getCcomMarkerIcon = (ccom, google) => {
  const type = ccom.primary ? 'primary' : 'secondary'
  const status = ccom.status_for_map
  const iconUrl = cComIcons[type][status] ?? cComIcons[type].offline
  return {
    url: iconUrl,
    anchor: new google.maps.Point(13, 43),
    scaledSize: new google.maps.Size(27, 43),
  }
}

export const getFarmMarkerIcon = google => {
  return {
    url: farmMarkerIcon,
    anchor: new google.maps.Point(13, 43),
    scaledSize: new google.maps.Size(27, 43),
  }
}

// Show circle with label (thi)
export const getCowComfortMarkerIcon = (google, m) => {
  return {
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: m.color,
      fillOpacity: 1,
      strokeWeight: 0,
      scale: 15,
    },
    label: {
      text: m.value,
      color: contrastColor(m.color),
      fontSize: '16px',
      fontWeight: '500',
    },
  }
}

// Show Chip with label (temperature, humidity)
export const getCowComfortMarkerChipIcon = (google, m) => {
  const svg = color =>
    window.btoa(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 30">
                  <rect stroke="#666" width="70" height="30" rx="15" fill="${color}" />
                </svg>`)

  return {
    icon: {
      url: `data:image/svg+xml;base64,${svg('#fff')}`,
      scaledSize: new google.maps.Size(70, 30),
      anchor: new google.maps.Point(35, 15),
    },
    label: {
      text: m.value,
      color: contrastColor(m.color),
      fontSize: '16px',
      fontWeight: '500',
    },
  }
}

// Show empty CowComfort icon - Point without device(cowcomfort)
export const getPointWithoutCowComfortIcon = google => {
  const svgcowcomfort =
    window.btoa(`<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M37.6702 19.0651C37.6702 29.4442 29.2563 37.8582 18.8771 37.8582C8.49795 37.8582 0.0839844 29.4442 0.0839844 19.0651C0.0839844 8.68594 8.49795 0.271973 18.8771 0.271973C29.2563 0.271973 37.6702 8.68594 37.6702 19.0651Z" fill="#CCCCCC"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9767 11.8394L15.4447 15.9906H22.3724L23.7899 11.8394H13.9767ZM12.3032 11.6061C12.0846 10.9879 12.5431 10.3394 13.1988 10.3394H24.5589C25.2102 10.3394 25.6684 10.9799 25.4579 11.5963L23.6648 16.8476C23.5335 17.2321 23.1721 17.4906 22.7658 17.4906H15.0558C14.6532 17.4906 14.2943 17.2368 14.1601 16.8573L12.3032 11.6061Z" fill="#4D4D4D"/>
              <path d="M23.7649 23.5049C23.9835 23.7147 24.0868 23.9652 24.0746 24.2564C24.0625 24.5477 23.941 24.7749 23.7102 24.9381C23.0422 25.4274 22.2983 25.809 21.4784 26.0828C20.6585 26.3566 19.7931 26.4935 18.8822 26.4935C17.9591 26.4935 17.0876 26.3537 16.2677 26.0741C15.4479 25.7944 14.7039 25.4041 14.0359 24.9031C13.8051 24.74 13.6867 24.5157 13.6806 24.2302C13.6745 23.9448 13.7808 23.6972 13.9994 23.4875C14.2059 23.2894 14.458 23.1874 14.7555 23.1816C15.0531 23.1758 15.3294 23.2544 15.5845 23.4176C16.0582 23.7321 16.5714 23.9739 17.124 24.1428C17.6767 24.3118 18.2627 24.3963 18.8822 24.3963C19.5016 24.3963 20.0877 24.3089 20.6403 24.1341C21.193 23.9593 21.7001 23.7205 22.1616 23.4176C22.4288 23.2428 22.7112 23.1641 23.0088 23.1816C23.3064 23.1991 23.5584 23.3069 23.7649 23.5049ZM26.8621 26.4586C27.0686 26.6566 27.1719 26.8984 27.1719 27.1838C27.1719 27.4693 27.0626 27.7052 26.8439 27.8917C25.7629 28.7655 24.5483 29.45 23.2001 29.9452C21.8519 30.4404 20.4126 30.688 18.8822 30.688C17.3518 30.688 15.9124 30.4404 14.5642 29.9452C13.216 29.45 12.0014 28.7655 10.9204 27.8917C10.7018 27.7052 10.5894 27.4722 10.5833 27.1926C10.5773 26.913 10.6836 26.6683 10.9022 26.4586C11.1087 26.2605 11.3607 26.1556 11.6583 26.144C11.9559 26.1323 12.2261 26.2197 12.469 26.4061C13.3557 27.0935 14.3426 27.6295 15.4296 28.014C16.5167 28.3985 17.6676 28.5907 18.8822 28.5907C20.0968 28.5907 21.2476 28.3985 22.3347 28.014C23.4218 27.6295 24.4086 27.0935 25.2953 26.4061C25.5382 26.2197 25.8085 26.1323 26.1061 26.144C26.4036 26.1556 26.6557 26.2605 26.8621 26.4586ZM18.8822 19.5027C19.2951 19.5027 19.6413 19.6367 19.9207 19.9047C20.2 20.1727 20.3397 20.5047 20.3397 20.9009C20.3397 21.297 20.2 21.6291 19.9207 21.8971C19.6413 22.165 19.2951 22.299 18.8822 22.299C18.4692 22.299 18.123 22.165 17.8437 21.8971C17.5643 21.6291 17.4246 21.297 17.4246 20.9009C17.4246 20.5047 17.5643 20.1727 17.8437 19.9047C18.123 19.6367 18.4692 19.5027 18.8822 19.5027Z" fill="#4D4D4D"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.67084 8.20258C9.96374 7.90969 10.4386 7.90969 10.7315 8.20258L29.6546 27.1257C29.9475 27.4186 29.9475 27.8934 29.6546 28.1863C29.3617 28.4792 28.8868 28.4792 28.5939 28.1863L9.67084 9.26324C9.37795 8.97035 9.37795 8.49547 9.67084 8.20258Z" fill="#4D4D4D"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.96374 7.49547C9.64715 6.81206 10.7552 6.81206 11.4386 7.49547L30.3617 26.4186C31.0451 27.102 31.0451 28.21 30.3617 28.8934C29.6783 29.5769 28.5702 29.5769 27.8868 28.8934L8.96373 9.97035L9.67084 9.26324L28.5939 28.1863C28.8868 28.4792 29.3617 28.4792 29.6546 28.1863C29.9475 27.8934 29.9475 27.4186 29.6546 27.1257L10.7315 8.20258C10.4386 7.90969 9.96374 7.90969 9.67084 8.20258C9.37795 8.49547 9.37795 8.97035 9.67084 9.26324L8.96373 9.97035C8.28032 9.28693 8.28032 8.17889 8.96374 7.49547Z" fill="#CCCCCC"/>
            </svg>`)

  return {
    icon: {
      url: `data:image/svg+xml;base64,${svgcowcomfort}`,
      scaledSize: new google.maps.Size(30, 30),
      anchor: new google.maps.Point(15, 15),
    },
  }
}

// Show Exclamation with circle
export const getPointWithCowComfortOutdatedTwentyFourHoursIcon = google => {
  const svgcowcomfort =
    window.btoa(`<svg width="39" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="19.3283" cy="19.2126" r="18.7931" fill="#CCCCCC"/>
                  <path d="M20.9281 10.641C20.9281 9.8508 20.2131 9.2124 19.3281 9.2124C18.4431 9.2124 17.7281 9.8508 17.7281 10.641V22.0695C17.7281 22.8597 18.4431 23.4981 19.3281 23.4981C20.2131 23.4981 20.9281 22.8597 20.9281 22.0695V10.641ZM19.3281 29.2124C19.8586 29.2124 20.3673 29.0243 20.7423 28.6894C21.1174 28.3545 21.3281 27.9003 21.3281 27.4267C21.3281 26.9531 21.1174 26.4989 20.7423 26.164C20.3673 25.8291 19.8586 25.641 19.3281 25.641C18.7977 25.641 18.289 25.8291 17.9139 26.164C17.5388 26.4989 17.3281 26.9531 17.3281 27.4267C17.3281 27.9003 17.5388 28.3545 17.9139 28.6894C18.289 29.0243 18.7977 29.2124 19.3281 29.2124Z" fill="#4D4D4D"/>
                </svg>`)

  return {
    icon: {
      url: `data:image/svg+xml;base64,${svgcowcomfort}`,
      scaledSize: new google.maps.Size(30, 30),
      anchor: new google.maps.Point(15, 15),
    },
  }
}
