import React from 'react'
import { Box, Drawer, IconButton } from '@mui/material'
import { mdiClose } from '@mdi/js'
import Icon from '@mdi/react'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: { maxWidth: '100%' },
  box: {
    height: '100%',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '30px 40px 25px',
  },
  content: {
    [theme.breakpoints.down('xl')]: {
      padding: 10,
    },
    [theme.breakpoints.up('sm')]: {
      padding: '20px 40px',
    },
  },
  closeButton: {
    padding: 5,
  },
  title: {
    marginTop: 0,
  },
}))

export default function DrawerBox(props) {
  const {
    pageName,
    title,
    header,
    headerColor,
    children,
    contentStyle,
    width = 500,
    anchor = 'right',
    isOpen,
    onClose,
  } = props

  const classes = useStyles()
  const CloseButton = () => (
    <IconButton className={classes.closeButton} onClick={onClose} size="large">
      <Icon path={mdiClose} size={1} />
    </IconButton>
  )

  return (
    <Drawer
      anchor={anchor}
      open={isOpen}
      onClose={onClose}
      classes={{ paper: classes.root }}
    >
      <Box style={{ width }} className={classes.box}>
        <div
          className={classes.header}
          style={{
            backgroundColor: headerColor,
            ...(!header ? { paddingBottom: 0 } : {}),
          }}
        >
          {header || (
            <div>
              {pageName}
              {title ? <h2 className={classes.title}>{title}</h2> : null}
            </div>
          )}
          <CloseButton />
        </div>
        <div style={contentStyle} className={classes.content}>
          {children}
        </div>
      </Box>
    </Drawer>
  )
}
