import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export default function BatchIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M2.48,8.53l9,5.09a1,1,0,0,0,1,0l9-5.09a1.06,1.06,0,0,0,.37-.36,1,1,0,0,0,0-1,1.06,1.06,0,0,0-.37-.36l-9-5.1a1.08,1.08,0,0,0-1,0l-9,5.1a1.06,1.06,0,0,0-.37.36,1,1,0,0,0,0,1A1.06,1.06,0,0,0,2.48,8.53ZM5,7.67l7-3.95,7,4-7,3.95Z" />
      <path d="M21.62,15.62a1,1,0,0,0-.35-.17,1,1,0,0,0-.39,0,.85.85,0,0,0-.38.12L12,20.36,3.5,15.55a1,1,0,0,0-.38-.12,1,1,0,0,0-.39,0,1.11,1.11,0,0,0-.35.16,1,1,0,0,0-.27.3,1.11,1.11,0,0,0-.13.36,1,1,0,0,0,0,.39.88.88,0,0,0,.17.35,1.08,1.08,0,0,0,.3.26l9,5.09a1,1,0,0,0,1,0l9-5.09a1.48,1.48,0,0,0,.3-.26.88.88,0,0,0,.17-.35,1,1,0,0,0,0-.39,1.11,1.11,0,0,0-.13-.36A.84.84,0,0,0,21.62,15.62Z" />
      <path d="M21.27,11.08a1,1,0,0,0-.77.1L12,16,3.5,11.18a.85.85,0,0,0-.37-.12,1.08,1.08,0,0,0-.4,0,1,1,0,0,0-.35.17.84.84,0,0,0-.27.29,1.08,1.08,0,0,0-.13.37,1,1,0,0,0,0,.38,1,1,0,0,0,.47.61l9,5.1a1.14,1.14,0,0,0,.51.13,1,1,0,0,0,.51-.14l9-5.09a1,1,0,0,0,.47-.61,1,1,0,0,0-.1-.75A1,1,0,0,0,21.27,11.08Z" />
    </SvgIcon>
  )
}
