import { isNumber } from 'lodash'

/**
 * It returns a number with a specified number of decimal places
 * @param value - The value to be formatted.
 * @param precision - The number of decimal places to show.
 * @returns Value formatted or value.
 */
export default function precise(value, precision) {
  /* Checking if the value is null or undefined. */
  if (!value) return '-'

  /* Checking if the value is a number and if it is a whole number. */
  if (!isNumber(value) || Number.isInteger(value)) {
    return value
  }

  /* Returning the value with the specified number of decimal places. */
  return value.toFixed(precision)
}
