function InterpolateColors(props) {
  const {
    value,
    maxValue,
    startColor = '#ed942f',
    endColor = '#F02B2B',
  } = props

  // Função para interpolar entre duas cores
  const factor = maxValue === 0 ? 0 : value / maxValue

  const hex = x => {
    const hexValue = x.toString(16)
    return hexValue.length === 1 ? `0${hexValue}` : hexValue
  }

  const r1 = parseInt(startColor.substring(1, 3), 16)
  const g1 = parseInt(startColor.substring(3, 5), 16)
  const b1 = parseInt(startColor.substring(5, 7), 16)

  const r2 = parseInt(endColor.substring(1, 3), 16)
  const g2 = parseInt(endColor.substring(3, 5), 16)
  const b2 = parseInt(endColor.substring(5, 7), 16)

  const r = hex(Math.floor(r1 + factor * (r2 - r1)))
  const g = hex(Math.floor(g1 + factor * (g2 - g1)))
  const b = hex(Math.floor(b1 + factor * (b2 - b1)))

  const resultColor = `#${r}${g}${b}`

  return resultColor
}

export default InterpolateColors
