import React from 'react'
import { Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'
import Translate from '#/components/Translate'

const TypeCEnv = ({ value, linkTo, ...rest }) => {
  if (!value) return <span>-</span>
  let link = rest.href

  switch (linkTo) {
    case 'c-env-inventory':
      link = `/system/inventory/c-env/${value}/status`
      break
    default: //nothing to moo
  }

  return (
    <Tooltip
      title={Translate({
        messageKey: 'access_item',
        params: { item: Translate({ messageKey: 'cow_comfort' }) },
      })}
    >
      <Link to={link}>{value}</Link>
    </Tooltip>
  )
}
export default TypeCEnv
