import React, { useEffect, useState } from 'react'
import { isEqual } from 'lodash'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Divider } from '@mui/material'
import ws from '#/utils/websocket'
import useDebounce from '#/store/hooks/debounce'
import { getCurrentCompanySlug } from '#/store/ducks/company'
import { isStaging } from '#/utils/processEnvironment'
import tryCache from '#/utils/tryCache'
import { endpoint } from '#/store/services/endpoints'
import Translate from '#/components/Translate'
import FormatAlert from '../Manipulate/FormatAlert'
import { fetcher } from '#/store/hooks/request'

export default function JobsTab(props) {
  const {
    farm,
    handleTabSwitch,
    isOpen,
    setIsOpen,
    setAlerts,
    context,
    headerData,
  } = props

  const history = useHistory()
  const company = useSelector(getCurrentCompanySlug, isEqual)
  const [newJobController, setNewJobController] = useState(null)

  /** Join new Job to existing ones from the slug */
  const unionBy = (destination, obj, key) => {
    return destination.map(dest =>
      dest[key] && obj[key] === dest[key] ? { ...dest, ...obj } : dest,
    )
  }

  /** Debounce to avoid triggering multiple requests */
  useDebounce(
    () => {
      if (newJobController) {
        fetcher({ controller: newJobController })
          .then(({ data }) => {
            if (data?.slug) {
              setAlerts(prevState => {
                /** Merge new job infos in existing, or create new info */
                if (prevState) {
                  return {
                    ...prevState,
                    jobs: {
                      data: prevState?.jobs
                        ? unionBy(prevState.jobs.data, data, 'slug')
                        : [data],
                      updated_at: Date.now(),
                      status: 'done',
                    },
                  }
                }
                return {
                  jobs: {
                    data: [data],
                    status: 'done',
                    updated_at: Date.now(),
                  },
                }
              })
            }
            setNewJobController(null)
            if (context !== 'farm') {
              tryCache.clear(`notification-list-${farm ?? context}-header`)
              headerData()
            }
            tryCache.clear(`notification-list-${farm ?? context}-jobs`)
            if (isOpen) {
              handleTabSwitch(null, 2)
            }
          })
          .catch(error => console.error(error))
      }
    },
    1000, // 1 second
    [newJobController],
  )

  useEffect(() => {
    if (window?.Echo) {
      let channel
      switch (context) {
        case 'system':
          const systemCompany = isStaging ? 'chipinside' : 'cowmed'
          channel = systemCompany ? `company.${systemCompany}` : null
          break
        case 'farm':
          channel = farm ? `farm.${farm}` : null
          break
        case 'company':
          channel = company ? `company.${company}` : null
          break
        default:
          channel = null
      }
      if (channel) {
        return ws.subscribe(channel, '.job', evt => {
          if (evt?.slug) {
            setNewJobController(endpoint.job.self({ job: evt.slug }))
          }
        })
      }
    }
  }, [company, context, farm, window?.Echo])

  const getEndpointByContext = () => {
    switch (context) {
      case 'system':
        return endpoint.system.job.list()
      case 'farm':
        return endpoint.notifications.job({ farm })
      case 'company':
        return endpoint.company.job.list({ company })
      default:
        return null
    }
  }

  return {
    key: 'jobs',
    endpoint: getEndpointByContext(),
    content: (
      <>
        <Divider />
        <FormatAlert {...props} />
      </>
    ),
    footer: [
      {
        text: Translate({ messageKey: 'view_all_tasks' }),
        onClick: () => {
          setIsOpen(false)
          history.push(
            context === 'farm' ? `/farm/${farm}/import` : `/system/import`,
          )
        },
      },
    ],
  }
}
