import { publish } from '#/utils/pubsub'

const DEBUG = import.meta.env.VITE_DEBUG === 'true'

/**
 * It registers a message handler in the service worker that listens for messages from Firebase in the background.
 */
export const registerServiceWorkerBackground = () => {
  const serviceURL = new URL('/firebase-messaging-sw.js', import.meta.url).href

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register(serviceURL).then(registration => {
      /* Log firebase register */
      if (DEBUG)
        console.info('Registration successful, scope is:', registration.scope)
    })
  }
}

/**
 * The update() method is a service worker method that checks for updates to the service worker.
 */
export const updateServiceWorkerBackground = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      registrations.map(registration => {
        registration.update()
        /* Log firebase update */
        if (DEBUG)
          console.info('Update successful, scope is:', registration.scope)
      })
    })
  }
}

/**
 * It registers a message handler on the service worker that listens for messages from Firebase.
 */
export const registerServiceWorkerForeground = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('message', ({ data }) => {
      const { type, content } = data?.data ?? {}
      if (type === 'alert' && typeof content === 'string') {
        const { alertable_slug, message } = JSON.parse(content)
        if (alertable_slug && message) {
          publish('firebase-alert-arrived', { message, alertable_slug })
        }
      }
      /* Log firebase message */
      if (DEBUG) console.info('Received message', data)
    })
  }
}
