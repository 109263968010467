import { Typography } from '@mui/material'
import React from 'react'

export const FindMyCowSubtitle = ({ title }) => {
  if (!title) return null

  return (
    <Typography variant="subtitle1" color="secondary">
      {title}
    </Typography>
  )
}
