import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import Colors from '#/styles/Old/Colors'
import isTrue from '#/utils/isTrue'
import WarningIcon from '#/icons/Warning'

const WarningBadge = ({ value, tooltip }) => {
  if (value === null) return ''
  let iconColor = isTrue(value) ? Colors.collarMinorError : Colors.borderGray

  return (
    <div>
      {tooltip ? (
        <Tooltip title={tooltip} aria-label={tooltip}>
          <WarningIcon style={{ color: iconColor }} />
        </Tooltip>
      ) : (
        <WarningIcon style={{ color: iconColor }} />
      )}
    </div>
  )
}

export default WarningBadge
