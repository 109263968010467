import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export default function SearchIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 0 16 17"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M13.0006 6.90524C13.0006 8.42904 12.535 9.83665 11.7506 10.9787L15.707 15.1849C16.0977 15.5999 16.0977 16.2738 15.707 16.6888C15.3164 17.1037 14.682 17.1037 14.2913 16.6888L10.3349 12.4825C9.25983 13.3191 7.93476 13.8105 6.50032 13.8105C2.90952 13.8105 0 10.7197 0 6.90524C0 3.09076 2.90952 0 6.50032 0C10.0911 0 13.0006 3.09076 13.0006 6.90524ZM6.50032 11.6858C7.09129 11.6858 7.67649 11.5621 8.22248 11.3219C8.76847 11.0816 9.26457 10.7295 9.68245 10.2856C10.1003 9.84168 10.4318 9.31468 10.658 8.73468C10.8841 8.15467 11.0005 7.53303 11.0005 6.90524C11.0005 6.27745 10.8841 5.6558 10.658 5.0758C10.4318 4.4958 10.1003 3.96879 9.68245 3.52488C9.26457 3.08096 8.76847 2.72883 8.22248 2.48859C7.67649 2.24834 7.09129 2.12469 6.50032 2.12469C5.90934 2.12469 5.32415 2.24834 4.77816 2.48859C4.23217 2.72883 3.73607 3.08096 3.31818 3.52488C2.9003 3.96879 2.56881 4.4958 2.34266 5.0758C2.1165 5.6558 2.0001 6.27745 2.0001 6.90524C2.0001 7.53303 2.1165 8.15467 2.34266 8.73468C2.56881 9.31468 2.9003 9.84168 3.31818 10.2856C3.73607 10.7295 4.23217 11.0816 4.77816 11.3219C5.32415 11.5621 5.90934 11.6858 6.50032 11.6858Z" />
    </SvgIcon>
  )
}
