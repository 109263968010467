import React from 'react'
import { Badge } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Palette from '#/styles/Old/Palette'

const StyledBadge = withStyles({
  root: {
    marginRight: 15,
  },
  badge: {
    backgroundColor: Palette.primary.self,
    color: 'white',
    lineHeight: '16px',
  },
})(props => <Badge {...props} />)

export const StyledTab = withStyles(() => ({
  root: {
    color: 'black',
    minWidth: 'unset',
    minHeight: 'unset',
    backgroundColor: Palette.grey.lighten[3],
    borderRadius: 50,
    opacity: 1,
    padding: '5px 15px',
  },
  selected: {
    backgroundColor: Palette.primary.lighten[2],
    color: 'black !important',
  },
  wrapper: {
    textTransform: 'capitalize',
    fontWeight: 'normal',
  },
}))(props => (
  <StyledBadge badgeContent={props.count}>
    <Tab disableRipple {...props} />
  </StyledBadge>
))

export const StyledTabs = withStyles({
  root: {
    minHeight: 'unset',
    overflow: 'unset !important',
    paddingTop: 15,
    paddingBottom: 20,
    backgroundColor: 'unset',
  },
  scroller: {
    overflow: 'unset !important',
  },
  indicator: {
    display: 'none',
  },
})(props => <Tabs {...props} />)
