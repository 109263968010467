import React from 'react'
import Avatar from '@mui/material/Avatar'
import Colors from '#/styles/Colors'

export default function AvatarCell({ icon, type, collar }) {
  let avatarStyles = {}
  switch (type) {
    case 'batch':
      /* If collar show cyan color, else gray */
      avatarStyles = collar ? { bgcolor: Colors.batch.backgroundColor } : {}
      break

    case 'animal':
      /* If collar show cyan color, else gray */
      avatarStyles = collar
        ? { bgcolor: Colors.devices.ctech.normal.backgroundColor }
        : {}
      break

    case 'discard':
      /* Discard avatar has color gray */
      avatarStyles = { bgcolor: Colors.animal.discard.backgroundColor }
      break

    default:
      break
  }

  return <Avatar sx={avatarStyles}>{icon}</Avatar>
}
