import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

function HardwareIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon style={{ fontSize, color, ...style }} {...rest}>
      <g transform="scale(.0625)">
        <path d="m256 128h-128v128h128zm-42.667 85.333h-42.667v-42.667h42.667z" />
        <path d="m384 170.67v-42.667h-42.667v-42.667c0-23.573-19.093-42.667-42.667-42.667h-42.666v-42.666h-42.667v42.667h-42.667v-42.667h-42.666v42.667h-42.667c-23.573 0-42.667 19.093-42.667 42.667v42.666h-42.666v42.667h42.667v42.667h-42.667v42.666h42.667v42.667c0 23.573 19.093 42.667 42.667 42.667h42.666v42.666h42.667v-42.667h42.667v42.667h42.666v-42.667h42.667c23.573 0 42.667-19.093 42.667-42.667v-42.666h42.666v-42.667h-42.667v-42.667h42.667zm-85.333 128h-213.33v-213.33h213.33v213.33z" />
      </g>
    </SvgIcon>
  )
}

export default HardwareIcon
