export default {
  red: {
    self: {
      100: '#FEDDCC',
      200: '#FDB49A',
      300: '#FA8067',
      400: '#F65041',
      500: '#F00505',
      600: '#CE0315',
      700: '#AC0220',
    },
    light: {
      100: '#FFE7D5',
      200: '#FFC9AB',
      300: '#FFA481',
      400: '#FF8061',
      500: '#FF462D',
      600: '#DB2820',
      700: '#B7161D',
    },
    salmon: {
      100: '#FDE0CB',
      200: '#FBC9B0',
      300: '#F7B39B',
      400: '#F3917A',
      500: '#D06659',
      600: '#AE423D',
      700: '#8C262A',
    },
    peach: {
      100: '#FBD1C3',
      200: '#ffaca4',
      300: '#EC9188',
      400: '#E06262',
      500: '#C04752',
      600: '#A13144',
      700: '#811F38',
    },
  },

  orange: {
    self: {
      100: '#FEF5CB',
      200: '#FEE898',
      300: '#FED765',
      400: '#FDC73F',
      500: '#FCAC00',
      600: '#D88C00',
      700: '#B56F00',
    },
    light: {
      100: '#FEEFD1',
      200: '#FED9A3',
      300: '#FEBF75',
      400: '#FDA552',
      500: '#FC7B1A',
      600: '#D85C13',
      700: '#B5420D',
    },
    dark: {
      100: '#FFEDD9',
      200: '#FFD5B3',
      300: '#FFB98E',
      400: '#FF9D71',
      500: '#FF7043',
      600: '#DB4D30',
      700: '#B73021',
    },
    deep: {
      100: '#FEEFD1',
      200: '#FED9A3',
      300: '#FEBF75',
      400: '#FDA552',
      500: '#FC7B1A',
      600: '#D85C13',
      700: '#B5420D',
    },
    sand: {
      100: '#FEF2D3',
      200: '#FCE9BC',
      300: '#FADFAB',
      400: '#F8D08F',
      500: '#D5A768',
      600: '#B28148',
      700: '#8F5E2D',
    },
    brick: {
      100: '#FEECD2',
      200: '#FEDFBC',
      300: '#FADFAB',
      400: '#F8D08F',
      500: '#D99168',
      600: '#B66948',
      700: '#92462D',
    },
    collar: {
      100: '#F8E3C4',
      200: '#FFDA99',
      300: '#FFC066',
      400: '#FFA73F',
      500: '#FF7D00',
      600: '#DB6000',
      700: '#B74700',
    },
  },

  green: {
    self: {
      100: '#EBFBD7',
      200: '#D3F7B0',
      300: '#AFE985',
      400: '#89D462',
      500: '#59B834',
      600: '#3F9E26',
      700: '#29841A',
    },
    light: {
      100: '#E8FCDA',
      200: '#CCFAB5',
      300: '#A6F18E',
      400: '#82E36E',
      500: '#4ED142',
      600: '#30B330',
      700: '#21962A',
    },
    mint: {
      100: '#D5FCD4',
      200: '#A9FAAE',
      300: '#7CF18E',
      400: '#5AE37C',
      500: '#29D164',
      600: '#1DB361',
      700: '#14965B',
    },
    night: {
      100: '#F2FAF4',
      200: '#E5F6EC',
      300: '#CEE6DA',
      400: '#B3CEC2',
      500: '#90AEA0',
      600: '#69958B',
      700: '#487D76',
    },
    herd: {
      100: '#E9F9EC',
      200: '#E0F4E5',
      300: '#D4EDDD',
      400: '#9ACBB1',
      500: '#6BAB90',
      600: '#4E937C',
      700: '#357B6B',
    },
    nutrition: {
      100: '#D6EDD4',
      200: '#ACDBA9',
      300: '#83CA7D',
      400: '#59B852',
      500: '#438A3E',
      600: '#2C5C29',
      700: '#173B14',
    },
  },

  blue: {
    self: {
      100: '#C8F6FB',
      200: '#94E7F7',
      300: '#5CC8E7',
      400: '#33A2CF',
      500: '#0072AF',
      600: '#005896',
      700: '#00427D',
    },
    light: {
      100: '#C9FBF1',
      200: '#95F8EB',
      300: '#5EECE5',
      400: '#36D5D9',
      500: '#00ACC1',
      600: '#0086A5',
      700: '#00658A',
    },
    dark: {
      100: '#DDF3FB',
      200: '#BCE5F8',
      300: '#95CCEA',
      400: '#77A4CB',
      500: '#4A86BA',
      600: '#36699F',
      700: '#17376B',
    },
    marine: {
      100: '#DEF1F9',
      200: '#BEE1F3',
      300: '#94C0DC',
      400: '#6E99BA',
      500: '#40678C',
      600: '#2E5078',
      700: '#203C64',
    },
    zinc: {
      100: '#E3F2FA',
      200: '#C9E4F5',
      300: '#A5C7E2',
      400: '#83A5C5',
      500: '#58799F',
      600: '#405E88',
      700: '#2C4672',
    },
    sky: {
      100: '#E1FBFE',
      200: '#D1F5FE',
      300: '#C6EFFD',
      400: '#B3E5FC',
      500: '#82B8D8',
      600: '#5A8CB5',
      700: '#396492',
    },
    production: {
      100: '#D6F3F9',
      200: '#93DEF6',
      300: '#5BBBE5',
      400: '#3394CC',
      500: '#0063AA',
      600: '#004C92',
      700: '#00397A',
    },
    cow_comfort: {
      100: '#DEE6FC',
      200: '#A9BEFE',
      300: '#7F9BFE',
      400: '#5E7FFD',
      500: '#2A51FC',
      600: '#1E3DD8',
      700: '#152CB5',
    },
  },

  gray: {
    light: {
      100: '#FAFAFA',
      200: '#EAF6E9',
      300: '#EFEFEF',
      400: '#e0e0e0',
      500: '#bdbdbd',
    },
    self: {
      50: '#fcfcfc',
      100: '#f7f7f7',
      200: '#f2f2f2',
      300: '#ebebeb',
      400: '#c9c9c9',
      500: '#ababab',
      600: '#818181',
      700: '#6d6d6d',
      800: '#4d4d4d',
      900: '#2b2b2b',
    },
    dark: {
      50: '#f9f9f9',
      100: '#f4f4f4',
      200: '#ececec',
      300: '#dddddd',
      400: '#b9b9b9',
      500: '#9a9a9a',
      600: '#717171',
      700: '#5d5d5d',
      800: '#333333',
      900: '#1e1e1e',
    },
  },

  yellow: {
    self: {
      100: '#FEFAD8',
      200: '#FEF4B2',
      300: '#FEEC8B',
      400: '#FDE46E',
      500: '#FDD83F',
      600: '#D9B42E',
      700: '#B6921F',
    },
    health: {
      100: '#FAF3D8',
      200: '#F9DF99',
      300: '#EDC464',
      400: '#DCA53D',
      500: '#C67D07',
      600: '#AA6405',
      700: '#8E4E03',
    },
  },

  pink: {
    self: {
      100: '#FFE5E4',
      200: '#FFC9CD',
      300: '#FFAFBC',
      400: '#FF9BB5',
      500: '#FF7AAA',
      600: '#DB5995',
      700: '#B73D81',
    },
    light: {
      100: '#FFF4F4',
      200: '#FFE9EA',
      300: '#FFDEE4',
      400: '#FFD6E1',
      500: '#FFC9DD',
      600: '#DB92B4',
      700: '#B76594',
    },
    dark: {
      100: '#FAC8D0',
      200: '#F693AD',
      300: '#E45B8D',
      400: '#CA3278',
      500: '#A7005E',
      600: '#8F005F',
      700: '#78005B',
    },
    handling: {
      100: '#FDD7D2',
      200: '#FBA7A6',
      300: '#F47983',
      400: '#E95671',
      500: '#DB2357',
      600: '#BC1957',
      700: '#9D1154',
    },
    rearing: {
      100: '#FEF0F0',
      200: '#FCE9EB',
      300: '#F9D1D9',
      400: '#F4C2D1',
      500: '#EDADC7',
      600: '#CB7EA5',
      700: '#AA5789',
    },
    birth: {
      50: '#fce4ed',
      100: '#f8bbd3',
      200: '#f48fb6',
      300: '#f16299',
      400: '#ee3e82',
      500: '#ed156b',
      600: '#db1368',
      700: '#c51162',
      800: '#b00e5e',
      900: '#8a0955',
    },
  },

  purple: {
    self: {
      100: '#FDECFA',
      200: '#FBDBF8',
      300: '#F3C4F2',
      400: '#E3B0E7',
      500: '#CE93D8',
      600: '#A86BB9',
      700: '#834A9B',
    },
    light: {
      100: '#FCC0D9',
      200: '#F9AFD5',
      300: '#F594D0',
      400: '#D26CB5',
      500: '#B04A9D',
      600: '#8E2F86',
      700: '#741C75',
    },
    dark: {
      100: '#EBD7F8',
      200: '#D6B1F2',
      300: '#B081DA',
      400: '#8559B5',
      500: '#522B84',
      600: '#3F1F71',
      700: '#2F155F',
    },
    reproduction: {
      50: '#efe8f0',
      100: '#F4E4F4',
      200: '#bf9fc4',
      300: '#a57cab',
      400: '#926498',
      500: '#815287',
      600: '#764c80',
      700: '#674475',
      800: '#5a3e69',
      900: '#433351',
    },
  },
  white: {
    self: {
      100: '#FFFFFF',
    },
  },
  black: {
    self: {
      100: '#000000',
    },
  },
}
