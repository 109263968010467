import { configureStore } from '@reduxjs/toolkit'
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import rootReducer from './combineReducers'

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredActionPaths: [
          'meta.arg.actionsOnSuccess',
          'meta.arg.cb',
          'meta.arg.manipulateDatagrid',
          'meta.arg.manipulateCcomMap',
          'meta.arg.manipulateCTechMap',
          'meta.arg.params',
          'meta.arg.renderCustomResponse',
          'meta.arg.setError',
          'payload.actionsOnSuccess',
          'payload.columns',
          'payload.conflictsAction',
          'payload.params',
          'payload.summary',
          'payload.url',
        ],
        ignoredPaths: ['chart', 'datagrid', 'dialog'],
      },
    }),
  ],
  devTools: import.meta.env.NODE_ENV !== 'production',
})

store.persist = () => persistStore(store)
window.globalStore = store

export default store
