import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { enUS, esES, ptBR } from '@mui/x-date-pickers/locales'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import 'dayjs/locale/en'
import 'dayjs/locale/pt-br'
import Translate from '#/components/Translate'

// Função para obter o texto de localização apropriado com base no idioma
function getLocaleText(language) {
  switch (language) {
    case 'pt':
      return {
        ...ptBR.components.MuiLocalizationProvider.defaultProps.localeText,
        // Adiciona tradução que falta no MUI
        fieldClearLabel: Translate({ messageKey: 'clear' }),
      }
    case 'es':
      return esES.components.MuiLocalizationProvider.defaultProps.localeText
    default:
      return enUS.components.MuiLocalizationProvider.defaultProps.localeText
  }
}

export default function LocalizationProviderConfig({ children }) {
  // Obtém o idioma do localStorage, ou usa 'en' como padrão
  const language = localStorage.getItem('language') ?? 'en'
  // Define o locale para o dayjs com base no idioma
  const locale = language === 'pt' ? 'pt-br' : language
  dayjs.locale(locale)

  // Obtém o texto de localização apropriado, utilizado no datepicker
  const localeText = getLocaleText(language)

  return (
    // Configura o LocalizationProvider com o adaptador Dayjs e o texto de localização apropriado
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={locale}
      localeText={localeText}
    >
      {children}
    </LocalizationProvider>
  )
}
