/**
 * Conversão de Hexadecimal para HSL
 * @param {string} hex Valor da cor hexadecimal a ser convertida
 * @param {Boolean} object Caso true retorna um objeto com os valores h, s, l
 * @returns
 */

export default function HexToHsl(hex, object) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  let r = parseInt(result[1], 16) / 255
  let g = parseInt(result[2], 16) / 255
  let b = parseInt(result[3], 16) / 255
  let max = Math.max(r, g, b)
  let min = Math.min(r, g, b)
  let h
  let s
  let l = (max + min) / 2

  if (max === min) {
    h = s = 0
  } else {
    let d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
      default:
    }
    h /= 6
  }
  let fh = Math.round(360 * h)
  let fs = Math.round(s * 100)
  let fl = Math.round(l * 100)

  return object ? { h: fh, s: fs, l: fl } : `hsl(${fh}, ${fs}%, ${fl}%)`
}

/**
 * Função responsável a deixar uma cor mais escura
 * @param {string} hex cor a ser alterada
 * @param {number} porcent numero a ser reduzido da luminosidade
 * @returns retorna hsl montado após calculo
 */
export function HSLDarker(hex, porcent) {
  if (!hex) return null

  const hsl = HexToHsl(hex, true)
  const l = hsl.l - porcent
  return `hsl(${hsl.h}, ${hsl.s}%, ${l < 0 ? 0 : l}%)`
}

/**
 * Função responsável a deixar uma cor mais clara
 * @param {string} hex cor a ser alterada
 * @param {number} porcent numero a ser aumentado da luminosidade
 * @returns retorna hsl montado após calculo
 */
export function HSLLighter(hex, porcent) {
  if (!hex) return null

  const hsl = HexToHsl(hex, true)
  const l = hsl.l + porcent
  return `hsl(${hsl.h}, ${hsl.s}%, ${l > 100 ? 100 : l}%)`
}
