import React from 'react'
import TextField from '@mui/material/TextField'

const InputText = ({ value, defaultValue, onChange, ...rest }) => {
  const initial = value || defaultValue
  const [selectedVal, setSelectedVal] = React.useState(
    initial ? String(initial) : '',
  )

  const handleChange = value => {
    setSelectedVal(value)
    if (onChange) {
      onChange(value)
    }
  }

  React.useEffect(() => {
    setSelectedVal(value)
  }, [value])
  return (
    <TextField
      {...rest}
      value={selectedVal ?? ''}
      onChange={event => handleChange(event.target.value)}
      InputProps={rest.InputProps}
      InputLabelProps={{ shrink: true }}
    />
  )
}

export default InputText
