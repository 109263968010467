import React from 'react'
import Stack from '@mui/material/Stack'
import { isEqual, isNumber } from 'lodash'
import { Box, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import stringLimit from '#/utils/stringLimit'
import Translate from '#/components/Translate'
import AvatarCell from '#/components/FormatCell/Types/AvatarLink/Manipulate/AvatarCell'
import Fonts from '#/styles/Fonts'
import DiscardIcon from '#/icons/Discard'
import AnimalIcon from '#/icons/Animal'
import BatchIcon from '#/icons/Batch'
import NewBadge from '#/components/badges/NewBadge'
import Colors from '#/styles/Colors'
import { getLink } from '#/utils/getLink'
import { getCurrentFarmSlug } from '#/store/ducks/farm'

const Title = styled('span')(({ discarded }) => ({
  fontSize: Fonts.fontSize.S,
  color: Colors.primary,
  fontWeight: 700,
  ...(discarded && {
    textDecoration: 'line-through',
    color: Colors.animal.discard.color,
  }),
}))

const Subtitle = styled('small')({
  maxWidth: 120,
  display: 'block',
  color: Colors.muted,
  fontSize: Fonts.fontSize.SS,
})

const AvatarWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const Container = styled(Stack)(({ collar }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: 16,
  ...(collar && {
    '&:hover h6': {
      color: Colors.primary,
    },
  }),
}))

/**
 * @param {string} title main
 * @param {string} subtitle subtitle
 * @param {string} linkTo type link 'animal', 'batch'
 * @param {string} slug used to create link
 * @param {string} tab used to create link
 * @param {string} collar number or string with collar value, color avatar
 * @returns It returns a link to an animal or batch, with an avatar, title, and subtitle
 */
const TypeAnimalLink = props => {
  const {
    title,
    subtitle,
    linkTo,
    icon = 'animal',
    limit = 30,
    collar,
    discarded,
    timestamp,
  } = props

  const currentFarmSlug = useSelector(getCurrentFarmSlug, isEqual)

  if (!title) return <span>-</span>

  const iconsList = {
    discard: <DiscardIcon sx={{ fontSize: '30px' }} />,
    animal: <AnimalIcon sx={{ fontSize: '30px' }} />,
    batch: <BatchIcon sx={{ fontSize: '28px' }} />,
  }
  const tooltip = (
    <React.Fragment>
      {Translate({ messageKey: ['access_item', linkTo], lower: [false, true] })}
      {collar && isNumber(collar) && (
        <>
          <br />
          <span>
            {Translate({
              messageKey: ['attached_collar', collar],
              translate: [true, false],
              separator: ': ',
            })}
          </span>
        </>
      )}
    </React.Fragment>
  )

  return (
    <Tooltip title={tooltip}>
      <Link to={getLink({ ...props, type: linkTo, farm: currentFarmSlug })}>
        <Container collar={String(collar)}>
          <AvatarWrapper>
            <AvatarCell icon={iconsList[icon]} type={icon} collar={collar} />
            <NewBadge timestamp={timestamp} />
          </AvatarWrapper>
          <Box>
            <Title discarded={discarded}>{title}</Title>
            <Subtitle>{stringLimit(subtitle, limit)}</Subtitle>
          </Box>
        </Container>
      </Link>
    </Tooltip>
  )
}
export default TypeAnimalLink
