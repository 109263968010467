import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export default function CowBabyIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 -960 960 960"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M327.693-668.461q-12.75 0-21.375-8.628-8.624-8.629-8.624-21.384 0-12.756 8.624-21.371 8.625-8.615 21.375-8.615h122.308v-86.155q0-12.749 8.628-21.374 8.629-8.625 21.384-8.625 12.756 0 21.371 8.625 8.615 8.625 8.615 21.374v86.155h122.308q12.75 0 21.375 8.628 8.624 8.629 8.624 21.385 0 12.755-8.624 21.37-8.625 8.615-21.375 8.615H327.693Zm44.616 559.997q-29.827 0-51.067-21.24-21.241-21.24-21.241-51.067V-520q0-45.192 31.635-76.826 31.634-31.635 76.826-31.635h143.076q45.192 0 76.826 31.635 31.635 31.634 31.635 76.826v339.229q0 29.827-21.241 51.067-21.24 21.24-51.067 21.24H372.309ZM360-168.462h227.691q5.385 0 8.847-3.462 3.462-3.462 3.462-8.847V-520q0-20.596-13.933-34.529-13.933-13.933-34.529-13.933H408.462q-20.596 0-34.529 13.933Q360-540.596 360-520v45h80q12.75 0 21.374 8.629 8.625 8.629 8.625 21.384 0 12.756-8.625 21.371-8.624 8.615-21.374 8.615h-80v93.078h80q12.769 0 21.384 8.628 8.615 8.629 8.615 21.385 0 12.755-8.625 21.37-8.624 8.615-21.374 8.615h-80v93.463Zm0 0v-400V-168.462Z" />
    </SvgIcon>
  )
}
