import ReactDOM from 'react-dom/client'
import App from './App'
import tryCache from '#/utils/tryCache'
import initializeSentry from '#/utils/initSentry'
import initializeFirebase from '#/store/services/Firebase'
import Providers from '#/store/providers/Providers'

import './index.css'

/* A function that removes expired items from the browser's local storage. */
tryCache.clearExpired()

/* Initializing the Firebase SDK. */
initializeFirebase()

/* Initializing the Sentry SDK. */
initializeSentry()

ReactDOM.createRoot(document.getElementById('root')).render(
  <Providers>
    <App />
  </Providers>,
)
