import React from 'react'
import CowmedChip from '#/components/Chip'
import Colors from '#/styles/Old/Colors'

function CollarsStockBadge({ code, label }) {
  let badgeBg = ''

  switch (code) {
    case -1:
      badgeBg = Colors.collar_revision
      break

    case -2:
      badgeBg = Colors.collar_discard
      break

    case -10:
      badgeBg = Colors.collar_cotrijal
      break

    case -11:
      badgeBg = Colors.collar_cotriba
      break

    default:
      badgeBg = Colors.collar_available
      break
  }

  return (
    <CowmedChip
      customStyle={{ color: '#fff', backgroundColor: badgeBg }}
      value={label}
      width="unset"
    />
  )
}

export default CollarsStockBadge
