import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export default function DoubleCheckIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 0 16 16"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z" />
      <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z" />
    </SvgIcon>
  )
}
