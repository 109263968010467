import axios from 'axios'
import { showDialogError } from '#/store/ducks/error'

// Configuração da instância do Axios com interceptadores
const axiosInstance = axios.create()

// request interceptor to add token to request headers
axiosInstance.interceptors.request.use(
  async config => {
    if (
      !config.controller?.includes('http') ||
      config.controller?.includes(import.meta.env.VITE_API_URL)
    ) {
      config.headers = {
        'Accept': 'application/json',
        'Accept-Language': localStorage.getItem('language'),
        'Content-Type': 'application/json',
        'app_version': import.meta.env.VITE_VERSION,
        ...config.headers,
      }
      // Repassa o cookie de autenticação para o backend
      config.withCredentials = true
      // URL base
      config.baseURL = import.meta.env.VITE_API_URL
    }

    // Renomeia controller
    config.url = config.controller

    return config
  },
  error => Promise.reject(error),
)

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    switch (error?.response?.status) {
      case 401: {
        const { url } = error.config
        //Ignore missing farm code 401
        if (!url.startsWith('api/farm/') && !url.endsWith('/cx')) {
          const intended =
            window.location.pathname !== '/logout' &&
            window.location.pathname !== '/'
              ? `?intended=${window.location.href}`
              : ''
          window.location.replace(
            `${import.meta.env.VITE_AUTH_URL}/login${intended}`,
          )
        }
        break
      }

      case 402:
        if (window.location.pathname !== '/inactive-mode')
          window.location.replace('/inactive-mode')
        break

      case 503:
        if (window.location.pathname !== '/maintenance')
          window.location.replace('/maintenance')
        break

      // Send error to sentry and show dialog for user feedback in ErrorBoundary
      case 500:
        const { error: responseError } = error.response.data

        if (responseError)
          window.globalStore.dispatch(
            showDialogError({
              origin: 'Backend',
              ...responseError,
            }),
          )
        break

      default:
        return Promise.reject(error?.response)
    }
  },
)
export { axiosInstance }
