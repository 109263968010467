import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export default function CTechIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 0 24 24"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M22.18,18.66,20.51,17a3.67,3.67,0,0,0-.35-4.76l-7.9-7.9A3.67,3.67,0,0,0,7.5,4l-2-2A1,1,0,0,0,4.07,2L1.81,4.26a1,1,0,0,0,0,1.45l2,2A3.66,3.66,0,0,0,4.3,12.3l7.89,7.89a3.66,3.66,0,0,0,4.59.49l1.7,1.69a1,1,0,0,0,.72.3,1.06,1.06,0,0,0,.74-.3l2.25-2.25A1,1,0,0,0,22.18,18.66ZM3.62,5,4.8,3.81l1,1L4.65,6Zm12.49,14a1.88,1.88,0,0,1-2.65,0L5.56,11a1.88,1.88,0,0,1,0-2.65L8.34,5.6A1.88,1.88,0,0,1,11,5.6l7.89,7.89a1.89,1.89,0,0,1,0,2.66Zm3.1,1.64-1-1,1.17-1.18,1,1Z" />
      <path d="M10.27,6.75a1.13,1.13,0,0,0-1.55,0L6.56,8.91a1.1,1.1,0,0,0-.32.77,1.14,1.14,0,0,0,.32.78l7.69,7.68a1.06,1.06,0,0,0,.77.32h0a1.07,1.07,0,0,0,.77-.32L18,16a1.1,1.1,0,0,0,0-1.55Zm5.93,8.46L15,16.38l-6.7-6.7L9.5,8.51Z" />
    </SvgIcon>
  )
}
