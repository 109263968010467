import React from 'react'

/**
 * Switch best options
 * @memberOf Utils
 * @function switchFn
 * 
 * // const logFruit = {
//   'apples': knownFruit,
//   'oranges': knownFruit,
//   'default': unknownFruit
// };

// const fruitSwitch = switchFn(logFruit, 'default');
// fruitSwitch('apples', parameters); // Logs: 'Known fruit'
 */
const switchFn =
  (lookupObject, defaultCase = '_default') =>
  (expression, rest) => {
    if (typeof lookupObject[expression] === 'function') {
      return lookupObject[expression](rest)
    }
    if (!expression && typeof lookupObject[defaultCase] === 'function') {
      return lookupObject[defaultCase](rest)
    }
    if (lookupObject[expression] || lookupObject[defaultCase]) {
      return lookupObject[expression] ?? lookupObject[defaultCase]
    }
    return <>-</>
  }

export default switchFn
