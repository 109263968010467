import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import clsx from 'clsx'
import useStyles from './styles'

export default function DatasheetSkeleton() {
  const classes = useStyles()
  const structure = [
    [...Array(4).keys()],
    [...Array(10).keys()],
    [...Array(2).keys()],
    [...Array(6).keys()],
  ]

  return (
    <>
      {structure.map((i, idx) => (
        <div className={classes.skeleton} key={`skeleton-${idx}`}>
          <Skeleton height={30} />
          <ul className={clsx(classes.ul, classes.ulNested)}>
            {i.map((_, idx) => (
              <Skeleton
                key={`skeleton-inside-${idx}`}
                width={`${parseInt(50 + Math.random() * 50, 10)}%`}
              />
            ))}
          </ul>
        </div>
      ))}
    </>
  )
}
