import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'
import { endpoint } from '#/store/services/endpoints'
import tryCache from '#/utils/tryCache'
import { getPermissions } from '#/store/ducks/auth'
import { fetcher } from '#/store/hooks/request'

const defaultState = {
  currentFarm: {
    expires: 0,
    status: null,
    slug: null,
    error: '',
    data: {},
  },
  currentBatch: {},
  currentAnimal: {},
}

export const loadFarmStatus = createAsyncThunk(
  'farm/loadFarmStatus',
  async (payload, thunkAPI) => {
    const { controller, name, params } = payload

    if (!isEmpty(controller)) {
      try {
        const { data } = await fetcher({ controller, params })
        return { name, controller, data, params }
      } catch (e) {
        return thunkAPI.rejectWithValue({
          name,
          controller,
          message: e.message,
        })
      }
    }

    return thunkAPI.rejectWithValue({
      name,
      controller,
      message: 'load farm status not handled',
    })
  },
)

// equivalent to loadFarmStatus
export const loadCurrentFarmInfo = createAsyncThunk(
  'farm/loadCurrentFarmInfo',
  async (payload, thunkAPI) => {
    const { farmSlug } = payload
    const state = thunkAPI.getState()

    // checks if same farm infos expired
    if (
      farmSlug === state?.farm?.currentFarm?.slug &&
      farmSlug === state?.farm?.currentFarm?.data?.slug &&
      !tryCache.isExpired(state?.farm?.currentFarm?.expires)
    ) {
      return {
        expires: state.farm.currentFarm.expires,
        data: state.farm.currentFarm.data,
      }
    }

    if (!isEmpty(farmSlug)) {
      try {
        const controller = endpoint.farm.self({ farm: farmSlug })
        const { data } = await fetcher({ controller })

        // get farm permissions
        await thunkAPI.dispatch(getPermissions({ context: 'farm', farmSlug }))

        return {
          expires: tryCache.expiresIn(10), // 10 minutes
          data,
        }
      } catch ({ message, code }) {
        return thunkAPI.rejectWithValue({ error: { message, code } })
      }
    }
    return thunkAPI.rejectWithValue({
      slug: farmSlug,
      message: 'load farm info not handled',
    })
  },
)

export const farmSlice = createSlice({
  name: 'farm',
  initialState: defaultState,
  reducers: {
    setCurrentFarm(state, action) {
      state.currentFarm.data = action.payload
    },
    resetCurrentFarm(state) {
      state.currentFarm = defaultState.currentFarm
    },
    setCurrentBatch(state, action) {
      state.currentBatch = action.payload
    },
    resetCurrentBatch(state) {
      state.currentBatch = {}
    },
    setNPSCampaign(state) {
      // Informação referente ao usuário na fazenda
      state.currentFarm.data.has_active_campaign = null
    },
    setCurrentAnimal(state, action) {
      state.currentAnimal = action.payload
    },
    resetCurrentAnimal(state) {
      state.currentAnimal = {}
    },
    setCurrentVicAssistant(state, action) {
      state.currentFarm.data = {
        ...state.currentFarm.data,
        vic_assistant: action.payload?.assistant,
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadFarmStatus.pending, (state, action) => {
        state[action.meta.arg.name] = {
          controller: action.meta.arg.controller,
          status: 'started',
          error: '',
          data: state[action.meta.arg.name]?.data ?? {},
        }
      })
      .addCase(loadFarmStatus.fulfilled, (state, action) => {
        state[action.payload?.name] = {
          controller: action.payload?.controller,
          status: 'done',
          error: '',
          data: action.payload?.data,
        }
      })
      .addCase(loadFarmStatus.rejected, (state, action) => {
        state[action.payload?.name] = {
          controller: action.payload?.controller,
          status: 'failed',
          error: action.payload?.message,
          data: state[action.payload?.name]?.data ?? {},
        }
      })
      .addCase(loadCurrentFarmInfo.fulfilled, (state, action) => {
        state.currentFarm = {
          data: action.payload?.data,
          slug: action.meta.arg.farmSlug,
          status: 'done',
          error: '',
          expires: action.payload?.expires,
        }
      })
      .addCase(loadCurrentFarmInfo.rejected, (state, action) => {
        state.currentFarm = {
          ...action.payload,
          slug: action.meta.arg.farmSlug,
          status: 'failed',
        }
      })
  },
  selectors: {
    getCurrentFarm: state => state?.currentFarm?.data ?? {},
    getCurrentFarmInfo: state => state?.currentFarm ?? {},
    getCurrentBatch: state => state?.currentBatch ?? null,
    getCurrentAnimal: state => state?.currentAnimal ?? null,
    getCurrentFarmSlug: state => state?.currentFarm?.data?.slug ?? null,
    getCurrentAnimalSlug: state => state?.currentAnimal?.slug ?? null,
    getCurrentFarmTimezone: state =>
      state?.currentFarm?.data?.timezone ?? 'America/Sao_Paulo',
    getCurrentFarmName: state => state?.currentFarm?.data?.name ?? null,
    getCurrentFarmVicAssistant: state =>
      state?.currentFarm?.data?.vic_assistant ?? null,
    getCurrentFarm2ndPregnancyConf: state =>
      state?.currentFarm?.data?.second_pregnancy_confirmation_active ?? null,
    getCurrentFarm3rdPregnancyConf: state =>
      state?.currentFarm?.data?.third_pregnancy_confirmation_active ?? null,
    getFarmNPSCampaign: state =>
      state?.currentFarm?.data?.has_active_campaign ?? null,
  },
})

export const {
  actions: {
    setNPSCampaign,
    setCurrentFarm,
    setCurrentBatch,
    setCurrentAnimal,
    setCurrentVicAssistant,
    resetCurrentFarm,
    resetCurrentBatch,
    resetCurrentAnimal,
  },
  selectors: {
    getCurrentFarm,
    getCurrentFarmInfo,
    getCurrentBatch,
    getCurrentAnimal,
    getCurrentFarmSlug,
    getCurrentAnimalSlug,
    getCurrentFarmTimezone,
    getCurrentFarmName,
    getCurrentFarmVicAssistant,
    getCurrentFarm2ndPregnancyConf,
    getCurrentFarm3rdPregnancyConf,
    getFarmNPSCampaign,
  },
} = farmSlice

export default farmSlice.reducer
