import * as React from 'react'
import { isEqual } from 'lodash'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import Translate from '#/components/Translate'
import Colors from '#/styles/Old/Colors'
import InitializeClarity from '#/store/services/Clarity'
import { getUserInfo } from '#/store/ducks/auth'

const style = {
  card: {
    width: '100%',
    position: 'sticky',
    bottom: 0,
    zIndex: 1000,
    backgroundColor: '#EAF6E9',
    color: Colors.laragrey,
    borderRadius: 0,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: { xs: 'column', md: 'row' },
    padding: '24px',
  },
  buttons: {
    'display': 'flex',
    'alignItems': 'center',
    'gap': '16px',
    'marginTop': { xs: '24px', md: 0 },
    'marginLeft': { md: '24px' },
    '& button': {
      borderRadius: '8px',
    },
  },
  text: {
    margin: 'auto 0',
    fontSize: '16px',
  },
  accept: {
    color: 'white',
  },
}

export default function Consent() {
  const [open, setOpen] = React.useState(true)
  const userInfo = useSelector(getUserInfo, isEqual)

  const isLogout = window.location.pathname === '/logout'

  /**
   * Verifica se a string "consent" ou "reject" está presente no valor do cookie "document.cookie"
   * Se estiver presente, retorna nulo para não exibir o consentimento.
   */
  if (
    /(consent|reject)/.test(document.cookie) ||
    userInfo.isSuspended ||
    isLogout
  ) {
    return null
  }

  /**
   * Esta função define um cookie para consentimento, aciona um evento de consentimento usando o Clarity se ele tiver sido iniciado
   * e fecha o modal.
   */
  const acceptHandle = () => {
    document.cookie = `smartfarm_clarity=consent; max-age="${
      60 * 60 * 24 * 365
    }`
    InitializeClarity(userInfo)
    setOpen(false)
  }

  /**
   * Esta função define um cookie com um valor de "reject" e uma idade máxima de 30 dias,
   * e fecha o modal.
   */
  const rejectHandle = () => {
    document.cookie = `smartfarm_clarity=reject; max-age="${60 * 60 * 24 * 30}`
    setOpen(false)
  }

  return open ? (
    <Card sx={style.card}>
      <CardContent sx={style.content}>
        <p style={style.text}>{Translate({ messageKey: 'cookie_consent' })}</p>
        <Box sx={style.buttons}>
          <Button variant="outlined" onClick={rejectHandle}>
            {Translate({ messageKey: 'reject' })}
          </Button>
          <Button variant="contained" onClick={acceptHandle} sx={style.accept}>
            {Translate({ messageKey: 'accept' })}
          </Button>
        </Box>
      </CardContent>
    </Card>
  ) : null
}
