import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import Translate from '#/components/Translate'

const Wrapper = styled('div')({
  alignSelf: 'end',
})

export const FindMyCowActions = props => {
  const { id, data, farm, redirect, onClose = () => {} } = props

  let animal_slug

  data?.filter(Boolean)?.forEach(item => {
    if ('animal_slug' in item) {
      animal_slug = item.animal_slug
    }
  })

  if (!animal_slug) return null

  const label = Translate({
    messageKey: ['access_item', 'animal'],
    lower: [false, true],
  })

  const link = `/farm/${farm}/modules/herd/animals/${animal_slug}`

  if (redirect) {
    return (
      <Wrapper key={id}>
        <Button
          variant="outlined"
          onClick={() => (window.location.href = link)}
        >
          {label}
        </Button>
      </Wrapper>
    )
  }

  return (
    <Wrapper key={id}>
      <Link to={link} onClick={onClose}>
        <Button variant="outlined">{label}</Button>
      </Link>
    </Wrapper>
  )
}
