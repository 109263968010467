import * as React from 'react'
import { IMaskInput } from 'react-imask'
import Input from '@mui/material/Input'
import { useState, useCallback } from 'react'
import { FormControl, InputLabel } from '@mui/material'

const definitions = {
  // hexadecimal
  F: /[0-9a-fA-F]/,
}

const TextMaskCustom = React.forwardRef((props, ref) => {
  const { onChange, mask, lazy, uppercase, ...rest } = props

  const prepare = React.useCallback(
    val => (uppercase ? val.toUpperCase() : val),
    [uppercase],
  )

  return (
    <IMaskInput
      {...rest}
      lazy={lazy}
      mask={mask}
      inputRef={ref}
      prepare={prepare}
      onAccept={onChange}
      definitions={definitions}
    />
  )
})

const InputMask = props => {
  const {
    value,
    defaultValue = '',
    onChange = () => {},
    lazy = false,
    mask,
    unmask = true,
    uppercase = true,
    label,
    ...rest
  } = props

  const initial = value || defaultValue
  const [selectedVal, setSelectedVal] = useState(initial ? String(initial) : '')

  const handleChange = useCallback(
    (val, mask) => {
      setSelectedVal(val)
      if (onChange) onChange(unmask ? mask.unmaskedValue : val)
    },
    [onChange, unmask],
  )

  return (
    <FormControl variant="standard">
      <InputLabel shrink>{label}</InputLabel>
      <Input
        {...rest}
        value={selectedVal}
        onChange={handleChange}
        inputComponent={TextMaskCustom}
        inputProps={{ lazy, mask, uppercase }}
      />
    </FormControl>
  )
}

export default InputMask
