import { createSlice } from '@reduxjs/toolkit'

// dialog reducer
const dialogSlice = createSlice({
  name: 'dialog',
  initialState: {},
  reducers: {
    toggleDialog: (state, action) => {
      state[action?.payload?.name ?? 'dialog'] = action?.payload
      return state
    },
  },
  selectors: {
    getDialog: (state, name) => state?.[name] ?? {},
  },
})

export const {
  actions: { toggleDialog },
  selectors: { getDialog },
} = dialogSlice

export default dialogSlice.reducer
