import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form/dist/react-hook-form.ie11'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isPlainObject from 'lodash/isPlainObject'
import debounce from 'lodash/debounce'
import _has from 'lodash/has'
import Collapse from '@mui/material/Collapse'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { Box, Button as MuiButton } from '@mui/material'
import { isNil } from 'lodash'
import Translate from '#/components/Translate'
import Button from '../Button'
import InputMask from '../HooksInputs/InputMask'
import InputText from '../HooksInputs/InputText'
import TextEditor from '../HooksInputs/TextEditor'
import InputPhone from '../HooksInputs/InputPhone'
import DateTimePicker from '../HooksInputs/DateTimePicker'
import TimePicker from '../HooksInputs/TimePicker'
import ReactSelect from '../HooksInputs/ReactSelect'
import Moment, { fmt } from '#/components/Moment'
import {
  getDefaultFormValues,
  getGroupFormValues,
} from '../HooksInputs/normalize'
import InputRadio from '../HooksInputs/InputRadio'
import InputSwitch from '../HooksInputs/InputSwitch'
import ResponseDialog from '#/components/Modal/ResponseDialog'
import LabelWithHelp from '../LabelWithHelp'
import triggerValidation, {
  disabledUnless,
  disabledIf,
  distinctFrom,
  formatFormValues,
  hiddenIf,
  hiddenUnless,
  renderFormHelper,
  requiredIf,
} from './validation'
import LinearIndeterminate from '#/components/Loader'
import EmailInput from '#/components/FormControl/EmailInput'
import PinMapInput from '#/components/FormControl/PinMap'
import { fetcher } from '#/store/hooks/request'
import CowmedGrid from '#/components/Grid'

export default function FormBuilder({
  formData = {},
  formStatus,
  formEvents,
  onSubmit,
  submittingStatus,
  responseDialog,
  variant = 'standard',
  submitButton = {
    title: Translate({ messageKey: 'register_item' }),
    props: { asCreate: true },
  },
  extraButtons = [],
  reverseQuicktip = [],
}) {
  let { fields } = formData

  /* Fix fields name with '.', change to '**' */
  fields = fields?.map(field => {
    return {
      ...field,
      ...(field.name ? { name: field.name.replace('.', '**') } : {}),
    }
  })

  /* Handle value fields, need to collapse type */
  const flatFields = [
    ...fields,
    ...fields.filter(f => f.type === 'collapse').map(f => f.fields),
  ]?.flat(1)

  const { errors, control, setError, clearError, getValues, watch } = useForm({
    defaultValues: getDefaultFormValues(flatFields),
  })

  const [groupValues, setGroupValues] = useState(getGroupFormValues(fields))
  const [expanded, setExpanded] = React.useState({})

  //const { touched, dirty } = formState //comes from useForm
  const isSubmitting = submittingStatus === 'started'
  const isLoadingFields = formStatus === 'started'
  const form = []

  let count = 0

  const handleAddFormLine = groupName => {
    const group = get(groupValues, groupName, null)
    if (group) {
      const { fields, groupedFields, max_entries } = group
      if (
        max_entries &&
        Array.isArray(fields) &&
        groupedFields.length < max_entries
      ) {
        setGroupValues(prevState => ({
          ...prevState,
          [groupName]: {
            ...prevState[groupName],
            groupedFields: [...groupedFields, fields],
          },
        }))
      }
    }
  }

  const handleRemoveFormLine = groupName => {
    const group = get(groupValues, groupName, null)
    if (group) {
      const { groupedFields, min_entries } = group
      if (
        !isNil(min_entries) &&
        Array.isArray(groupedFields) &&
        groupedFields.length > min_entries
      ) {
        setGroupValues(prevState => ({
          ...prevState,
          [groupName]: {
            ...prevState[groupName],
            groupedFields: [...groupedFields.slice(0, -1)],
          },
        }))
      }
    }
  }

  // trigger actions based in a field events
  const onFieldChangeActions = (fieldName, fieldVal, fieldEvents) => {
    const onUpdateEvents = get(fieldEvents, 'change.actions', [])
    if (
      onUpdateEvents.includes('re-source-form') &&
      formEvents &&
      formEvents.resourceForm
    ) {
      const formValues = getValues()
      formValues[fieldName] = fieldVal || null // field value from getValues() inst set yet on this function
      formEvents.resourceForm(formValues)
    }
  }

  const getField = ({
    name,
    type,
    label,
    isRequired,
    disabled,
    error,
    rules,
    value,
    defaultValue,
    placeholder,
    events,
    rest,
  }) => {
    const isHiddenUnless = hiddenUnless(name, rules, watch)
    const isHiddenIf = hiddenIf(name, rules, watch)
    // no need to proceed if is hidden
    if (isHiddenUnless || isHiddenIf) return null

    const isDisabledUnless = disabledUnless(name, rules, watch)
    const isDisabledIf = disabledIf(name, rules, watch)
    const isDisabled =
      disabled ||
      isSubmitting ||
      isDisabledUnless ||
      isDisabledIf ||
      rest.readonly ||
      isLoadingFields
    const isRequiredIf = requiredIf(name, rules, isDisabled, watch)
    const isDistinctFrom = distinctFrom(name, rules, groupValues, watch)

    const inputProps = {}
    const fieldLabel = isEmpty(rest.help) ? (
      label
    ) : (
      <LabelWithHelp
        fieldLabel={label}
        helpTitle={rest.help.title}
        helpBody={rest.help.body}
      />
    )

    switch (type) {
      case 'number':
      case 'percentage':
      case 'integer': {
        if (rest.suffix) {
          inputProps.endAdornment = (
            <InputAdornment position="end">{rest.suffix}</InputAdornment>
          )
        }

        const parseNumericData = data => {
          if (data === '') return null
          if (type === 'integer') return parseInt(data, 10)
          let floatData = data.replace(/,/g, '.') // replaces all commas (,) with dots (.)
          let parts = floatData.split('.') // splits everything by dots (.)
          // checks if there's more than one dot (.)
          if (parts.length > 2) {
            floatData = `${parts.slice(0, -1).join('')}.${parts.slice(-1)}` // removes all dots except the last one
            parts = floatData.split('.') // splits everything by dots (.) again
          }
          floatData = parseFloat(floatData) // parses the data to real number
          // checks whether it's necessary to cut digits after the dot
          if (
            !rest.precision ||
            parts.length < 2 ||
            parts[1].length <= rest.precision
          )
            return floatData
          // rounds floating number to the precision attribute
          return parseFloat(floatData.toFixed(rest.precision))
        }

        return (
          <Controller
            key={name}
            name={name}
            control={control}
            onChange={([data]) => {
              const val = parseNumericData(data)
              triggerValidation(
                name,
                val,
                rules,
                isDisabled,
                setError,
                clearError,
                watch,
                groupValues,
                getValues,
              )
              onFieldChangeActions(name, val, events)
              return val
            }}
            as={
              <InputText
                type="number"
                required={isRequired || isRequiredIf}
                label={fieldLabel}
                placeholder={placeholder}
                error={!!error || isDistinctFrom}
                disabled={isDisabled}
                helperText={rest.description}
                defaultValue={defaultValue}
                InputProps={inputProps}
                variant={variant}
                fullWidth
              />
            }
          />
        )
      }

      case 'textarea': {
        return (
          <Controller
            key={name}
            name={name}
            control={control}
            onChange={([data]) => {
              const value = String(data)
              triggerValidation(
                name,
                value,
                rules,
                isDisabled,
                setError,
                clearError,
                watch,
                groupValues,
                getValues,
              )
              onFieldChangeActions(name, value, events)
              return value
            }}
            as={
              <InputText
                required={isRequired || isRequiredIf}
                label={fieldLabel}
                placeholder={placeholder}
                error={!!error || isDistinctFrom}
                disabled={isDisabled}
                defaultValue={defaultValue}
                InputProps={inputProps}
                multiline
                rows={3}
                fullWidth
                variant={variant}
              />
            }
          />
        )
      }

      case 'wysiwyg': {
        return (
          <Controller
            key={name}
            name={name}
            control={control}
            onChange={([data]) => {
              const value = String(data)
              triggerValidation(
                name,
                value,
                rules,
                isDisabled,
                setError,
                clearError,
                watch,
                groupValues,
                getValues,
              )
              onFieldChangeActions(name, value, events)
              return value
            }}
            as={
              <TextEditor
                required={isRequired || isRequiredIf}
                label={fieldLabel}
                placeholder={placeholder}
                error={!!error || isDistinctFrom}
                disabled={isDisabled}
                defaultValue={defaultValue}
              />
            }
          />
        )
      }

      case 'email-list': {
        return (
          <Controller
            key={name}
            name={name}
            control={control}
            onChange={([value]) => {
              triggerValidation(
                name,
                value,
                rules,
                isDisabled,
                setError,
                clearError,
                watch,
                groupValues,
                getValues,
              )
              onFieldChangeActions(name, value, events)
              return value
            }}
            as={
              <EmailInput
                type={type}
                required={isRequired || isRequiredIf}
                label={fieldLabel}
                placeholder={placeholder}
                error={!!error || isDistinctFrom}
                disabled={isDisabled}
                defaultValue={defaultValue}
                fullWidth
                variant={variant}
              />
            }
          />
        )
      }

      case 'pin': {
        return (
          <Controller
            key={name}
            name={name}
            control={control}
            onChange={([value]) => {
              triggerValidation(
                name,
                value,
                rules,
                isDisabled,
                setError,
                clearError,
                watch,
                groupValues,
                getValues,
              )
              onFieldChangeActions(name, value, events)
              return value
            }}
            as={
              <PinMapInput
                required={isRequired || isRequiredIf}
                error={!!error || isDistinctFrom}
                disabled={isDisabled}
                defaultValue={defaultValue}
                {...rest}
              />
            }
          />
        )
      }

      case 'email':
      case 'password':
      case 'text': {
        return (
          <Controller
            key={name}
            name={name}
            control={control}
            onChange={([data]) => {
              const value = String(data)
              triggerValidation(
                name,
                value,
                rules,
                isDisabled,
                setError,
                clearError,
                watch,
                groupValues,
                getValues,
              )
              onFieldChangeActions(name, value, events)
              return value
            }}
            as={
              <InputText
                type={type}
                required={isRequired || isRequiredIf}
                label={fieldLabel}
                placeholder={placeholder}
                error={!!error || isDistinctFrom}
                disabled={isDisabled}
                defaultValue={defaultValue}
                fullWidth
                variant={variant}
              />
            }
          />
        )
      }

      case 'mask': {
        return (
          <Controller
            key={name}
            name={name}
            control={control}
            onChange={([data]) => {
              const value = String(data)
              triggerValidation(
                name,
                value,
                rules,
                isDisabled,
                setError,
                clearError,
                watch,
                groupValues,
                getValues,
              )
              onFieldChangeActions(name, value, events)
              return value
            }}
            as={
              <InputMask
                mask={rest.mask}
                unmask={rest.unmask}
                lazy={rest.lazy}
                uppercase={rest.uppercase}
                type="text"
                required={isRequired || isRequiredIf}
                label={fieldLabel}
                placeholder={placeholder}
                error={!!error || isDistinctFrom}
                disabled={isDisabled}
                defaultValue={defaultValue}
                variant={variant}
                fullWidth
              />
            }
          />
        )
      }

      case 'tel': {
        return (
          <Controller
            key={name}
            name={name}
            control={control}
            onChange={([data]) => {
              const value = data ? String(data) : null
              const customRules = {
                ...rules,
                validPhoneNumber: {
                  value: true,
                  message: Translate({ messageKey: 'enter_valid_phone' }),
                },
              }
              triggerValidation(
                name,
                value,
                customRules,
                isDisabled,
                setError,
                clearError,
                watch,
                groupValues,
                getValues,
              )
              onFieldChangeActions(name, value, events)
              return value
            }}
            as={
              <InputPhone
                required={isRequired || isRequiredIf}
                label={fieldLabel}
                placeholder={placeholder}
                error={!!error || isDistinctFrom}
                disabled={isDisabled}
                defaultValue={defaultValue}
                defaultCountry={rest.defaultCountry || 'BR'}
                country={rest.country}
                international={rest.international}
                fullWidth
                variant={variant}
              />
            }
          />
        )
      }

      case 'date':
      case 'timestamp': {
        const limits = {}

        if (rules?.before_or_equal?.value) {
          limits.maxDate = Moment(rules.before_or_equal.value).format(fmt.api)
        } else if (rules?.before?.value) {
          limits.maxDate = Moment(rules.before.value)
            .subtract(1, 'minute')
            .format(fmt.api)
        }

        if (rules?.after_or_equal?.value) {
          limits.minDate = Moment(rules.after_or_equal.value).format(fmt.api)
        } else if (rules?.after?.value) {
          limits.minDate = Moment(rules.after.value)
            .add(1, 'minute')
            .format(fmt.api)
        }
        return (
          <Controller
            key={name}
            name={name}
            control={control}
            onChange={([data]) => {
              triggerValidation(
                name,
                data,
                rules,
                isDisabled,
                setError,
                clearError,
                watch,
                groupValues,
                getValues,
              )
              onFieldChangeActions(name, data, events)
              return data
            }}
            as={
              <DateTimePicker
                type={type}
                label={fieldLabel}
                placeholder={placeholder}
                required={isRequired || isRequiredIf}
                disabled={isDisabled}
                error={!!error || isDistinctFrom}
                defaultValue={defaultValue}
                inputVariant={variant}
                {...limits}
              />
            }
          />
        )
      }

      case 'time': {
        return (
          <Controller
            key={name}
            name={name}
            control={control}
            onChange={([data]) => {
              triggerValidation(
                name,
                data,
                rules,
                isDisabled,
                setError,
                clearError,
                watch,
                groupValues,
                getValues,
              )
              onFieldChangeActions(name, data, events)
              return data
            }}
            as={
              <TimePicker
                label={fieldLabel}
                placeholder={placeholder}
                required={isRequired || isRequiredIf}
                disabled={isDisabled}
                error={!!error || isDistinctFrom}
                defaultValue={defaultValue}
                inputVariant={variant}
              />
            }
          />
        )
      }

      case 'select': {
        let select = { async: false }
        let defOptions = null

        if (value) {
          if (isPlainObject(value) && 'value' in value) {
            defOptions = [value]
          } else if (Array.isArray(value)) {
            defOptions = value
          }
        }

        if (rest.source) {
          // is async searchable (searchable by API)
          const srcUri = get(rest, 'source.uri', rest.source)
          const srcParams = get(rest, 'source.params', {})
          if (rest.searchable) {
            select = {
              ...select,
              source: srcUri,
              async: true,
              searchable: rest.searchable,
              defaultOptions: defOptions,
              cacheOptions: false,
              loadOptions: debounce((search, callback) => {
                const values = getValues(name)

                const ignoredValues = Array.isArray(values)
                  ? values?.join(',')
                  : values

                fetcher({
                  controller: srcUri,
                  params: {
                    search,
                    ...srcParams,
                    ignore: !isEmpty(values) ? ignoredValues : undefined,
                  },
                })
                  .then(({ data }) => {
                    callback(data?.results ?? [])
                  })
                  .catch(error => console.error(error))
              }, 500),
            }
          } else {
            select = {
              ...select,
              defaultOptions: defOptions,
              source: srcUri,
              sourceParams: srcParams,
            }
          }
        } else {
          select = { options: rest.options, ...select }
        }
        return (
          <Controller
            key={name}
            name={name}
            control={control}
            onChange={([data]) => {
              let value = data
              if (select.async) {
                if (
                  rest.multi &&
                  Array.isArray(data) &&
                  _has(data, '0.value')
                ) {
                  value = data.map(option => option.value) // converts to an array of strings
                } else {
                  value = get(data, 'value', null)
                }
              }
              triggerValidation(
                name,
                value,
                rules,
                isDisabled,
                setError,
                clearError,
                watch,
                groupValues,
                getValues,
              )
              // if (!isNewOption) { // do not update form if its a new option
              onFieldChangeActions(name, value, events)
              // }
              return value
            }}
            as={
              <ReactSelect
                isMulti={rest.multi}
                allowSelectAll={rest.allowSelectAll}
                allowCreateOption={rest.allowCreateOption}
                label={fieldLabel}
                placeholder={placeholder}
                required={isRequired || isRequiredIf}
                disabled={isDisabled}
                readOnly={rest.readonly}
                error={!!error || isDistinctFrom}
                value={value}
                defaultValue={defaultValue}
                variant={variant}
                {...select}
              />
            }
          />
        )
      }

      case 'choice': {
        return (
          <Controller
            key={name}
            name={name}
            control={control}
            onChange={([data]) => {
              triggerValidation(
                name,
                data,
                rules,
                isDisabled,
                setError,
                clearError,
                watch,
                groupValues,
                getValues,
              )
              onFieldChangeActions(name, data, events)
              return data
            }}
            as={
              <InputRadio
                label={fieldLabel}
                required={isRequired || isRequiredIf}
                disabled={isDisabled}
                error={!!error || isDistinctFrom}
                helperText={rest.description}
                readOnly={rest.readonly}
                defaultValue={defaultValue}
                {...rest}
              />
            }
          />
        )
      }

      case 'boolean': {
        return (
          <Controller
            key={name}
            name={name}
            control={control}
            onChange={([data]) => {
              triggerValidation(
                name,
                data,
                rules,
                isDisabled,
                setError,
                clearError,
                watch,
                groupValues,
                getValues,
              )
              onFieldChangeActions(name, data, events)
              return data
            }}
            as={
              <InputSwitch
                label={fieldLabel}
                required={isRequired || isRequiredIf}
                disabled={isDisabled}
                error={!!error || isDistinctFrom}
                helperText={rest.description}
                value={value}
                defaultValue={defaultValue}
                variant={variant}
                readOnly={rest.readonly}
                {...rest}
              />
            }
          />
        )
      }

      case 'session': {
        return (
          <>
            <Typography
              key={name}
              variant="h6"
              style={{ fontWeight: 600, margin: 0 }}
              gutterBottom
            >
              {label}
            </Typography>
            {rest?.description && <span>{rest?.description}</span>}
          </>
        )
      }

      case 'collapse': {
        let isExpanded = expanded[name]
        if (typeof expanded[name] !== 'boolean') {
          setExpanded({ ...expanded, [name]: !!value })
          isExpanded = !!value
        }
        return rest?.fields?.length ? (
          <>
            <MuiButton
              onClick={() => setExpanded({ ...expanded, [name]: !isExpanded })}
              aria-expanded={name}
              endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            >
              {label}
            </MuiButton>
            <Collapse in={isExpanded} timeout="auto">
              <CowmedGrid container spacing={2} mt={2}>
                {rest.fields.map((field, idxField) =>
                  createRegularField(field, idxField),
                )}
              </CowmedGrid>
            </Collapse>
          </>
        ) : null
      }

      case 'message': {
        return value ? (
          <div>
            <Alert severity={rest.variation ? rest.variation : 'error'}>
              {label && <AlertTitle>{label}</AlertTitle>}
              {value}
            </Alert>
          </div>
        ) : null
      }

      case 'separator': {
        return (<br />)
      }

      default:
        return null
    }
  }

  // regular field build method
  function createRegularField(field, idxField) {
    const {
      name,
      type,
      label,
      cols,
      disabled,
      rules,
      value,
      default: defaultValue,
      placeholder,
      events,
      ...rest
    } = field
    const error = get(errors, `${field.name}.message`, null)
    const isRequired = get(rules, 'required.value', false)
    const fieldControl = getField({
      name,
      type,
      label,
      isRequired,
      disabled,
      error,
      rules,
      value,
      defaultValue,
      placeholder,
      events,
      renderQuicktipToTheRight: reverseQuicktip.includes(idxField),
      rest,
    })

    return fieldControl ? (
      <CowmedGrid
        size={{ xs: 12, sm: cols }}
        key={`${name}.${idxField}`}
        style={{
          ...(rest.hidden ? { display: 'none' } : {}),
        }}
      >
        <FormControl error={!!error} fullWidth>
          {fieldControl}
          {renderFormHelper(error)}
        </FormControl>
      </CowmedGrid>
    ) : null
  }

  fields.forEach((field, idxField) => {
    if (field.type === 'group' && !isEmpty(groupValues)) {
      const {
        name: groupName,
        label,
        groupedFields,
        min_entries,
        max_entries,
        line_button_add_text,
        line_button_remove_text,
        cols,
      } = groupValues[field.name]
      form.push(
        <CowmedGrid
          container
          spacing={4}
          size={cols}
          key={`${groupName}.${count}`}
        >
          {label && (
            <CowmedGrid
              size={{ xs: 12, sm: 12, md: cols }}
              key={`label-group-${count}`}
            >
              <Typography variant="h6" gutterBottom>
                {label}
              </Typography>
            </CowmedGrid>
          )}
          {groupedFields.map((row, idxRow) => {
            return (
              <CowmedGrid
                container
                key={`${groupName}.${count}.${idxRow}`}
                spacing={2}
              >
                {row.map(eachField => {
                  const {
                    name,
                    type,
                    label,
                    cols,
                    disabled,
                    rules,
                    value,
                    default: defaultValue,
                    placeholder,
                    events,
                    ...rest
                  } = eachField

                  const isRequired = get(rules, 'required.value', false)
                  const fieldName = `${groupName}[${idxRow}].${name}`
                  const error = get(errors, `${fieldName}.message`, null)

                  const fieldControl = getField({
                    name: fieldName,
                    type,
                    label: label?.replace('{row}', idxRow + 1),
                    isRequired,
                    disabled,
                    error,
                    rules,
                    value,
                    defaultValue,
                    placeholder: placeholder?.replace('{row}', idxRow + 1),
                    events,
                    rest,
                  })

                  return fieldControl ? (
                    <CowmedGrid
                      size={{
                        xs: 12,
                        sm: cols > 6 ? cols : 6,
                        md: cols > 3 ? cols : 3,
                        lg: cols,
                      }}
                      key={fieldName}
                      style={rest.hidden ? { display: 'none' } : {}}
                    >
                      <FormControl error={!!error} fullWidth>
                        {fieldControl}
                        {renderFormHelper(error)}
                      </FormControl>
                    </CowmedGrid>
                  ) : null
                })}
              </CowmedGrid>
            )
          })}
          <CowmedGrid
            size={12}
            key="add-remove-line-btns"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
            }}
          >
            {max_entries !== min_entries ? (
              <Box sx={{ gap: 1, display: 'flex' }}>
                <MuiButton
                  size="small"
                  onClick={() => handleAddFormLine(groupName)}
                  disabled={groupedFields.length === max_entries}
                >
                  {line_button_add_text ||
                    Translate({
                      messageKey: 'add_item',
                      params: { item: Translate({ messageKey: 'row' }) },
                    })}
                </MuiButton>
                <MuiButton
                  color="error"
                  size="small"
                  onClick={() => handleRemoveFormLine(groupName)}
                  disabled={groupedFields.length === min_entries}
                >
                  {line_button_remove_text ||
                    Translate({ messageKey: 'remove_row' })}
                </MuiButton>
              </Box>
            ) : null}
          </CowmedGrid>
        </CowmedGrid>,
      )
      count++
    } else {
      // regular field
      const newRegularField = createRegularField(field, idxField)
      if (newRegularField) form.push(newRegularField)
    }
  })

  form.push(
    <CowmedGrid
      size={12}
      key="form-buttons"
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      {extraButtons.length > 0 &&
        extraButtons.map((btn, index) => (
          <Button
            key={btn.key ?? `extra-button-${index}`}
            btnProps={{
              variant: 'extended',
              size: 'medium',
              disabled: isSubmitting,
            }}
            {...btn.props}
          >
            {btn.title}
          </Button>
        ))}
      <Button
        {...submitButton.props}
        key="formbuilder-submit"
        btnProps={{
          type: 'submit',
          variant: 'extended',
          size: 'medium',
          disabled: isSubmitting,
        }}
      >
        {submitButton.title}
      </Button>
    </CowmedGrid>,
  )

  const renderResponseDialog = () => {
    if (isEmpty(responseDialog)) return null
    return <ResponseDialog dialogResponse={responseDialog} />
  }

  const handleSubmit = e => {
    e.preventDefault()
    const unformattedValues = getValues()

    /* Revert fields name '**' to '.' */
    const formattedValues = Object.keys(unformattedValues).map(key => ({
      [key?.replace('**', '.')]: unformattedValues[key],
    }))
    const values = Object.assign({}, ...formattedValues)

    const allValidations = []
    fields.forEach(field => {
      if (field.type === 'group') {
        Object.values(groupValues).forEach(group => {
          const { name: groupName, groupedFields } = group
          groupedFields.forEach((row, idxRow) => {
            row.forEach(eachField => {
              const { name, rules, disabled } = eachField
              const fieldName = `${groupName}[${idxRow}].${name}`
              const hasError = triggerValidation(
                fieldName,
                values[fieldName],
                rules,
                disabled,
                setError,
                clearError,
                watch,
                groupValues,
                getValues,
              )
              allValidations.push(hasError)
            })
          })
        })
      } else if (
        field.type === 'collapse' &&
        field.fields?.length &&
        expanded[field.name]
      ) {
        field.fields.forEach(cField => {
          const { name, rules = {}, disabled } = cField
          const hasError = triggerValidation(
            name,
            values[name],
            rules,
            disabled,
            setError,
            clearError,
            watch,
            groupValues,
            getValues,
          )
          allValidations.push(hasError)
        })
      } else {
        const { name, rules = {}, disabled } = field
        const hasError = triggerValidation(
          name,
          values[name],
          rules,
          disabled,
          setError,
          clearError,
          watch,
          groupValues,
          getValues,
        )
        allValidations.push(hasError)
      }
    })

    if (allValidations.filter(i => i).length === 0) {
      //  no validation errors
      const resultObj = formatFormValues(
        values,
        fields.filter(f => f.type !== 'session'),
      )
      // adds expanded state to the request
      onSubmit({ ...resultObj, ...expanded }, setError)
    }
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit} noValidate>
        {(submittingStatus || formStatus) && (
          <LinearIndeterminate
            status={submittingStatus || formStatus}
            absolute
          />
        )}
        <CowmedGrid container spacing={2}>
          {form}
        </CowmedGrid>
      </form>
      {renderResponseDialog()}
    </React.Fragment>
  )
}
