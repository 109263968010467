function hasInRoute(value, index) {
  const pathSplit = window.location.pathname.split('/')
  return pathSplit[index < 0 ? pathSplit.length + index : index] === value
}

/**
 * checks if it's a business environment page
 * @returns {boolean}
 */
export default function isBusinessEnvironment() {
  return !hasInRoute('farm', 1)
}
