import { useState } from 'react'

/**
 * Switches between true/false states
 *
 * @param {boolean} defaultValue - Function to be delayed
 *
 * @returns {object} Array with current value and toggle function
 *
 * @example
 * const [isOpen, toggleIsOpen] = useToggle(false)
 * */
export default function useToggle(defaultValue) {
  const [value, setValue] = useState(defaultValue)

  function toggleValue(newValue) {
    setValue(currentValue =>
      typeof newValue === 'boolean' ? newValue : !currentValue,
    )
  }

  return [value, toggleValue]
}
