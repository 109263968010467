import React from 'react'
import isEmpty from 'lodash/isEmpty'
import Tooltip from '@mui/material/Tooltip'
import CollarIcon from '@mui/icons-material/Memory'
import Fonts from '#/styles/Fonts'
import Colors from '#/styles/Colors'
import Translate from '#/components/Translate'
import Moment from '#/components/Moment'

const getStatus = unit => {
  let style = { color: Colors.success }
  let tooltipStyle = {
    last_monitoring_update: {
      color: Colors.muted,
    },
    last_collar_update: {
      color: Colors.muted,
    },
    collars_not_reporting_12: {
      color: Colors.muted,
    },
    collars_not_reporting_24: {
      color: Colors.muted,
    },
  }

  const {
    last_monitoring_update,
    last_collar_update,
    collars_not_reporting: {
      // four_hours: collars_not_reporting_4,
      twelve_hours: collars_not_reporting_12,
      twenty_four_hours: collars_not_reporting_24,
    },
  } = unit

  if (last_monitoring_update === null && last_collar_update === null) {
    style = { color: Colors.muted }
  }

  if (collars_not_reporting_12 > 0) {
    // Coleiras que não estão reportando por mais de 12h
    tooltipStyle.collars_not_reporting_12 = { color: Colors.warning }
    style = { color: Colors.warning }
  }

  if (collars_not_reporting_24 > 0) {
    // Coleiras que não estão reportando por mais de 24h
    tooltipStyle.collars_not_reporting_24 = { color: Colors.danger }
    style = { color: Colors.danger }
  }

  if (Moment.diff('now', last_monitoring_update, 'minutes') > 4 * 60) {
    // problemas na transmissão de dados
    tooltipStyle.last_monitoring_update = { color: Colors.warning }
  }
  if (Moment.diff('now', last_monitoring_update, 'minutes') > 12 * 60) {
    // problemas na transmissão de dados
    tooltipStyle.last_monitoring_update = { color: Colors.danger }
  }

  if (Moment.diff('now', last_collar_update, 'minutes') > 4 * 60) {
    // problemas na transmissão de dados
    tooltipStyle.last_collar_update = { color: Colors.warning }
  }
  if (Moment.diff('now', last_collar_update, 'minutes') > 12 * 60) {
    // problemas na transmissão de dados
    tooltipStyle.last_collar_update = { color: Colors.danger }
  }

  return { style, tooltipStyle }
}

const CollarStatusByUnit = props => {
  const style = {
    icon: {
      fontSize: Fonts.fontSize.XXL,
      marginRight: 5,
    },
    tooltip: {
      fontSize: Fonts.fontSize.S,
      fontWeight: Fonts.fontWeight.bold,
      lineHeight: 1.5,
    },
  }

  const { unit, customStyle = {}, withTitles = true } = props

  const status = getStatus(unit)
  const tooltipId = `tooltip-collar-${Math.floor(Math.random() * 1000)}`
  return (
    <span style={{ ...customStyle }}>
      <Tooltip
        id={tooltipId}
        title={
          <div>
            {withTitles ? (
              <h3>
                {Translate({ messageKey: 'unit' })}: {unit.name}
              </h3>
            ) : null}
            <div
              style={{
                ...style.tooltip,
                ...status.tooltipStyle.last_collar_update,
              }}
            >
              {Translate({ messageKey: 'last_collar_update' })} :{' '}
              {isEmpty(unit.last_collar_update)
                ? '-'
                : Moment.view(unit.last_collar_update)}
            </div>
            <div
              style={{
                ...style.tooltip,
                ...status.tooltipStyle.last_monitoring_update,
              }}
            >
              {Translate({ messageKey: 'collar_last_report' })} :{' '}
              {isEmpty(unit.last_monitoring_update)
                ? '-'
                : Moment.view(unit.last_monitoring_update)}
            </div>
            <div
              style={{
                ...style.tooltip,
                ...status.tooltipStyle.collars_not_reporting_12,
              }}
            >
              {Translate({
                messageKey: 'collars_not_reporting_more_last_hours',
                params: { hours: 12 },
              })}
              : {unit.collars_not_reporting.twelve_hours}
            </div>
            <div
              style={{
                ...style.tooltip,
                ...status.tooltipStyle.collars_not_reporting_24,
              }}
            >
              {Translate({
                messageKey: 'collars_not_reporting_more_last_hours',
                params: { hours: 24 },
              })}
              : {unit.collars_not_reporting.twenty_four_hours}
            </div>
          </div>
        }
      >
        <span
          data-for={tooltipId}
          style={{
            ...style.icon,
            ...status.style,
          }}
        >
          <CollarIcon />
        </span>
      </Tooltip>
    </span>
  )
}

export default CollarStatusByUnit
