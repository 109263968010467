import React from 'react'
import FormHelperText from '@mui/material/FormHelperText'
import { get, set, isNumber, isEmpty, isNil, isObject } from 'lodash'
import { isPossiblePhoneNumber } from 'react-phone-number-input/min'
import isValidEmail from '#/utils/isValidEmail'
import Moment from '#/components/Moment'
import { isValidCoordinates } from '#/utils/validCoordinates'

/**
 * @function triggerValidation
 * @description set or clear errors on fields based on its values.
 * Call this function in onChange and onSubmit handlers
 * @param name {String} - field name
 * @param value {String|Number|Array|Object} - field value
 * @param rules {Object} - field rules
 * {
 *    required: { value: true, message: 'esse campo é obrigatório' },
 *    min: { value: 2, message: 'o valor deve ser menor que 2' },
 *    max: { value: 2, message: 'o valor deve ser maior que 2' },
 *    required_f: { value: 'someValue', field: 'someField', message: 'O campo `someField` é obrigatório quando o valor é `someValue`' },
 *    distinct: { message: 'o campo precisa ter um valor distinto' }, // only for FieldArrays
 * }
 * @param isDisabled {boolean} - checks if validation is needed
 * @param setError {Function} - react-hook-form setError function (https://react-hook-form.com/api/#setError)
 * @param clearError {Function} - react-hook-form clearError function (https://react-hook-form.com/api/#clearError)
 * @param watch {Function} - react-hook-form watch function (https://react-hook-form.com/api/#watch)
 * @param allGroupedFields {Object} - FieldArrays (Group of fields)
 * @return {boolean} - If field has error or not
 */
export default function triggerValidation(
  name,
  value,
  rules,
  isDisabled,
  setError,
  clearError,
  watch,
  allGroupedFields = {},
  getValues,
) {
  if (!name || isDisabled) return false

  const {
    required,
    min,
    max,
    required_if,
    distinct,
    same,
    confirmed,
    validPhoneNumber,
    email,
    before_or_equal,
    after_or_equal,
    greater_than,
    less_than,
    is_location,
  } = rules

  const allValues = getValues()
  const isString = typeof value === 'string'
  const isArray = Array.isArray(value)
  const groupFieldPosition = name.match(/\[\d]/g) // has to be a string with [\d] on it's content
  const allErrors = []

  const getFieldValue = target => {
    const targetCompleteKey = Object.keys(allValues).find(i =>
      i.includes(target),
    )
    return allValues[targetCompleteKey]
  }

  if (
    required &&
    required.value &&
    (isString || Array.isArray(value) ? !value.length : isNil(value))
  ) {
    allErrors.push({ type: 'required', name, message: required.message })
  }

  if (is_location && isObject(value) && (!value.lat || !value.lng)) {
    allErrors.push({ type: 'required', name, message: required.message })
  }

  if (
    !isNil(value) &&
    min &&
    (isString || isArray ? value.length < min.value : value < min.value)
  ) {
    allErrors.push({ type: 'min', name, message: min.message })
  }

  if (
    !isNil(value) &&
    max &&
    (isString || isArray ? value.length > max.value : value > max.value)
  ) {
    allErrors.push({ type: 'max', name, message: max.message })
  }

  if (
    !isNumber(value) &&
    isEmpty(value) &&
    required_if &&
    get(required_if, 'value', null)
  ) {
    let fieldVal = null

    if (
      groupFieldPosition &&
      Array.isArray(groupFieldPosition) &&
      groupFieldPosition.length
    ) {
      const groupIndex = groupFieldPosition[0]
      const arrayField = rules.required_if.field.split('.')
      const field =
        arrayField.length > 1 ? arrayField.join(`${groupIndex}.`) : ''
      fieldVal = field ? watch(field) : null
    } else {
      fieldVal = watch(rules.required_if.field)
    }

    if (
      Array.isArray(required_if.value)
        ? required_if.value.includes(fieldVal)
        : fieldVal === required_if.value
    ) {
      allErrors.push({ type: 'required', name, message: required_if.message })
    }
  }

  if (!isNil(value) && distinct) {
    if (
      !isEmpty(allGroupedFields) &&
      groupFieldPosition &&
      Array.isArray(groupFieldPosition) &&
      groupFieldPosition.length
    ) {
      const groupIndex = groupFieldPosition[0]
      const splitedFieldName = name.split(groupIndex)
      const groupName = splitedFieldName[0]
      const fieldCurrentVal = value

      const someEqualValue = allGroupedFields[groupName].groupedFields.map(
        (group, gIdx) => {
          if (!isNil(fieldCurrentVal)) {
            const pos = `[${gIdx}]` !== groupIndex ? `[${gIdx}]` : null
            return (
              fieldCurrentVal ===
              (pos ? watch(`${groupName}${pos}${splitedFieldName[1]}`) : null)
            )
          }
          return false
        },
      )

      if (someEqualValue.filter(i => i).length) {
        allErrors.push({ type: 'manual', name, message: distinct.message })
      }
    }
  }

  if (!isNil(value) && confirmed) {
    const confirmationField = `${name}_confirmation`
    const toCompareFieldValue = watch(confirmationField)
    clearError(confirmationField) // to fix unwanted errors
    if (toCompareFieldValue !== value) {
      allErrors.push({
        type: 'manual',
        name: confirmationField,
        message: confirmed.message,
      })
    }
  }

  if (!isNil(value) && same && same.value) {
    const toCompareFieldValue = watch(same.field)
    if (toCompareFieldValue !== value) {
      allErrors.push({ type: 'manual', name, message: same.message })
    }
  }

  if (!isNil(value) && validPhoneNumber && validPhoneNumber.value) {
    if (!isPossiblePhoneNumber(value)) {
      allErrors.push({
        type: 'manual',
        name,
        message: validPhoneNumber.message,
      })
    }
  }

  // Verificar se o 'value' não é nulo, não é indefinido e existe um objeto 'email'
  if (!isNil(value) && email) {
    // Se 'value' for uma string, verificar se é um email válido
    if (typeof value === 'string' && !isValidEmail(value)) {
      allErrors.push({ type: 'manual', name, message: email.message })
    }
    // Se 'value' for uma matriz, verificar se há algum email inválido na matriz
    if (Array.isArray(value) && value.some(val => !isValidEmail(val))) {
      allErrors.push({ type: 'manual', name, message: email.message })
    }
  }

  if (!isNil(value) && before_or_equal && before_or_equal.value) {
    const base = Moment(before_or_equal.value).endOf('d')
    const compare = Moment(value).startOf('d')
    if (Moment(compare).isAfter(base)) {
      allErrors.push({ type: 'manual', name, message: before_or_equal.message })
    }
  }

  if (!isNil(value) && after_or_equal && after_or_equal.value) {
    const base = Moment(after_or_equal.value).endOf('d')
    const compare = Moment(value).startOf('d')
    if (Moment(compare).isBefore(base)) {
      allErrors.push({ type: 'manual', name, message: after_or_equal.message })
    }
  }

  if (!isNil(value) && greater_than && greater_than.field) {
    const base = Moment(getFieldValue(greater_than.field)).endOf('d')
    const compare = Moment(value)
    if (Moment(compare).isBefore(base)) {
      allErrors.push({ type: 'manual', name, message: greater_than.message })
    }
  }

  if (!isNil(value) && less_than && less_than.field) {
    const base = Moment(getFieldValue(less_than.field)).endOf('d')
    const compare = Moment(value)
    if (Moment(compare).isAfter(base)) {
      allErrors.push({ type: 'manual', name, message: less_than.message })
    }
  }

  if (!isNil(value) && is_location) {
    const { lat, lng } = value
    if (!isValidCoordinates(lat, lng)) {
      allErrors.push({ type: 'manual', name, message: is_location.message })
    }
  }

  if (allErrors.length) {
    // set all field errors
    clearError(name) // clear ALL FIELD ERRORS
    allErrors.forEach(({ name, type, message }) =>
      setError(name, type, message),
    )
  } else {
    clearError(name)
  }

  return !!allErrors.length
}

// returns de value of the rule field reference
const getRuleFieldValue = (name, rule, watch) => {
  if (name && rule?.field?.indexOf('[*]')) {
    const groupFieldPosition = name.match(/\[\d+]/g)

    if (Array.isArray(groupFieldPosition) && groupFieldPosition?.length) {
      return watch(rule.field.replace('[*]', groupFieldPosition[0]))
    }
  }
  return watch(rule.field)
}

/**
 * @function hiddenUnless
 * @description return if the given field has to be hidden
 * @param name {String} - field name
 * @param rules {Object} - field rules
 * {
 *    hidden_unless: { field: `someField`, value: `someValue` },
 * }
 * @param watch {Function} - react-hook-form watch function (https://react-hook-form.com/api/#watch)
 * @return {boolean}
 */
export const hiddenUnless = (name, rules, watch) => {
  if (rules && rules.hidden_unless) {
    const fieldVal = getRuleFieldValue(name, rules.hidden_unless, watch)

    return Array.isArray(rules.hidden_unless.value)
      ? !rules.hidden_unless.value.includes(fieldVal)
      : fieldVal !== rules.hidden_unless.value
  }
  return false
}

/**
 * @function hiddenIf
 * @description return if the given field has to be hidden
 * @param name {String} - field name
 * @param rules {Object} - field rules
 * {
 *    hidden_if: { field: `someField`, value: `someValue` },
 * }
 * @param watch {Function} - react-hook-form watch function (https://react-hook-form.com/api/#watch)
 * @return {boolean}
 */
export const hiddenIf = (name, rules, watch) => {
  if (name && rules && rules.hidden_if) {
    const fieldVal = getRuleFieldValue(name, rules.hidden_if, watch)

    return Array.isArray(rules.hidden_if.value)
      ? rules.hidden_if.value.includes(fieldVal)
      : fieldVal === rules.hidden_if.value
  }
  return false
}

/**
 * @function disabledUnless
 * @description return if the given field has to be disabled
 * @param name {String} - field name
 * @param rules {Object} - field rules
 * {
 *    disabled_unless: { field: `someField`, value: `someValue` },
 * }
 * @param watch {Function} - react-hook-form watch function (https://react-hook-form.com/api/#watch)
 * @return {boolean}
 */
export const disabledUnless = (name, rules, watch) => {
  if (name && rules && rules.disabled_unless) {
    const fieldVal = getRuleFieldValue(name, rules.disabled_unless, watch)

    return Array.isArray(rules.disabled_unless.value)
      ? !rules.disabled_unless.value.includes(fieldVal)
      : fieldVal !== rules.disabled_unless.value
  }
  return false
}

/**
 * @function disabledIf
 * @description return if the given field has to be disabled
 * @param name {String} - field name
 * @param rules {Object} - field rules
 * {
 *    disabled_if: { field: `someField`, value: `someValue` },
 * }
 * @param watch {Function} - react-hook-form watch function (https://react-hook-form.com/api/#watch)
 * @return {boolean}
 */
export const disabledIf = (name, rules, watch) => {
  if (name && rules?.disabled_if) {
    const rulesArray = Array.isArray(rules.disabled_if)
      ? rules.disabled_if
      : [rules.disabled_if]

    for (let i = 0; i < rulesArray.length; i++) {
      const fieldVal = getRuleFieldValue(name, rulesArray[i], watch)

      const result = Array.isArray(rulesArray[i].value)
        ? rulesArray[i].value.includes(fieldVal)
        : fieldVal === rulesArray[i].value

      if (result) return true
    }
  }
  return false
}

/**
 * @function requiredIf
 * @description return if the given field has to be required
 * @param name {String} - field name
 * @param rules {Object} - field rules
 * {
 *    required_if: { field: `someField`, value: `someValue` },
 * }
 * @param isDisabled {boolean} - result from isDisabled variable
 * @param watch {Function} - react-hook-form watch function (https://react-hook-form.com/api/#watch)
 * @return {boolean}
 */
export const requiredIf = (name, rules, isDisabled, watch) => {
  if (isDisabled) return false
  if (name && rules && rules.required_if) {
    const fieldVal = getRuleFieldValue(name, rules.required_if, watch)

    return Array.isArray(rules.required_if.value)
      ? rules.required_if.value.includes(fieldVal)
      : rules.required_if.value === fieldVal
  }
  return false
}

/**
 * @function distinctFrom
 * @description return if the given field has a distinct value from the other fields from group
 * @param name
 * @param rules
 * @param groupValues {Object} - field values from a group
 * @param watch {Function} - react-hook-form watch function (https://react-hook-form.com/api/#watch)
 * @return {boolean}
 */
export const distinctFrom = (name, rules, groupValues, watch) => {
  if (rules && rules.distinct) {
    const groupFieldPosition = name.match(/\[\d+]/g)
    // let fieldVal

    if (
      groupFieldPosition &&
      Array.isArray(groupFieldPosition) &&
      groupFieldPosition.length
    ) {
      const groupIndex = groupFieldPosition[0]
      const splitedFieldName = name.split(groupIndex)
      const groupName = splitedFieldName[0]
      const fieldCurrentVal = watch(name)

      const someEqualValue = groupValues[groupName].groupedFields.map(
        (group, gIdx) => {
          if (!isNil(fieldCurrentVal)) {
            const pos = `[${gIdx}]` !== groupIndex ? `[${gIdx}]` : null
            return (
              fieldCurrentVal ===
              (pos ? watch(`${groupName}${pos}${splitedFieldName[1]}`) : null)
            )
          }
          return false
        },
      )

      return Boolean(someEqualValue.filter(i => i).length)
    }
  }
  return false
}

export const renderFormHelper = error => {
  if (!error) {
    return null
  }
  return <FormHelperText>{error}</FormHelperText>
}

/**
 * @function formatFormValues
 * @param values {Object} - form values - result from react-hook-form getValues() function https://react-hook-form.com/api#getValues
 * @param fields {Object} - fields from API
 * @return {Object} - formatted API values
 */
export const formatFormValues = (values, fields) => {
  let resultObj = {}
  Object.keys(values).forEach((key, valIdx) => {
    if (fields[valIdx]) {
      if (!fields[valIdx].disabled) {
        set(resultObj, key, values[key])
      }
    } else {
      set(resultObj, key, values[key])
    }
  })
  return resultObj
}
