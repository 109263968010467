import React, { useState } from 'react'
import { isEmpty, isEqual } from 'lodash'
import Paper from '@mui/material/Paper'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import { Avatar, Button, ListItemText, Tooltip } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import MuiSkeleton from '@mui/material/Skeleton'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import DrawerBox from '#/components/DrawerBox'
import Colors from '#/styles/Old/Colors'
import Translate from '#/components/Translate'
import FormatCell from '#/components/FormatCell'
import { eventCode } from '#/utils'
import stringLimit from '#/utils/stringLimit'
import ReproductionIcon from '#/icons/Reproduction'
import { getCurrentFarmSlug } from '#/store/ducks/farm'
import ActionsDialog from '#/components/Modal/ActionDialog'
import ResponseDialog from '#/components/Modal/ResponseDialog'
import { getEvent } from '#/store/ducks/event'
import { getDialog } from '#/store/ducks/dialog'
import ReproductionEventsForm from '../../../pages/Farm/Modules/Reproduction/Forms/Events'
import Moment from '#/components/Moment'
import Flex from '#/components/Flex'
import FindMyCow, { FindMyCowButton } from '#/components/FindMyCow'
import { useFetch } from '#/store/hooks/request'
import CowmedGrid from '#/components/Grid'

const styles = {
  avatar: {
    backgroundColor: Colors.heat_alert,
    width: '3em',
    height: '3em',
    marginRight: 20,
  },
  detected: {
    fontSize: 11,
    fontWeight: 300,
    color: Colors.darkgrey,
    display: 'block',
    lineHeight: '11px',
  },
  drawer: {
    borderTop: '1px solid #e0e0e0',
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header_status: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    marginRight: '10px',
  },
}

const StyledPaper = styled(Paper)(({ type, padding = 10 }) => ({
  ...(type === 'info' && {
    background: '#fafafa',
  }),
  borderRadius: 8,
  boxShadow: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  margin: 5,
  padding,
}))
const MatingStyle = styled(ListItem)({
  background: '#fafafa',
  marginBottom: '8px',
  padding: '5px 16px',
  borderRadius: 8,
})
const FooterWrapper = styled(Box)({
  marginTop: 'auto',
  display: 'flex',
  justifyContent: 'space-around',
  padding: '10px 0',
  width: '100%',
})

const HealthWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 20,
})

const EmptyList = styled('small')({
  textAlign: 'center',
  display: 'block',
  margin: '10px 0',
  color: 'gray',
})

const StyledListItem = styled(ListItem)({
  display: 'flex',
  alignItems: 'center',
  gap: 30,
})

const StyledList = styled(List)({
  overflowY: 'scroll',
  maxHeight: 300,
})

const InfosValue = styled('span')({
  fontSize: '20px',
  fontWeight: '700',
  color: '#666',
})

const InfosText = styled('p')({
  fontSize: '14px',
  fontWeight: '400',
  color: '#999',
  margin: 0,
})

const IntelLabel = styled('p')({
  fontSize: '14px',
  fontWeight: '400',
  color: '#999',
  margin: '0 0 10px',
})
const SessionTitle = styled('h4')({
  margin: '16px 0',
})

const HEAT_CONFIRM = 'heat-alert/confirm'

export default function AnimalDrawer(props) {
  const { controller, event = {}, open, onClose, afterSuccess } = props
  const farm = useSelector(getCurrentFarmSlug, isEqual)
  const history = useHistory()

  const animalSlug = event['subject.slug'] ?? event['animal.slug']

  const responseDialog = {
    [HEAT_CONFIRM]: {
      name: HEAT_CONFIRM,
      ...useSelector(state => getEvent(state, HEAT_CONFIRM), isEqual),
      ...useSelector(state => getDialog(state, HEAT_CONFIRM), isEqual),
      afterSuccess: () => {
        if (afterSuccess) {
          afterSuccess()
        }
        onClose()
      },
      onClose: () => setDialogActions(dialogInitialState),
    },
  }
  const dialogInitialState = {
    type: null,
    dialogOpened: false,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogContent: null,
    dialogOnClose: () => {},
  }
  const [dialogActions, setDialogActions] = useState(dialogInitialState)

  const { data, isLoading } = useFetch({ controller })

  const {
    del,
    dui,
    lactations,
    services,
    heat_strength,
    dtr,
    ppi_status,
    health_status,
    diagnostics,
    confirmed_as,
    date,
    mating,
  } = data ?? {}

  const StatusBlocks = [
    {
      text: Translate({ messageKey: 'strength' }),
      value: (
        <FormatCell
          type="heat-strength"
          alignItems="center"
          {...heat_strength}
        />
      ),
    },
    {
      text: Translate({ messageKey: 'DTR_initials' }),
      value: (
        <FormatCell
          type="dtr"
          alignItems="center"
          {...dtr}
          flexDirection="column"
        />
      ),
    },
    {
      text: Translate({ messageKey: 'ppi' }),
      value: (
        <FormatCell type="ppi-status" alignItems="center" {...ppi_status} />
      ),
    },
  ]

  const ServicesBlocks = [
    {
      text: Translate({ messageKey: 'DEL' }),
      label: Translate({ messageKey: 'DEL_long_text' }),
      value: del,
    },
    {
      text: Translate({ messageKey: 'DUI' }),
      label: Translate({ messageKey: 'DUI_long_text' }),
      value: dui,
    },
    {
      text: Translate({ messageKey: 'lactation_plural' }),
      label: Translate({ messageKey: 'lactation_plural' }),
      value: lactations,
    },
    {
      text: Translate({ messageKey: 'service_plural' }),
      label: Translate({ messageKey: 'service_plural' }),
      value: services,
    },
  ]

  const DrawerHeader = props => {
    const { name, earring, date, reproduction_status, inapt, loading, batch } =
      props
    const Skeleton = value =>
      loading ? (
        <MuiSkeleton width={`${parseInt(50 + Math.random() * 50, 10)}%`} />
      ) : (
        value
      )

    return (
      <Flex>
        <Avatar
          style={styles.avatar}
          sx={{ display: { xs: 'none', sm: 'flex' } }}
        >
          <ReproductionIcon style={styles.icon} />
        </Avatar>
        <Box display="flex" flexDirection="column" gap="4px">
          {Skeleton(
            <div style={styles.header_status}>
              <h3 className="m0">
                {stringLimit(`${earring} - ${batch?.name}`, 20)}
              </h3>
              <FindMyCowButton
                onClick={() => handleConfirm({ type: 'find-my-cow' })}
              />
            </div>,
          )}
          <div>
            <p className="m0">{Skeleton(stringLimit(name, 37))}</p>
            <small style={styles.detected}>
              {Skeleton(
                Translate({
                  messageKey: 'detected',
                  params: { item: Moment(date).fromNow() },
                }),
              )}
            </small>
          </div>
          {Skeleton(
            <Box display="inline-flex" gap={1} alignItems="center">
              <FormatCell
                type="reproduction_status"
                value={reproduction_status}
              />
              <FormatCell type="inapt" flag={inapt} />
            </Box>,
          )}
        </Box>
      </Flex>
    )
  }

  const handleConfirm = ({ type, slug }) => {
    const dialogSubTitle =
      event['subject.label'] ??
      `${event['animal.earring']} - ${event['animal.name']}`

    switch (type) {
      case 'confirm':
        setDialogActions({
          type: HEAT_CONFIRM,
          dialogOpened: true,
          dialogWidth: 'xs',
          dialogTitle: Translate({
            messageKey: 'confirmation_of_item',
            params: {
              item: Translate({ messageKey: 'heat_alert', lower: true }),
            },
          }),
          dialogSubTitle,
          dialogContent: (
            <ReproductionEventsForm
              eventSlug={slug}
              eventType={eventCode.reproduction.heat}
              saveEventName={HEAT_CONFIRM}
            />
          ),
          dialogOnClose: () => {
            setDialogActions(dialogInitialState)
          },
        })
        break

      case 'find-my-cow':
        setDialogActions({
          dialogOpened: true,
          dialogWidth: 'md',
          dialogContent: (
            <FindMyCow farm={farm} animal={animalSlug} type="animal" redirect />
          ),
          dialogOnClose: () => setDialogActions(dialogInitialState),
        })
        break
      default:
        break
    }
  }

  const IA = () => {
    return (
      <CowmedGrid size={12}>
        <SessionTitle>
          {Translate({ messageKey: 'best_time_for_ai' })}
        </SessionTitle>
        <FormatCell type="ai-timeline" size="large" value={data?.ai} />
      </CowmedGrid>
    )
  }

  const Intel = () => {
    return (
      <CowmedGrid size={12}>
        <SessionTitle>
          {Translate({ messageKey: 'intelligence_parameters' })}
        </SessionTitle>
        <CowmedGrid container>
          {StatusBlocks.map(({ value, text }, index) => {
            return (
              <React.Fragment key={text}>
                <CowmedGrid
                  size={{ xs: 6, sm: 4 }}
                  divider={index < StatusBlocks.length - 1 ? 'right' : null}
                >
                  <StyledPaper padding={0}>
                    <IntelLabel>{text}</IntelLabel>
                    {value}
                  </StyledPaper>
                </CowmedGrid>
              </React.Fragment>
            )
          })}
        </CowmedGrid>
      </CowmedGrid>
    )
  }

  const AdditionalInfos = () => {
    return (
      <CowmedGrid size={12}>
        <SessionTitle>
          {Translate({ messageKey: 'additional_info' })}
        </SessionTitle>
        <CowmedGrid container>
          {ServicesBlocks.map(({ value, text, label }) => {
            return (
              <CowmedGrid size={{ xs: 6, sm: 4 }} key={text}>
                <Tooltip title={label}>
                  <StyledPaper type="info">
                    <InfosValue>{value ?? '-'}</InfosValue>
                    <InfosText>{text}</InfosText>
                  </StyledPaper>
                </Tooltip>
              </CowmedGrid>
            )
          })}
        </CowmedGrid>
      </CowmedGrid>
    )
  }

  const Health = () => {
    return (
      <CowmedGrid size={12}>
        <HealthWrapper>
          <SessionTitle>{Translate({ messageKey: 'health' })}</SessionTitle>
          <FormatCell type="health_status" value={health_status} />
        </HealthWrapper>
        {diagnostics?.length ? (
          <StyledList>
            {diagnostics?.map((diagnostic, index) => (
              <React.Fragment key={`health-list-${index}`}>
                <StyledListItem>
                  <FormatCell type="date" value={diagnostic.date} />
                  <span>{diagnostic.text}</span>
                </StyledListItem>
                <Divider />
              </React.Fragment>
            ))}
          </StyledList>
        ) : (
          <EmptyList>
            {Translate({
              messageKey: 'without_diagnostic_text',
              params: { item: '30' },
            })}
          </EmptyList>
        )}
      </CowmedGrid>
    )
  }

  const Mating = () => {
    return (
      <CowmedGrid size={12}>
        <SessionTitle>
          {Translate({ messageKey: 'best_bull_mating' })}
        </SessionTitle>
        <List disablePadding>
          {isEmpty(mating) ? (
            <MatingStyle>
              <ListItemText
                primary={Translate({ messageKey: 'without_information' })}
              />
            </MatingStyle>
          ) : (
            mating?.map((item, index) => {
              return (
                <MatingStyle key={item}>
                  <ListItemText primary={`${index + 1} - ${item}`} />
                </MatingStyle>
              )
            })
          )}
        </List>
      </CowmedGrid>
    )
  }

  const Footer = () => {
    return (
      <FooterWrapper>
        {animalSlug ? (
          <Button
            variant="outlined"
            onClick={() => {
              if (afterSuccess) {
                afterSuccess()
              }
              history.push(`/farm/${farm}/modules/herd/animals/${animalSlug}`)
            }}
          >
            {Translate({
              messageKey: ['access_item', 'animal'],
              lower: [false, true],
            })}
          </Button>
        ) : null}
        {Moment.diff('now', date, 'h') < 72 && confirmed_as === null ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              handleConfirm({ type: 'confirm', slug: event?.slug })
            }
          >
            {Translate({ messageKey: 'confirm_item' })}
          </Button>
        ) : null}
      </FooterWrapper>
    )
  }

  return (
    <DrawerBox
      header={<DrawerHeader {...data} loading={isLoading} />}
      isOpen={open}
      onClose={onClose}
      contentStyle={styles.drawer}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <CowmedGrid container spacing={1} rowSpacing={3}>
            <IA />
            <Mating />
            <Intel />
            <AdditionalInfos />
            <Health />
          </CowmedGrid>
          <Footer />
          {dialogActions && <ActionsDialog dialogActions={dialogActions} />}
          {responseDialog[dialogActions.type] && (
            <ResponseDialog
              dialogResponse={responseDialog[dialogActions.type]}
            />
          )}
        </>
      )}
    </DrawerBox>
  )
}
