import React from 'react'
import CowmedChip from '#/components/Chip'
import Colors from '#/styles/Colors'

const TypeNPSCampaignAnswerGroup = ({ value }) => {
  if (!value) return '-'

  const { status, text } = value

  const color = {
    detractor: Colors.nps.detractor,
    neutral: Colors.nps.neutral,
    promotter: Colors.nps.promotter,
  }

  return <CowmedChip value={text} width={130} customStyle={color[status]} />
}

export default TypeNPSCampaignAnswerGroup
