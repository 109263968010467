import React from 'react'
import { Box, CssBaseline, Divider, Drawer } from '@mui/material'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isEqual } from 'lodash'
import { styled } from '@mui/material/styles'
import { getCurrentFarmSlug } from '#/store/ducks/farm'
import Colors from '#/styles/Old/Colors'
import LogoB2b from '#/assets/logo-drawer/logo_cowmed_b2b.png'
import LogoB2c from '#/assets/logo-drawer/logo_cowmed_b2c.png'
import Header from '#/components/Layout/Header'
import useWidth from '#/store/hooks/useWidth'
import Palette from '#/styles/Old/Palette'
import Menu from '#/components/Layout/Menu'
import AppDownloadBanner from '#/components/Layout/AppDownloadBanner'

const HeaderLogoWrapper = styled(Box)({
  'backgroundColor': Colors.darkSecondary,
  'display': 'flex',
  '& :hover': {
    cursor: 'pointer',
  },
})

const HeaderLogo = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: 16,
  height: '64px',
  [theme.breakpoints.down('sm')]: {
    height: '48px',
  },
}))

const StyledDrawer = styled(Drawer)({
  '& >.MuiPaper-root': {
    width: 240,
    background: Palette.grey.darken[3],
    color: 'white',
    zIndex: 1,
  },
})

function Layout({ container }) {
  const width = useWidth()

  const currentFarmSlug = useSelector(getCurrentFarmSlug, isEqual)
  const history = useHistory()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [desktopOpen, setDesktopOpen] = React.useState(
    ['lg', 'xl'].includes(width),
  )
  const handleDrawerToggle = () => {
    if (['md', 'sm', 'xs'].includes(width)) {
      setMobileOpen(!mobileOpen)
    } else if (['lg', 'xl'].includes(width)) {
      setDesktopOpen(!desktopOpen)
    }
  }

  const goHome = () => {
    history.push(currentFarmSlug ? `/farm/${currentFarmSlug}` : '/')
  }

  const drawer = (
    <Box>
      <HeaderLogoWrapper onClick={goHome}>
        <HeaderLogo>
          <img alt="COWMED" src={currentFarmSlug ? LogoB2c : LogoB2b} />
        </HeaderLogo>
      </HeaderLogoWrapper>
      <Divider />
      <Menu />
    </Box>
  )

  return (
    <Box>
      <CssBaseline />
      <AppDownloadBanner />
      <Header
        width={width}
        setMobileOpen={setMobileOpen}
        setDesktopOpen={setDesktopOpen}
      />
      <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
        <StyledDrawer
          variant="temporary"
          anchor="left"
          container={container}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
        >
          {drawer}
        </StyledDrawer>
      </Box>
      <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
        <StyledDrawer
          variant="permanent"
          anchor="left"
          container={container}
          open
        >
          {drawer}
        </StyledDrawer>
      </Box>
    </Box>
  )
}

export default Layout
