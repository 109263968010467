import React from 'react'
import { uniqueId } from 'lodash'
import { Stack } from '@mui/material'
import TypePPI from '#/components/FormatCell/Types/ppi'
import TypeDTR from '#/components/FormatCell/Types/dtr'
import TypeHeatStrength from '#/components/FormatCell/Types/heatStrength'
import TypeString from '#/components/FormatCell/Types/string'
import TypeProduction from '#/components/FormatCell/Types/production'
import TypeHealth from '#/components/FormatCell/Types/healthStatus'
import TypeDiagnosisPregnancy from '#/components/FormatCell/Types/pregnancyDiagnostics'
import TypeBreathingIndicator from '#/components/FormatCell/Types/breathingIndicator'
import TypeChip from '#/components/FormatCell/Types/chip'

// Definindo um objeto que mapeia os tipos de dados
const ComponentsList = {
  'health_status': TypeHealth,
  'dtr': TypeDTR,
  'ppi-status': TypePPI,
  'heat-strength': TypeHeatStrength,
  'production_status': TypeProduction,
  'pregnancy_diagnostics': TypeDiagnosisPregnancy,
  'breathing_indicator': TypeBreathingIndicator,
  'chip': TypeChip,

  'default': TypeString,
}

const TypeMultiData = ({ value }) => {
  // Definindo e renderiza o componente com as propriedades
  const ComponentsSwitch = (type, props) => {
    const Component = ComponentsList[type] || ComponentsList.default
    return <Component {...props} key={uniqueId(type)} />
  }
  // Chamando a função ComponentsSwitch para cada valor no array "value"
  const list = value.map(({ type, ...rest }) => ComponentsSwitch(type, rest))
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      {list}
    </Stack>
  )
}
export default TypeMultiData
