import React from 'react'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker'
import TextField from '@mui/material/TextField'
import Translate from '#/components/Translate'
import Moment, { fmt } from '#/components/Moment'

const TimePickerInput = props => {
  const { value, defaultValue, onChange, inputVariant, ...rest } = props

  const date = value || defaultValue
  const initialDate = Moment.isValid(date, fmt.hour)
    ? Moment.pure(date, fmt.hour)
    : null
  const [selectedDate, setSelectedDate] = React.useState(initialDate)

  const handleDateChange = value => {
    if (!value) {
      setSelectedDate(null)
    } else {
      setSelectedDate(value)
      if (onChange) {
        const formattedDate = value.format(fmt.hour)
        onChange(formattedDate)
      }
    }
  }

  return (
    <MobileTimePicker
      {...rest}
      value={selectedDate}
      defaultValue={selectedDate}
      onChange={handleDateChange}
      ampm={false}
      invalidDateMessage={Translate({ messageKey: 'invalid_date_format' })}
      minDateMessage={Translate({ messageKey: 'date_before_minimal' })}
      maxDateMessage={Translate({ messageKey: 'date_after_maximal' })}
      cancelLabel={Translate({ messageKey: 'cancel' })}
      clearLabel={Translate({ messageKey: 'clear' })}
      clearable
      inputVariant={inputVariant}
      InputLabelProps={{ shrink: true }}
      renderInput={params => (
        <TextField {...params} variant="standard" fullWidth />
      )}
    />
  )
}

export default TimePickerInput
