import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export default function FarmDatabaseIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 0 24 24"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M19.41,6a5.79,5.79,0,0,0-1.84-1A15.85,15.85,0,0,0,12,4.06a15.85,15.85,0,0,0-5.57.87A5.76,5.76,0,0,0,4.59,6a2,2,0,0,0-.72,1.48v9.34c0,1.12.83,2,2.46,2.66a15.54,15.54,0,0,0,5.67.89,15.54,15.54,0,0,0,5.67-.89c1.63-.64,2.46-1.54,2.46-2.66V7.43A2,2,0,0,0,19.41,6ZM14.27,18.22a19.15,19.15,0,0,1-4.54,0,13,13,0,0,1-1.91-.37,7,7,0,0,1-1.39-.56,1.91,1.91,0,0,1-.6-.48V14.34a6.5,6.5,0,0,0,.61.25,10.23,10.23,0,0,0,1.65.48,15.86,15.86,0,0,0,1.87.3,19.29,19.29,0,0,0,4,0,15.24,15.24,0,0,0,1.87-.29,11.77,11.77,0,0,0,1.68-.49,6.14,6.14,0,0,0,.64-.27v2.46a2.13,2.13,0,0,1-.61.49,6.26,6.26,0,0,1-1.39.57A11.86,11.86,0,0,1,14.27,18.22Zm3.3-10.61A9.48,9.48,0,0,1,16,8.17a14.81,14.81,0,0,1-8.06,0,8.69,8.69,0,0,1-1.55-.58A2.82,2.82,0,0,1,6,7.35l.34-.2h0A8.85,8.85,0,0,1,8,6.58,14.62,14.62,0,0,1,16,6.6a7.61,7.61,0,0,1,1.54.58,2.84,2.84,0,0,1,.36.22A2.87,2.87,0,0,1,17.57,7.61ZM10,10.52a20.76,20.76,0,0,0,4,0,17.4,17.4,0,0,0,1.87-.3,11.77,11.77,0,0,0,1.68-.48l.64-.27v2.77a7.72,7.72,0,0,1-1,.46,11.56,11.56,0,0,1-1.5.44H15.6a14.74,14.74,0,0,1-1.72.27,19.4,19.4,0,0,1-3.81,0,17.09,17.09,0,0,1-1.76-.28,13.15,13.15,0,0,1-1.52-.44,8.61,8.61,0,0,1-1-.44V9.48l.61.26a13.24,13.24,0,0,0,1.65.48A18.24,18.24,0,0,0,10,10.52Z" />
    </SvgIcon>
  )
}
