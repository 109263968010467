import Colors from '#/styles/Old/Colors'

export const getSizedStyles = size => {
  let customSize = {}

  // Set custom styling for each timeline size
  if (size === 'small') {
    customSize = {
      squareWidth: 8,
      squareHeight: 15,
      squareBorderRadius: 2,
      squareMargin: '1px 1px 3px 1px',
      hoursFontSize: 9,
      currentHourWidth: 2,
      currentHourHeight: 60,
      currentHourBottom: 1,
      currentHourPadding: '1px 2px',
      expiredIconSize: 40,
      containerMarginTop: 10,
    }
  }
  if (size === 'large') {
    customSize = {
      squareWidth: 13.5,
      squareHeight: 25,
      squareBorderRadius: 4,
      squareMargin: '0px 4px 7px 0px',
      hoursFontSize: 11,
      currentHourWidth: 4,
      currentHourHeight: 90,
      currentHourBottom: -1,
      currentHourPadding: '1px 3px',
      expiredIconSize: 70,
      containerMarginTop: 20,
    }
  }

  return {
    square: {
      width: customSize.squareWidth,
      height: customSize.squareHeight,
      margin: customSize.squareMargin,
      borderRadius: customSize.squareBorderRadius,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    },
    hours: {
      width: customSize.squareWidth,
      height: 18,
      margin: customSize.squareMargin,
      fontSize: customSize.hoursFontSize,
      borderRadius: customSize.squareBorderRadius,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    },
    legend_row: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: 3,
    },
    legend: {
      width: customSize.squareWidth,
      height: customSize.squareHeight,
      margin: customSize.squareMargin,
      fontSize: customSize.hoursFontSize + 3,
      borderRadius: customSize.squareBorderRadius,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 600,
    },
    timeline_container: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: customSize.containerMarginTop,
    },
    line_container: {
      display: 'flex',
    },
    recomended: {
      backgroundColor: Colors.primary,
    },
    not_recomended: {
      backgroundColor: Colors.warning,
    },
    currentHour_line: {
      height: customSize.currentHourHeight,
      bottom: customSize.currentHourBottom,
      width: customSize.currentHourWidth,
      backgroundColor: Colors.ai_recommended,
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      borderRadius: 10,
    },
    currentHour_base: {
      fontSize: customSize.hoursFontSize,
      backgroundColor: Colors.ai_recommended,
      color: 'white',
      padding: customSize.currentHourPadding,
      borderRadius: 5,
    },
    expired_container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 80,
      marginLeft: 10,
    },
    expired_text: {
      display: 'block',
      maxWidth: 140,
      marginLeft: 10,
      fontSize: 11,
    },
    expired_icon: customSize.expiredIconSize,
  }
}
