import React from 'react'
import FormatCell from '#/components/FormatCell'
import Moment from '#/components/Moment'

export default function TypesDatasheet({ id, value }) {
  if (!value) return <>-</>

  const datetime = value => value && Moment.view(value)

  const datetime_diffInDays = value =>
    value && `${Moment.view(value)} (${Moment.diffInDays(value)})`

  switch (id) {
    case 'animal_accumulated_last_rumination':
    case 'animal_accumulated_last_leisure':
    case 'animal_accumulated_last_activity':
    case 'animal_accumulated_last_panting':
      return (
        <FormatCell
          type="monitoring"
          value={value.value}
          timestamp={value.timestamp}
          invertColors
        />
      )

    case 'ppi_status':
      return <FormatCell type="ppi-status" {...value} with_label={false} />

    case 'production_status':
      return <FormatCell type="production_status" value={value} />

    case 'calving_prediction':
    case 'last_insemination_date':
    case 'last_delivery_date':
    case 'last_service':
      return datetime_diffInDays(value)

    case 'forecast_return':
    case 'last_heat':
      return datetime(value)

    case 'birth_date':
      return <FormatCell type="date" value={value} />

    case 'reproduction_status':
      return (
        <FormatCell type="reproduction_status" contrastColor value={value} />
      )

    case 'health_status':
      return <FormatCell type="health_status" value={value} />

    case 'breathing':
      return <FormatCell value={value} with_text type="breathing_indicator" />

    case 'collar':
      return (
        <FormatCell type="c-tech" value={value.code} status={value.status} />
      )

    case 'DTR':
      return <FormatCell type="dtr" {...value} with_label={false} />

    case 'diagnostics':
      return value
        .filter(v => v)
        .map(v => v?.name_text)
        .join(', ')

    default: {
      return typeof value === 'string' || typeof value === 'number'
        ? value
        : null
    }
  }
}
