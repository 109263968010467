import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { isEqual } from 'lodash'
import { useSelector } from 'react-redux'
import { TextField } from '@mui/material'
import { getCurrentFarm } from '#/store/ducks/farm'
import PinMap from '#/components/FormControl/PinMap/map'
import { isValidLatitude, isValidLongitude } from '#/utils/validCoordinates'
import Translate from '#/components/Translate'
import Fonts from '#/styles/Fonts'

const Wrapper = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
})

const MapWrapper = styled('div')({
  minHeight: 300,
  display: 'flex',
  marginTop: 7,
  borderRadius: 10,
  overflow: 'hidden',
})

const StyledDescription = styled('span')({
  marginTop: 15,
  fontSize: Fonts.fontSize.S,
  fontWeight: Fonts.fontWeight.medium,
})

const Inputs = styled('div')({
  'display': 'flex',
  'gap': 10,
  '& > div': {
    flex: 1,
  },

  /* Start hide arrows from input number */
  '& input::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: '0',
  },
  '& input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: '0',
  },
  '& input[type="number"]': {
    MozAppearance: 'textfield',
  },
  /* End hide arrows from input number */
})

export default function PinMapInput({
  value,
  disabled,
  required,
  description,
  onChange,
  error,
}) {
  // Redux selector to get current farm's latitude and longitude
  const { latitude, longitude } = useSelector(getCurrentFarm, isEqual)

  const [center, setCenter] = useState() // Center coordinates for the map
  const [lat, setLat] = useState() // Latitude
  const [lng, setLng] = useState() // Longitude
  const [hasPin, setHasPin] = useState(false) // Flag indicating whether a pin is set on the map
  const [helperText, setHelperText] = useState({
    lat: null, // Helper text for latitude input
    lng: null, // Helper text for longitude input
  })

  useEffect(() => {
    if (value) {
      setCenter(value)
      setLat(value?.lat)
      setLng(value?.lng)
      setHasPin(true)
    } else if (latitude && longitude) {
      // Set the center to the farm's location
      setCenter({ lat: latitude, lng: longitude })
    }
  }, [])

  // Function to handle changes in latitude or longitude
  const handleChange = (value, name) => {
    const handleFunction = name === 'lat' ? setLat : setLng
    handleFunction(value)
  }

  // Function to handle input changes in latitude or longitude text fields
  const handleChangeInput = (value, name) => {
    handleChange(value, name)

    setCenter(prevState => ({
      ...prevState,
      [name]: value,
    }))

    setHelperText(prevState => ({
      ...prevState,
      [name]: name === 'lat' ? isValidLatitude(value) : isValidLongitude(value),
    }))
  }

  // Effect to notify the parent component of changes in latitude or longitude
  useEffect(() => {
    onChange({
      lat: lat ? Number(lat) : null,
      lng: lng ? Number(lng) : null,
    })
  }, [lat, lng])

  return (
    <Wrapper>
      {/* Input fields for latitude and longitude */}
      <Inputs>
        <TextField
          name="lat"
          type="number"
          label={Translate({ messageKey: 'latitude' })}
          onChange={e => handleChangeInput(e.target.value, 'lat')}
          value={String(lat)}
          required={required}
          disabled={disabled}
          error={error && !!helperText.lat}
          helperText={helperText.lat}
        />
        <TextField
          name="lng"
          label={Translate({ messageKey: 'longitude' })}
          type="number"
          onChange={e => handleChangeInput(e.target.value, 'lng')}
          value={String(lng)}
          required={required}
          disabled={disabled}
          error={error && !!helperText.lng}
          helperText={helperText.lng}
        />
      </Inputs>
      {/* Description of the input */}
      <StyledDescription>{description}</StyledDescription>
      {/* Map wrapper containing PinMap component */}
      <MapWrapper>
        <PinMap
          {...center}
          setLat={e => handleChange(e, 'lat')}
          setLng={e => handleChange(e, 'lng')}
          hasPin={hasPin}
          zoom={17}
        />
      </MapWrapper>
    </Wrapper>
  )
}
