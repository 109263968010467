import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export default function AsteriskIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props
  return (
    <SvgIcon
      viewBox="0 -960 960 960"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M410-120v-238L204-239l-70-121 206-120-206-119 70-121 206 119v-239h140v239l206-119 70 121-206 119 206 120-70 121-206-119v238H410Z" />
    </SvgIcon>
  )
}
