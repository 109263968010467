import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

function ReproductionIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 0 24 24"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M21.27,5.87l-.07-.08,0,0A3.06,3.06,0,0,0,18.39,5,6.68,6.68,0,0,0,15,6.62c-1.9,1.66-2.74,3.9-2.06,5.48a3.14,3.14,0,0,1-.86.33,2.72,2.72,0,0,0-1.87,1.21A6,6,0,0,0,9.56,17a4.33,4.33,0,0,1-.08,1.12c-.29.67-.52.71-1.22.82h0a3.49,3.49,0,0,0-2.4,1.22A1,1,0,0,0,6,21.59a.94.94,0,0,0,.62.23,1,1,0,0,0,.76-.36,1.59,1.59,0,0,1,1.19-.53h0a3.06,3.06,0,0,0,2.73-2A5,5,0,0,0,11.53,17a4.51,4.51,0,0,1,.41-2.34c.08-.14.21-.2.73-.34a4.27,4.27,0,0,0,1.76-.86,3.57,3.57,0,0,0,1.95.14A6.85,6.85,0,0,0,19.59,12a6.94,6.94,0,0,0,2.07-3A3.2,3.2,0,0,0,21.27,5.87ZM19.94,8.48a5.2,5.2,0,0,1-1.5,2,5,5,0,0,1-2.33,1.21c-.34,0-.94.1-1.22-.22-.47-.56.09-2.18,1.51-3.42a4.66,4.66,0,0,1,2.77-1.25,1.1,1.1,0,0,1,.78.27c.31.38.07,1.11,0,1.25l0,.14ZM8.55,20.83Z" />
      <path d="M7.5,3.37a3.9,3.9,0,0,0,0,7.8,3.9,3.9,0,0,0,0-7.8Zm0,5.85a2,2,0,1,1,1.92-2A1.93,1.93,0,0,1,7.5,9.22Z" />
    </SvgIcon>
  )
}

export default ReproductionIcon
