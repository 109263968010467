import React from 'react'
import { capitalize, isNil } from 'lodash'
import CowmedChip from '#/components/Chip'
import Colors from '#/styles/Colors'

const TypeCComStatus = ({ value }) => {
  if (isNil(value)) return '-'

  let formattedValue
  let style

  // Tipos esperados: true(online) e false(offline)
  if (typeof value === 'boolean') {
    if (value) {
      formattedValue = 'Online'
      style = Colors.feedback.success
    } else {
      formattedValue = 'Offline'
      style = Colors.feedback.error
    }
  }

  // Tipos esperados: operational, offline e inactive
  if (typeof value === 'object') {
    formattedValue = value.text

    switch (value.status) {
      case 'offline':
        style = Colors.feedback.error
        break

      case 'operational':
        style = Colors.feedback.success
        break

      default: // nothing to moo
    }
  }

  return <CowmedChip value={capitalize(formattedValue)} customStyle={style} />
}

export default TypeCComStatus
