import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles(({ breakpoints }) => ({
  list: {
    marginBottom: 30,
  },
  footer: {
    bottom: '0',
    backgroundColor: 'white',
    left: '32px',
    right: '32px',
    position: 'absolute',
  },
  footer_grid: {
    marginTop: 10,
    marginBottom: 10,
    float: 'right',
  },
  download: {
    marginLeft: 5,
  },
  download_label: {
    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  error: {
    textAlign: 'center',
    marginBottom: 20,
    marginTop: 20,
  },
}))
