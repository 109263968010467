import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import HelpIcon from '@mui/icons-material/Help'
import Tooltip from '@mui/material/Tooltip'
import Fonts from '#/styles/Fonts'
import Colors from '#/styles/Old/Colors'

const useStyles = makeStyles(theme => ({
  helpTitle: {
    color: Colors.secondaryLight,
    marginBottom: theme.spacing(1),
    fontWeight: Fonts.fontWeight.bold,
  },
  helpBody: {
    fontWeight: Fonts.fontWeight.normal,
  },
  labelHelp: {
    display: 'inline-flex',
    position: 'relative',
    alignItems: 'baseline',
    cursor: 'help',
  },
  labelHelpIcon: {
    'marginLeft': 5,
    'fontSize': Fonts.fontSize.M,
    'color': Colors.lightgrey,
    '.MuiInputLabel-shrink &': {
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      transform: 'translate(0, 1.5px) scale(calc(1 + 1 / 3))',
    },
    '.MuiFormLabel-root:hover &': {
      transition: 'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      color: Colors.help,
    },
  },
  tooltip: {
    padding: `${theme.spacing(2)} !important`,
    color: '#fff',
  },
}))

const LabelWithHelp = ({ fieldLabel, helpTitle, helpBody }) => {
  const classes = useStyles()
  return (
    <Tooltip
      title={
        <>
          <div className={classes.helpTitle}>{helpTitle}</div>
          <div className={classes.helpBody}>{helpBody}</div>
        </>
      }
      classes={{ tooltip: classes.tooltip }}
      aria-label={`${helpTitle}: ${helpBody}`}
      arrow
    >
      <div className={classes.labelHelp}>
        <div>{fieldLabel}</div>
        <div>
          <HelpIcon className={classes.labelHelpIcon} />
        </div>
      </div>
    </Tooltip>
  )
}

export default LabelWithHelp
