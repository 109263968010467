import React, { useCallback, useEffect, useState } from 'react'
import { isEmpty, isEqual, uniqueId } from 'lodash'
import { useSelector } from 'react-redux'
import Snackbar from '@mui/material/Snackbar'
import { Badge, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import DrawerBox from '#/components/DrawerBox'
import Translate from '#/components/Translate'
import TabPanel from './Manipulate/TabPanel'
import { endpoint } from '#/store/services/endpoints'
import { getCurrentFarmSlug } from '#/store/ducks/farm'
import tryCache from '#/utils/tryCache'
import { TabsButtons } from './Manipulate/TabsButtons'
import Colors from '#/styles/Old/Colors'
import ReleaseTab from './Tabs/Release'
import JobsTab from './Tabs/Jobs'
import AnimalSystemTab from './Tabs/AnimalSystem'
import ButtonIconLoader from '#/components/Loader/ButtonLoader'
import NotificationIcon from '#/icons/Notification'
import { fetcher } from '#/store/hooks/request'
import ViewAll from './Manipulate/ViewAll'

const StyledBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    color: '#333',
    backgroundColor: Colors.secondaryLight,
    fontSize: 12,
  },
})

const title = (
  <h2 style={{ margin: 0 }}>
    {Translate({ messageKey: 'notification_plural' })}
  </h2>
)

const cacheName = 'notification-list'
const cacheTime = 5

export default function Notifications({ context }) {
  const farm = useSelector(getCurrentFarmSlug, isEqual)
  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState(0)
  const [hasNewRelease, setHasNewRelease] = useState(false)
  const [forceRefresh, setForceRefresh] = useState(false)
  const [alerts, setAlerts] = useState(null)
  const [data, setData] = useState(null)
  /** Snackbar Alert */
  const [showSnack, setShowSnack] = useState(false)
  const [newMessage, setNewMessage] = useState(null)

  /**
   * Function to get Notification Data
   */
  function headerData() {
    const controller =
      context === 'farm'
        ? endpoint.notifications.farm({ farm })
        : endpoint.notifications.system()
    const cacheKey = `${cacheName}-${farm ?? context}-header`
    const cachedData = tryCache.get(cacheKey)
    if (!cachedData) {
      setData(null)
      fetcher({ controller })
        .then(res => {
          if (res) {
            setData(res.data)
            tryCache.set(cacheKey, res.data, cacheTime)
          }
        })
        .catch(error => console.error(error))
    } else {
      setData(cachedData)
    }
  }

  /**
   * Set Interval to request Notification Data
   */
  useEffect(() => {
    headerData()
    const interval = setInterval(() => headerData(), 1000 * 60 * 2)
    return () => clearInterval(interval)
  }, [])

  /**
   * Request, Open and cache when click in tab
   */
  const handleTabSwitch = useCallback(
    (_, tabNumber) => {
      /** Set Header if cache clear */
      headerData()
      const { endpoint, key } = Tabs[tabNumber]
      const cacheKey = `${cacheName}-${
        key !== 'release' ? `${farm ?? context}-${key}` : key
      }`
      setIsOpen(true)
      setValue(tabNumber)
      // looks for cached data
      const cachedData = tryCache.get(cacheKey)
      if (!cachedData) {
        fetcher({ controller: endpoint })
          .then(({ data }) => {
            const timestamps = data.data.map(
              ({ timestamp }) => new Date(timestamp),
            )
            const timestamp = !isEmpty(timestamps)
              ? Math.max(...timestamps)
              : undefined

            let alerts = {
              [key]: { data, status: 'done', timestamp },
            }
            if (key !== 'release') {
              alerts[key].data = data.data
            }

            setAlerts(prevState =>
              prevState ? { ...prevState, ...alerts } : alerts,
            )
            tryCache.set(cacheKey, alerts, cacheTime)
          })
          .catch(error => console.error(error))
      } else {
        setAlerts(prevState =>
          prevState ? { ...prevState, ...cachedData } : cachedData,
        )
      }
    },

    [],
  )

  /**
   * Refresh all alerts
   */
  useEffect(() => {
    if (forceRefresh) {
      Tabs.map(({ key }) =>
        tryCache.clear(`${cacheName}-${farm ?? context}-${key}`),
      )
      tryCache.clear(`${cacheName}-release`)
      setAlerts(null)
      setForceRefresh(false)
      handleTabSwitch(null, value)
    }
  }, [forceRefresh])

  /**
   * Tab check, open tab with new/unread alert
   */
  useEffect(() => {
    if (!isOpen) {
      if (data) {
        /**TAB ANIMALS - INDEX 0 */
        if (data.unread_animal_alerts > 0) {
          setValue(0)
          /**TAB SYSTEM - INDEX 1 */
        } else if (data.unread_system_alerts > 0) {
          setValue(1)
          /**TAB JOBS - INDEX 2 || 0*/
        } else if (data.jobs > 0) {
          setValue(context === 'farm' ? 2 : 0)
        }
        /** DEFAULT */
      } else {
        setValue(0)
      }
    }
  }, [data])

  const ReleaseNote = ReleaseTab({
    ...alerts?.release,
    farm,
    handleTabSwitch,
    setHasNewRelease,
    setIsOpen,
    context,
    viewed_last_release_note: data?.viewed_last_release_note,
  })
  const Jobs = JobsTab({
    ...alerts?.jobs,
    farm,
    handleTabSwitch,
    isOpen,
    setIsOpen,
    setAlerts,
    context,
    headerData,
  })
  const Animal = () =>
    AnimalSystemTab({
      ...alerts?.animal,
      farm,
      headerData,
      setIsOpen,
      type: 'animal',
      setShowSnack,
      setNewMessage,
    })
  const System = () =>
    AnimalSystemTab({
      ...alerts?.system,
      farm,
      headerData,
      setIsOpen,
      type: 'system',
      setShowSnack,
      setNewMessage,
    })

  /**
   * Tab List/Content
   * */
  const Tabs =
    context === 'farm'
      ? [Animal(), System(), Jobs, ReleaseNote]
      : [Jobs, ReleaseNote]
  /*
   * onClose conditional, if is ReleaseNote Tab (value === 3)
   * simulates clicking on "Ver Depois", avoids reopening the tab with each click/window
   */
  return (
    <>
      <ButtonIconLoader
        isLoading={!data}
        onClick={() => handleTabSwitch(null, value)}
        loadingSize={30}
        icon={
          <StyledBadge badgeContent={data?.bell}>
            <NotificationIcon />
          </StyledBadge>
        }
      />
      <DrawerBox
        pageName={title}
        isOpen={isOpen}
        onClose={() =>
          value === 3
            ? ReleaseNote.onClose(alerts?.release?.data?.id)
            : setIsOpen(false)
        }
      >
        <TabsButtons
          data={data}
          value={value}
          handleTabSwitch={handleTabSwitch}
          setForceRefresh={setForceRefresh}
          hasNewRelease={hasNewRelease}
          context={context}
        />
        {Tabs.map((tab, index) => (
          <TabPanel
            {...tab}
            value={value}
            index={index}
            setIsOpen={setIsOpen}
            key={uniqueId(index)}
          >
            {tab.timestamp && (tab.key === 'system' || tab.key === 'animal') ? (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginBottom: 10,
                  marginRight: 10,
                }}
              >
                <ViewAll
                  timestamp={tab.timestamp}
                  filters={[{ name: 'alert_type', value: tab.key }]}
                  enabled={data?.[`unread_${tab.key}_alerts`] > 0}
                  refresh={() => {
                    tryCache.clear(`${cacheName}-${farm ?? context}-header`)
                    headerData()
                  }}
                />
              </Box>
            ) : null}
            {tab.content}
          </TabPanel>
        ))}
      </DrawerBox>
      {/*SNACK BAR ON NEW ALERT*/}
      <Snackbar
        open={showSnack}
        onClose={() => setShowSnack(false)}
        autoHideDuration={5000}
        message={newMessage}
      />
    </>
  )
}
