export default {
  primary: {
    self: '#59b852',
    lighten: {
      5: '#eef6f1',
      4: '#ddeee2',
      3: '#cde5d4',
      2: '#bcdcc5',
      1: '#9acba9',
    },
    darken: {
      1: '#57a86f',
      2: '#458759',
      3: '#346543',
      4: '#23432c',
    },
    accent: {
      1: '#a9c247',
      2: '#b1d136',
      3: '#bfe723',
      4: '#ceff0a',
    },
    muted: {
      1: '#d7dbd6',
      2: '#bdc4bb',
      3: '#a2aca0',
      4: '#889484',
      5: '#7b8877',
      6: '#626d5f',
    },
  },
  red: {
    self: '#f44336',
    lighten: {
      5: '#ffebee',
      4: '#ffcdd2',
      3: '#ef9a9a',
      2: '#e57373',
      1: '#ef5350',
    },
    darken: {
      1: '#e53935',
      2: '#d32f2f',
      3: '#c62828',
      4: '#b71c1c',
    },
    accent: {
      1: '#ffb9b3',
      2: '#ff8a80',
      3: '#ff5252',
      4: '#ff1744',
      5: '#d50000',
      6: '#FF1414',
    },
  },
  pink: {
    self: '#e91e63',
    lighten: {
      5: '#fce4ec',
      4: '#f8bbd0',
      3: '#f48fb1',
      2: '#f06292',
      1: '#ec407a',
    },
    darken: {
      1: '#d81b60',
      2: '#c2185b',
      3: '#ad1457',
      4: '#880e4f',
    },
    accent: {
      1: '#ff80ab',
      2: '#ff4081',
      3: '#f50057',
      4: '#c51162',
      5: '#ba4383',
      6: '#d4479e',
    },
  },
  purple: {
    self: '#9c27b0',
    lighten: {
      5: '#f3e5f5',
      4: '#e1bee7',
      3: '#ce93d8',
      2: '#ba68c8',
      1: '#ab47bc',
    },
    darken: {
      1: '#8e24aa',
      2: '#7b1fa2',
      3: '#6a1b9a',
      4: '#4a148c',
    },
    accent: {
      1: '#ea80fc',
      2: '#e040fb',
      3: '#d500f9',
      4: '#aa00ff',
    },
    muted: {
      1: '#c69ec7',
      2: '#b47db5',
      3: '#a15da2',
      4: '#603861',
    },
  },
  deep_purple: {
    self: '#673ab7 ',
    lighten: {
      5: '#ede7f6',
      4: '#d1c4e9',
      3: '#b39ddb',
      2: '#9575cd',
      1: '#7e57c2',
    },
    darken: {
      1: '#5e35b1',
      2: '#512da8',
      3: '#4527a0',
      4: '#311b92',
    },
    accent: {
      1: '#b388ff',
      2: '#7c4dff',
      3: '#651fff',
      4: '#6200ea',
    },
  },
  indigo: {
    self: '#3f51b5',
    lighten: {
      5: '#e8eaf6',
      4: '#c5cae9',
      3: '#9fa8da',
      2: '#7986cb',
      1: '#5c6bc0',
    },
    darken: {
      1: '#3949ab',
      2: '#303f9f',
      3: '#283593',
      4: '#1a237e',
    },
    accent: {
      1: '#8c9eff',
      2: '#536dfe',
      3: '#3d5afe',
      4: '#304ffe',
    },
  },
  blue: {
    self: '#2196f3',
    lighten: {
      5: '#e3f2fd',
      4: '#bbdefb',
      3: '#90caf9',
      2: '#64b5f6',
      1: '#42a5f5',
    },
    darken: {
      1: '#1e88e5',
      2: '#1976d2',
      3: '#1565c0',
      4: '#0d47a1',
    },
    accent: {
      1: '#82b1ff',
      2: '#448aff',
      3: '#2979ff',
      4: '#2F70E6',
      5: '#2962ff',
    },
  },
  light_blue: {
    self: '#03a9f4',
    lighten: {
      5: '#e1f5fe',
      4: '#b3e5fc',
      3: '#81d4fa',
      2: '#4fc3f7',
      1: '#29b6f6',
    },
    darken: {
      1: '#039be5',
      2: '#0288d1',
      3: '#0277bd',
      4: '#01579b',
    },
    accent: {
      1: '#80d8ff',
      2: '#40c4ff',
      3: '#00b0ff',
      4: '#0091ea',
    },
  },
  deep_blue: {
    //extra-colors
    self: '#5081af',
    lighten: {
      5: '#eef2f7',
      4: '#dce6ef',
      3: '#b9cddf',
      2: '#96b3cf',
      1: '#739abf',
    },
    darken: {
      1: '#40678c',
      2: '#304d69',
      3: '#203446',
      4: '#182734',
    },
  },
  cyan: {
    self: '#00bcd4',
    lighten: {
      5: '#e0f7fa',
      4: '#b2ebf2',
      3: '#80deea',
      2: '#4dd0e1',
      1: '#26c6da',
    },
    darken: {
      1: '#00acc1',
      2: '#0097a7',
      3: '#00838f',
      4: '#006064',
    },
    accent: {
      1: '#84ffff',
      2: '#18ffff',
      3: '#00e5ff',
      4: '#00b8d4',
    },
  },
  teal: {
    self: '#009688',
    lighten: {
      5: '#e0f2f1',
      4: '#b2dfdb',
      3: '#80cbc4',
      2: '#4db6ac',
      1: '#26a69a',
    },
    darken: {
      1: '#00897b',
      2: '#00796b',
      3: '#00695c',
      4: '#004d40',
    },
    accent: {
      1: '#a7ffeb',
      2: '#64ffda',
      3: '#1de9b6',
      4: '#00bfa5',
    },
  },
  green: {
    self: '#4caf50',
    lighten: {
      5: '#e8f5e9',
      4: '#c8e6c9',
      3: '#a5d6a7',
      2: '#81c784',
      1: '#66bb6a',
    },
    darken: {
      1: '#43a047',
      2: '#388e3c',
      3: '#2e7d32',
      4: '#1b5e20',
    },
    accent: {
      1: '#b9f6ca',
      2: '#69f0ae',
      3: '#00e676',
      4: '#00c853',
    },
  },
  light_green: {
    self: '#8bc34a',
    lighten: {
      5: '#f1f8e9',
      4: '#dcedc8',
      3: '#c5e1a5',
      2: '#aed581',
      1: '#9ccc65',
    },
    darken: {
      1: '#7cb342',
      2: '#689f38',
      3: '#558b2f',
      4: '#33691e',
    },
    accent: {
      1: '#ccff90',
      2: '#b2ff59',
      3: '#76ff03',
      4: '#64dd17',
    },
  },
  lime: {
    self: '#cddc39',
    lighten: {
      5: '#f9fbe7',
      4: '#f0f4c3',
      3: '#e6ee9c',
      2: '#dce775',
      1: '#d4e157',
    },
    darken: {
      1: '#c0ca33',
      2: '#afb42b',
      3: '#9e9d24',
      4: '#827717',
    },
    accent: {
      1: '#f4ff81',
      2: '#eeff41',
      3: '#c6ff00',
      4: '#aeea00',
    },
  },
  yellow: {
    self: '#ffeb3b',
    lighten: {
      6: '#fffef0',
      5: '#fffde7',
      4: '#fff9c4',
      3: '#fff59d',
      2: '#fff176',
      1: '#ffee58',
    },
    darken: {
      1: '#fdd835',
      2: '#fbc02d',
      3: '#f9a825',
      4: '#f57f17',
    },
    accent: {
      1: '#ffff8d',
      2: '#ffff00',
      3: '#ffea00',
      4: '#ffd600',
    },
  },
  ambar: {
    self: '#ffc107',
    lighten: {
      5: '#fff8e1',
      4: '#ffecb3',
      3: '#ffe082',
      2: '#ffd54f',
      1: '#E8D53E',
    },
    darken: {
      1: '#ffb300',
      2: '#ffa000',
      3: '#ff8f00',
      4: '#ff6f00',
    },
    accent: {
      1: '#ffe57f',
      2: '#ffd740',
      3: '#ffc400',
      4: '#ffab00',
    },
  },
  orange: {
    self: '#ff9800',
    lighten: {
      5: '#fff3e0',
      4: '#ffe0b2',
      3: '#ffcc80',
      2: '#ffb74d',
      1: '#ffa726',
    },
    darken: {
      1: '#fb8c00',
      2: '#f57c00',
      3: '#ef6c00',
      4: '#e65100',
    },
    accent: {
      1: '#ffd180',
      2: '#ffab40',
      3: '#ff9100',
      4: '#ff6d00',
    },
  },
  deep_orange: {
    self: '#ff5722',
    lighten: {
      5: '#fbe9e7',
      4: '#ffccbc',
      3: '#ffab91',
      2: '#ff8a65',
      1: '#ff7043',
    },
    darken: {
      1: '#f4511e',
      2: '#e64a19',
      3: '#d84315',
      4: '#bf360c',
    },
    accent: {
      1: '#ff9e80',
      2: '#ff6e40',
      3: '#ff3d00',
      4: '#dd2c00',
    },
    muted: {
      1: '#ef6a50',
    },
  },
  brown: {
    self: '#795548',
    lighten: {
      5: '#efebe9',
      4: '#d7ccc8',
      3: '#bcaaa4',
      2: '#a1887f',
      1: '#8d6e63',
    },
    darken: {
      1: '#6d4c41',
      2: '#5d4037',
      3: '#4e342e',
      4: '#3e2723',
    },
    accent: {
      //extra-colors
      1: '#cfb081',
    },
  },
  grey: {
    self: '#9e9e9e',
    lighten: {
      6: '#fbfdff',
      5: '#fafafa',
      4: '#f5f5f5',
      3: '#eeeeee',
      2: '#e0e0e0',
      1: '#bdbdbd',
    },
    darken: {
      1: '#757575',
      2: '#616161',
      3: '#4d4d4d',
      4: '#3b3b3b',
      5: '#212121',
    },
  },
  blue_grey: {
    self: '#607d8b',
    lighten: {
      5: '#eceff1',
      4: '#cfd8dc',
      3: '#b0bec5',
      2: '#90a4ae',
      1: '#78909c',
    },
    darken: {
      1: '#546e7a',
      2: '#455a64',
      3: '#37474f',
      4: '#263238',
    },
  },
  green_grey: {
    //extra-colors
    self: '#608b7d',
    lighten: {
      5: '#ecf1ef',
      4: '#cfdcd8',
      3: '#b0c5be',
      2: '#90aea4',
      1: '#789c90',
    },
    darken: {
      1: '#547a6e',
      2: '#45645a',
      3: '#374f47',
      4: '#263832',
    },
  },
  brown_grey: {
    //extra-colors
    self: '#8e7f71',
    lighten: {
      5: '#e8e5e3',
      4: '#ddd8d5',
      3: '#d2ccc6',
      2: '#bbb2aa',
      1: '#a4988e',
    },
    darken: {
      1: '#554c44',
      2: '#39332d',
      3: '#2a2622',
      4: '#1c1917',
    },
  },
  transparent: {
    //extra-colors
    self: 'transparent',
    1: 'rgba(0,0,0,0.1)',
    2: 'rgba(0,0,0,0.2)',
    3: 'rgba(0,0,0,0.3)',
    4: 'rgba(0,0,0,0.4)',
    5: 'rgba(0,0,0,0.5)',
    6: 'rgba(0,0,0,0.6)',
    7: 'rgba(0,0,0,0.7)',
    8: 'rgba(0,0,0,0.8)',
    9: 'rgba(0,0,0,0.9)',
  },
  white: {
    self: '#ffffff',
  },
  black: {
    self: '#000000',
  },
}
