import React from 'react'
import { styled } from '@mui/material/styles'
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined'
import { Button } from '@mui/material'
import { endpoint } from '#/store/services/endpoints'
import LinearIndeterminate from '../Loader'
import { FindMyCowElements } from './Types/Elements'
import { FindMyCowActions } from '#/components/FindMyCow/Types/Actions'
import Translate from '#/components/Translate'
import { useFetch } from '#/store/hooks/request'

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
})

const FindMyCowButtonStyled = styled(Button)({
  padding: '2px 10px',
  minWidth: 'unset',
  minHeight: '16px',
  lineHeight: '16px',
})

const FindMyCowGroup = props => {
  const { data, type } = props
  return (
    <Wrapper>
      {data?.filter(Boolean)?.map(item => {
        return (
          <div key={item.id} id={item.id}>
            <FindMyCowElements {...item} type={type} />
          </div>
        )
      })}
      <FindMyCowActions id="find-my-cow-actions" {...props} />
    </Wrapper>
  )
}

export default function FindMyCow(props) {
  const { animal, collar, farm, type } = props
  const controller = {
    animal: endpoint.farm.find_my_cow.animal({ animal, farm }),
    collar: endpoint.farm.find_my_cow.collar({ collar, farm }),
  }

  const { data, isLoading } = useFetch({ controller: controller[type] })

  return (
    <>
      <LinearIndeterminate status={isLoading} />
      <FindMyCowGroup data={data} {...props} />
    </>
  )
}

export const FindMyCowButton = ({ onClick, style }) => {
  return (
    <FindMyCowButtonStyled
      size="small"
      startIcon={<RoomOutlinedIcon />}
      variant="outlined"
      onClick={onClick}
      style={style}
    >
      {Translate({ messageKey: 'find_item' })}
    </FindMyCowButtonStyled>
  )
}
