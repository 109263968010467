import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export default function AccountIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 0 24 24"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M1.35,19.27v-2A2.77,2.77,0,0,1,1.62,16,2.85,2.85,0,0,1,2.9,14.66,14.21,14.21,0,0,1,6,13.54a13.2,13.2,0,0,1,6.29,0,14.21,14.21,0,0,1,3,1.12A2.85,2.85,0,0,1,16.57,16a2.77,2.77,0,0,1,.27,1.24v2a.67.67,0,0,1-.67.67H2A.66.66,0,0,1,1.35,19.27Zm18.1.67a.67.67,0,0,1-.67-.67V17a3.91,3.91,0,0,0-.59-2,5.12,5.12,0,0,0-1.69-1.68,12,12,0,0,1,2.33.49,12.38,12.38,0,0,1,2,.86,4.12,4.12,0,0,1,1.33,1.08A2,2,0,0,1,22.65,17v2.24a.66.66,0,0,1-.66.67ZM9.09,12.19a3.75,3.75,0,0,1-2.73-1.13A3.91,3.91,0,0,1,5.22,8.32,3.76,3.76,0,0,1,6.35,5.58,3.93,3.93,0,0,1,9.1,4.44a3.72,3.72,0,0,1,2.73,1.14A3.91,3.91,0,0,1,13,8.32a3.72,3.72,0,0,1-1.14,2.73A3.91,3.91,0,0,1,9.09,12.19Zm9.69-3.88a4,4,0,0,1-1.14,2.75,3.75,3.75,0,0,1-2.73,1.13,4.82,4.82,0,0,1-.68-.06,5.53,5.53,0,0,1-.68-.13,5.62,5.62,0,0,0,1-1.72,5.52,5.52,0,0,0,0-3.92,5.5,5.5,0,0,0-1-1.72,2.8,2.8,0,0,1,.68-.16l.25,0a4.08,4.08,0,0,1,3.16,1.12A3.72,3.72,0,0,1,18.78,8.31ZM3.28,18H14.91v-.77a.93.93,0,0,0-.14-.49,1,1,0,0,0-.35-.34,13,13,0,0,0-2.64-1,11.42,11.42,0,0,0-5.37,0,13,13,0,0,0-2.64,1,1,1,0,0,0-.35.34.93.93,0,0,0-.14.49Zm5.81-7.75A1.93,1.93,0,0,0,11,8.32,1.95,1.95,0,0,0,9.09,6.38,1.95,1.95,0,0,0,7.16,8.32a1.93,1.93,0,0,0,1.93,1.93Z" />
    </SvgIcon>
  )
}
