/* eslint-disable no-unused-vars */
/* prettier-ignore */
export const endpoint = {
  auth: {
    deploy_version:                         (data = {}) => `version`, // GET
    firebase_custom_token:                  (data = {}) => `api/firebase/token`, // GET
    firebase_register_device:               (data = {}) => `api/firebase/register-device`, // POST
    firebase_remove_device:                 (data = {}) => `api/firebase/remove-device`, // POST
    change_password:                        (data = {}) => `change-password`, // POST
  },

  cs:{
    nps:{
      campaigns:{
        datatable:                          (data = {}) => `api/nps/campaigns/datatable`, // GET
        register_form:                      (data = {}) => `api/nps/campaigns/form`, // GET
        register:                           (data = {}) => `api/nps/campaigns`, // POST
        update:                             (data = {}) => `api/nps/campaigns/${data.campaign}`, // PUT
        update_form:                        (data = {}) => `api/nps/campaigns/${data.campaign}/form`, // GET
        delete:                             (data = {}) => `api/nps/campaigns/${data.campaign}`, // DELETE
      }, 
      answer: { 
        datatable:                          (data = {}) => `api/nps/campaigns/${data.campaign}/answers/datatable`, // GET
        register:                           (data = {}) => `api/farm/${data.farm}/nps/campaign/answer`, // POST
        delay:                              (data = {}) => `api/farm/${data.farm}/nps/campaign/answer/delay`, // GET
      },
      unanswered:{
        datatable:                          (data = {}) => `api/nps/campaigns/${data.campaign}/non-responding-farms/datatable`, // GET
      },
    }
  },

  sentry: {
    backend_feedback:                       (data = {}) => `sentry`, // POST
  },

  websocket:{
    authorize:                              (data = {}) => `broadcasting/auth`, // POST
  },

  user: {
    info:                                   (data = {}) => `api/user-info`, // GET
    system_permissions:                     (data = {}) => `api/user/permission`, // GET
    farm_permissions:                       (data = {}) => `api/farm/${data.farm}/user/permission`, // GET
    company_permissions:                    (data = {}) => `api/company/${data.company}/user/permission`, // GET
    me: {
      form:                                 (data = {}) => `api/user/me/form`, // GET
      register:                             (data = {}) => `api/user/me`, // POST
      pwd_change_form:                      (data = {}) => `api/user/me/change-password-form`, // GET
    },
    notification: {
      form:                                 (data = {}) => `api/user/notification/form`, // GET
      register:                             (data = {}) => `api/user/notification/`, // PUT
    }
  },

  notifications:{
    system:                                 (data = {}) => `api/notification/system`, // GET
    farm:                                   (data = {}) => `api/notification/farm/${data.farm}`, // GET
    job:                                    (data = {}) => `api/farm/${data.farm}/job`, // GET
    alerts:                                 (data = {}) => `api/farm/${data.farm}/alert/datatable`, // GET
  },

  job: {
    self:                                   (data = {}) => `api/job/${data.job}`, // GET
    file:                                   (data = {}) => `api/job/${data.job}/file`, // GET
    result:                                 (data = {}) => `api/job/${data.job}/result`, // GET
  },

  event: {
    restore:                                (data = {}) => `api/event/${data.event}/restore`, // POST
  },

  import:{
    files: {
      datagrid:                             (data = {}) => `api/farm/${data.farm}/cowsync/uploaded-files`, // GET
      file:                                 (data = {}) => `api/farm/${data.farm}/cowsync/uploaded-files/${data.file}`, // GET, DELETE
    },
    farm:{
      animal:{
        file:                               (data = {}) => `api/farm/${data.farm}/import-file/import-animals`, // GET
        self:                               (data = {}) => `api/farm/${data.farm}/import/animals`, // POST
      },
      discards:{
        file:                               (data = {}) => `api/farm/${data.farm}/import-file/import-discards`, // GET
        self:                               (data = {}) => `api/farm/${data.farm}/import/discards`, // POST
      },
      health:{
        file:                               (data = {}) => `api/farm/${data.farm}/import-file/import-health`, // GET
        self:                               (data = {}) => `api/farm/${data.farm}/import/diagnostics`, // POST
      },
      dry:{
        file:                               (data = {}) => `api/farm/${data.farm}/import-file/import-dry`, // GET
        self:                               (data = {}) => `api/farm/${data.farm}/import/dry`, // POST
      },
      milk_control:{
        file:                               (data = {}) => `api/farm/${data.farm}/import-file/import-milk-production-individualized`, // GET
        self:                               (data = {}) => `api/farm/${data.farm}/import/milk-production`, // POST
      },
      prepartum:{
        file:                               (data = {}) => `api/farm/${data.farm}/import-file/import-prepartum`, // GET
        self:                               (data = {}) => `api/farm/${data.farm}/import/prepartum`, // POST
      },
      reproduction:{
        file:                               (data = {}) => `api/farm/${data.farm}/import-file/import-reproduction`, // GET
        self:                               (data = {}) => `api/farm/${data.farm}/import/reproductions`, // POST
      },
      calving:{
        file:                               (data = {}) => `api/farm/${data.farm}/import-file/import-calving`, // GET
        self:                               (data = {}) => `api/farm/${data.farm}/import/calving`, // POST
      },
      dairy_plan:{
        reproduction:                       (data = {}) => `api/farm/${data.farm}/import/reproduction/dairy-plan`, // POST
      },
      abs_monitor:{
        reproduction:                       (data = {}) => `api/farm/${data.farm}/import/reproduction/abs-monitor`, // POST
      },
      delpro:{
        reproduction:                       (data = {}) => `api/farm/${data.farm}/import/reproduction/delpro`, // POST
      },
      ideagri:{
        reproduction:                       (data = {}) => `api/farm/${data.farm}/import/reproduction/ideagri`, // POST
        milk_production:                    (data = {}) => `api/farm/${data.farm}/import/milk-production/ideagri`, // POST
      },
      bull_semen:{
        file:                               (data = {}) => `api/farm/${data.farm}/import-file/import-bull-semen`, // GET
        self:                               (data = {}) => `api/farm/${data.farm}/import/bull-semen`, // POST
      },
      weighing:{
        file:                               (data = {}) => `api/farm/${data.farm}/import-file/import-weighing`, // GET
        self:                               (data = {}) => `api/farm/${data.farm}/import/weighing`, // POST
      },
      mating:{
        file:                               (data = {}) => `api/farm/${data.farm}/import-file/import-mating`, // GET
        self:                               (data = {}) => `api/farm/${data.farm}/import/mating`, // POST
      },
    },
    system:{
      ccom:{
        file:                               (data = {}) => `api/import-file/import-ccom`, // GET
      },
      ctech: {
        file:                               (data = {}) => `api/import-file/import-collar`, // GET
        self:                               (data = {}) => `api/collar/import`, // POST
      },
      cenv: {
        file:                               (data = {}) => `api/import-file/import-cenv`, // GET
        self:                               (data = {}) => `api/cenv/import`, // POST
      },
      ctech_review:{
        file:                               (data = {}) => `api/import-file/review-collars`, // GET
        self:                               (data = {}) => `api/collar/review`, // POST
      },
      bull_semen:{
        file:                               (data = {}) => `api/import-file/import-semen`, // GET
        self:                               (data = {}) => `api/semen/import`, // POST
      },
    }
  },

  export:{
    farm:{
      general: {
        form:                               (data = {}) => `api/farm/${data.farm}/export/general-data/form`, // GET
        register:                           (data = {}) => `api/farm/${data.farm}/export/general-data`, // POST
      },
      holstein: {
        form:                               (data = {}) => `api/farm/${data.farm}/export/holstein-data/form`, // GET
        register:                           (data = {}) => `api/farm/${data.farm}/export/holstein-data`, // POST
      }
    }
  },

  farm: {
    self:                                   (data = {}) => `api/farm/${data.farm}`, // GET
    access_datatable:                       (data = {}) => `api/farm/access`, // GET
    global_search:                          (data = {}) => `api/farm/${data.farm}/search`, // GET
    favorite:                               (data = {}) => `api/farm/${data.farm}/favorite`, // DELETE, POST
    status:                                 (data = {}) => `api/farm/${data.farm}/status`, // GET
    register_form:                          (data = {}) => `api/farm/form`, // GET
    register:                               (data = {}) => `api/farm`, // POST
    update_form:                            (data = {}) => `api/farm/${data.farm}/form`, // GET
    update:                                 (data = {}) => `api/farm/${data.farm}`, // PUT
    remove:                                 (data = {}) => `api/farm/${data.farm}`, // DELETE
    cx:                                     (data = {}) => `api/farm/${data.farm}/cx`, // POST
    dashboard:                              (data = {}) => `api/farm/${data.farm}/dashboard/new-dashboard`, // GET

    tokens:{
      datagrid:                             (data = {}) => `api/farm/${data.farm}/tokens/datatable`, // GET
      delete:                               (data = {}) => `oauth/tokens/${data.token}`, // DELETE
    },

    find_my_cow:{
      animal:                               (data = {}) => `api/farm/${data.farm}/find-my/animal/${data.animal}`, // GET
      collar:                               (data = {}) => `api/farm/${data.farm}/find-my/collar/${data.collar}`, // GET
    },


    gynecologic:{
      datatable:                            (data = {}) => `api/farm/${data.farm}/gynecologic-exam/datatable`, // GET
      apt:{
        form:                               (data = {}) => `api/farm/${data.farm}/event/reproduction/gynecologic-examp-apt/form`, // GET
        update_form:                        (data = {}) => `api/farm/${data.farm}/event/reproduction/gynecologic-examp-apt/${data.event}/form`, // PUT
      },
      inapt:{
        form:                               (data = {}) => `api/farm/${data.farm}/event/reproduction/gynecologic-examp-inapt/form`, // GET
        update_form:                        (data = {}) => `api/farm/${data.farm}/event/reproduction/gynecologic-examp-inapt/${data.event}/form`, // PUT
      }
    },

    contract:{
      datatable:                            (data = {}) => `api/farm/${data.farm}/contract-event/datatable`, // GET
      allowed_events:                       (data = {}) => `api/farm/${data.farm}/contract-event/next-events`, // GET
      activation:{  
        form:                               (data = {}) => `api/farm/${data.farm}/contract-event/monitoring-activation/form`, // GET
        register:                           (data = {}) => `api/farm/${data.farm}/contract-event/monitoring-activation`, // POST
        self_form:                          (data = {}) => `api/farm/${data.farm}/contract-event/monitoring-activation/${data.contract}/form`, // GET
        self:                               (data = {}) => `api/farm/${data.farm}/contract-event/monitoring-activation/${data.contract}`, // POST
      },  
      cancellation:{  
        form:                               (data = {}) => `api/farm/${data.farm}/contract-event/cancellation/form`, // GET
        register:                           (data = {}) => `api/farm/${data.farm}/contract-event/cancellation`, // POST
        self_form:                          (data = {}) => `api/farm/${data.farm}/contract-event/cancellation/${data.contract}/form`, // GET
        self:                               (data = {}) => `api/farm/${data.farm}/contract-event/cancellation/${data.contract}`, // POST
      },  
      up_sell:{ 
        form:                               (data = {}) => `api/farm/${data.farm}/contract-event/up-sell/form`, // GET
        register:                           (data = {}) => `api/farm/${data.farm}/contract-event/up-sell`, // POST
        self_form:                          (data = {}) => `api/farm/${data.farm}/contract-event/up-sell/${data.contract}/form`, // GET
        self:                               (data = {}) => `api/farm/${data.farm}/contract-event/up-sell/${data.contract}`, // POST
      },  
      down_sell:{ 
        form:                               (data = {}) => `api/farm/${data.farm}/contract-event/down-sell/form`, // GET
        register:                           (data = {}) => `api/farm/${data.farm}/contract-event/down-sell`, // POST
        self_form:                          (data = {}) => `api/farm/${data.farm}/contract-event/down-sell/${data.contract}/form`, // GET
        self:                               (data = {}) => `api/farm/${data.farm}/contract-event/down-sell/${data.contract}`, // POST
      },  
      monitoring:{  
        form:                               (data = {}) => `api/farm/${data.farm}/contract-event/monitoring-contract/form`, // GET
        register:                           (data = {}) => `api/farm/${data.farm}/contract-event/monitoring-contract`, // POST
        self_form:                          (data = {}) => `api/farm/${data.farm}/contract-event/monitoring-contract/${data.contract}/form`, // GET
        self:                               (data = {}) => `api/farm/${data.farm}/contract-event/monitoring-contract/${data.contract}`, // POST
      },  
      payment_start:{ 
        form:                               (data = {}) => `api/farm/${data.farm}/contract-event/payment-start/form`, // GET
        register:                           (data = {}) => `api/farm/${data.farm}/contract-event/payment-start`, // POST
        self_form:                          (data = {}) => `api/farm/${data.farm}/contract-event/payment-start/${data.contract}/form`, // GET
        self:                               (data = {}) => `api/farm/${data.farm}/contract-event/payment-start/${data.contract}`, // POST
      },  
      reactivation_cowconnect:{ 
        form:                               (data = {}) => `api/farm/${data.farm}/contract-event/reactivation-cowconnect/form`, // GET
        register:                           (data = {}) => `api/farm/${data.farm}/contract-event/reactivation-cowconnect`, // POST
        self_form:                          (data = {}) => `api/farm/${data.farm}/contract-event/reactivation-cowconnect/${data.contract}/form`, // GET
        self:                               (data = {}) => `api/farm/${data.farm}/contract-event/reactivation-cowconnect/${data.contract}`, // POST
      },  
      reactivation_monitoring:{ 
        form:                               (data = {}) => `api/farm/${data.farm}/contract-event/reactivation-monitoring/form`, // GET
        register:                           (data = {}) => `api/farm/${data.farm}/contract-event/reactivation-monitoring`, // POST
        self_form:                          (data = {}) => `api/farm/${data.farm}/contract-event/reactivation-monitoring/${data.contract}/form`, // GET
        self:                               (data = {}) => `api/farm/${data.farm}/contract-event/reactivation-monitoring/${data.contract}`, // POST
      },  
      cross_sell_monitoring:{ 
        form:                               (data = {}) => `api/farm/${data.farm}/contract-event/cross-sell-monitoring/form`, // GET
        register:                           (data = {}) => `api/farm/${data.farm}/contract-event/cross-sell-monitoring`, // POST
        self_form:                          (data = {}) => `api/farm/${data.farm}/contract-event/cross-sell-monitoring/${data.contract}/form`, // GET
        self:                               (data = {}) => `api/farm/${data.farm}/contract-event/cross-sell-monitoring/${data.contract}`, // POST
      },  
      cross_sell_cowconnect:{ 
        form:                               (data = {}) => `api/farm/${data.farm}/contract-event/cross-sell-cowconnect/form`, // GET
        register:                           (data = {}) => `api/farm/${data.farm}/contract-event/cross-sell-cowconnect`, // POST
        self_form:                          (data = {}) => `api/farm/${data.farm}/contract-event/cross-sell-cowconnect/${data.contract}/form`, // GET
        self:                               (data = {}) => `api/farm/${data.farm}/contract-event/cross-sell-cowconnect/${data.contract}`, // POST
      },  
      suspension:{  
        form:                               (data = {}) => `api/farm/${data.farm}/contract-event/temporary-suspension/form`, // GET
        register:                           (data = {}) => `api/farm/${data.farm}/contract-event/temporary-suspension`, // POST
        self_form:                          (data = {}) => `api/farm/${data.farm}/contract-event/temporary-suspension/${data.contract}/form`, // GET
        self:                               (data = {}) => `api/farm/${data.farm}/contract-event/temporary-suspension/${data.contract}`, // POST
      },  
      reinstate:{ 
        form:                               (data = {}) => `api/farm/${data.farm}/contract-event/reinstate-contract/form`, // GET
        register:                           (data = {}) => `api/farm/${data.farm}/contract-event/reinstate-contract`, // POST
        self_form:                          (data = {}) => `api/farm/${data.farm}/contract-event/reinstate-contract/${data.contract}/form`, // GET
        self:                               (data = {}) => `api/farm/${data.farm}/contract-event/reinstate-contract/${data.contract}`, // POST
      }
    },

    cow_analytics:{
      wellness:{
        thermal_comfort_and_leisure:        (data = {}) => `api/farm/${data.farm}/pages/wellness/thermal-comfort-and-leisure`, // GET
        nutrition_and_consumption:          (data = {}) => `api/farm/${data.farm}/pages/wellness/nutrition-and-consumption`, // GET
      },
      health: {
        diagnosed_animals:                  (data = {}) => `api/farm/${data.farm}/datamart/health-new`, // GET
        occurrence_total_graph:             (data = {}) => `api/farm/${data.farm}/diseases-occurrences/graph`, // GET
        occurrence_total_datatable:         (data = {}) => `api/farm/${data.farm}/diseases-occurrences/datatable`, // GET
        history_graph:                      (data = {}) => `api/farm/${data.farm}/diseases-occurrences/history-graph`, // GET
        history_datatable:                  (data = {}) => `api/farm/${data.farm}/diseases-occurrences/history-datatable`, // GET
      },
      conception:{
        del_page:                           (data = {}) => `api/farm/${data.farm}/pages/conception/by-dim`, // GET
        per_service_graph:                  (data = {}) => `api/farm/${data.farm}/conception/by-service/graph`, // GET
        per_service_datatable:              (data = {}) => `api/farm/${data.farm}/conception/by-service/datatable`, // GET
        calving_interval_graph:             (data = {}) => `api/farm/${data.farm}/graph/average-delivery`, // GET
      },
      services:{
        performed:                          (data = {}) => `api/farm/${data.farm}/services/datatable`, // GET
        cycles:                             (data = {}) => `api/farm/${data.farm}/service-rates/datatable`, // GET
        rate_graph:                         (data = {}) => `api/farm/${data.farm}/graph/multi-rate`, // GET
        dim_on_first_service_graph:         (data = {}) => `api/farm/${data.farm}/dim-on-first-service/graph`, // GET
        dim_on_first_service_datatable:     (data = {}) => `api/farm/${data.farm}/dim-on-first-service/datatable`, // GET
      },
      discard:{
        unfit_discard:                      (data = {}) => `api/farm/${data.farm}/animal/datatable/unfit-discard`, // GET
        reason_graph:                       (data = {}) => `api/farm/${data.farm}/graph/discard-reason`, // GET
        reason_datatable:                   (data = {}) => `api/farm/${data.farm}/discard/datatable`, // GET
        rate_graph:                         (data = {}) => `api/farm/${data.farm}/graph/discard-rate`, // GET
        discarded_animal:                   (data = {}) => `api/farm/${data.farm}/animal/discard/datatable`, // GET
        departure_animal:                   (data = {}) => `api/farm/${data.farm}/animal/departure/datatable`, // GET
        total_datatable:                    (data = {}) => `api/farm/${data.farm}/monthly-discard/datatable`, // GET
        total_graph:                        (data = {}) => `api/farm/${data.farm}/graph/monthly-discard`, // GET
        moment:                             (data = {}) => `api/farm/${data.farm}/pages/discards/moment`, // GET
      },
      herd:{
        distribution_graph:                 (data = {}) => `api/farm/${data.farm}/herd/reproductive-distribution/graph`, // GET
        distribution_datatable:             (data = {}) => `api/farm/${data.farm}/herd/reproductive-distribution/datatable`, // GET
        lactating_animals_datatable:        (data = {}) => `api/farm/${data.farm}/lactating-animal/datatable`, // GET
        lactating_animals_graph:            (data = {}) => `api/farm/${data.farm}/lactating-animal/graph`, // GET
        avarege_dim_graph:                  (data = {}) => `api/farm/${data.farm}/pages/herd/average-dim`, // GET
        age_groups_graph:                   (data = {}) => `api/farm/${data.farm}/age-groups/graph`, // GET
        age_groups_datatable:               (data = {}) => `api/farm/${data.farm}/age-groups/datatable`, // GET
        replacement_animals_datatable:      (data = {}) => `api/farm/${data.farm}/replacement/datatable`, // GET
        replacement_animals_graph:          (data = {}) => `api/farm/${data.farm}/replacement/graph`, // GET
      },
      transition:{
        animal:                             (data = {}) => `api/farm/${data.farm}/event/datatable/reproduction/ppi`, // GET
        herd:                               (data = {}) => `api/farm/${data.farm}/pages/transition/average-ppi`, // GET
      },
      gestational:{
        bull_datatable:                     (data = {}) => `api/farm/${data.farm}/gestational-loss/by-semen/datatable`, // GET
        list_datatable:                     (data = {}) => `api/farm/${data.farm}/gestational-loss/list/datatable`, // GET
        monthly_datatable:                  (data = {}) => `api/farm/${data.farm}/gestational-loss/monthly/datatable`, // GET
        monthly_graph:                      (data = {}) => `api/farm/${data.farm}/gestational-loss/monthly/graph`, // GET
        stage_datatable:                    (data = {}) => `api/farm/${data.farm}/gestational-loss/by-stage/datatable`, // GET
        stage_graph:                        (data = {}) => `api/farm/${data.farm}/gestational-loss/by-stage/graph`, // GET
      },
      rearing:{
        weaning:                            (data = {}) => `api/farm/${data.farm}/animal-weight/weaning/graph`, // GET
        birth:                              (data = {}) => `api/farm/${data.farm}/pages/rearing/birth`, // GET
        daily_weight_gain:                  (data = {}) => `api/farm/${data.farm}/animal-weight/daily-weight-gain/graph`, // GET
        mortality:                          (data = {}) => `api/farm/${data.farm}/pages/rearing/mortality`, // GET
      }
    },

    management: {
      datatable:                            (data = {}) => `api/farm/datatable`, // GET
      network: {
        datatable:                          (data = {}) => `api/farm/${data.farm}/networks/datatable`, // GET
        register_form:                      (data = {}) => `api/farm/${data.farm}/networks/form`, // GET
        register:                           (data = {}) => `api/farm/${data.farm}/networks`, // POST
        update_form:                        (data = {}) => `api/farm/${data.farm}/networks/${data.network}/form`, // GET
        self:                               (data = {}) => `api/farm/${data.farm}/networks/${data.network}`, // PUT, DELETE
        set_main:                           (data = {}) => `api/farm/${data.farm}/networks/${data.network}/set-main`, // POST
      },
      extra_modules: {
        datatable:                          (data = {}) => `api/farm/${data.farm}/modules/form`, // GET
        update:                             (data = {}) => `api/farm/${data.farm}/modules/${data.module_slug}`, // PUT
      }
    },

    alert: {
      datatable:                            (data = {}) => `api/farm/${data.farm}/alert/datatable`, // GET
      view:                                 (data = {}) => `api/farm/${data.farm}/alert/${data.alert}/view`, // PUT
      view_many:                            (data = {}) => `api/farm/${data.farm}/alert/view-many`, // PUT
      view_all:                             (data = {}) => `api/farm/${data.farm}/alert/view-all`, // PUT
    },

    config: {
      handling: {
        form:                               (data = {}) => `api/farm/${data.farm}/config/handling/form`, // GET
        save:                               (data = {}) => `api/farm/${data.farm}/config`, // PUT
      },
      okr: {
        form:                               (data = {}) => `api/farm/${data.farm}/config/kpi/form`, // GET
        save:                               (data = {}) => `api/farm/${data.farm}/config`, // PUT
      }
    },

    vic: {
      form:                                 (data = {}) => `api/farm/${data.farm}/vic/form`, // GET
      save:                                 (data = {}) => `api/farm/${data.farm}/vic`, // PUT
        messages: (data = {}) => `api/farm/${data.farm}/vic/message`, // GET, PUT
      msg:                                  (data = {}) => `api/farm/${data.farm}/vic/message/${data.msg}`, // PUT, DELETE
      msg_read:                             (data = {}) => `api/farm/${data.farm}/vic/message/${data.msg}/read`, // PUT
    },

    batch: {
      datatable:                            (data = {}) => `api/farm/${data.farm}/batch/datatable`, // GET
      reorder:                              (data = {}) => `api/farm/${data.farm}/batch/${data.batch}/reorder`, // PUT
      revision:                             (data = {}) => `api/farm/${data.farm}/batch/${data.batch}/animal/datatable/revision`, // GET
      all:                                  (data = {}) => `api/farm/${data.farm}/batch`, // POST
      self:                                 (data = {}) => `api/farm/${data.farm}/batch/${data.batch}`, // PUT
      register_form:                        (data = {}) => `api/farm/${data.farm}/batch/form`, // GET
      update_form:                          (data = {}) => `api/farm/${data.farm}/batch/${data.batch}/form`, // GET
      plotlines:                            (data = {}) => `api/farm/${data.farm}/batch/${data.batch}/plot-lines`, // GET
      monitored:{
        datatable:                          (data = {}) => `api/farm/${data.farm}/batch/datatable/compiled`, // GET
      },
      accumulated:{
        graph:                              (data = {}) => `api/farm/${data.farm}/batch/${data.batch}/graph/accumulated`, // GET
        card:                               (data = {}) => `api/farm/${data.farm}/batch/${data.batch}/card/accumulated-average`, // GET
      },
      meal: {
        graph:                              (data = {}) => `api/farm/${data.farm}/batch/${data.batch}/graph/meal-panting-hours`, // GET
      },
      panting:{
        graph:                              (data = {}) => `api/farm/${data.farm}/batch/${data.batch}/graph/panting-percentage`, // GET
        card:                               (data = {}) => `api/farm/${data.farm}/batch/${data.batch}/card/panting-percentage`, // GET
      },
      diet: {
        datatable:                          (data = {}) => `api/farm/${data.farm}/batch/${data.batch}/diet/datatable`, // GET
        form:                               (data = {}) => `api/farm/${data.farm}/batch/${data.batch}/diet/form`, // GET
        update_form:                        (data = {}) => `api/farm/${data.farm}/batch/${data.batch}/diet/${data.diet}/form`, // GET
        register:                           (data = {}) => `api/farm/${data.farm}/batch/${data.batch}/diet`, // POST
        update:                             (data = {}) => `api/farm/${data.farm}/batch/${data.batch}/diet/${data.diet}`, // UPDATE
        remove:                             (data = {}) => `api/farm/${data.farm}/batch/${data.batch}/diet/${data.diet}`, // DELETE
      },
      graph: {
        production:                         (data = {}) => `api/farm/${data.farm}/batch/${data.batch}/graph/production`, // GET
        rumination:                         (data = {}) => `api/farm/${data.farm}/batch/${data.batch}/graph/rumination`, // GET
        panting:                            (data = {}) => `api/farm/${data.farm}/batch/${data.batch}/graph/panting`, // GET
        intake:                             (data = {}) => `api/farm/${data.farm}/batch/${data.batch}/graph/intake`, // GET
        intake_thi:                         (data = {}) => `api/farm/${data.farm}/batch/${data.batch}/graph/intake-thi`, // GET
        temperature_humidity:               (data = {}) => `api/farm/${data.farm}/graph/temperature-humidity`, // GET
        thi:                                (data = {}) => `api/farm/${data.farm}/graph/thi`, // GET
        real_time_temperature_humidity:     (data = {}) => `api/farm/${data.farm}/graph/real-time-temperature-humidity`, // GET
        real_time_thi:                      (data = {}) => `api/farm/${data.farm}/graph/real-time-thi`, // GET
      },
      card: {
        production_rumination:              (data = {}) => `api/farm/${data.farm}/batch/${data.batch}/card`, // GET
        panting_profile:                    (data = {}) => `api/farm/${data.farm}/batch/${data.batch}/card/panting-profile`, // GET
        rumination_profile:                 (data = {}) => `api/farm/${data.farm}/batch/${data.batch}/card/rumination-profile`, // GET
      }
    },

    role: {
      self:                                 (data = {}) => `api/farm/${data.farm}/role/${data.role}`, // PUT, DELETE
      register:                             (data = {}) => `api/farm/${data.farm}/role`, // POST
      register_form:                        (data = {}) => `api/farm/${data.farm}/role/form`, // GET
      update_form:                          (data = {}) => `api/farm/${data.farm}/role/${data.role}/form`, // GET
        permissions: (data = {}) => `api/farm/${data.farm}/role/${data.role}/permission`, // GET, POST, DELETE
      duplicate:                            (data = {}) => `api/farm/${data.farm}/role/duplicate`, // POST
      datatable:                            (data = {}) => `api/farm/${data.farm}/role/datatable`, // GET
    },

    user: {
      self:                                 (data = {}) => `api/farm/${data.farm}/user/${data.user}`, // DELETE
      datatable:                            (data = {}) => `api/farm/${data.farm}/user/datatable`, // GET
      register:                             (data = {}) => `api/farm/${data.farm}/user`, // POST
      update:                               (data = {}) => `api/farm/${data.farm}/user/${data.user}`, // PUT
      register_form:                        (data = {}) => `api/farm/${data.farm}/user/form`, // GET
      update_form:                          (data = {}) => `api/farm/${data.farm}/user/${data.user}/form`, // GET
    },

    unit: {
      datatable:                            (data = {}) => `api/farm/${data.farm}/unit/datatable`, // GET
      self:                                 (data = {}) => `api/farm/${data.farm}/unit/${data.unit}`, // PUT, DELETE
      register:                             (data = {}) => `api/farm/${data.farm}/unit`, // POST
      register_form:                        (data = {}) => `api/farm/${data.farm}/unit/form`, // GET
      update_form:                          (data = {}) => `api/farm/${data.farm}/unit/${data.unit}/form`, // GET
    },

    job: {
      export_datatable:                     (data = {}) => `api/farm/${data.farm}/job/export/datatable`, // GET
      import_datatable:                     (data = {}) => `api/farm/${data.farm}/job/import/datatable`, // GET
    },

    animal: {
      register:                             (data = {}) => `api/farm/${data.farm}/animal`, // POST
      on_date:                              (data = {}) => `api/farm/${data.farm}/animal/on-date`, // GET
      form:                                 (data = {}) => `api/farm/${data.farm}/animal/form`, // GET
      accumulated_datatable:                (data = {}) => `api/farm/${data.farm}/animal/datatable/accumulated`, // GET
      management_datatable:                 (data = {}) => `api/farm/${data.farm}/animal/datatable`, // GET
      eligible_datatable:                   (data = {}) => `api/farm/${data.farm}/animal/datatable/apt`, // GET
      ineligible_datatable:                 (data = {}) => `api/farm/${data.farm}/animal/datatable/unfit`, // GET
      self:                                 (data = {}) => `api/farm/${data.farm}/animal/${data.animal}`, // GET
      remove:                               (data = {}) => `api/farm/${data.farm}/animal/${data.animal}`, // DELETE
      update:                               (data = {}) => `api/farm/${data.farm}/animal/${data.animal}`, // PUT
      status:                               (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/new-status`, // GET
      move_form:                            (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/move/form`, // GET
      move:                                 (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/move`, // PUT
      update_form:                          (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/form`, // GET
      inapt:                                (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/inapt`, // DELETE, PUT, POST
      inapt_form:                           (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/inapt/form`, // GET
      inapt_remove_form:                    (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/inapt/remove/form`, // GET
      inapt_delete:                         (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/inapt/delete`, // DELETE
      remove_form:                          (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/delete/form`, // GET
      collar_history:                       (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/collar/datatable`, // GET
      production_quality_datatable:         (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/event/datatable/milking`, // GET
      reproduction_events_datatable:        (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/event/datatable/reproduction`, // GET
      undo_discard_form:                    (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/undo-discard/form`, // GET
      undo_discard:                         (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/undo-discard`, // POST
      pages:{
        daily:                              (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/pages/daily-monitoring`, // GET
        hourly:                             (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/pages/hourly-monitoring`, // GET
        heat_index:                         (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/pages/heat-index`, // GET
        health_index:                       (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/pages/health-index`, // GET
        breathing_index:                    (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/pages/breathing-index`, // GET
        birth_index:                        (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/pages/birth-index`, // GET
      },
      discard:{
        form:                               (data = {}) => `api/farm/${data.farm}/animal/remove/form`, // GET
      },
      birth:{
        register:                           (data = {}) => `api/farm/${data.farm}/animal/birth`, // POST
        form:                               (data = {}) => `api/farm/${data.farm}/animal/birth/form`, // GET
        update:                             (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/event/birth/${data.event}`, // PUT
        update_form:                        (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/event/birth/${data.event}/form`, // GET
      },
      health:{
        event_without_animal_form:          (data = {}) => `api/farm/${data.farm}/animal/health/form`, // GET
        event_register:                     (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/event/health`, // POST
        event_form:                         (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/event/health/form`, // GET
        event_update_form:                  (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/event/health/${data.event}/form`, // GET
        event_update:                       (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/event/health/${data.event}`, // PUT
        event_remove:                       (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/event/health/${data.event}`, // DELETE
        normalize:                          (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/event/health/normalize`, // POST
        group_events_datatable:             (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/event/datatable/health/group`, // GET
        group_detail_datatable:             (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/event/datatable/health/group/${data.group}`, // GET
      },
      cowbaby:{
        expired:                            (data = {}) => `api/farm/${data.farm}/animal/expired-cow-baby/datatable`, // GET
      },
      batch:{
        move_many:                          (data = {}) => `api/farm/${data.farm}/animal/move-many`, // PUT
        move_many_form:                     (data = {}) => `api/farm/${data.farm}/animal/move-many/form`, // GET
      },
      notes: {
        datatable:                          (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/notes/datatable`, // GET
        register_form:                      (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/notes/form`, // GET
        update_form:                        (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/notes/${data.note}/form`, // GET
        register:                           (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/notes`, // POST
        update:                             (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/notes/${data.note}`, // PUT
        remove:                             (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/notes/${data.note}`, // DELETE
        register_many_animals_form:         (data = {}) => `api/farm/${data.farm}/notes/form`, // GET
        register_many_animals:              (data = {}) => `api/farm/${data.farm}/notes`, // POST
      },
      event: {
        reproduction_event_many:            (data = {}) => `api/farm/${data.farm}/event/reproduction/many/simple`, // POST
        reproduction_event:                 (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/event/reproduction`, // POST
        reproduction_event_self:            (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/event/reproduction/${data.event}`, // PUT
        health_group:                       (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/event-group/health/${data.event_slug}/info`, // GET
        heat_group:                         (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/event-group/reproduction/${data.event_slug}/info`, // GET
      },
      weighing: {
        datatable:                          (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/weights/datatable`, // GET
        register_form_animal:               (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/weights/form`, // GET
        register_form:                      (data = {}) => `api/farm/${data.farm}/weights/form`, // GET
        register_animal:                    (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/weights`, // POST
        register:                           (data = {}) => `api/farm/${data.farm}/weights`, // POST
        update_form:                        (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/weights/${data.weight}/form`, // GET
        update:                             (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/weights/${data.weight}`, // PUT
        delete:                             (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/weights/${data.weight}`, // DELETE
        group_register_form:                (data = {}) => `api/farm/${data.farm}/weights/group/form`, // GET
        group_register:                     (data = {}) => `api/farm/${data.farm}/weights/group`, // POST
      },
    },

    milk_control: {
      induction:{
        realized:                           (data = {}) => `api/farm/${data.farm}/induced-lactation/datatable`, // GET
        marked:                             (data = {}) => `api/farm/${data.farm}/induced-lactation/marked/datatable`, // GET
      },
      production:{
        datatable:                          (data = {}) => `api/farm/${data.farm}/milk-production/datatable`, // GET
        self:                               (data = {}) => `api/farm/${data.farm}/milk-production/${data.date}`, // GET
        animal:                             (data = {}) => `api/farm/${data.farm}/milk-production/${data.date}/datatable`, // GET
        batch:                              (data = {}) => `api/farm/${data.farm}/milk-production/${data.date}/batch/datatable`, // GET
        forms:{
          production_register:              (data = {}) => `api/farm/${data.farm}/milk-production`, // POST
          production_delete:                (data = {}) => `api/farm/${data.farm}/milk-production/${data.date}`, // DELETE
          production_form:                  (data = {}) => `api/farm/${data.farm}/milk-production/form`,  // GET

          per_animal_form:                  (data = {}) => `api/farm/${data.farm}/milk-production/${data.date}/per-animal/form`, // GET
          per_animal_register:              (data = {}) => `api/farm/${data.farm}/milk-production/${data.date}/per-animal`, // POST
          per_animal_update:                (data = {}) => `api/farm/${data.farm}/milk-production/${data.date}/per-animal/${data.id}/form`, // GET
          per_animal_delete:                (data = {}) => `api/farm/${data.farm}/milk-production/${data.date}/per-animal/${data.id}`, // DELETE

          per_batch_form:                   (data = {}) => `api/farm/${data.farm}/milk-production/${data.date}/per-batch/form`, // GET
          per_batch_register:               (data = {}) => `api/farm/${data.farm}/milk-production/${data.date}/per-batch`, // POST
          per_batch_update:                 (data = {}) => `api/farm/${data.farm}/milk-production/${data.date}/per-batch/${data.id}/form`, // GET
          per_batch_delete:                 (data = {}) => `api/farm/${data.farm}/milk-production/${data.date}/per-batch/${data.id}`, // DELETE

          per_farm_form:                    (data = {}) => `api/farm/${data.farm}/milk-production/${data.date}/per-farm/form`, // GET
          per_farm_register:                (data = {}) => `api/farm/${data.farm}/milk-production/${data.date}/per-farm`, // POST
        },
      },
      analysis: {
        total:                              (data = {}) => `api/farm/${data.farm}/milk-production/graph/total`, // GET
        quality:                            (data = {}) => `api/farm/${data.farm}/milk-production/graph/quality/${data.sample}`, // GET
        quality_detail:                     (data = {}) => `api/farm/${data.farm}/milk-production/graph/quality-detail/${data.sample}`, // GET
        samples:                            (data = {}) => `api/farm/${data.farm}/milk-quality/samples`, // GET
      },
      quality: {
        register:                           (data = {}) => `api/farm/${data.farm}/milk-quality`, // POST
        register_form:                      (data = {}) => `api/farm/${data.farm}/milk-quality/form`, // GET
        on_range:                           (data = {}) => `api/farm/${data.farm}/milk-quality/on-range`, // GET
        datatable:                          (data = {}) => `api/farm/${data.farm}/milk-quality/datatable`, // GET
        update_form:                        (data = {}) => `api/farm/${data.farm}/milk-quality/${data.id}/form`, // GET
        update:                             (data = {}) => `api/farm/${data.farm}/milk-quality/${data.id}`, // PUT
        remove:                             (data = {}) => `api/farm/${data.farm}/milk-quality/${data.id}`, // DELETE
      },
      discard: {
        datatable:                          (data = {}) => `api/farm/${data.farm}/milk-production/milk-discard/datatable`, // GET
      }
    },

    nutrition: {
      diet: {
        register:                           (data = {}) => `api/farm/${data.farm}/diet`, // POST
        register_form:                      (data = {}) => `api/farm/${data.farm}/diet/form`, // GET
        list_datatable:                     (data = {}) => `api/farm/${data.farm}/diet/datatable`, // GET
        update:                             (data = {}) => `api/farm/${data.farm}/diet/${data.diet}`, // PUT
        remove:                             (data = {}) => `api/farm/${data.farm}/diet/${data.diet}`, // DELETE
        update_form:                        (data = {}) => `api/farm/${data.farm}/diet/${data.diet}/form`, // GET
      },
      ingredient: {
        register:                           (data = {}) => `api/farm/${data.farm}/ingredient`, // POST
        register_form:                      (data = {}) => `api/farm/${data.farm}/ingredient/form`, // GET
        list_datatable:                     (data = {}) => `api/farm/${data.farm}/ingredient/datatable`, // GET
        update:                             (data = {}) => `api/farm/${data.farm}/ingredient/${data.ingredient}`, // PUT
        remove:                             (data = {}) => `api/farm/${data.farm}/ingredient/${data.ingredient}`, // DELETE
        update_form:                        (data = {}) => `api/farm/${data.farm}/ingredient/${data.ingredient}/form`, // GET
      }
    },

    reports: {
      collar:                               (data = {}) => `api/farm/${data.farm}/datamart/collar`, // GET
      collar_on_farm:                       (data = {}) => `api/farm/${data.farm}/datamart/collar-revision-local`, // GET
      health:                               (data = {}) => `api/farm/${data.farm}/datamart/health`, // GET
      collar_heat:                          (data = {}) => `api/farm/${data.farm}/datamart/collar-heat`, // GET
      open_animals:                         (data = {}) => `api/farm/${data.farm}/animal/datatable/empty`, // GET
      heat_return:                          (data = {}) => `api/farm/${data.farm}/insemination-return/datatable`, // GET
    },

    collar: {
      list_datatable:                       (data = {}) => `api/farm/${data.farm}/collar/datatable`, // GET
      change:                               (data = {}) => `api/farm/${data.farm}/collar/animal/change`, // PUT
      attach_form:                          (data = {}) => `api/farm/${data.farm}/collar/animal/attach/form`, // GET
      attach_with_collar_form:              (data = {}) => `api/farm/${data.farm}/collar/${data.collar}/animal/attach/form`, // GET
      attach:                               (data = {}) => `api/farm/${data.farm}/collar/animal/attach`, // PUT
      change_form:                          (data = {}) => `api/farm/${data.farm}/collar/animal/change/form`, // GET
      cowbaby_change_form:                  (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/collar/change/form`, // GET
      cowbaby_change:                       (data = {}) => `api/farm/${data.farm}/animal/${data.animal}/collar/change`, // PUT
      misplaced_datatable:                  (data = {}) => `api/farm/${data.farm}/collar/misplaced/datatable`, // GET
      self:                                 (data = {}) => `api/farm/${data.farm}/collar/${data.collar}`, // GET
      update_attach:                        (data = {}) => `api/farm/${data.farm}/collar/${data.collar}/animal`, // PUT
      update_attach_form:                   (data = {}) => `api/farm/${data.farm}/collar/${data.collar}/animal/form`, // GET
      detach:                               (data = {}) => `api/farm/${data.farm}/collar/${data.collar}/detach`, // PUT
      detach_form:                          (data = {}) => `api/farm/${data.farm}/collar/${data.collar}/detach/form`, // GET
      list_history_datatable:               (data = {}) => `api/farm/${data.farm}/collar/${data.collar}/animal/history/datatable`, // GET
      lost_collars:                         (data = {}) => `api/farm/${data.farm}/collar/lost/datatable`, // GET
      replace:                              (data = {}) => `api/farm/${data.farm}/collar/replace/datatable`, // GET
      inventory: {
        new_datatable:                      (data = {}) => `api/farm/${data.farm}/collar/new/datatable`, // GET
        summary_datatable:                  (data = {}) => `api/farm/${data.farm}/collar/summary/datatable`, // GET
        defective_datatable:                (data = {}) => `api/farm/${data.farm}/collar/defective/datatable`, // GET
      },
      delay:{
        animal:                             (data = {}) => `api/farm/${data.farm}/collar/animal-delay/datatable`, // GET
        batch_datatable:                    (data = {}) => `api/farm/${data.farm}/collar/batch-delay/datatable`, // GET
        batch_graph:                        (data = {}) => `api/farm/${data.farm}/collar/batch-delay/graph`, // GET
      }
    },

    rearing:{
      weaning:                              (data = {}) => `api/farm/${data.farm}/animal/datatable/weaning`, // GET
    },

    health: {
      infirmary:{
        with_diagnostic:                    (data = {}) => `api/farm/${data.farm}/event/datatable/health/group/with-diagnostic`, // GET
        without_diagnostic:                 (data = {}) => `api/farm/${data.farm}/event/datatable/health/group/without-diagnostic`, // GET
        normalized:                         (data = {}) => `api/farm/${data.farm}/event/datatable/health/normalized`, // GET
      },
      baby_infirmary:{
        with_diagnostic:                    (data = {}) => `api/farm/${data.farm}/event/datatable/health/heifer/with-diagnostic`, // GET
        without_diagnostic:                 (data = {}) => `api/farm/${data.farm}/event/datatable/health/heifer/without-diagnostic`, // GET
        normalized:                         (data = {}) => `api/farm/${data.farm}/event/datatable/health/heifer/normalized`, // GET
      },
      challenge:                            (data = {}) => `api/farm/${data.farm}/event/datatable/health/challenge`, // GET
      events_history:                       (data = {}) => `api/farm/${data.farm}/event/datatable/health/group/with-filters`, // GET
    },

    heat: {
      heat_events_datatable:                (data = {}) => `api/farm/${data.farm}/event/datatable/reproduction/heat`, // GET
      heat_event_details:                   (data = {}) => `api/farm/${data.farm}/event/datatable/reproduction/heat/${data.event}`, // GET
      confirm:                              (data = {}) => `api/farm/${data.farm}/event/reproduction/${data.event}/confirm-heat`, // POST
      unconfirm:                            (data = {}) => `api/farm/${data.farm}/event/reproduction/${data.event}/unconfirm-heat`, // POST
      form:                                 (data = {}) => `api/farm/${data.farm}/event/reproduction/confirm-heat/${data.event}/form`, // GET
    },

    visual_heat:{
      form:                                 (data = {}) => `api/farm/${data.farm}/event/reproduction/visual-heat/form`, // GET
      form_without_animal:                  (data = {}) => `api/farm/${data.farm}/animal/reproduction/visual-heat/form`, // GET
      update_form:                          (data = {}) => `api/farm/${data.farm}/event/reproduction/visual-heat/${data.event}/form`, // GET
    },

    pregnancy: {
      datatable:                            (data = {}) => `api/farm/${data.farm}/event/datatable/reproduction/pregnancy-diagnostic`, // GET
      form:                                 (data = {}) => `api/farm/${data.farm}/event/reproduction/pregnancy-diagnostic/animal/${data.animal}/form`, // GET
      update_form:                          (data = {}) => `api/farm/${data.farm}/event/reproduction/pregnancy-diagnostic/${data.event}/form`, // GET
      form_without_animal:                  (data = {}) => `api/farm/${data.farm}/animal/reproduction/pregnancy-diagnostic/form`, // GET
      second:{
        datatable:                          (data = {}) => `api/farm/${data.farm}/event/datatable/reproduction/pregnancy-second-confirmation`, // GET
      },
      third:{
        datatable:                          (data = {}) => `api/farm/${data.farm}/event/datatable/reproduction/pregnancy-third-confirmation`, // GET
      },
      many:{
        first:                              (data = {}) => `api/farm/${data.farm}/event/reproduction/many/pregnancy/form`, // GET
      },
    },

    drying: {
      drying_datatable:                     (data = {}) => `api/farm/${data.farm}/event/datatable/reproduction/dry`, // GET
      graph: {
        dry_prediction:                     (data = {}) => `api/farm/${data.farm}/graph/dry-prediction`, // GET
      },
      performed: {
        datatable:                          (data = {}) => `api/farm/${data.farm}/event/datatable/reproduction/performed-dryings`, // GET
      }
    },

    calving: {
      form:                                 (data = {}) => `api/farm/${data.farm}/event/reproduction/birth/form`, // GET
      datatable:                            (data = {}) => `api/farm/${data.farm}/event/datatable/reproduction/calving`, // GET
      pre_calving_datatable:                (data = {}) => `api/farm/${data.farm}/event/datatable/reproduction/pre-calving`, // GET
      update_form:                          (data = {}) => `api/farm/${data.farm}/event/reproduction/birth/${data.event}/form`, // GET
      graph: {
        calving_prediction:                 (data = {}) => `api/farm/${data.farm}/graph/calving-prediction`, // GET
      },
      performed: {
        datatable:                          (data = {}) => `api/farm/${data.farm}/calving/datatable`, // GET
      }
    },

    pre_calving: {
      form:                                 (data = {}) => `api/farm/${data.farm}/event/reproduction/pre-delivery/form`, // GET
      update_form:                          (data = {}) => `api/farm/${data.farm}/event/reproduction/pre-delivery/${data.event}/form`, // GET
    },

    abortion: {
      form:                                 (data = {}) => `api/farm/${data.farm}/event/reproduction/abortion/form`, // GET
      form_without_animal:                  (data = {}) => `api/farm/${data.farm}/animal/reproduction/abortion/form`, // GET
      update_form:                          (data = {}) => `api/farm/${data.farm}/event/reproduction/abortion/${data.event}/form`, // GET
    },

    dry: {
      form:                                 (data = {}) => `api/farm/${data.farm}/event/reproduction/dry/form`, // GET
      form_without_animal:                  (data = {}) => `api/farm/${data.farm}/animal/reproduction/dry/form`, // GET
      update_form:                          (data = {}) => `api/farm/${data.farm}/event/reproduction/dry/${data.event}/form`, // GET
    },

    induced_lactation: {
      form:                                 (data = {}) => `api/farm/${data.farm}/event/reproduction/induced-lactation/form`, // GET
      update_form:                          (data = {}) => `api/farm/${data.farm}/event/reproduction/induced-lactation/${data.event}/form`, // GET
    },

    embryo_transfer: {
      form:                                 (data = {}) => `api/farm/${data.farm}/event/reproduction/embryo-transfer/form`, // GET
      update_form:                          (data = {}) => `api/farm/${data.farm}/event/reproduction/embryo-transfer/${data.event}/form`, // GET
    },

    weaning: {
      form:                                 (data = {}) => `api/farm/${data.farm}/event/reproduction/weaning/form`, // GET
      form_without_animal:                  (data = {}) => `api/farm/${data.farm}/animal/reproduction/weaning/form`, // GET
      many_form:                            (data = {}) => `api/farm/${data.farm}/event/reproduction/many/weaning/form`, // GET
      update_form:                          (data = {}) => `api/farm/${data.farm}/event/reproduction/weaning/${data.event}/form`, // GET
    },

    insemination: {
      form:                                 (data = {}) => `api/farm/${data.farm}/event/reproduction/insemination/form`, // GET
      update_form:                          (data = {}) => `api/farm/${data.farm}/event/reproduction/insemination/${data.event}/form`, // GET
    },

    natural_breeding: {
      form:                                 (data = {}) => `api/farm/${data.farm}/event/reproduction/natural-breeding/form`, // GET
      update_form:                          (data = {}) => `api/farm/${data.farm}/event/reproduction/natural-breeding/${data.event}/form`, // GET
    },

    handling: {
      register:                             (data = {}) => `api/farm/${data.farm}/event/handling`, // POST
      register_form:                        (data = {}) => `api/farm/${data.farm}/event/handling/form`, // GET
      list_datatable:                       (data = {}) => `api/farm/${data.farm}/event/datatable/handling`, // GET
      update_form:                          (data = {}) => `api/farm/${data.farm}/event/handling/${data.handling}/form`, // GET
      update:                               (data = {}) => `api/farm/${data.farm}/event/handling/${data.handling}`, // PUT
      remove:                               (data = {}) => `api/farm/${data.farm}/event/handling/${data.handling}`, // DELETE
    },

    protocol: {
      self:                                 (data = {}) => `api/farm/${data.farm}/protocol-schedule/${data.protocol}`, // GET
      index:                                (data = {}) => `api/farm/${data.farm}/protocol-schedule/${data.protocol}/index`, // GET
      register_form:                        (data = {}) => `api/farm/${data.farm}/protocol-schedule/form`, // GET
      register:                             (data = {}) => `api/farm/${data.farm}/protocol-schedule`, // POST
      update_form:                          (data = {}) => `api/farm/${data.farm}/protocol-schedule/${data.protocol}/form`, // GET
      update:                               (data = {}) => `api/farm/${data.farm}/protocol-schedule/${data.protocol}`, // PUT
      remove:                               (data = {}) => `api/farm/${data.farm}/protocol-schedule/${data.protocol}`, // DELETE
      list_datatable:                       (data = {}) => `api/farm/${data.farm}/protocol-schedule/datatable`, // GET
      animal_datatable:                     (data = {}) => `api/farm/${data.farm}/protocol-schedule/${data.protocol}/datatable`, // GET
      add_animal:                           (data = {}) => `api/farm/${data.farm}/protocol-schedule/${data.protocol}/animals/add`, // PUT
      add_animal_form:                      (data = {}) => `api/farm/${data.farm}/protocol-schedule/${data.protocol}/animals/add/form`, // GET
      remove_animal:                        (data = {}) => `api/farm/${data.farm}/protocol-schedule/${data.protocol}/animals/remove`, // DELETE
      register_implant_loss:                (data = {}) => `api/farm/${data.farm}/protocol-schedule/${data.protocol}/animals/implant-lost`, // POST
      register_reproduction_form:           (data = {}) => `api/farm/${data.farm}/protocol-schedule/${data.protocol}/animals/reproductive-method/form/quick`, // GET
      register_ai:                          (data = {}) => `api/farm/${data.farm}/protocol-schedule/${data.protocol}/animals/artificial-insemination`, // POST
      register_ai_form:                     (data = {}) => `api/farm/${data.farm}/protocol-schedule/${data.protocol}/animals/artificial-insemination/form`, // GET
      register_et:                          (data = {}) => `api/farm/${data.farm}/protocol-schedule/${data.protocol}/animals/embryo-transfer`, // POST
      register_et_form:                     (data = {}) => `api/farm/${data.farm}/protocol-schedule/${data.protocol}/animals/embryo-transfer/form`, // GET
    },

    weekly_services: {
      datatable:                            (data = {}) => `api/farm/${data.farm}/weekly-services/datatable`, // GET
      graph:                                (data = {}) => `api/farm/${data.farm}/weekly-services/graph`, // GET
    },

    event: {
      reproduction: {
        many_events:                        (data = {}) => `api/farm/${data.farm}/event/reproduction/many`, // POST
        many_ai_form:                       (data = {}) => `api/farm/${data.farm}/event/reproduction/many/insemination/form`, // GET
        many_et_form:                       (data = {}) => `api/farm/${data.farm}/event/reproduction/many/embryo-transfer/form`, // GET
        many_nm_form:                       (data = {}) => `api/farm/${data.farm}/event/reproduction/many/natural-breeding/form`, // GET
        many_delivery_form:                 (data = {}) => `api/farm/${data.farm}/event/reproduction/many/delivery/form`, // GET
        many_induced_lactation_form:        (data = {}) => `api/farm/${data.farm}/event/reproduction/many/induced-lactation/form`, // GET
        empty_update_form:                  (data = {}) => `api/farm/${data.farm}/event/reproduction/empty/${data.event}/form`, // GET
        heifer_register_form:               (data = {}) => `api/farm/${data.farm}/event/reproduction/heifer/form`, // GET
        heifer_update_form:                 (data = {}) => `api/farm/${data.farm}/event/reproduction/heifer/${data.event}/form`, // GET
        executed_weaning_datatable:          (data = {}) => `api/farm/${data.farm}/event/datatable/reproduction/performed-weanings`, // GET
        conception_rate: {
          inseminators:                     (data = {}) => `api/farm/${data.farm}/event/reproduction/inseminators-conception-rate`, // GET
          semens:                           (data = {}) => `api/farm/${data.farm}/event/reproduction/semens-conception-rate`, // GET
        },
        confirmation: {
          self:                             (data = {}) => `api/farm/${data.farm}/event/reproduction/${data.event}/confirm`, // POST
          self_form:                        (data = {}) => `api/farm/${data.farm}/event/reproduction/birth/${data.event}/confirm/form`, // GET
        }
      }
    },

    semen: {
      register_form:                        (data = {}) => `api/farm/${data.farm}/semen/form`, // GET
      datatable:                            (data = {}) => `api/farm/${data.farm}/semen/datatable`, // GET
      register:                             (data = {}) => `api/farm/${data.farm}/semen`, // POST
      update_form:                          (data = {}) => `api/farm/${data.farm}/semen/${data.semen}/form`, // GET
      update:                               (data = {}) => `api/farm/${data.farm}/semen/${data.semen}`, // PUT
      remove:                               (data = {}) => `api/farm/${data.farm}/semen/${data.semen}`, // DELETE
      replace_form:                         (data = {}) => `api/farm/${data.farm}/semen/replace/form`, // GET
      replace:                              (data = {}) => `api/farm/${data.farm}/semen/replace`, // PUT
      stock:{
        enable:                             (data = {}) => `api/farm/${data.farm}/semen/${data.semen}/stock/enable`, // POST
        enable_form:                        (data = {}) => `api/farm/${data.farm}/semen/${data.semen}/stock/enable/form`, // GET
        delete:                             (data = {}) => `api/farm/${data.farm}/semen/${data.semen}/stock`, // DELETE
        self:                               (data = {}) => `api/farm/${data.farm}/semen/${data.semen}`, // GET
        movement:{
          datagrid:                         (data = {}) => `api/farm/${data.farm}/semen/${data.semen}/stock/datagrid`, // GET
          delete:                           (data = {}) => `api/farm/${data.farm}/semen/${data.semen}/stock/${data.event}`, // DELETE
          incoming:                         (data = {}) => `api/farm/${data.farm}/semen/${data.semen}/stock/incoming`, // POST
          incoming_form:                    (data = {}) => `api/farm/${data.farm}/semen/${data.semen}/stock/incoming/form`, // GET
          incoming_update_form:             (data = {}) => `api/farm/${data.farm}/semen/${data.semen}/stock/incoming/${data.event}/form`, // GET
          incoming_update:                  (data = {}) => `api/farm/${data.farm}/semen/${data.semen}/stock/incoming/${data.event}`, // PUT
          outgoing:                         (data = {}) => `api/farm/${data.farm}/semen/${data.semen}/stock/outgoing`, // POST
          outgoing_form:                    (data = {}) => `api/farm/${data.farm}/semen/${data.semen}/stock/outgoing/form`, // GET
          outgoing_update_form:             (data = {}) => `api/farm/${data.farm}/semen/${data.semen}/stock/outgoing/${data.event}/form`, // GET
          outgoing_update:                  (data = {}) => `api/farm/${data.farm}/semen/${data.semen}/stock/outgoing/${data.event}`, // PUT
          correction:                       (data = {}) => `api/farm/${data.farm}/semen/${data.semen}/stock/correction`, // POST
          correction_form:                  (data = {}) => `api/farm/${data.farm}/semen/${data.semen}/stock/correction/form`, // GET
          correction_update_form:           (data = {}) => `api/farm/${data.farm}/semen/${data.semen}/stock/correction/${data.event}/form`, // GET
          correction_update:                (data = {}) => `api/farm/${data.farm}/semen/${data.semen}/stock/correction/${data.event}`, // PUT
        },
        services:{
          datagrid:                         (data = {}) => `api/farm/${data.farm}/semen/${data.semen}/services/datagrid`, // GET
        }
      }
    },

    inseminator: {
      datatable:                            (data = {}) => `api/farm/${data.farm}/inseminator/datatable`, // GET
      register_form:                        (data = {}) => `api/farm/${data.farm}/inseminator/form`, // GET
      update_form:                          (data = {}) => `api/farm/${data.farm}/inseminator/${data.inseminator}/form`, // GET
      register:                             (data = {}) => `api/farm/${data.farm}/inseminator`, // POST
        self: (data = {}) => `api/farm/${data.farm}/inseminator/${data.inseminator}`, // PUT, DELETE
    },

    mating: {
      form:                                 (data = {}) => `api/farm/${data.farm}/mating/form`, // GET
      datagrid:                             (data = {}) => `api/farm/${data.farm}/mating/datagrid`, // GET
      register:                             (data = {}) => `api/farm/${data.farm}/mating`, // POST
      update_form:                          (data = {}) => `api/farm/${data.farm}/mating/${data.mating}/form`, //GET
      update_register:                      (data = {}) => `api/farm/${data.farm}/mating/${data.mating}`, //PUT
      delete:                               (data = {}) => `api/farm/${data.farm}/mating/${data.mating}`, //DELETE
    },

    cow_comfort:{
      map:                                  (data = {}) => `api/farm/${data.farm}/c-env/map`, // GET
      thi_resource:                         (data = {}) => `api/farm/${data.farm}/thi-resource/${data.point}`, // GET
      datatable:                            (data = {}) => `api/farm/${data.farm}/c-env/datatable`, // GET
      attach_form:                          (data = {}) => `api/farm/${data.farm}/c-env/device-point/form`, // GET
      attach:                               (data = {}) => `api/farm/${data.farm}/c-env/device-point`, // POST
      change_form:                          (data = {}) => `api/farm/${data.farm}/c-env/device-point/change/form`, // GET
      change:                               (data = {}) => `api/farm/${data.farm}/c-env/device-point/change`, // PUT
      detach_form:                          (data = {}) => `api/farm/${data.farm}/c-env/device-point/detach/${data.point}/form`, // GET
      detach:                               (data = {}) => `api/farm/${data.farm}/c-env/device-point/detach/${data.point}`, // DELETE
      history:                              (data = {}) => `api/farm/${data.farm}/c-env/${data.c_env}/device-point/history/datatable`, // GET
      point:{
        list_filter:                        (data = {}) => `api/farm/${data.farm}/device-point/search-with-batches`, // GET
        datatable:                          (data = {}) => `api/farm/${data.farm}/device-point/datatable`, // GET
        register:                           (data = {}) => `api/farm/${data.farm}/device-point`, // POST
        register_form:                      (data = {}) => `api/farm/${data.farm}/device-point/form`, // GET
        update_form:                        (data = {}) => `api/farm/${data.farm}/device-point/${data.point}/form`, // GET
        update:                             (data = {}) => `api/farm/${data.farm}/device-point/${data.point}`, // PUT, PATCH
        delete:                             (data = {}) => `api/farm/${data.farm}/device-point/${data.point}`, // DELETE
      },
      thi:{
        datatable:                          (data = {}) => `api/farm/${data.farm}/device-point/data/datatable`, // GET
      }
    },
  },

  database: {
    semen: {
      datatable:                            (data = {}) => `api/semen/datatable`, // GET
      register_form:                        (data = {}) => `api/semen/form`, // GET
      register:                             (data = {}) => `api/semen`, // POST
      update_form:                          (data = {}) => `api/semen/${data.semen}/form`, // GET
      update:                               (data = {}) => `api/semen/${data.semen}`, // PUT
      remove:                               (data = {}) => `api/semen/${data.semen}`, // DELETE
    },
    semen_company: {
      datatable:                            (data = {}) => `api/semen-company/datatable`, // GET
      register_form:                        (data = {}) => `api/semen-company/form`, // GET
      register:                             (data = {}) => `api/semen-company`, // POST
      update_form:                          (data = {}) => `api/semen-company/${data.semen_company}/form`, // GET
      update:                               (data = {}) => `api/semen-company/${data.semen_company}`, // PUT
      remove:                               (data = {}) => `api/semen-company/${data.semen_company}`, // DELETE
    },
    treatment: {
      datatable:                            (data = {}) => `api/treatment/datatable`, // GET
      self:                                 (data = {}) => `api/treatment/${data.id}`, // PUT, DELETE
      register_form:                        (data = {}) => `api/treatment/form`, // GET
      register:                             (data = {}) => `api/treatment`, // POST
      update_form:                          (data = {}) => `api/treatment/${data.id}/form`, // GET
    },
    manufacturer:{
      datatable:                            (data = {}) => `api/treatment/manufacturer/datatable`, // GET
      self:                                 (data = {}) => `api/treatment/manufacturer/${data.id}`, // PUT, DELETE
      register_form:                        (data = {}) => `api/treatment/manufacturer/form`, // GET
      register:                             (data = {}) => `api/treatment/manufacturer`, // POST
      update_form:                          (data = {}) => `api/treatment/manufacturer/${data.id}/form`, // GET
    },
    help_pages:{
      datatable:                            (data = {}) => `api/help/datatable`, // GET
      register_form:                        (data = {}) => `api/help/form`, // GET
      register:                             (data = {}) => `api/help`, // POST
      update_form:                          (data = {}) => `api/help/${data.help}/form`, // GET
      self:                                 (data = {}) => `api/help/${data.help}`, // DELETE, PUT
      list:                                 (data = {}) => `api/help`, // GET
      help:                                 (data = {}) => `api/help/help-route/${data.help}`, // GET
    }
  },

  dataview: {
    contracts:{
      active_datatable:                     (data = {}) => `api/data-view/account-contracts/datatable`, // GET
      active_graph:                         (data = {}) => `api/data-view/account-contracts/graph`, // GET
      signed_datatable:                     (data = {}) => `api/data-view/with-waiting-contracts/datatable`, // GET
      signed_graph:                         (data = {}) => `api/data-view/with-waiting-contracts/graph`, // GET
      lead_time_datatable:                  (data = {}) => `api/data-view/lead-time/datatable`, // GET
      lead_time_graph:                      (data = {}) => `api/data-view/lead-time/graph`, // GET
      cowbaby_contract:                     (data = {}) => `api/data-view/cowbaby-contract-change/graph`, // GET
      cowcomfort_contract:                  (data = {}) => `api/data-view/cowcomfort-contract-change/graph`, // GET
      cowconnect_contract:                  (data = {}) => `api/data-view/cowconnect-contract-change/graph`, // GET
      ctech_contract:                       (data = {}) => `api/data-view/monitoring-contract-change/graph`, // GET
      opportunities:                        (data = {}) => `api/farm/opportunities`, // GET
    },
    benchmarking:{
      calving_interval:                     (data = {}) => `api/benchmark/calving-interval`, // GET
      conception_rate:                      (data = {}) => `api/benchmark/conception-rate`, // GET
      dim:                                  (data = {}) => `api/benchmark/dim`, // GET
      discard_rate:                         (data = {}) => `api/benchmark/discard-rate`, // GET
      pregnancy_rate:                       (data = {}) => `api/benchmark/pregnancy-rate`, // GET
      service_rate:                         (data = {}) => `api/benchmark/service-rate`, // GET
    },
    c_tech: {
      replacement:                          (data = {}) => `api/farm/${data.farm}/collar/replacement/datatable`, // GET
      technical_revision:                   (data = {}) => `api/farm/${data.farm}/collar/technical-revision/datatable`, // GET
    },
    c_com:{
      ccom_location:                        (data = {}) => `api/ccom/location`, // GET
      farm_location:                        (data = {}) => `api/farm/location`, // GET
      farm_ccom_count:                      (data = {}) => `api/farm/${data.farm}/ccom/count`, // GET
    }
  },

  cow_comfort:{
    datatable:                              (data = {}) => `api/cenv/datatable`, // GET
    self:                                   (data = {}) => `api/cenv/${data.c_env}`, // GET
    revision:                               (data = {}) => `api/cenv/${data.c_env}/revision`, // PUT
    discard:                                (data = {}) => `api/cenv/${data.c_env}/discard`, // PUT
    history:                                (data = {}) => `api/cenv/${data.c_env}/history`, // GET
    status:                                 (data = {}) => `api/cenv/${data.c_env}/status/datatable`, // GET
  },

  collar: {
    inventory_datatable:                    (data = {}) => `api/collar/datatable`, // GET
    inventory_status_datatable:             (data = {}) => `api/collar/status/datatable`, // GET
    self:                                   (data = {}) => `api/collar/${data.collar}`, // GET
    map:                                    (data = {}) => `api/collar/${data.collar}/history`, // GET
    status_datatable:                       (data = {}) => `api/collar/${data.collar}/status/datatable`, // GET
    animal_history_datatable:               (data = {}) => `api/collar/${data.collar}/animal/history/datatable`, // GET
    farm_history_datatable:                 (data = {}) => `api/collar/${data.collar}/farm/history/datatable`, // GET
    discard:                                (data = {}) => `api/collar/${data.collar}/discard`, // PUT
    revision:                               (data = {}) => `api/collar/${data.collar}/revision`, // PUT
    break:                                  (data = {}) => `api/collar/replacement/datatable`, // GET
    break_version_detail:                   (data = {}) => `api/collar/version/${data.version}/manufacture-shipment/${data.manufacture}`, // GET

    event: {
      datatable:                            (data = {}) => `api/collar/${data.collar}/event/datatable`, // GET
      normalization:                        (data = {}) => `api/collar/${data.collar}/event/normalization`, // GET
      normalization_form:                   (data = {}) => `api/collar/${data.collar}/event/create/normalization`, // GET
      replacement_request_form:             (data = {}) => `api/collar/${data.collar}/event/create/replacement-request`, // GET
      replacement_request:                  (data = {}) => `api/collar/${data.collar}/event/replacement-request`, // POST
    },
  },

  ccom: {
    inventory_datatable:                    (data = {}) => `api/ccom/datatable`, // GET
    store_many:                             (data = {}) => `api/ccom/store-many`, // POST
    update_many:                            (data = {}) => `api/ccom/update-many`, // PUT
    attach_form:                            (data = {}) => `api/ccom/attach/form`, // GET
    farm_connectivity_datatable:            (data = {}) => `api/ccom/connectivity/datatable`, // GET
    self:                                   (data = {}) => `api/ccom/${data.ccom}`, // GET
    replace:                                (data = {}) => `api/ccom/${data.ccom}/replace`, // PUT
    replace_form:                           (data = {}) => `api/ccom/${data.ccom}/replace/form`, // GET
    status_map:                             (data = {}) => `api/ccom/${data.ccom}/status/map`, // GET
    map:                                    (data = {}) => `api/farm/${data.farm}/ccom/map`, // GET
    status_datatable:                       (data = {}) => `api/ccom/${data.ccom}/status/datatable`, // GET
    summary_ccom:                           (data = {}) => `api/farm/ccom/summary/datatable`, // GET
    primary_status_datatable:               (data = {}) => `api/ccom/${data.ccom}/primary/status/datatable`, // GET
    attachment_farm_history_datatable:      (data = {}) => `api/ccom/${data.ccom}/farm/history/datatable`, // GET
    interval:{
      farm:                                 (data = {}) => `api/ccom/data-delays/by-farm/datatable`, // GET
      batch:                                (data = {}) => `api/ccom/data-delays/by-batch/datatable`, // GET
    },
    farm: {
      attach:                               (data = {}) => `api/ccom/${data.ccom}/farm/${data.farm}/attach`, // PUT
      detach:                               (data = {}) => `api/ccom/${data.ccom}/farm/${data.farm}/detach`, // PUT
      connectivity:                         (data = {}) => `api/farm/${data.farm}/ccom/connectivity`, // GET
      datatable:                            (data = {}) => `api/farm/${data.farm}/ccom/datatable`, // GET
      history_ccom:                         (data = {}) => `api/farm/${data.farm}/ccom/history`, // GET
      alert_history:                        (data = {}) => `api/farm/${data.farm}/ccom/alert-history`, // GET
      data_interval:                        (data = {}) => `api/farm/${data.farm}/collar/animal-batch-delay/datatable`, // GET
      collars_not_reporting:                (data = {}) => `api/farm/collars-not-reporting`, // GET
      graph_collars_not_reporting:          (data = {}) => `api/farm/${data.farm}/graph/collars-not-reporting`, // GET
    },
    config: {
      register:                             (data = {}) => `api/ccom/${data.ccom}/configuration`, // PUT
      form:                                 (data = {}) => `api/ccom/${data.ccom}/configuration/form`, // GET
      hexkey_register:                      (data = {}) => `api/ccom/${data.ccom}/configuration/hex-key`, // POST
      hexkey_form:                          (data = {}) => `api/ccom/${data.ccom}/configuration/hex-key/form`, // GET
      history_datatable:                    (data = {}) => `api/ccom/${data.ccom}/configuration/history/datatable`, // GET
    },
    maintenance: {
      self:                                 (data = {}) => `api/ccom/${data.ccom}/maintenance-history/${data.maintenance}`, // PUT, DELETE
      register:                             (data = {}) => `api/ccom/${data.ccom}/maintenance-history`, // POST
      form:                                 (data = {}) => `api/ccom/${data.ccom}/maintenance-history/form`, // GET
      update_form:                          (data = {}) => `api/ccom/${data.ccom}/maintenance-history/${data.maintenance}/form`, // GET
      history_datatable:                    (data = {}) => `api/ccom/${data.ccom}/maintenance-history/datatable`, // GET
    },
    support_notes:{
      register:                             (data = {}) => `api/farm/${data.farm}/tech-support-notes`, // POST
      form:                                 (data = {}) => `api/farm/${data.farm}/tech-support-notes/form`, // GET
      self:                                 (data = {}) => `api/farm/${data.farm}/tech-support-notes/${data.note}`, // PUT, DELETE
      form_update:                          (data = {}) => `api/farm/${data.farm}/tech-support-notes/${data.note}/form`, // GET
      datatable:                            (data = {}) => `api/farm/${data.farm}/tech-support-notes/datatable`, // GET
    }
  },

  company: {
    self:                                   (data = {}) => `api/company/${data.company}`, // GET
    list_datatable:                         (data = {}) => `api/company/datatable`, // GET
    register_form:                          (data = {}) => `api/company/form`, // GET
    register:                               (data = {}) => `api/company`, // POST
    update_form:                            (data = {}) => `api/company/${data.company}/form`, // GET
    update:                                 (data = {}) => `api/company/${data.company}`, // PUT
    remove:                                 (data = {}) => `api/company/${data.company}`, // DELETE
    role: {
      datatable:                            (data = {}) => `api/company/${data.company}/role/datatable`, // GET
      register:                             (data = {}) => `api/company/${data.company}/role`, // POST
      register_form:                        (data = {}) => `api/company/${data.company}/role/form`, // GET
      update_form:                          (data = {}) => `api/company/${data.company}/role/${data.role}/form`, // GET
      self:                                 (data = {}) => `api/company/${data.company}/role/${data.role}`, // PUT, DELETE
        permissions: (data = {}) => `api/company/${data.company}/role/${data.role}/permission`, // GET, POST, DELETE
      duplicate:                            (data = {}) => `api/company/${data.company}/role/duplicate`, // POST
    },
    user: {
      datatable:                            (data = {}) => `api/company/${data.company}/user/datatable`, // GET
      register:                             (data = {}) => `api/company/${data.company}/user`, // POST
      register_form:                        (data = {}) => `api/company/${data.company}/user/form`, // GET
      update:                               (data = {}) => `api/company/${data.company}/user/${data.user}`, // PUT
      update_form:                          (data = {}) => `api/company/${data.company}/user/${data.user}/form`, // GET
      remove:                               (data = {}) => `api/company/${data.company}/user/${data.user}`, // DELETE
    },
    job: {
      list:                                 (data = {}) => `api/company/${data.company}/job`, // GET
      datatable:                            (data = {}) => `api/job/datatable`, // GET
    }
  },

  system: {
    job: {
      list:                                 (data = {}) => `api/job`, // GET
    },
    role: {
      datatable:                            (data = {}) => `api/role/datatable`, // GET
      self:                                 (data = {}) => `api/role/${data.role}`, // GET
      register:                             (data = {}) => `api/role`, // DELETE, PUT
      register_form:                        (data = {}) => `api/role/form`, // GET
      update_form:                          (data = {}) => `api/role/${data.role}/form`, // GET
        permissions: (data = {}) => `api/role/${data.role}/permission`, // GET, POST, DELETE
      duplicate:                            (data = {}) => `api/role/duplicate`, // POST
      duplicate_form:                       (data = {}) => `api/role/duplicate/form`, // GET
    },
    permissions: {
      datatable:                            (data = {}) => `api/permission/datatable`, // GET
      update_form:                          (data = {}) => `api/permission/${data.permission}/form`, // GET
      update:                               (data = {}) => `api/permission/${data.permission}`, // PUT
    },
    release_notes: {
      datatable:                            (data = {}) => `api/release-notes/datatable`, // GET
      register_form:                        (data = {}) => `api/release-notes/form`, // GET
      register:                             (data = {}) => `api/release-notes`, // POST
      update_form:                          (data = {}) => `api/release-notes/${data.id}/form`, // GET
      update:                               (data = {}) => `api/release-notes/${data.id}`, // PUT
      remove:                               (data = {}) => `api/release-notes/${data.id}`, // DELETE
      last:                                 (data = {}) => `api/release-notes/last-release-note`, // GET
      view:                                 (data = {}) => `api/release-notes/${data.note}/view`, // PUT
      read_later:                           (data = {}) => `api/release-notes/${data.note}/read-later`, // PUT
      viewed_last:                          (data = {}) => `api/user/viewed-last-release-note`, // GET
    },
    transactional_emails:{
      datatable:                            (data = {}) => `api/mail/transactional/datatable`, // GET
      update_form:                          (data = {}) => `api/mail/transactional/${data.type}/form`, // GET
      update:                               (data = {}) => `api/mail/transactional/${data.type}`, // PUT
    }
  },

  module: {
    datatable:                              (data = {}) => `api/module/datatable`, // GET
      permissions: (data = {}) => `api/module/${data.module}/permission`, // GET, POST, DELETE
  },

  permissions: {
    list:                                   (data = {}) => `api/permission`, // GET
  }
}
