import React from 'react'
import CowmedChip from '#/components/Chip'
import Colors from '#/styles/Colors'

const TypeFarmContractBadge = ({ value, text }) => {
  if (!value) return <span>-</span>

  return <CowmedChip customStyle={Colors?.contract?.[value]} value={text} />
}
export default TypeFarmContractBadge
