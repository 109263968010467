import React from 'react'
import { styled } from '@mui/material/styles'
import CowmedChip from '#/components/Chip'
import Fonts from '#/styles/Fonts'
import Colors from '#/styles/Colors'

const CowmedChipStyled = styled(CowmedChip)(({ status }) => ({
  ...(status ? Colors.feedback[status] : Colors.feedback.neutral),
  fontWeight: Fonts.fontWeight.medium,
}))

const TypeFailsReportVariation = ({ status, text }) => {
  if (!status) return <span>-</span>

  return <CowmedChipStyled value={text} />
}

export default TypeFailsReportVariation
