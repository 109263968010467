import React from 'react'
import { Link } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import Translate from '#/components/Translate'
import CowmedChip from '#/components/Chip'
import Colors from '#/styles/Colors'

const CcomBadgeLink = ({ value, style, title, ...rest }) => {
  if (!value) return <span>-</span>
  let link = rest.href

  switch (rest.linkTo) {
    case 'ccom-primary':
      link = `/system/inventory/c-com/farm/${rest.slug}/primary`
      title = Translate({
        messageKey: 'access_item',
        params: {
          item: Translate({
            messageKey: 'c_com_plural',
            params: {
              item: Translate({ messageKey: 'primary_plural', lower: true }),
            },
            lower: true,
          }),
        },
      })
      break

    case 'ccom-secondary':
      link = `/system/inventory/c-com/farm/${rest.slug}/secondary`
      title = Translate({
        messageKey: 'access_item',
        params: {
          item: Translate({
            messageKey: 'c_com_plural',
            params: {
              item: Translate({ messageKey: 'secondary_plural', lower: true }),
            },
            lower: true,
          }),
        },
      })
      break

    case 'ccom-data-inverval':
      link = `/system/inventory/c-com/farm/${rest.slug}/data-inverval-by-batch`
      title = Translate({
        messageKey: 'access_item',
        params: {
          item: Translate({
            messageKey: 'data_interval_per_batch',
            lower: true,
          }),
        },
      })
      break

    default: //nothing to moo but be aware
  }

  let badgeStyle = ''
  if (rest.status === 'danger') {
    badgeStyle = Colors.feedback.danger
  }
  if (rest.status === 'warning') {
    badgeStyle = Colors.feedback.warning
  }
  if (rest.status === 'info') {
    badgeStyle = Colors.feedback.info
  }

  const target = rest.asNewTab ? { target: '_blank' } : {}

  return rest.linkTo ? (
    <Tooltip title={title} aria-label={value}>
      <Link to={link} style={style} {...target}>
        <CowmedChip value={value} customStyle={badgeStyle} width={40} />
      </Link>
    </Tooltip>
  ) : (
    <CowmedChip value={value} customStyle={badgeStyle} width={40} />
  )
}
export default CcomBadgeLink
