import * as Sentry from '@sentry/react'
import { getReleaseVersion, isDev } from './processEnvironment'

/**
 * The function `initializeSentry` sets up Sentry error tracking with specific configurations for
 * different environments and error handling.
 * https://docs.sentry.io/platforms/javascript/guides/react/
 */
const initializeSentry = () => {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: isDev ? 'development' : 'production',
    release: getReleaseVersion(),
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'TypeError: Failed to fetch',
    ],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: isDev ? 0 : 1.0,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [
          '',
          /^https:\/\/api\.(chipinside|cowmed)\.com\.br/,
        ],
      }),
    ],
  })
}

export default initializeSentry
