import React, { useEffect, useMemo, useRef, useState } from 'react'
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import Translate from '#/components/Translate'
import Colors from '#/styles/Old/Colors'
import MapWrapper from '#/components/Map/Wrapper'

const NoLocationWrapper = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: Colors.lighterGray,
  borderRadius: 2,
  color: Colors.lightgrey,
})

function MapContainer(props) {
  const { latitude, longitude, zoom, setLng, setLat, hasPin } = props

  const ref = useRef()
  const [map, setMap] = useState()
  const [marker, setMarker] = useState(null)

  const options = useMemo(
    () => ({
      center: new window.google.maps.LatLng(latitude, longitude),
      zoom,
      tilt: 0,
      mapTypeId: window.google.maps.MapTypeId.HYBRID,
      mapTypeControl: false,
      streetViewControl: false,
      keyboardShortcuts: false,
      rotateControl: false,
      styles: mapStyle,
    }),
    [latitude, longitude, zoom],
  )

  useEffect(() => {
    if (map) {
      map.setCenter(new window.google.maps.LatLng(latitude, longitude))
    }
  }, [latitude, longitude, map])

  useEffect(() => {
    if (!marker && map && hasPin) {
      updateMarker(latitude, longitude)
    }
  }, [map])

  useEffect(() => {
    if (!latitude || !longitude) {
      setMap(null)
    }
  }, [latitude, longitude])

  useEffect(() => {
    if (ref.current && !map && options) {
      setMap(new window.google.maps.Map(ref.current, options))
    }
  }, [ref, map, options])

  const updateMarker = (newLatitude, newLongitude) => {
    // Remove o marcador existente
    if (marker) {
      marker.setMap(null)
    }

    // Cria um novo marcador com as novas coordenadas
    const newMarker = new window.google.maps.Marker({
      position: new window.google.maps.LatLng(newLatitude, newLongitude),
      map,
    })

    // Define o novo marcador no estado
    setMarker(newMarker)
  }

  const handleDoubleClick = event => {
    if (map) {
      const position = event.latLng
      setLng(position.lng())
      setLat(position.lat())

      // Atualiza o marcador com as novas coordenadas
      updateMarker(position.lat(), position.lng())
    }
  }

  useEffect(() => {
    if (map) {
      map.addListener('dblclick', handleDoubleClick)
    }

    return () => {
      if (map) {
        window.google.maps.event.clearListeners(map, 'dblclick')
      }
    }
  }, [map, handleDoubleClick])

  // Atualiza o marcador sempre que as coordenadas mudam
  useEffect(() => {
    if (latitude && longitude && map) {
      updateMarker(latitude, longitude)
    }
  }, [latitude, longitude])

  return (
    <div style={{ flex: 1 }}>
      {latitude && longitude ? (
        <div ref={ref} style={{ height: '100%', width: '100%' }} />
      ) : (
        <NoLocationWrapper>
          <NotListedLocationIcon fontSize="large" />
          <Typography variant="h4">
            {Translate({ messageKey: 'no_location_registered' })}
          </Typography>
        </NoLocationWrapper>
      )}
    </div>
  )
}

export default function PinMap(props) {
  const { lat: latitude, lng: longitude } = props
  return (
    <MapWrapper>
      <MapContainer {...props} latitude={latitude} longitude={longitude} />
    </MapWrapper>
  )
}

export const mapStyle = [
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'road.local',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'transit',
    stylers: [{ visibility: 'off' }],
  },
]
