import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export default function HomeIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props

  return (
    <SvgIcon
      viewBox="0 0 24 24"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <path d="M5.38,19.24h3.1V14.07a1,1,0,0,1,.3-.73,1,1,0,0,1,.73-.3h4.14a1,1,0,0,1,1,1v5.17h3.1V9.94l-6.2-4.65L5.38,9.94Zm-2.07,0V9.94A2,2,0,0,1,3.53,9a2,2,0,0,1,.61-.72l6.2-4.65a2,2,0,0,1,1.24-.42,2,2,0,0,1,1.24.42L19,8.29a2,2,0,0,1,.61.72,2.12,2.12,0,0,1,.22.93v9.3a2,2,0,0,1-.61,1.46,2,2,0,0,1-1.46.61H13.65a1,1,0,0,1-1-1V15.11H10.54v5.16a1,1,0,0,1-.29.74,1,1,0,0,1-.74.3H5.38a2,2,0,0,1-1.46-.61A2,2,0,0,1,3.31,19.24Z" />
    </SvgIcon>
  )
}
