import { useEffect } from 'react'
import useTimeout from './timeout'

/**
 * Debounces a function run by a desirable delay
 * reseting the delay as dependencies change
 *
 * @param {function} callback - Function to be delayed
 * @param {number} delay - Delay amount in milliseconds
 * @param {object} dependencies - Array of dependencies
 *
 * @returns {undefined} Nothing
 *
 * @example
 * useDebounce(() => request(endpoint), 1000, [endpoint])
 * */
export default function useDebounce(callback, delay, dependencies) {
  const { reset, clear } = useTimeout(callback, delay)
  useEffect(reset, [...dependencies, reset])

  useEffect(clear, [])
}
