/**
 * Formata uma lista de itens com base em uma lista de chaves e um separador opcional.
 *
 * @param {object} items - Um objeto contendo os itens a serem formatados.
 * @param {Array} list - Uma lista de chaves que especifica quais itens do objeto devem ser usados na formatação.
 * @param {string} separator - (Opcional) O separador a ser usado entre os itens formatados. O padrão é ' - '.
 * @returns {string|null} - A lista de itens formatados como uma string, ou null se o objeto items for falso ou indefinido.
 */
export default function textFormatter(items, list, separator = ' - ') {
  // Verifica se o objeto items é falso ou indefinido e retorna null se for o caso.
  if (!items) return null

  // Filtra os itens com base na lista de chaves e remove valores falsos ou indefinidos.
  const filteredItems = list
    .flatMap(i => {
      if (Array.isArray(items)) {
        return items.map(item => item[i]).filter(Boolean)
      }
      return items[i]
    })
    .filter(Boolean)

  // Junta os itens formatados em uma única string usando o separador especificado.
  return filteredItems.join(separator)
}
