import Alert from '@mui/material/Alert'
import { styled } from '@mui/material/styles'

const StyledAlert = styled(Alert)({
  borderRadius: 16,
})

export const FindMyCowMessage = props => {
  const { id, value } = props
  if (!value && !value.message) return null

  return (
    <div key={id}>
      <StyledAlert severity={value.level}>{value.message}</StyledAlert>
    </div>
  )
}
