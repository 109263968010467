import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export default function LogoIcon(props = {}) {
  const { fontSize, color, style, ...rest } = props
  return (
    <SvgIcon
      viewBox="0 0 238.61 176.93"
      style={{ fontSize, color, ...style }}
      {...rest}
    >
      <defs>
        <clipPath id="clippath">
          <path d="m18.66,18.84c.44-.01.88-.02,1.3-.02,13.79,0,27,4.94,37.37,13.77-3.57,1.36-7.37,2.07-11.28,2.07-11.5,0-21.86-6.21-27.39-15.82M104.71,1.22c-12.8,4.53-24.61,12.07-34.16,21.83-.46.45-.89.93-1.34,1.41C55.95,11.91,38.37,4.82,19.95,4.82c-4.11,0-8.3.36-12.46,1.1L0,7.24l1.91,7.35c5.21,20.07,23.37,34.09,44.13,34.09,7.47,0,14.67-1.79,21.16-5.2,6.74,9.64,10.42,21.19,10.42,33.03h14.01c0-14.86-4.65-29.36-13.19-41.41.68-.75,1.39-1.5,2.11-2.23,7.23-7.39,15.99-13.28,25.53-17.17,12.9,8.53,27.64,13.65,43.07,14.96.19.71.39,1.41.58,2.1,9.96,34.56,29.77,65.23,57.29,88.7,4.54,3.88,9.31,7.55,14.21,10.98-5.93,12.06-15.37,21.98-27.16,28.51-5.67-3.28-11.59-6.2-17.63-8.72-18.63-7.76-38.38-11.69-58.7-11.69-4.43,0-8.99.2-13.54.6-7.28.64-14.52,1.81-21.55,3.45l3.2,13.65c6.37-1.5,12.97-2.57,19.57-3.13,4.15-.37,8.29-.56,12.32-.56,18.46,0,36.4,3.58,53.32,10.62,6.59,2.74,13.02,6.03,19.12,9.76l3.27,2,3.44-1.67c18.18-8.78,32.27-23.99,39.64-42.81l2.08-5.32-4.8-3.12c-6.14-3.99-12.1-8.44-17.71-13.21-25.42-21.68-43.72-50.01-52.91-81.92-.6-2.11-1.19-4.25-1.75-6.59l-1.27-5.19-5.34-.15c-15.82-.45-30.93-5.57-43.72-14.8l-2.97-2.15-3.43,1.22Z" />
        </clipPath>
        <linearGradient
          id="Gradiente_sem_nome"
          data-name="Gradiente sem nome"
          x1="-1084.57"
          y1="-136.49"
          x2="-1075.72"
          y2="-136.49"
          gradientTransform="translate(28536.57 3679.27) scale(26.31)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#73beab" />
          <stop offset="1" stopColor="#bcce00" />
        </linearGradient>
      </defs>
      <g id="Camada_1-2" data-name="Camada 1">
        <g className="path" clipPath="url(#clippath)">
          <rect
            fill="url(#Gradiente_sem_nome)"
            className="cls-1"
            x="0"
            y="0"
            width="238.63"
            height="176.92"
          />
        </g>
      </g>
    </SvgIcon>
  )
}
