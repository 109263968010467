/* prettier-ignore */
export const mapping = {
  '\/farm\/.*?\/settings':                                            'config-farm-overview',                                //'Configuração - Fazenda - Geral'
  '\/farm\/.*?\/settings\/handling':                                  'config-farm-farmManagement',                          //'Configuração - Fazenda - Gestão da Fazenda'
  '\/farm\/.*?\/settings\/okr':                                       'config-farm-okr',                                     //'Configuração - Fazenda - OKR'
  '\/farm\/.*?\/settings\/vic':                                       'config-farm-vic',                                     //'Configuração - Fazenda - Vic'
  '\/farm\/.*?\/settings\/users':                                     'config-farm-users',                                   //'Configuração - Fazenda - Usuários'
  '\/farm\/.*?\/settings\/roles':                                     'config-farm-roles',                                   //'Configuração - Fazenda - Papéis'
  '\/farm\/.*?\/settings\/units':                                     'config-farm-units',                                   //'Configuração - Fazenda - Unidades'
  '\/farm\/[\\w\\-]+\/?':                                             'dashboard',                                           //'Dashboard Fazenda'
  '\/farm\/.*?\/modules\/herd\/animals':                              'herd-animalsRegistration',                            //'Rebanho - Animal - Registro'
  '\/farm\/.*?\/modules\/herd\/animals\/.*?':                         'herd-animal-accum24h',                                //'Rebanho - Animal - Monitoramento dia'
  '\/farm\/.*?\/modules\/herd\/animals\/.*?\/hourly':                 'herd-animal-hourPerHour',                             //'Rebanho - Animal - Monitoramento hora'
  '\/farm\/.*?\/modules\/herd\/animals\/.*?\/reproduction':           'herd-animal-reproduction',                            //'Rebanho - Animal - Reprodução'
  '\/farm\/.*?\/modules\/herd\/animals\/.*?\/health':                 'herd-animal-health',                                  //'Rebanho - Animal - Saúde'
  '\/farm\/.*?\/modules\/herd\/animals\/.*?\/production':             'herd-animal-production',                              //'Rebanho - Animal - Produção'
  '\/farm\/.*?\/modules\/herd\/animals\/.*?\/collar-history':         'heard-animal-collarHistory',                          //'Rebanho - Animal - Histórico de Coleiras'
  '\/farm\/.*?\/modules\/herd\/animals\/.*?\/heat-index':             'herd-animal-heatIndex',                               //'Rebanho - Animal - Índice de Cio'
  '\/farm\/.*?\/modules\/herd\/animals\/.*?\/healt-index':            'herd-animal-healthIndex',                             //'Rebanho - Animal - Índice de Saúde'
  '\/farm\/.*?\/modules\/herd\/animals\/.*?\/delivery-index':         'herd-animal-deliveryIndex',                           //'Rebanho - Animal - Índice de Parto'
  '\/farm\/.*?\/modules\/herd\/animals\/.*?\/breathing-index':        'herd-animal-breathingIndex',                          //'Rebanho - Animal - Índice de Respiração'
  '\/farm\/.*?\/modules\/herd\/batch':                                'herd-groupRegistration',                              //'Rebanho - Lote - Registro'
  '\/farm\/.*?\/modules\/herd\/batch\/.*?\/monitoring':               'herd-batch-monitoring',                               //'Rebanho - Lote - Monitoramento do lote'
  '\/farm\/.*?\/modules\/herd\/batch\/.*?\/diet':                     'herd-batch-batchDiet',                                //'Rebanho - Lote - Dieta'
  '\/farm\/.*?\/modules\/herd\/batch\/.*?\/review':                   'herd-batch-animalsToBeReview',                        //'Rebanho - Lote - Animais pra revisão'
  '\/farm\/.*?\/modules\/herd\/manage':                               'herd-handlingBetweenGroups',                          //'Rebanho - Manejo Entre Lotes'
  '\/farm\/.*?\/modules\/reproduction\/heat':                         'reproduction-heatAlerts',                             //'Reprodução - Alertas de Cio'
  '\/farm\/.*?\/modules\/reproduction\/protocol':                     'reproduction-protocols',                              //'Reprodução - Protocolos'
  '\/farm\/.*?\/modules\/reproduction\/protocol\/.*?\/detail':        'reproduction-protocol-details',                       //'Reprodução - Detalhes do protocolo'
  '\/farm\/.*?\/modules\/reproduction\/pregnancy':                    'reproduction-pregnancyDiagnosis-confirmation1',       //'Reprodução - Diagnóstico de Prenhez - 1ª Confirmação'
  '\/farm\/.*?\/modules\/reproduction\/pregnancy\/check2':            'reproduction-pregnancyDiagnosis-confirmation2',       //'Reprodução - Diagnóstico de Prenhez - 2ª Confirmação'
  '\/farm\/.*?\/modules\/reproduction\/pregnancy\/check3':            'reproduction-pregnancyDiagnosis-confirmation3',       //'Reprodução - Diagnóstico de Prenhez - 3ª Confirmação'
  '\/farm\/.*?\/modules\/reproduction\/drying':                       'reproduction-drying-newDrying',                       //'Reprodução - Secagem - Nova Secagem'
  '\/farm\/.*?\/modules\/reproduction\/drying\/projection':           'reproduction-drying-dryingProjection',                //'Reprodução - Secagem - Projeção de Secagem'
  '\/farm\/.*?\/modules\/reproduction\/calving':                      'reproduction-calving-calvingPrediction',              //'Reprodução - Parto - Predição de Parto'
  '\/farm\/.*?\/modules\/reproduction\/calving\/performed':           'reproduction-calving-performed',                      //'Reprodução - Parto - Partos Realizados'
  '\/farm\/.*?\/modules\/reproduction\/calving\/projection':          'reproduction-calving-calvingProjection',              //'Reprodução - Parto - Projeção de Parto'
  '\/farm\/.*?\/modules\/reproduction\/pre-calving-prediction':       'reproduction-preCalvingPrediction',                   //'Reprodução - Pré-Parto - Predição de Pré-parto'
  '\/farm\/.*?\/modules\/reproduction\/bull-semen':                   'reproduction-bullSemen',                              //'Reprodução - Touro\/Semen'
  '\/farm\/.*?\/modules\/reproduction\/gynecologic':                  'reproduction-gynecologicalExamination',               //'Reprodução - Exame Ginecológico'
  '\/farm\/.*?\/modules\/reproduction\/inseminator':                  'reproduction-inseminator',                            //'Reprodução - Inseminador'
  '\/farm\/.*?\/modules\/health\/infirmary':                          'health-healthEvents',                                 //'Saúde - Eventos de Saúde'
  '\/farm\/.*?\/modules\/health\/challenge':                          'health-animalsInChallenge',                           //'Saúde - Animais em Desafio'
  '\/farm\/.*?\/modules\/health\/history':                            'health-eventsHistory',                                //'Saúde - Histórico de Eventos'
  '\/farm\/.*?\/modules\/precision-nutrition':                        'nutrition-monitoredGroups',                           //'Nutrição - Lotes Monitorados'
  '\/farm\/.*?\/modules\/precision-nutrition\/ingredient':            'nutrition-ingredientManagement',                      //'Nutrição - Gestão de Insumos'
  '\/farm\/.*?\/modules\/precision-nutrition\/diet':                  'nutrition-dietRegistration',                          //'Nutrição - Registro de dietas'
  '\/farm\/.*?\/modules\/milk-control':                               'milkControl-productionAnalysis',                      //'Controle Leiteiro - Análise de Produção'
  '\/farm\/.*?\/modules\/milk-control\/production':                   'milkControl-productRegister',                         //'Controle Leiteiro - Registro de Produção'
  '\/farm\/.*?\/modules\/milk-control\/discard':                      'milkControl-discardMilk',                             //'Controle Leiteiro - Descarte de Leite'
  '\/farm\/.*?\/modules\/milk-control\/quality':                      'milkControl-milkQuality',                             //'Controle Leiteiro - Qualidade do Leite'
  '\/farm\/.*?\/modules\/thermal-stress\/thi':                        'thermalStress-environmentalComfortAnalysis',          //'Estresse Térmico - Análise de Conforto Térmico'
  '\/farm\/.*?\/modules\/cattle-handling':                            'handlingOccurrences-handlingRegister',                //'Eventos de Manejo - Registro'
  '\/farm\/.*?\/modules\/monitoring\/animals':                        'monitoring-monitoredAnimals',                         //'Monitoramento - Animais Monitorados'
  '\/farm\/.*?\/modules\/monitoring\/c-tech':                         'monitoring-cTech-management',                         //'Monitoramento - Gestão de Coleiras'
  '\/farm\/.*?\/modules\/monitoring\/c-tech\/.*?\/map':               'monitoring-cTech-collarMapReport',                    //'Monitoramento - Coleira - Mapa de Reports'
  '\/farm\/.*?\/modules\/monitoring\/c-tech\/.*?\/history':           'monitoring-cTech-animalHistory',                      //'Monitoramento - Coleira - Histórico de Animais'
  '\/farm\/.*?\/modules\/monitoring\/c-tech\/review':                 'monitoring-cTech-review',                             //'Monitoramento - Coleiras para Revisão'
  '\/farm\/.*?\/cow-analytics':                                       'cowAnalytics',                                        //'CowAnalytics'
  '\/farm\/.*?\/cow-analytics\/herd\/reproductive-distribution':      'cowAnalytics-herd-reproductiveDistribution',          //'CowAnalytics - Rebanho - Distribuição Reprodutiva'
  '\/farm\/.*?\/cow-analytics\/services\/rate':                       'cowAnalytics-services-serviceRate',                   //'CowAnalytics - Serviços - Taxa de Serviço'
  '\/farm\/.*?\/cow-analytics\/services\/dim-first-service':          'cowAnalytics-services-dimOnFirstService',             //'CowAnalytics - Serviços - DEL no Primeiro Serviço'
  '\/farm\/.*?\/cow-analytics\/services\/weekly':                     'cowAnalytics-services-weeklyServices',                //'CowAnalytics - Serviços - Serviços Semanais'
  '\/farm\/.*?\/cow-analytics\/services\/performed':                  'cowAnalytics-services-servicesPerformed',             //'CowAnalytics - Serviços - Serviços Realizados'
  '\/farm\/.*?\/cow-analytics\/services\/cycles':                     'cowAnalytics-services-serviceCycles',                 //'CowAnalytics - Serviços - Ciclos de Serviço'
  '\/farm\/.*?\/cow-analytics\/services\/heat-alert':                 'cowAnalytics-services-heatAlerts',                    //'CowAnalytics - Serviços - Alertas de Cio'
  '\/farm\/.*?\/cow-analytics\/services\/ineligible-cows':            'cowAnalytics-services-ineligibleCows',                //'CowAnalytics - Serviços - Vacas Não-Aptas'
  '\/farm\/.*?\/cow-analytics\/heat-return':                          'cowAnalytics-heatReturn',                             //'CowAnalytics - Retorno de Cio'
  '\/farm\/.*?\/cow-analytics\/health\/occurrences':                  'cowAnalytics-health-totalOccurrences',                //'CowAnalytics - Saúde - Casos Totais'
  '\/farm\/.*?\/cow-analytics\/health\/history':                      'cowAnalytics-health-caseHistory',                     //'CowAnalytics - Saúde - Histórico de casos'
  '\/farm\/.*?\/cow-analytics\/health\/diagnosed':                    'cowAnalytics-health-diagnosedAnimals',                //'CowAnalytics - Saúde - Animais Diagnosticados'
  '\/farm\/.*?\/cow-analytics\/health\/health-alert':                 'cowAnalytics-health-healthAlerts',                    //'CowAnalytics - Saúde - Alertas de Saúde'
  '\/farm\/.*?\/cow-analytics\/no-service':                           'cowAnalytics-noService',                              //'CowAnalytics - Sem Serviço'
  '\/farm\/.*?\/cow-analytics\/eligible-cows':                        'cowAnalytics-eligibleCows',                           //'CowAnalytics - Vacas Aptas'
  '\/farm\/.*?\/cow-analytics\/conception\/rate':                     'cowAnalytics-conception-conceptionRate',              //'CowAnalytics - Prenhez - Taxa de Prenhez'
  '\/farm\/.*?\/cow-analytics\/conception\/per-inseminator':          'cowAnalytics-conception-perInseminator',              //'CowAnalytics - Prenhez - Por Inseminador'
  '\/farm\/.*?\/cow-analytics\/conception\/per-bull-semen':           'cowAnalytics-conception-perBullSemen',                //'CowAnalytics - Prenhez - Por Touro/Semen'
  '\/farm\/.*?\/cow-analytics\/conception\/per-service-type':         'cowAnalytics-conception-perServiceType',              //'CowAnalytics - Prenhez - Por Tipo de Serviço'
  '\/farm\/.*?\/cow-analytics\/conception\/per-del':                  'cowAnalytics-conception-perDaysInLactation',          //'CowAnalytics - Prenhez - Por DEL'
  '\/farm\/.*?\/cow-analytics\/discard\/rate':                        'cowAnalytics-discardOfAnimals-discardRate',           //'CowAnalytics - Animais Descartados - Taxa de Descarte'
  '\/farm\/.*?\/cow-analytics\/discard\/reason':                      'cowAnalytics-discardOfAnimals-discardReason',         //'CowAnalytics - Animais Descartados - Motivo de Descarte'
  '\/farm\/.*?\/cow-analytics\/discard\/mortality':                   'cowAnalytics-discardOfAnimals-mortality',             //'CowAnalytics - Animais Descartados - Mortalidade'
  '\/farm\/.*?\/cow-analytics\/discard\/report':                      'cowAnalytics-discardOfAnimals-markedForDiscard',      //'CowAnalytics - Animais Descartados - Marcados para Descarte'
  '\/farm\/.*?\/cow-analytics\/discard\/unfit':                       'cowAnalytics-discardOfAnimals-discardedAnimals',      //'CowAnalytics - Animais Descartados - Animais Descartados'
  '\/farm\/.*?\/modules\/notifications':                              'notifications',                                       //'Notificações'
  '\/farm\/.*?\/tools':                                               'tools',                                               //'Ferramentas'
  '\/farm\/.*?\/tools\/import':                                       'tools-dataImport',                                    //'Ferramentas - Importação de Dados'
  '\/farm\/.*?\/tools\/export':                                       'tools-dataExport'                                     //'Ferramentas - Exportação de Dados'
}
