import { createSlice } from '@reduxjs/toolkit'

export const errorSlice = createSlice({
  name: 'error',
  initialState: {
    data: null,
  },
  reducers: {
    showDialogError(state, action) {
      state.data = {
        ...action?.payload,
        eventId: action?.payload?.error_id,
      }
    },
    resetError(state) {
      state.data = null
    },
  },
})

export const { showDialogError, resetError } = errorSlice.actions

export const getError = state => state?.error?.data ?? null

export default errorSlice.reducer
