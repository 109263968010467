import React from 'react'
import Tooltip from '@mui/material/Tooltip'

import Translate from '#/components/Translate'
import Moment, { fmt } from '#/components/Moment'
import AsteriskIcon from '#/icons/Asterisk'
import Fonts from '#/styles/Fonts'
import { minutesToHours } from '#/utils'

const style = {
  text: {
    fontSize: Fonts.fontSize.S,
    fontWeight: Fonts.fontWeight.normal,
    alignItems: 'center',
    display: 'flex',
  },
}

const baseComponent = (title, children, asterisk = true) => {
  return (
    <Tooltip title={title}>
      <div style={{ gap: '5px', display: 'flex', alignItems: 'center' }}>
        {children}
        {asterisk && <AsteriskIcon style={{ fontSize: '10px' }} />}
      </div>
    </Tooltip>
  )
}

export const monitoringVerifyRecentData = ({
  timestamp,
  value,
  withMinutesToHours = true,
}) => {
  if (!timestamp) {
    return baseComponent(
      Translate({
        messageKey: 'no_data_after_hours',
        params: { hours: 48 },
      }),
    )
  }
  timestamp = Moment(timestamp).format(fmt.api)

  let text = null
  let content = null
  let asterisk = true

  let lastRead = Moment.diff('now', timestamp, 'h')

  if (lastRead >= 12 && lastRead < 48) {
    text = (
      <span>
        {Translate({
          messageKey: 'data_from_date',
          params: { date: Moment.view(timestamp) },
        })}
        .{' '}
        {Translate({
          messageKey: 'no_data_between_hours',
          params: { from: 12, to: 48 },
        })}
      </span>
    )
    content = value
  } else if (lastRead >= 48) {
    text = Translate({
      messageKey: 'no_data_after_hours',
      params: { hours: 48 },
    })
  } else {
    text = Translate({
      messageKey: 'data_from_date',
      params: { date: Moment.view(timestamp) },
    })
    content = value
    asterisk = false
  }

  if (withMinutesToHours && lastRead < 48) {
    content = (
      <span>
        {value} ({minutesToHours(value)})
      </span>
    )
  }

  return baseComponent(text, content, asterisk)
}

const MonitoringStatus = props => {
  const { value, invertColors, withMinutesToHours } = props
  let { timestamp } = props

  const content = timestamp
    ? monitoringVerifyRecentData({ timestamp, value, withMinutesToHours })
    : value

  const invertColorsStyle = invertColors ? { color: '#fff' } : null

  return <span style={{ ...style.text, ...invertColorsStyle }}>{content}</span>
}

export default MonitoringStatus
